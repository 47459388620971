import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};
export const getOperateurImmobilier = async (id) => {
  try {
    return await axios.get("setting/list/operateur_immo?idquartier=" + id, config);
  } catch (error) {
    return error;
  }
};

export const createOperateurImmobilier = async (d) => {
  try {
    const { data } = await axios.post("setting/create/operateur_immo", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getOperateurImmobiliers = async (d) => {

  try {
    const { data } = await axios.get("setting/list/operation/operateur_immo", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateOperateurImmobilier = async (d) =>
  await axios.post("setting/update/operateur_immo", d, config);

export const deleteOperateurImmobilier = async (d) =>
  await axios.post("setting/delete/operateur_immo", d, config);

