import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import LayoutClient from "./LayoutClient";

const ResetPasswordClient = () => {
    const [current, setCurrent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [flash, setFlash] = useState({ message: null, color: "" });
    const [stateForm, setStateForm] = useState({ 
      iddossier: 0,
      montant: "",
      mode: "",
      liendocument: "",
      observ: "",
      total_a_payer: "",
      montant_encaisser: "",
      reste_payer: "",
      banque: "",
      reference: "",
      deposant: "",
      emett: new Date
    });
    const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
    const titleForm = "Changement de mot de passe";
  
    const forms = [
      { label: "Ancien mot de passe", name: "total_a_payer", values: "", type: "text" },
      { label: "Nouveau mot de passe", name: "montant", values: "", type: "text" },
      { label: "Confirmer mouveau mot de passe", name: "total_encaiser", values: "", type: "text" },
    ];

    const btnStyle = [
      {value: "Enregister", type:"submit", className: "btn-success"},
      {value: "Annuler", type:"submit", className: "btn-warning"},
    ];
  
    const onChange = (e) => {
      setStateForm({ ...stateForm, [e.target.name]: e.target.value })
    };
  
    const enregistrementClient = async (d) => {
    };
  
    const onSubmit = async (data) => {
      data = { ...data, ...stateForm };
      console.log(data);
      try {
        await enregistrementClient(data);
        reset();
        setCurrent(null);
        setStateForm({ ...stateForm,
          iddossier: 0,
          montant: "",
          mode: "",
          liendocument: "",
          observ: "",
          total_a_payer: "",
          montant_encaisser: "",
          reste_payer: "",
          banque: "",
          reference: "",
          deposant: "",
          emett: ""
        });
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
    }, []);
  
  
  return (
    <LayoutClient>
      <div className="row">
          <div className="col-xs-2" />
          <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-horizontal col-xs-8 ui-sortable">
              <div className="widget-box widget-color-blue">
                  {/* Entete du cadre */}
                  <div className="widget-header ui-sortable-handle">
                  <h5 className="widget-title">{titleForm}</h5>
                  </div>

                  {/* Contenu du cadre */}
                  <div className="widget-body">
                  <div className="widget-main">
                      {flash.message !== null && <Alert color={flash.color} message={flash.message} />}            
                      {forms.map((item, index) => {
                      setValue(item.name, item.values);
                      if (Array.isArray(item?.table)) {
                          return (
                          <label key={index} className="block clearfix">
                              <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                              <select {...register(item.name)}
                              onChange={onChange} 
                              className="form-control input-xxlarge">
                              <option value="" />
                              {item.table.map((t, i) => (
                                  <option key={i} value={t.ID}>{t.LIBELLE}</option>
                              ))}
                              </select>
                          </label>
                          );
                      } else {
                          return (
                          <label key={index} className="block clearfix">
                              <span className="block">
                              <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                              <input style={{ width: item?.width }}
                                  onChange={onChange}
                                  name={item.name}
                                  type={item.type}
                                  value={item.values}
                                  disabled={item.disabled}
                                  className="form-control input-xxlarge"
                                  placeholder=""
                              />
                              </span>
                          </label>
                          );
                      }
                      })}
                  </div>
                  {/* Pied du cadre */}
                  <div className="widget-toolbox padding-8 clearfix">
                      <div className="pull-right">
                      {btnStyle.map((btn, key) => {
                          return (<input
                          key={key}
                          type={`${btn.type}`}
                          value={`${btn.value}`}
                          disabled={isLoading}
                          className={`btn ${btn.className}`}
                          style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                          onClick={ btn.value === "Annuler" && (() => {reset(); setCurrent(null)})}
                      />)                  
                      })}
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </form>
      </div>
    </LayoutClient>
  );
};

export default ResetPasswordClient;
