import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getTypelogementOperation = async () => {
  try {
    const { data } = await axios.get(
      "setting/list/type_logement_par_operation",
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const listTypelogementOperation = async (id) => {
  return await axios.post("setting/operationimmo/type_logement_par_operation", id, config);
};

export const createTypelogementOperation = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/create/type_logement_par_operation",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updateTypelogementOperation = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/update/type_logement_par_operation",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
