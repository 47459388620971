import React, { useRef, useState, useEffect } from "react";
import {
  FooterPDF,
  HeaderPDF,
} from "../../../../components/headerAndFooterOfPDF";
import Layout from "../../../../layout/Layout";
import ReactToPrint from "react-to-print";
import "./style-facture.css";
import { ficheClient } from "../../../../services/dossier";
import Loading from "../../../../components/Loading";
import Alert from "../../../../components/Alert";
import { pathUrl } from "../../../..";

const FicheClient = () => {
  const [flash, setFlash] = useState({ message: null, color: "success" });

  const infoFiche = localStorage.getItem("__info_fiche");

  const [isLoading, setIsLoading] = useState(false);


  const [fiche, setFiche] = useState(null);
  let idClient = null;
  if (infoFiche) {
    idClient = infoFiche;
  }

  setTimeout(() => {
    localStorage.removeItem("__info_fiche");
  }, 5000);
  const getFicheClient = () => {
    setIsLoading(true);
    ficheClient(idClient)
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          let dateCache = new Date()
          const source = pathUrl + data.data + "?" + dateCache.getTime();
          window.open(source, '_blank');
        } else {
          setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
        }
      })
      .catch(({ response }) => {
        setIsLoading(false);
      });
  };



  useEffect(() => {
    getFicheClient();
  }, []);

  return (
    <Layout>
      <section className="col-xs-12">
        {isLoading && <Loading isLoading={isLoading} />}
        {flash.message && <Alert message={flash.message} color={flash.color} />}
      </section>
    </Layout>
  );
};

export default FicheClient;
