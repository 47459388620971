import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Formulaire from "../../components/Formulaire";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
  createtypeLogement,
  gettypeLogement,
  updatetypeLogement,
  deletetypeLogement
} from "../../services/typeLogement.js";

const TypeLogement = () => {
  const headTable = [
    { name: "Libellé", width: "100%" },
    { name: "", width: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Libelle (*)",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination


  const queryClient = useQueryClient();
  const query = useQuery(["typeLogement"], gettypeLogement);

  const fetchTypeLogement = async () => {
    setIsLoading(true);
    gettypeLogement()
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setTableData(data);
        setFlash({ ...flash, message, color: "success" });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { mutate } = useMutation(createtypeLogement, {
    onSuccess: async ({ data, message }) => {
      // Invalidate and refetch

      setIsLoading(true);
      setFlash({ ...flash, message, color: "success" });
      gettypeLogement()
        .then((res) => {
          const { data } = res;
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setFlash({ ...flash, message: "Liste des types de logement", color: "success" });
          setIsLoading(false);
          // setValues({ ...values, libelle: "" });
        })
        .catch((err) => {
          console.log(err);
        });
      // queryClient.invalidateQueries("entites");
    },
    onSettled: ({ response: { data } }) => {
      setFlash({ ...flash, message: data.message, color: "danger" });
      // console.log(data.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updatetypeLogement, {
    onSuccess: (res) => {
      // Invalidate and refetch
      // console.log(resultat);
      setFlash({ ...flash, message: res.data.message, color: "success" });
      setIsLoading(false);
      // setValues({ ...values, libelle: "" });
      gettypeLogement()
        .then((res) => {
          const { data } = res;
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setFlash({ ...flash, message: "Liste des types de logement", color: "success" });
          setIsLoading(false);
          // setValues({ ...values, libelle: "" });
        })
        .catch((err) => {
          console.log(err);
        });

    },
    onSettled: ({ response: { data } }) => {
      setFlash({ ...flash, message: data.message, color: "danger" });
      // console.log(data.message);
    },
  });

  const post = (data) => mutate(data);
  const put = (data) => updateMutate(data);
  const onclick = (data) => {
    setCurrent(data);
  };


  const onDelete = async () => {
    if (window.confirm("Etre vous sur de vouloir supprimer le type de logement ?")) {
      const data = { id: current.ID, libelle: current.LIBELLE };
      deletetypeLogement(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          gettypeLogement()
            .then((res) => {
              const { data } = res;
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setTableData(data);
              setFlash({ ...flash, message: "Liste des types de logement", color: "success" });
              setIsLoading(false);
              // setValues({ ...values, libelle: "" });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          gettypeLogement()
            .then((res) => {
              const { data } = res;
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setTableData(data);
              setFlash({ ...flash, message: "Liste des types de logement", color: "success" });
              setIsLoading(false);
              // setValues({ ...values, libelle: "" });
            })
            .catch((err) => {
              // setIsLoading(false)
              // setIsLoading(true);
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(false);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
        });
    }
  };
  useEffect(() => {
    fetchTypeLogement();
  }, []);
  return (
    <Layout>
      <Formulaire
        message={flash?.message}
        color={flash?.color}
        current={current}
        setCurrent={setCurrent}
        cb={current ? put : post}
        forms={forms}
        title="Gestion des types d’utilisation des logements"
        isLoading={isLoading}
        onDelete={onDelete}
      />

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table
          headTable={headTable}
          title={"Liste des types d’utilisation des logements"}
        >
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              {/* <td className="MasquerColonne"></td> */}
              <td>{item.LIBELLE}</td>
              <td className="text-center" align="right">
                {/* <button onClick={() => onclick(item)}> */}
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // className="img img-fluid"
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
                {/* </button> */}
              </td>
            </tr>
          ))}
          {/* pagination */}
          {/* <Pagination onPageChange={onPageChange} totalPage={totalPage} /> */}
          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
        </Table>
      )}
    </Layout>
  );
};

export default TypeLogement;
