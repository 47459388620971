import React, { useEffect, useState } from "react";
import { editOnglet2, listeIlot, listeLot } from "../../../../services/dossier";

const TableDetailDossier = ({
  dataOnglet2,
  dossiers,
  setDataOnglet3,
  setOnglet,
  onglet,
  values,
  setValues,
  ilots,
  setIlots,
  lots,
  setLots,
  setIsLoading,
  setIsLoadingGlobal,
  setClickCrayon
}) => {
  const [flash, setFlash] = useState({ message: null, color: "success" });

  const edit = async (id) => {
    setIsLoading(true);
    console.log(id);
    // setIsLoadingGlobal(true);
    localStorage.setItem("__info_onglet3", id);

    setClickCrayon(false);

    editOnglet2(id)
      .then(({ data }) => {
        // console.log(data.data);
        localStorage.setItem("__info_facture", id);

        setDataOnglet3(data?.data);
        setClickCrayon(true);

        // appel api liste ilot
        listeIlot(data.data?.affichageDossier[0]?.IDOPERATIONIMMO)
          .then(({ data }) => {
            setIlots(data.data);
          })
          .catch();

        // appel api liste lot
        listeLot(data.data?.affichageDossier[0]?.IDILOT)
          .then(({ data }) => {
            setLots(data.data);
          })
          .catch();

        // mise a jour des données
        setValues({
          ...values,
          operation: data.data.affichageDossier[0].IDOPERATIONIMMO,
          ilot: data.data.affichageDossier[0].IDILOT,
          lot: data.data.affichageDossier[0].IDLOT,
          ncptecomptable: data.data.affichageDossier[0].NCPTECOMPTABLE,
          natcont: data.data.affichageDossier[0].IDNATURECONTRAT,
          agence: data.data.affichageDossier[0].IDSUCCURSALE,
          idclient: id,
          // idclient: data.data.affichageDossier[0].NUMDOSSIER,
        });
        setIsLoading(false);
        setIsLoadingGlobal(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoadingGlobal(false);
      });
  };
  return (
    <>
      <label className="block clearfix">
        <span className="block">
          <div>
            <table
              cellSpacing={0}
              rules="all"
              className="table-header"
              border={1}
              id="ContentPlaceHolder1_GVDOSS"
              style={{
                borderColor: "#E1E1E1",
                fontSize: "10pt",
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr
                  align="center"
                  valign="middle"
                  style={{
                    color: "#707070",
                    backgroundColor: "#F1F1F1",
                    fontWeight: "normal",
                  }}
                >
                  <th scope="col" style={{ width: 80 }}>
                    Date
                  </th>
                  <th scope="col" style={{ width: 110 }}>
                    N° de compte
                  </th>
                  <th scope="col" style={{ width: 110 }}>
                    Numéro dossier
                  </th>
                  <th scope="col" style={{ width: 80 }}>
                    Numéro lot
                  </th>
                  <th scope="col">Type de logement</th>
                  <th scope="col">Agence</th>
                  <th scope="col">Agent</th>
                  <th scope="col" style={{ width: 30 }}>
                    &nbsp;
                  </th>
                </tr>
                {dossiers?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        color: "Black",
                        backgroundColor: "#E4EFC9",
                        fontWeight: "normal",
                      }}
                    >
                      <td>{item.DATE}</td>
                      <td>{item.NCPTECOMPTABLE}</td>
                      <td>{item.NUMDOSSIER}</td>
                      <td>{item.NUMLOT}</td>
                      <td>{item.TYPELOGEMENT}</td>
                      <td>{item.AGENCE}</td>
                      <td>{item.AGENT}</td>
                      <td className="text-center" align="right">
                        <a href="#navbar">
                          <img
                            // className="img img-fluid"
                            onClick={() => {
                              edit(item.ID);
                              setOnglet({
                                ...onglet,
                                one: "",
                                two: "",
                                three: "active",
                                four: "",
                              });
                              // console.log(onglet);
                            }}
                            src={"/Catef_files/modifier.png"}
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </span>
      </label>

      {/* */}
    </>
  );
};

export default TableDetailDossier;
