import React, { useEffect, useState } from "react";
import InputFile from "../../../components/InputFile";
import { getTypeActeurs } from "../../../services/typeActeur";
import { getDocument } from "../../../services/document";
import { ajoutPieceOnglet4 } from "../../../services/dossier";
import Alert from "../../../components/Alert";

const DocDelivreClient = ({ }) => {
  const session = localStorage.getItem("__info_onglet3");
  const [flash, setFlash] = useState({ message: null, color: "success" });
  let iddossier;
  if (session) {
    iddossier = session;
  }

  const [isLoading, setIsloading] = useState(false);
  const [values, setValues] = useState({
    iddossier: iddossier,
    piece: "",
    typedoc: "",
    numordre: "",
    idacteur: "",
    idtypacteur: "",
  });

  const [grilleDocClient, setGrilleDocClient] = useState([]);

  const [comboDocClient, setComboDocClient] = useState([]);

  const onchange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangePiece = (e) => {
    setValues({ ...values, piece: e.target.files[0] });
  };

  const [acteurs, setActeurs] = useState([]);
  const fetchActeur = () => {
    getTypeActeurs().then(({ data }) => {
      setActeurs(data);
    });
  };

  const fetchTypeDoc = () => {
    getDocument().then(({ data }) => {
      // console.log(data);
      setComboDocClient(data);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    const formData = new FormData();
    formData.append("iddossier", iddossier);
    formData.append("typedoc", values.typedoc);
    formData.append("numordre", values.numordre);
    formData.append("idacteur", values.idacteur);
    formData.append("piece", values.piece);
    formData.append("idtypacteur", values.idtypacteur);
    console.log(values);
    ajoutPieceOnglet4(formData)
      .then(({ data }) => {
        console.log(data);
        setIsloading(false);
      })
      .catch(({ response }) => {
        setFlash({ message: response?.data?.message, color: "danger" });
        setIsloading(false);
      });
  };

  useEffect(() => {
    fetchActeur();
    fetchTypeDoc();
  }, []);
  return (
    <>
      {flash.message && <Alert color={flash.color} message={flash.message} />}
      <form onSubmit={onSubmit}>
        <label className="block clearfix">
          <span className="block">
            <label htmlFor="text" className="col-lg-3">
              Acteur *
            </label>
            <select
              name="idacteur"
              onChange={onchange}
              tabIndex={41}
              className="form-control input-xxlarge"
            >
              <option value=""></option>
              {acteurs?.map((item, key) => (
                <option key={key} value={item.ID}>
                  {item.LIBELLE}
                </option>
              ))}
            </select>
          </span>
        </label>
        <label className="block clearfix">
          <span className="block">
            <label htmlFor="text" className="col-lg-3">
              Type document *
            </label>
            <select
              onChange={onchange}
              name="typedoc"
              tabIndex={37}
              className="form-control input-xxlarge"
            >
              <option selected="selected" value={""} />
              {comboDocClient?.map((item, key) => {
                return (
                  <option key={key} value={item.ID}>
                    {item.LIBELLE}
                  </option>
                );
              })}
            </select>
          </span>
        </label>
        <label className="block clearfix">
          <span className="block">
            <label htmlFor="text" className="col-lg-3">
              N° Document *
            </label>
            <input
              onChange={onchange}
              value={values?.numordre}
              name="numordre"
              type="text"
              tabIndex={38}
              className="form-control input-medium text-uppercase"
              placeholder=""
            />
          </span>
        </label>

        <label className="block clearfix">
          <span className="block">
            <label htmlFor="text" className="col-lg-3">
              Document numérisé(pdf) *
            </label>
            <input
              type="file"
              accept=".pdf"
              onChange={onChangePiece}
              name={"piece"}
              className="form-control-file"
            //   tabIndex={44}
            />
          </span>
        </label>
        <label className="block clearfix">
          <span className="block">
            <label htmlFor="text" className="col-lg-3" />
            <input
              type="submit"
              className="btn btn-danger"
              value={"Télécharger"}
              tabIndex={44}
            />
          </span>
        </label>
      </form>
    </>
  );
};

export default DocDelivreClient;
