import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getSecteurByQuartier = async (id) => {
  const { data } = await axios.post("setting/list/secteur", id, config);
  return data;
};

export const createSecteur = async (d) =>
  await axios.post("setting/create/secteur", d, config);

export const updateSecteur = async (d) =>
  await axios.post("setting/update/secteur", d, config);

export const deleteSecteur = async (d) =>
  await axios.post("setting/delete/secteur", d, config);
