import React from "react";
import { SpinnerCircular } from "spinners-react";

const Loading = ({ isLoading = false }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        backgroundColor: "White",
      }}
    >
      <SpinnerCircular style={{ color: "#1B6AAA" }} enabled={isLoading} />
    </div>
  );
};

export default Loading;
