import * as React from "react";
import { Link, Outlet } from "react-router-dom";
const SidebarClient = () => {

  return (
    <div id="sidebar" className="sidebar responsive display">
      {/* Menu Tableau de bord */}
      <ul className="nav nav-list" style={{ top: 0 }}>
        <li className="active">
          <Link to="/client/accueil">
            <i className="menu-icon fa fa-tachometer blue" />
            <span className="menu-text"> Tableau de bord </span>
          </Link>
          <b className="arrow" />
        </li>
        <li className>
        <Link to={"/client/paiement-client"}>
            <i className="menu-icon fa fa-print blue" />
            <span className="menu-text">Paiement</span>
            <b className="arrow" />
        </Link>
        </li>
      </ul>
      {/* Barre de navigation */}
      <div className="sidebar-toggle sidebar-collapse" id="sidebar-collapse">
        <i
          className="ace-icon fa fa-angle-double-left"
          data-icon1="ace-icon fa fa-angle-double-left"
          data-icon2="ace-icon fa fa-angle-double-right"
        />
      </div>
      <Outlet />
    </div>
  );
};

export default SidebarClient;
