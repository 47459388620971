import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Layout from "../../layout/Layout";
import {
  soumissionDossier,
  verificationSolde,
} from "../../services/verificationSolde";

const VerificationSolde = () => {
  const [search, setSearch] = useState({ search: "" });
  const [flash, setFlash] = useState({ success: null, error: null });

  // pagination code
  let PageSize = 10;

  const [isLoading, setIsloading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };

  const [check, setCheck] = useState({});
  const [soumettreCheck, setSoumettreCheck] = useState([]);
  const onChecked = (e) => {
    e.persist();

    setCheck((check) => ({ ...check, [e.target.name]: e.target.checked }));
    // console.log(e.target.checked);
    // console.log(e.target.value);
    let datas = [];
    const find = soumettreCheck.find((item) => item.id === e.target.value);
    if (find) {
      const d = soumettreCheck.filter((item) => item.id !== e.target.value);
      setSoumettreCheck(d);
    } else {
      datas.push({ id: e.target.value });
      setSoumettreCheck([...soumettreCheck, { id: e.target.value }]);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    // console.log(soumettreCheck);
    setCheck((check) => ({ ...check, [e.target.name]: "" }));
    soumissionDossier({ dossiers: soumettreCheck })
      .then((res) => {
        // console.log(res);
        setIsloading(false);
        verificationSolde({ search: search.search })
          .then((res) => {
            // console.log(res.data);
            const d = page(1, res?.data);
            setCurrentTableData(d);
            setTableData(res.data);
            setFlash({ success: res.message });
            const coutPage = parseInt(res.data.length / PageSize);
            setTotalPage(coutPage);
            setIsloading(false);
          })
          .catch((err) => {
            // console.log(err);
          });
        setFlash({ success: res.message });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const onVerifySolde = (e) => {
    e.preventDefault();
    // console.log(e);
    const data = { search: search.search };
    setIsloading(true);
    if (e.key == "Enter") {
      verificationSolde(data)
        .then((res) => {
          // console.log(res.data);
          const d = page(1, res?.data);
          setCurrentTableData(d);
          setTableData(res.data);
          setFlash({ success: res.message });
          const coutPage = parseInt(res.data.length / PageSize);
          setTotalPage(coutPage);
          setIsloading(false);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  return (
    <Layout>
      <section className="col-xs-12">
        <div id="ContentPlaceHolder1_UpdatePanel1">
          <div className="row">
            <div className="form-horizontal col-xs-12 ui-sortable">
              <div className="widget-box widget-color-blue">
                {/* Entete du cadre */}
                <div className="widget-header ui-sortable-handle">
                  <h5 className="widget-title">Liste des clients</h5>
                  <div
                    className="navbar-form navbar-right form-search"
                  // onKeyDown={onVerifySolde}
                  >
                    <div id="">
                      <span className="input-icon">
                        <input
                          type="text"
                          value={search.search}
                          name="search"
                          tabIndex={1}
                          className="nav-search-input"
                          placeholder="Recherche ..."
                          onChange={onChangeSearch}
                          onKeyUpCapture={onVerifySolde}
                        />
                        <i className="ace-icon fa fa-search nav-search-icon" />
                        {/* <input
                          type="submit"
                          defaultValue="OK"
                          id=""
                          tabIndex={2}
                          className="btn-info btn-sm MasquerColonne"
                          style={{ borderStyle: "None" }}
                        /> */}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Contenu du cadre */}
                <div className="widget-body">
                  <div className="widget-main">
                    {flash?.success && (
                      <span
                        id="ContentPlaceHolder1_LBINFO"
                        className="alert alert-success"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {flash.success}
                      </span>
                    )}
                    <label className="block clearfix">
                      <span className="block">
                        {isLoading ? (
                          <Loading isLoading={isLoading} />
                        ) : (
                          <div>
                            <table
                              cellSpacing={0}
                              rules="all"
                              className="table-header"
                              border={1}
                              id="ContentPlaceHolder1_GV"
                              style={{
                                borderColor: "#E1E1E1",
                                fontSize: "10pt",
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <tbody>
                                <tr
                                  align="center"
                                  valign="middle"
                                  style={{
                                    color: "#707070",
                                    backgroundColor: "#F1F1F1",
                                    fontWeight: "normal",
                                  }}
                                >
                                  <th
                                    className="MasquerColonne"
                                    scope="col"
                                    style={{ width: 90 }}
                                  >
                                    Code
                                  </th>
                                  <th
                                    className="MasquerColonne"
                                    scope="col"
                                    style={{ width: 90 }}
                                  >
                                    Nature contrat
                                  </th>
                                  <th
                                    className="MasquerColonne"
                                    scope="col"
                                    style={{ width: 90 }}
                                  >
                                    Etat traitement
                                  </th>
                                  <th
                                    className="MasquerColonne"
                                    scope="col"
                                    style={{ width: 90 }}
                                  >
                                    Matricule
                                  </th>
                                  <th
                                    className="MasquerColonne"
                                    scope="col"
                                    style={{ width: 90 }}
                                  >
                                    Nom
                                  </th>
                                  <th scope="col">Client</th>
                                  <th scope="col">Contacts</th>
                                  <th scope="col">Opération</th>
                                  <th scope="col">Type logement</th>
                                  <th scope="col" style={{ width: 40 }}>
                                    Ilot
                                  </th>
                                  <th scope="col" style={{ width: 40 }}>
                                    Lot
                                  </th>
                                  <th scope="col" style={{ width: 70 }}>
                                    Superficie
                                  </th>
                                  {/* <th scope="col" style={{ width: 100 }}>
                                    Etat
                                  </th>
                                  {/* <th scope="col" style={{ width: 30 }}>
                                    &nbsp;
                                  </th> */}
                                  <th scope="col" style={{ width: 50 }}>
                                    &nbsp;
                                  </th>
                                </tr>
                                {isLoading ? (
                                  <Loading isLoading={isLoading} />
                                ) : (
                                  currentTableData?.map((item, key) => {
                                    // console.log(item);
                                    let color;
                                    if (item.ETAT === "NON SOLDE") {
                                      color = {
                                        color: "Black",
                                        backgroundColor: "#87CEFA",
                                      };
                                    } else if (item.ETAT === "SOLDE") {
                                      color = {
                                        color: "Black",
                                        backgroundColor: "#90EE90",
                                      };
                                    } else if (item.ETAT === "INCONNU") {
                                      color = {
                                        color: "Black",
                                        backgroundColor: "#FFA500",
                                      };
                                    }
                                    return (
                                      <tr key={key} style={color}>
                                        <td> {item.NOMCLIENT} </td>
                                        <td>&nbsp;</td>
                                        <td>{item.NOMPROJET}</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>{item.LOT}</td>
                                        <td>{item.SUPERFICIE}</td>
                                        <td>{item.ETAT}</td>
                                        {/* <td className="ace text-center">
                                          <input
                                            id=""
                                            type="checkbox"
                                            name={`etat${key}`}
                                            value={item.ID}
                                            onChange={onChecked}
                                          />
                                        </td> */}
                                        <td
                                          className="text-center"
                                          align="right"
                                        >
                                          {item.ETATTRAITEMENT === "OK" ? (
                                            <input
                                              type="button"
                                              disabled={true}
                                              defaultValue="Ouvrir le dossier"
                                              className="btn-danger btn text-white"
                                              style={{ borderStyle: "None" }}
                                            />
                                          ) : (
                                            <Link
                                              to="/dossier-client/dossier"
                                              onClick={() => {
                                                // console.log(item);
                                                localStorage.setItem(
                                                  "__dossier_client",
                                                  JSON.stringify({
                                                    id: item.ID,
                                                    matricule: item.CODECLIENT,
                                                    nom: item.NOM,
                                                    idnaturecontrat:
                                                      item.IDNATURECONTRAT,
                                                  })
                                                );
                                              }}
                                            >
                                              <input
                                                type="button"
                                                defaultValue="Ouvrir le dossier"
                                                className="btn-danger btn"
                                                style={{ borderStyle: "None" }}
                                              />
                                            </Link>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}

                                <Pagination
                                  onPageChange={onPageChange}
                                  totalPage={totalPage}
                                />
                              </tbody>
                            </table>
                          </div>
                        )}
                      </span>
                    </label>
                  </div>

                  {/* <div className="widget-toolbox padding-8 clearfix">
                    <div className="pull-right">
                      <input
                        type="submit"
                        disabled={isLoading ? true : false}
                        name=""
                        defaultValue="Soumettre"
                        id=""
                        tabIndex={2}
                        onClick={onSubmit}
                        className="btn btn-success"
                        style={{ borderStyle: "None", width: 120 }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VerificationSolde;
