import { Navigate, Route, Routes } from "react-router-dom";
import Pays from "../pages/Table/Pays";
import Villes from "../pages/Table/Villes";
import Communes from "../pages/Table/Communes";
import Quartiers from "../pages/Table/Quartiers";
import Secteurs from "../pages/Table/Secteurs";
import Societes from "../pages/Table/Societes";
import Entites from "../pages/Table/Entites";
import Agences from "../pages/Table/Agences";
import TypePieces from "../pages/Table/TypePieces";
import CirconscriptionFoncieres from "../pages/Table/CirconscriptionFoncieres ";
import OperateurImmobiliers from "../pages/Table/OperateurImmobiliers";
import ActeurFonciers from "../pages/Table/ActeurFonciers";
import TypeActeurs from "../pages/Table/TypeActeurs";
import RubriquePaiement from "../pages/Table/RubriquePaiement";
import NatureContrat from "../pages/Table/NatureContrat";
import Document from "../pages/Table/Document";
import DocumentTransmettre from "../pages/Table/DocumentTransmettre";
import EtapesObtentionProFonc from "../pages/Table/EtapesObtentionProFonc";
import DocEtapesObtentionProFonc from "../pages/Table/DocEtapesObtentionProFonc";
import DocumentAttributeClient from "../pages/Table/DocumentAttributeClient";
import TypeFoncier from "../pages/Table/TypeFoncier";
import OptentionTitreProprietaire from "../pages/Table/OptentionTitreProprietaire";
import TypeUtilisateurLogement from "../pages/Table/TypeUtilisateurLogement";
import OperationImmobiliers from "../pages/Table/OperationImmobiliers";
import Ilots from "../pages/Table/Ilots";
import TypeLogement from "../pages/Table/TypeLogement";
import TypeLogoementOperation from "../pages/Table/TypeLogoementOperation";
import RubriquePaiementLogement from "../pages/Table/RubriquePaiementLogement";
import Lots from "../pages/Table/Lots";
import Banque from "../pages/Table/Banque";
import ResetPassword from "../pages/Utilisateur/ResetPassword";

const TableRoute = () => (
  <Routes>
    <Route path="pays" element={<Pays />} />
    <Route path="villes" element={<Villes />} />
    <Route path="communes" element={<Communes />} />
    <Route path="quartiers" element={<Quartiers />} />
    <Route path="secteurs" element={<Secteurs />} />
    <Route path="societes" element={<Societes />} />
    <Route path="entites" element={<Entites />} />
    <Route path="agences" element={<Agences />} />
    <Route
      path="circonscription-foncieres"
      element={<CirconscriptionFoncieres />}
    />
    <Route path="type-pieces" element={<TypePieces />} />
    <Route path="operateur-immobiliers" element={<OperateurImmobiliers />} />
    <Route path="acteur-fonciers" element={<ActeurFonciers />} />
    <Route path="type-acteurs" element={<TypeActeurs />} />
    <Route path="rubrique-paiement" element={<RubriquePaiement />} />
    <Route path="naturecontrat" element={<NatureContrat />} />
    <Route path="naturecontrat" element={<NatureContrat />} />
    <Route path="document-fournir" element={<Document />} />
    <Route path="documenttransmettre" element={<DocumentTransmettre />} />
    <Route
      path="documentattributeclient"
      element={<DocumentAttributeClient />}
    />
    <Route path="typefoncier" element={<TypeFoncier />} />
    <Route path="Etapetpf" element={<EtapesObtentionProFonc />} />
    <Route path="Docetape" element={<DocEtapesObtentionProFonc />} />
    <Route
      path="optentiontitreproprietaire"
      element={<OptentionTitreProprietaire />}
    />
    <Route
      path="typeutilisateurlogement"
      element={<TypeUtilisateurLogement />}
    />
    <Route path="operationimmobiliers" element={<OperationImmobiliers />} />
    <Route path="ilots" element={<Ilots />} />
    <Route path="typelogement" element={<TypeLogement />} />
    <Route path="typelogoementoperation" element={<TypeLogoementOperation />} />
    <Route
      path="rubriquepaiementlogement"
      element={<RubriquePaiementLogement />}
    />
    <Route path="lots" element={<Lots />} />
    <Route path="banque" element={<Banque />} />

    <Route path="*" element={<Navigate to={"/error-page"} replace />} />
  </Routes>
);
export default TableRoute;
