import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

// export const getActeur = async () => {
//   try {
//     const { data } = await axios.post("setting/list/actfoncier", config);
//     return data;
//   } catch (error) {
//     return error;
//   }
// };

export const listActeurs = async (d) => {
  // return await axios.post("setting/list/actfoncier?idtactfoncier=" + id, config);
  // console.log(config)
  return await axios.post("setting/list/actfoncier", { idtactfoncier: d }, config);
  // try {
  //   const { data } = await axios.post("setting/list/actfoncier", d, config);
  //   // console.log(data);
  //   return data;
  // } catch (error) {
  //   return error;
  // }
}

export const listeacteurs = async () => {

  try {
    const { data } = await axios.get("etape/list/acteurs", config);
    return data;

  } catch (error) {
    return error;
  };
};

export const createActeur = async (d) => {
  return await axios.post("setting/create/actfoncier", d, config);
}

export const updateActeur = async (d) => {
  return await axios.post("setting/update/actfoncier", d, config);

};

export const deleteActeur = async (d) =>
  await axios.post("setting/delete/actfoncier", d, config);

