import { Route, Routes } from "react-router-dom";
import AjouterNewClient from "../pages/Acteur/AjouterNewClient";
import ResetPasswordActeur from "../pages/Acteur/ResetPassword";
import SoldeClient from "../pages/Acteur/SoldeClient";
import TableBordActeur from "../pages/Acteur/TableBordActeur";

const ActeurInterfaceRoute = () => (
  <Routes>
    <Route path="ajouter-nouveau-client" element={<AjouterNewClient />} />
    <Route path="accueil" element={<TableBordActeur />} />
    <Route path="solde-client" element={<SoldeClient />} />
    <Route path="modifier-password-acteur" element={<ResetPasswordActeur />} />
  </Routes>
);

export default ActeurInterfaceRoute;
