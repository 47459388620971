import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};
export const dossierClient = async (d) => {
  try {
    const url = `client/get/dossier-paie?idclient=${d.idclient}`
    if (d.search.trim() != "") {
      url += `&search=${d.search}`;
    }
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const detailDossierClient = async (d) => {
  try {
    const url = `client/get/detail-dossier-client?iddossier=${d.iddossier}`
    if (d.search.trim() != "") {
      url += `&search=${d.search}`;
    }
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};