import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};
export const getOperationImmobiliere = async (id) => {
    // console.log(id)

    try {
        const { data } = await axios.get("setting/list/operation_immo?idoperateurimmo=" + id, config);
        return data;
    } catch (error) {
        return error;
    }
};

export const getOperationImmobilieres = async () => {

    return await axios.get("setting/list/operation/operation_immo", config);
};

//liste des operations
export const getOperations = async () => {
    // console.log(id)
    try {
        const { data } = await axios.get("setting/list/operation_immo", config);
        return data;

    } catch (error) {
        return error;
    }
}


// creer des opérations
export const createOperationImmobiliere = async (d) => {

    return await axios.post("setting/create/operation_immo", d, config);

};

export const updateOperationImmobiliere = async (d) => {

    return await axios.post("setting/update/operimmo", d, config);

};


export const deleteOperationImmobiliere = async (d) => await axios.post("setting/delete/operimmo", d, config);

