import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getCirconscriptionFoncier = async () => {
  try {
    const { data } = await axios.get("setting/list/circonscription", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createCirconscriptionFoncier = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/create/circonscription",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updateCirconscriptionFoncier = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/update/circonscription",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteCirconscriptionFoncier = async (data) =>
  await axios.post("setting/delete/circonscription", data, config);
