import { Navigate, Route, Routes } from "react-router-dom";
import PaiementActeur from "../pages/Finances/PaiementActeur";
import PaiementClient from "../pages/Finances/PaiementClient";
import ValidationPaiementActeur from "../pages/Finances/ValidationPaiementActeur";
import ValidationPaiementClient from "../pages/Finances/ValidationPaiementClient";

const FiancesRoute = () => (
  <Routes>
    <Route path="paiement-client" element={<PaiementClient />} />
    <Route path="paiement-acteur" element={<PaiementActeur />} />
    <Route
      path="validation-paiement-client"
      element={<ValidationPaiementClient />}
    />
    <Route
      path="validation-paiement-acteur"
      element={<ValidationPaiementActeur />}
    />
    <Route path="*" element={<Navigate to={"/error-page"} replace />} />
  </Routes>
);

export default FiancesRoute;
