import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { createActeur, deleteActeur, listActeurs, updateActeur } from "../../services/acteur";
import ReactPaginate from "react-paginate";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import { getTypeActeurs } from "../../services/typeActeur";
// import { getPays } from "../../services/pays";
const ActeurFonciers = () => {
  const headTable = [
    { name: "nom" },
    { name: "localisation" },
    { name: "", with: "5%" },
  ];

  const [values, setValues] = useState({
    idtactfoncier: "",
    nom: "",
    localisation: "",
    societe: "",
    adresse: "",
    contact: "",
    cnps: "",
    cc: "",
    rc: "",
    email: "",
    autorisation: "Non",
  });

  const [current, setCurrent] = useState(null);
  const [acteurs, setActeurs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type_acteurs, setActeurFonciers] = useState([]);
  // const [pays, setPays] = useState([]);
  const [flash, setFlash] = useState({ message: null, color: "success" });

  const _getTypeActeurs = async () => {
    const { data } = await getTypeActeurs();
    setActeurFonciers(data);
  };

  // pagination code
  let PageSize = 10;
  const [currentTableData, setCurrentTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), acteurs);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onSelectActeurs = (e = false) => {
    setIsLoading(true);
    setValues({ ...values, idtactfoncier: e.target.value });
    setActeurs([]);
    listActeurs(e.target.value)
      .then((res) => {
        const { data } = res;
        const d = page(1, data.data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.data.length / PageSize);
        setTotalPage(coutPage);
        setActeurs(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(isLoading);
    if (current) {
      let data = values;
      data["id"] = current.ID;
      if (data.idtactfoncier.length === 0 || data.nom.length === 0 || data.societe.length === 0 || data.localisation.length === 0
        || data.email.length === 0 || data.autorisation.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      updateActeur(data)
        .then((response) => {
          console.log(response)
          // console.log(input);
          setFlash({ message: response.data.message, color: "success" });
          listActeurs(values.idtactfoncier)
            .then((res) => {
              console.log(res);
              // console.log("test modif and show list");
              const { data } = res;
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setActeurs(data.data);
              setIsLoading(false);
              setCurrent(null);
              // setValues({ ...values, nom: "", localisation: "", societe: "", adresse: "", contact: "", cnps: "", cc: "", rc: "", email: "", autorisation: "" });
            })
            .catch((err) => {
              console.log(err);
            });
          setValues({ ...values, nom: "", localisation: "", societe: "", adresse: "", contact: "", cnps: "", cc: "", rc: "", email: "", autorisation: "" });
        })
        .catch((response) => {
          console.log(response);
        });
    } else {
      if (values.idtactfoncier.length === 0 || values.nom.length === 0 || values.societe.length === 0 || values.localisation.length === 0
        || values.email.length === 0 || values.autorisation.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      createActeur(values)
        .then((reponse) => {
          console.log(reponse);
          setFlash({ message: reponse.data.message, color: "success" });
          setIsLoading(false);
          listActeurs(values.idtactfoncier)
            .then((res) => {
              console.log("test create and show list");
              const { data } = res;
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setActeurs(data.data);
              setIsLoading(false);
            })
            .catch(({ err }) => {
              console.log(err);
            });
          setValues({ ...values, nom: "", localisation: "", societe: "", adresse: "", contact: "", cnps: "", cc: "", rc: "", email: "", autorisation: "" });
        })
        .catch((response) => {
          setFlash({ ...flash, message: response.response.data.message, color: "danger" });
          setIsLoading(false);
        })


    }
  };

  const onclick = (data) => {
    setCurrent(data);
    const prevVal = values;
    setValues({ ...values, nom: "", localisation: "", societe: "", adresse: "", contact: "", cnps: "", cc: "", rc: "", email: "", autorisation: "" });

    setValues({
      ...values, idtactfoncier: prevVal.idtactfoncier, nom: data.NOM, localisation: data.LOCALISATION,
      societe: data.SOCIETE, adresse: data.ADRESSE, contact: data.CONTACTS, cnps: data.NCNPS, rc: data.RC, cc: data.CC, email: data.EMAIL,
    });
    // , localisation: data.LOCALISATION
  };

  const onDelete = async () => {
    if (window.confirm("Etre vous sur de vouloir supprimer?")) {
      const data = { id: current.ID, nom: current.NOM, localisation: current.LOCALISATION };
      deleteActeur(data)
        .then(({ data }) => {
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          setValues({ ...values, nom: "", localisation: "", societe: "", adresse: "", contact: "", cnps: "", cc: "", rc: "", email: "", autorisation: "" });
          listActeurs(values.idtactfoncier)
            .then((res) => {
              const { data } = res;
              const d = page(1, data.data);
              setActeurs(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          listActeurs({ id: values.idtactfoncier })
            .then((res) => {
              const { data } = res;
              const d = page(1, data);
              setActeurs(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  //delete acteurs
  // const onDelete = async () => {
  //   // console.log(current);
  //   if (window.confirm("Voulez vous supprimer?")) {
  //     const delet = { id: current.ID, libelle: current.LIBELLE };
  //     console.log(_idTactfoncier);
  //     deleteActeur(delet)
  //       .then(({ data }) => {
  //         setIsLoading(true);
  //         setFlash({ ...flash, color: "success", message: data.message });
  //         // setCommunes([]);
  //         setValues({ ...values, libelle: "" });
  //         listActeurs({ idtactfoncier: _idTactfoncier })
  //           .then((res) => {
  //             // setCurrent(null);
  //             const { data, message } = res;
  //             console.log(res);
  //             const d = page(1, data);
  //             setCurrentTableData(d);
  //             // setFlash({ message, color: "success" });
  //             const coutPage = parseInt(data.length / PageSize);
  //             setTotalPage(coutPage);
  //             setActeurs(data);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((error) => {
  //         // console.log(error.response);
  //         setIsLoading(true);

  //         // setCurrent(null);
  //         setFlash({
  //           ...flash,
  //           color: "danger",
  //           message: error.response.data.message,
  //         });
  //         setActeurs([]);
  //         setValues({ ...values, libelle: "" });
  //         listActeurs({ idtactfoncier: _idTactfoncier })
  //           .then((res) => {
  //             // console.log(res);
  //             const { data, message } = res;
  //             const d = page(1, data);
  //             setCurrentTableData(d);
  //             // setFlash({ message, color: "success" });
  //             const coutPage = parseInt(data.length / PageSize);
  //             setTotalPage(coutPage);
  //             setActeurs(data);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       });
  //   }
  // };
  // fin de la suppression
  useEffect(() => {
    _getTypeActeurs();
  }, []);

  return (
    <Layout>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <section className="col-xs-12">
            <div id="ContentPlaceHolder1_UpdatePanel1">
              <div className="row">
                <div className="col-xs-2" />
                <div className="form-horizontal col-xs-8 ui-sortable">
                  <div className="widget-box widget-color-blue">
                    {/* Entete du cadre */}
                    <div className="widget-header ui-sortable-handle">
                      <h5 className="widget-title">
                        Saisie des acteurs du foncier
                      </h5>
                    </div>
                    {/* Contenu du cadre */}
                    <div className="widget-body">
                      <div className="widget-main">
                        {flash?.message !== null && (
                          <Alert color={flash?.color} message={flash?.message} />
                        )}
                        <span
                          id="ContentPlaceHolder1_LBINFO"
                          style={{
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center",
                          }}
                        />
                        <label className="block clearfix">
                          <label htmlFor="text" className="col-lg-2">
                            Type acteur (*)
                          </label>
                          <select
                            name="idtypefoncier"
                            className="form-control input-xxlarge"
                            onChange={onSelectActeurs}
                          >
                            <option value="-1"></option>
                            {type_acteurs?.map((item, key) => (
                              <option key={key} value={item.ID}>
                                {item.LIBELLE}
                              </option>
                            ))}
                          </select>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              Nom (*)
                            </label>
                            <input
                              name="nom"
                              value={values.nom}
                              type="text"
                              id="ContentPlaceHolder1_TBNOM"
                              tabIndex={2}
                              className="form-control input-xxlarge"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              Société(*)
                            </label>
                            <input
                              name="societe"
                              value={values.societe}
                              type="text"
                              id="ContentPlaceHolder1_TBSOCIETE"
                              tabIndex={3}
                              className="form-control input-xxlarge"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              Localisation (*)
                            </label>
                            <input
                              name="localisation"
                              value={values.localisation}
                              type="text"
                              id="ContentPlaceHolder1_TBLOCALISATION"
                              tabIndex={4}
                              className="form-control input-xxlarge"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              Adresse (*)
                            </label>
                            <input
                              name="adresse"
                              value={values.adresse}
                              type="text"
                              id="ContentPlaceHolder1_TBADRESSE"
                              tabIndex={5}
                              className="form-control input-xxlarge"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              Contacts (*)
                            </label>
                            <input
                              name="contact"
                              value={values.contact}
                              type="text"
                              id="ContentPlaceHolder1_TBCONTACT"
                              tabIndex={6}
                              className="form-control input-xxlarge"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              N° CNPS (*)
                            </label>
                            <input
                              name="cnps"
                              value={values.cnps}
                              type="text"
                              id="ContentPlaceHolder1_TBCNPS"
                              tabIndex={7}
                              className="form-control input-large"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              N° CC (*)
                            </label>
                            <input
                              name="cc"
                              value={values.cc}
                              type="text"
                              id="ContentPlaceHolder1_TBCC"
                              tabIndex={8}
                              className="form-control input-large"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              N° RC (*)
                            </label>
                            <input
                              name="rc"
                              value={values.rc}
                              type="text"
                              id="ContentPlaceHolder1_TBRC"
                              tabIndex={9}
                              className="form-control input-large"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">
                              Email (*)
                            </label>
                            <input
                              name="email"
                              value={values.email}
                              type="email"
                              id="ContentPlaceHolder1_TBEMAIL"
                              tabIndex={13}
                              className="form-control input-xxlarge text-lowercase"
                              placeholder
                              onChange={handleChange}
                            />
                          </span>
                        </label>
                        <label className="block clearfix">
                          <label htmlFor="text" className="col-lg-5">
                            Autorisé à certifier le solde des clients (*)
                          </label>
                          <select
                            name="autorisation"
                            value={values.autorisation}
                            id=""
                            tabIndex={14}
                            className="form-control input-small"
                            onChange={handleChange}
                          >
                            <option selected="selected" value="Non">
                              Non
                            </option>
                            <option value="Oui">Oui</option>
                          </select>
                        </label>
                      </div>
                      {/* Pied du cadre */}
                      <div className="widget-toolbox padding-8 clearfix">
                        <div className="pull-right">
                          <input
                            type="submit"
                            value="Enregistrer"
                            className="btn btn-success"
                            disabled={isLoading ? true : false}
                            style={{
                              borderStyle: "None",
                              width: 100,
                              marginRight: 2,
                              marginBottom: 2,
                            }}
                          />

                          {current && (
                            <input
                              type="button"
                              onClick={onDelete}
                              value="Supprimer"
                              className="btn btn-danger"
                              style={{
                                borderStyle: "None",
                                width: 100,
                                marginRight: 2,
                                marginBottom: 2,
                              }}
                            />
                          )}
                          <input
                            type="button"
                            value="Annuler"
                            className="btn btn-warning"
                            style={{
                              borderStyle: "None",
                              width: 100,
                              marginBottom: 2,
                            }}
                            onClick={() => { }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table title={"Liste des acteurs fonciers"} headTable={headTable}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.NOM}</td>
              <td>{item.LOCALISATION}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}

          {/* pgination */}

          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
        </Table>
      )}
    </Layout>
  );
};

export default ActeurFonciers;
