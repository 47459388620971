import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Protected from "./ProtectedRoute";
import TableRoute from "./tableRoute";
import Dashboard from "../pages/Dashboard/Dashboard";
import Logout from "../pages/Auth/Logout";
import DossierClientRoute from "./dossierClientRoute";
import FinancesRoute from "./financesRoute";
import EditionRoute from "./editionRoute";
import ActeurInterfaceRoute from "./acteurInterfaceRoute";
import LoginActeur from "../pages/Acteur/LoginActeur";
import EtapeDossierRoute from "./etapeDossierRoute";
import LoginClient from "../pages/Client/LoginClient";
import ClientInterfaceRoute from "./clientInterfaceRoute";
import SecuriteRoute from "./securiteRoute";
import ResetPassword from "../pages/Utilisateur/ResetPassword";

const MainRoute = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/login/acteur" element={<LoginActeur />} />
    <Route path="/login/client" element={<LoginClient />} />
    <Route
      path="/*"
      element={
        <Protected>
          <Routes>
            <Route index path="/" element={<Dashboard />} />
            <Route path="/setting/*" element={<TableRoute />} />
            <Route path="/dossier-client/*" element={<DossierClientRoute />} />
            <Route path="/finances/*" element={<FinancesRoute />} />
            <Route path="/edition/*" element={<EditionRoute />} />
            <Route path="/acteur/*" element={<ActeurInterfaceRoute />} />
            <Route path="/client/*" element={<ClientInterfaceRoute />} />
            <Route path="/etape/*" element={<EtapeDossierRoute />} />
            <Route path="/securite/*" element={<SecuriteRoute />} />
            <Route path="modifier-password" element={<ResetPassword />} />

            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Navigate to={"/error-page"} replace />} />
          </Routes>
        </Protected>
      }
    />
  </Routes>
);

export default MainRoute;
