import React from "react";
import Formulaire from "../../components/Formulaire";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";

const OptentionTitreProprietaire = () => {
  const table = [{ id: 1, valeur: "" }];
  const forms = [{ name: "etape", table: [], label: "Etape" }];
  return (
    <Layout>
      {/* Formulaire */}
      <Formulaire
        forms={forms}
        title="Saisie des documents à obtenir par étapes d’obtention du titre de propriété foncière"
      />
      {/*  liste */}
      <Table headTable={[]} title={"Liste des rubriques de paiement"}></Table>
    </Layout>
  );
};

export default OptentionTitreProprietaire;
