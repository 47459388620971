import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Layout from "../../layout/Layout";
import { getPays } from "../../services/pays";
import { getStructure, updateStructure } from "../../services/service";
import { getVillesByPays } from "../../services/ville";
// import { updateSociete } from "../../services/societe";
const Societes = () => {
  const [pays, setPays] = useState([]);
  const [villes, setVilles] = useState([]);
  const [structure, setStructure] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [current, setCurrent] = useState(null);

  const [values, setValues] = useState({
    idpays: structure?.IDPAYS ?? "",
    idville: structure?.IDVILLE ?? "",
    sigle: structure?.SIGLE ?? "",
    raison: structure?.RAISONSOCIALE ?? "",
    localisation: structure?.LOCALISATION ?? "",
    adresse: structure?.ADRESSE ?? "",
    tel: structure?.TEL ?? "",
    fax: structure?.FAX ?? "",
    email: structure?.EMAIL ?? "",
    cc: structure?.CC ?? "",
    rc: structure?.RC ?? "",
    lieu: structure?.LIEUIMPOSITION ?? "",
    nr: structure?.NRECUAUTO ?? "",
    representant: structure?.REPRESENTANT ?? "",
    validite: structure?.VALIDITE ?? "",
    libellefacture: structure?.LIBELLEFACTURE ?? "",
    taux: structure?.TAUXTVA ?? "",
    delai: structure?.DELAISTRAITEMENT ?? "",
    ri: structure?.REGIMEIMPOSITION ?? "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const _getStructure = async () => {
    // setIsLoading(true);
    const { data } = await getStructure();
    // console.log(data);
    setStructure(data[0]);

    setValues({
      ...values,
      idpays: data[0]?.IDPAYS ?? "",
      idville: data[0]?.IDVILLE ?? "",
      sigle: data[0]?.SIGLE ?? "",
      raison: data[0]?.RAISONSOCIALE ?? "",
      localisation: data[0]?.LOCALISATION ?? "",
      adresse: data[0]?.ADRESSE ?? "",
      tel: data[0]?.TEL ?? "",
      fax: data[0]?.FAX ?? "",
      email: data[0]?.EMAIL ?? "",
      cc: data[0]?.CC ?? "",
      rc: data[0]?.RC ?? "",
      lieu: data[0]?.LIEUIMPOSITION ?? "",
      nr: data[0]?.NRECUAUTO ?? "",
      representant: data[0]?.REPRESENTANT ?? "",
      validite: data[0]?.VALIDITE ?? "",
      libellefacture: data[0]?.LIBELLEFACTURE ?? "",
      taux: data[0]?.TAUXTVA ?? "",
      delai: data[0]?.DELAISTRAITEMENT ?? "",
      ri: data[0]?.REGIMEIMPOSITION ?? "",
    });
    getVillesByPays({ id: data[0]?.IDPAYS })
      .then((res) => {
        const { data: v } = res;
        setVilles(v);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(data[0]);

  };

  const _getPays = async () => {
    const { data } = await getPays();
    setPays(data);
  };
  const onSelectPays = async (e) => {
    setValues({ ...values, idpays: e.target.value });
    getVillesByPays({ id: e.target.value })
      .then((res) => {
        console.log(data);
        const { data } = res;
        setVilles(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // traitement des données
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // if (current) {
    // let update = { ...values, id: structure?.ID };

    // console.log(structure);

    updateStructure(values)
      // console.log(data)
      .then((res) => {
        // console.log(res)
        setFlash({ ...flash, message: res.data.message, color: "success" });
        const { data } = res;
        setStructure(data);
        setCurrent(data)
        setValues({
          ...values,
          idpays: data.IDPAYS,
          idville: data.IDVILLE,
          sigle: data.SIGLE,
          raison: data.RAISONSOCIALE,
          localisation: data.LOCALISATION,
          adresse: data.ADRESSE,
          tel: data.TEL,
          fax: data.FAX,
          email: data.EMAIL,
          cc: data.CC,
          rc: data.RC,
          lieu: data.LIEUIMPOSITION,
          nr: data.NRECUAUTO,
          representant: data.REPRESENTANT,
          validite: data.VALIDITE,
          libellefacture: data.LIBELLEFACTURE,
          taux: data.TAUXTVA,
          delai: data.DELAISTRAITEMENT,
          ri: data.REGIMEIMPOSITION,

        })
          .catch((err) => {
            console.log(err);
          });
        setValues({ ...values, idpays: data.IDPAYS });
      })
      .catch(({ response }) => {
        setFlash({ message: response.data.message, color: "danger" });
      });
    // }
  };

  const onclick = (data) => {
    // console.log(data);
    setCurrent(data);
    setValues({
      ...values,
      idpays: data?.IDPAYS,
      idville: data?.IDVILLE,
      sigle: data?.SIGLE,
      raison: data?.RAISONSOCIALE,
      localisation: data?.LOCALISATION,
      adresse: data?.ADRESSE,
      tel: data?.TEL,
      fax: data?.FAX,
      email: data?.EMAIL,
      cc: data?.CC,
      rc: data?.RC,
      lieu: data?.LIEUIMPOSITION,
      nr: data?.NRECUAUTO,
      representant: data?.REPRESENTANT,
      validite: data?.VALIDITE,
      libellefacture: data?.LIBELLEFACTURE,
      taux: data?.TAUXTVA,
      delai: data?.DELAISTRAITEMENT,
      ri: data?.REGIMEIMPOSITION,
    });
  }
  useEffect(() => {
    _getPays();
    _getStructure();
  }, []);
  return (
    <Layout>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <section className="col-xs-12">
            <Loading isLoading={isLoading} />
            <div className="row">
              <div className="col-xs-2" />
              <div className="form-horizontal col-xs-8 ui-sortable">
                <div className="widget-box widget-color-blue">
                  {/* Entete du cadre */}
                  <div className="widget-header ui-sortable-handle">
                    <h5 className="widget-title">Saisie de la structure</h5>
                  </div>
                  {/* Contenu du cadre */}
                  <div className="widget-body">
                    <div className="widget-main">
                      <span
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                        }}
                      />
                      <label className="block clearfix">
                        <label htmlFor="text" className="col-lg-3">
                          Pays
                        </label>
                        <select
                          name="idpays"
                          value={values.idpays}
                          defaultChecked={values?.idpays}
                          onChange={onSelectPays}
                          tabIndex={1}
                          className="form-control input-xxlarge"
                        >
                          <option value=""></option>
                          {pays?.map((item, key) => (
                            <option key={key} value={item.ID}>
                              {item.NOM}
                            </option>
                          ))}
                        </select>
                      </label>
                      <label className="block clearfix">
                        <label htmlFor="text" className="col-lg-3">
                          Ville
                        </label>
                        <select
                          name="idville"
                          value={values.idville}
                          defaultChecked={values?.idville}
                          onChange={handleChange}
                          tabIndex={2}
                          className="form-control input-xxlarge"
                        >
                          <option value=""></option>
                          {villes?.map((item, key) => (
                            <option key={key} value={item.ID}>
                              {item.LIBELLE}
                            </option>
                          ))}
                        </select>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Sigle
                          </label>
                          <input
                            name="sigle"
                            type="text"
                            value={values.sigle}
                            onChange={handleChange}
                            id=""
                            tabIndex={3}
                            className="form-control input-medium"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Raison sociale
                          </label>
                          <input
                            name="raison"
                            type="text"
                            value={values.raison}
                            onChange={handleChange}
                            id=""
                            tabIndex={4}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Localisation
                          </label>
                          <input
                            name="localisation"
                            type="text"
                            value={values.localisation}
                            onChange={handleChange}
                            id=""
                            tabIndex={5}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Adresse
                          </label>
                          <input
                            name="adresse"
                            type="text"
                            value={values.adresse}
                            onChange={handleChange}
                            tabIndex={6}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Télephone
                          </label>
                          <input
                            name="tel"
                            type="text"
                            value={values.tel}
                            onChange={handleChange}
                            tabIndex={7}
                            className="form-control input-large"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Fax
                          </label>
                          <input
                            name="fax"
                            type="text"
                            value={values.fax}
                            onChange={handleChange}
                            tabIndex={8}
                            className="form-control input-large"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            E-mail
                          </label>
                          <input
                            name="email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            tabIndex={9}
                            className="form-control input-large"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Compte contribuable
                          </label>
                          <input
                            name="cc"
                            type="text"
                            value={values.cc}
                            onChange={handleChange}
                            tabIndex={10}
                            className="form-control input-xlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Registre de commerce
                          </label>
                          <input
                            name="rc"
                            type="text"
                            value={values.rc}
                            onChange={handleChange}
                            tabIndex={11}
                            className="form-control input-xlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Lieu imposition
                          </label>
                          <input
                            name="lieu"
                            type="text"
                            value={values.lieu}
                            onChange={handleChange}
                            tabIndex={12}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Régime imposition
                          </label>
                          <input
                            name="ri"
                            type="text"
                            value={values.ri}
                            onChange={handleChange}
                            tabIndex={13}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Representant
                          </label>
                          <input
                            name="representant"
                            type="text"
                            value={values.representant}
                            onChange={handleChange}
                            tabIndex={14}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Désignation facture
                          </label>
                          <textarea
                            name="libellefacture"
                            value={values.libellefacture}
                            onChange={handleChange}
                            rows={2}
                            cols={20}
                            id="ContentPlaceHolder1_TBLIBELLE"
                            tabIndex={15}
                            className="form-control input-xxlarge"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Taux tva
                          </label>
                          <input
                            name="taux"
                            type="text"
                            value={values.taux}
                            onChange={handleChange}
                            tabIndex={16}
                            className="form-control input-small text-center"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">
                            Délais traitement (Jour)
                          </label>
                          <input
                            name="delai"
                            type="text"
                            value={values.delai}
                            onChange={handleChange}
                            tabIndex={17}
                            className="form-control input-small text-center"
                            placeholder
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <label htmlFor="text" className="col-lg-3">
                          N° Reçu
                        </label>
                        <select
                          onChange={handleChange}
                          name="nr"
                          defaultChecked={values.nr}
                          tabIndex={18}
                          className="form-control input-medium"
                        >
                          <option value="Automatique">Automatique</option>
                          <option value="Manuel">Manuel</option>
                        </select>
                      </label>
                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-1">
                            Logo
                          </label>
                          <input
                            type="file"
                            name="logo"
                            id="ContentPlaceHolder1_FUPLOAD"
                            tabIndex={19}
                            placeholder="Saisir le sigle ici"
                          />
                        </span>
                      </label>
                      <label className="block clearfix">
                        <span className="block"></span>
                      </label>
                    </div>
                    {/* Pied du cadre */}
                    <div className="widget-toolbox padding-8 clearfix">
                      <div className="pull-right">
                        <input
                          // onClick={() => onclick(values.data)}
                          type="submit"
                          value="Valider"
                          tabIndex={20}
                          className="btn btn-success"
                          style={{ borderStyle: "None", width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </Layout>
  );
};

export default Societes;
