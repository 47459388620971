import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getStructure = async () => {
  try {
    const { data } = await axios.get("setting/list/structure", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateStructure = async (d) => {

  try {
    const { data } = await axios.post("setting/update/structure", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

