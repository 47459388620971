import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getCommuneByVille } from "../../services/commune";
import { getPays } from "../../services/pays";
import { getQuartierByCommune } from "../../services/quartier";
import {
  createSecteur,
  getSecteurByQuartier,
  updateSecteur,
  deleteSecteur
} from "../../services/secteur";
import { getVillesByPays } from "../../services/ville";
import ReactPaginate from "react-paginate";
import { SpinnerCircular } from "spinners-react";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
const Secteurs = () => {
  const headTable = [
    { name: "Libellé", width: "95%" },
    { name: "", with: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const [communes, setCommunes] = useState([]);
  const [quartiers, setQuartiers] = useState([]);
  const [pays, setPays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [villes, setVilles] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [flash, setFlash] = useState({ message: null, color: "success" });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [values, setValues] = useState({
    idpays: "",
    idville: "",
    idcommune: "",
    libelle: "",
    idquartier: "",
  });
  const _getPays = async () => {
    const { data } = await getPays();
    setPays(data);
  };
  // pagination code
  let PageSize = 10;

  const [currentTableData, setCurrentTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), secteurs);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onSelectPays = async (e) => {
    setIsLoading(true);
    setValues({ ...values, idpays: e.target.value });
    setCurrentTableData([]);
    setVilles([]);
    setCommunes([]);
    setQuartiers([]);
    getVillesByPays({ id: e.target.value })
      .then((res) => {
        const { data } = res;
        setVilles(data);
        setIsLoading(false);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectVille = (e) => {
    setIsLoading(true);
    setValues({ ...values, idville: e.target.value });
    setCommunes([]);
    setQuartiers([]);
    getCommuneByVille({ idville: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setCommunes(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectCommune = (e) => {
    setIsLoading(true);
    setValues({ ...values, idcommune: e.target.value });
    setQuartiers([]);
    getQuartierByCommune({ idcommune: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setQuartiers(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectQuartier = (e) => {
    setIsLoading(true);
    setValues({ ...values, idquartier: e.target.value });
    setSecteurs([]);
    getSecteurByQuartier({ idquartier: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data, message } = res;
        setFlash({ message, color: "success" });
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setSecteurs(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (current) {
      // update
      let data = values;
      data["id"] = current.ID;
      if (data.idpays.length === 0 || data.idville.length === 0 || data.idcommune.length === 0 || data.idquartier.length === 0 || data.libelle.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      // console.log(data);
      updateSecteur(data)
        .then((res) => {
          setFlash({ message: res.data.message, color: "success" });
          getSecteurByQuartier({ idquartier: current.IDQUARTIER })
            .then((res) => {
              // console.log(res);
              const { data } = res;
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setSecteurs(data);
              setIsLoading(false);
              setCurrent(null);
              setValues({ ...values, libelle: "" });
              // for (const value in values) {
              //   if (Object.hasOwnProperty.call(values, value)) {
              //     values[value] = "";
              //     setValues({ values });
              //   }
              // }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(({ response }) => {
          setFlash({ message: response.data.message, color: "danger" });
        });
    } else {
      //  create
      // console.log(values);
      if (values.idpays.length === 0 || values.idville.length === 0 || values.idcommune.length === 0 || values.idquartier.length === 0 || values.libelle.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      createSecteur(values)
        .then((res) => {
          // console.log(res);
          setFlash({ message: res.data.message, color: "success" });
          getSecteurByQuartier({ idquartier: values.idquartier })
            .then((res) => {
              // console.log(res);
              const { data } = res;
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setSecteurs(data);
              setIsLoading(false);
              //
              setValues({ ...values, libelle: "" });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(({ response }) => {
          setFlash({ message: response.data.message, color: "danger" });
        });
    }
  };

  const [_idQuartier, setIdQuartier] = useState(null);
  const onclick = (data) => {
    // console.log(data);
    setCurrent(data);
    setIdQuartier(data.IDQUARTIER);
    setValues({ ...values, libelle: data.LIBELLE });
  };


  //delete de secteur
  const onDelete = async () => {
    // console.log(current);
    if (window.confirm("Voulez vous supprimer? ")) {
      const delet = { id: current.ID, libelle: current.LIBELLE };
      console.log(_idQuartier);
      deleteSecteur(delet)
        .then(({ data }) => {
          setIsLoading(true);
          setFlash({ ...flash, color: "success", message: data.message });
          // setCommunes([]);
          setCurrent(null);
          setValues({ ...values, libelle: "" });
          getSecteurByQuartier({ idquartier: _idQuartier })
            .then((res) => {
              // setCurrent(null);
              const { data, message } = res;
              console.log(res);
              const d = page(1, data);
              setCurrentTableData(d);
              // setFlash({ message, color: "success" });
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setSecteurs(data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);

          // setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          setSecteurs([]);
          setValues({ ...values, libelle: "" });
          getSecteurByQuartier({ idquartier: _idQuartier })
            .then((res) => {
              // console.log(res);
              const { data, message } = res;
              const d = page(1, data);
              setCurrentTableData(d);
              // setFlash({ message, color: "success" });
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setSecteurs(data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  //fin de delete de secteur
  useEffect(() => {
    _getPays();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Saisie des secteurs</h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Pays (*)
                    </label>
                    <select
                      name="idpays"
                      className="form-control input-xxlarge"
                      onChange={onSelectPays}
                    >
                      <option value="-1"></option>
                      {pays?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.NOM}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Ville (*)
                    </label>
                    <select
                      name="idville"
                      className="form-control input-xxlarge"
                      onChange={onSelectVille}
                    >
                      <option value="-1"></option>
                      {villes?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Commune (*)
                    </label>
                    <select
                      name="idcommune"
                      className="form-control input-xxlarge"
                      onChange={onSelectCommune}
                    >
                      <option value="-1"></option>
                      {communes?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Quartier (*)
                    </label>
                    <select
                      name="idquartier"
                      className="form-control input-xxlarge"
                      onChange={onSelectQuartier}
                    >
                      <option value="-1"></option>
                      {quartiers?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Secteur (*)
                    </label>
                    <input
                      value={values.libelle}
                      onChange={handleChange}
                      name="libelle"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Enregistrer"
                      className="btn btn-success"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        onClick={onDelete}
                        value="Supprimer"
                        className="btn btn-danger"
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => {
                        setCurrent(null);
                        setValues({ ...values, libelle: "" });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table title={"Liste des secteurs"} headTable={headTable}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.LIBELLE}</td>
              <td className="text-center" align="right">
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
              </td>
            </tr>
          ))}
          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
        </Table>
      )}
    </Layout>
  );
};

export default Secteurs;
