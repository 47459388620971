
import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const listlot = async (id) => {

    // if (id != null) {
    //     return await axios.get("setting/list/lot?search =" + id, config);
    // }
    return await axios.get("setting/list/lot?idilot=" + id, config);

};

export const Searchelistlot = async (id) => {
    return await axios.get("setting/list/lot?idilot=" + id, config);

};

export const createlot = async (d) => {
    return await axios.post("setting/create/lot", d, config);
};

export const updatelot = async (d) => {
    return await axios.post("setting/update/lot", d, config);
};

export const deletelot = async (d) => {
    return await axios.post("setting/delete/lot", d, config);

};