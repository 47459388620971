import React, { useState } from "react";
import { useEffect } from "react";
// import { get } from "react-hook-form";
import Loading from "../../components/Loading";
import Layout from "../../layout/Layout";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import { getListeEtape, createEtapeDossier, getListeEtapeDossier, listeEtapeDossier, deleteEtapeDossier } from "../../services/etapeDossier";
// import { listActeurs } from "../../services/acteur";
import { listeacteurs } from "../../services/acteur";
import ReactPaginate from "react-paginate";
// import Alert from "../../components/Alert";
const CreateEtape = () => {
  const headTable = [
    { name: "Dossier", width: "40%" },
    { name: "Etape", width: "20" },
    { name: "Type Acteurs", width: "20" },
    { name: "Acteurs", width: "20" },
    { name: "", width: "5%" },
  ];
  const [etapedossier, setEtapeDossier] = useState([]);
  const [current, setCurrent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dossier, setDossier] = useState([]);
  const [etape, setEtape] = useState([]);
  // const [typeActeurs, setTypeActeurs] = useState([]);
  const [acteurs, setListeActeurs] = useState([]);
  const [values, setValues] = useState({
    typeacteur: current?.TYPEACTEUR ?? "Operateurs",
    idacteur: current?.ACTEUR ?? "",
    idetape: current?.IDETAPE ?? "",
    iddossier: current?.IDDOSSIER ?? "",
  });

  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [search, setSearch] = useState({ search: "" });

  // pagination code
  // pagination code
  let PageSize = 10;
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), etapedossier);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };
  const onRecherche = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      setIsLoading(true);
      setDossier([]);
      getListeEtapeDossier({ search: search.search }).then(({ data }) => {
        setFlash({ message: "Recherche Dossier effectuée avec succès", color: "success" });
        setDossier(data.data);
        setIsLoading(false);
      });
    }
  };

  const onclick = async (item) => {
    console.log(item);
    setCurrent(item);

  }

  const onDelete = async () => {
    if (window.confirm("Etes vous sur de vouloir supprimer?")) {
      const data = { id: current.ID, iddossier: current.IDDOSSIER };
      deleteEtapeDossier(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setFlash({ ...flash, message: data.message });
          listeEtapeDossier(current.IDDOSSIER).then(({ data }) => {
            setCurrent(null);
            setEtapeDossier(data.data);
            setIsLoading(false);
            const d = page(1, data.data);
            setCurrentTableData(d);
            const coutPage = parseInt(data.data.length / PageSize);
            setTotalPage(coutPage);
          });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
        });
    }
  }

  const onChangeDossier = (e) => {
    setIsLoading(true);
    setEtape([]);
    setValues({ ...values, [e.target.name]: e.target.value });
    getListeEtape(e.target.value).then(({ data }) => {
      setEtape(data.data);
      setIsLoading(false);
    });
    listeEtapeDossier(e.target.value).then(({ data }) => {
      setEtapeDossier(data.data);
      setIsLoading(false);
      const d = page(1, data.data);
      setCurrentTableData(d);
      const coutPage = parseInt(data.data.length / PageSize);
      setTotalPage(coutPage);
    });
  };

  const fetchListeDossier = () => {
    getListeEtapeDossier().then(({ data }) => {
      setDossier(data.data);
    });
  };


  // afficher la liste de acteur en function de type acteur

  const fecthlisteacteur = async () => {
    setIsLoading(true);
    listeacteurs()
      .then(({ data }) => {
        setListeActeurs(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.iddossier.length == 0 || values.idetape.length == 0 || values.idacteur.length == 0 || values.typeacteur == 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      return false;
    }
    createEtapeDossier(values)
      .then((response) => {
        // console.log(response);
        setFlash({ message: response.message, color: "success" });
        listeEtapeDossier(values.iddossier).then(({ data }) => {
          setEtapeDossier(data.data);
          setCurrent(null);
          setIsLoading(false);
          const d = page(1, data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
        });
        setIsLoading(false);
        setValues({ ...values, idetape: "", idacteur: "", typeacteur: "Operateurs" });
      })
      .catch(({ response }) => {
        setFlash({ message: response.message, color: "danger" });
      });
  };
  useEffect(() => {
    // fetchListeDossier();
    // fetchListeEtape();
    fecthlisteacteur();
    // _fetchlisteD();
    // fetchActeurs();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Etape dossier</h5>
                <div className="navbar-form navbar-right form-search">
                  <div id="ContentPlaceHolder1_Panel1">
                    <span className="input-icon">
                      <input
                        type="text"
                        value={search.search}
                        name="search"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                        onChange={onChangeSearch}
                        onKeyUpCapture={onRecherche}
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <input
                        type="submit"
                        name="ctl00$ContentPlaceHolder1$BTRECH"
                        value="OK"
                        id="ContentPlaceHolder1_BTRECH"
                        tabIndex={2}
                        className="btn-info btn-sm MasquerColonne"
                        style={{ borderStyle: "None" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Dossiers
                    </label>
                    <select
                      name="iddossier"
                      onChange={onChangeDossier}
                      tabIndex={3}
                      className="form-control input-xxlarge"
                    >
                      <option value={""} />
                      {dossier?.map((item, i) => (
                        <option key={i} value={item.ID}>{item.LIBELLE}</option>
                      ))}
                    </select>
                  </label>

                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Etapes
                    </label>
                    <select
                      name="idetape"
                      onChange={onChange}
                      tabIndex={4}
                      className="form-control input-xxlarge"
                    >
                      <option value={""} />
                      {etape?.map((item, i) => (
                        <option value={item.ID}>{item.LIBELLE}</option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Type Acteurs
                    </label>
                    <select
                      onChange={onChange}
                      value={values.typeacteur}
                      name="typeacteur"
                      tabIndex={5}
                      className="form-control input-xxlarge"
                    >
                      {/* <option selected={true} value="">
                          Type Acteurs
                        </option> */}
                      <option selected={true} value="Operateurs">Operateurs</option>
                      <option value="Acteurs">Acteurs</option>
                    </select>
                  </label>
                  {values.typeacteur === "Operateurs" && (
                    <label className="block clearfix">
                      <label htmlFor="text" className="col-lg-2">
                        Opérateurs
                      </label>
                      <select
                        onChange={onChange}
                        value={values.idoperateurimmo}
                        name="idacteur"
                        tabIndex={6}
                        className="form-control input-xxlarge"
                      >
                        <option value=""></option>
                        <option value="Catef">Catef</option>
                        <option value="Sicogi">Sicogi</option>
                      </select>
                    </label>
                  )}
                  {values.typeacteur === "Acteurs" && (
                    <label className="block clearfix">
                      <label htmlFor="text" className="col-lg-2">
                        Acteurs
                      </label>
                      <select
                        name="idacteur"
                        onChange={onChange}
                        tabIndex={7}
                        className="form-control input-xxlarge"
                      >
                        <option value={""} />
                        {acteurs?.map((item, i) => (
                          <option value={item.ID}>{item.NOM}</option>
                        ))}
                      </select>
                    </label>
                  )}

                </div>
                {/* pieds de page */}
                <div className="widget-toolbox padding-8- clearfix">
                  <div className="pull-right">
                    {isLoading ? (
                      <Loading isLoading={isLoading} />
                    ) : (
                      <input
                        type="submit"
                        value="Enregistrer"
                        className="btn btn-success"
                        tabIndex={15}
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    {current && (
                      <input
                        type="button"
                        value="Annuler"
                        onClick={() => {
                          setCurrent(null);
                        }}
                        tabIndex={25}
                        className="btn btn-warning"
                        style={{ borderStyle: "None", width: 100, margin: 2 }}
                      />
                    )}
                    {current && (
                      <input
                        type="button"
                        value="Supprimer"
                        onClick={() => {
                          onDelete();
                        }}
                        tabIndex={25}
                        className="btn btn-danger"
                        style={{ borderStyle: "None", width: 100, margin: 2 }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (

        <Table
          title={"Liste des dossiers"}
          headTable={headTable}
        >
          {etapedossier?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.LIBELLE}</td>
              <td>{item.ETAPE}</td>
              <td>{item.TYPEACTEUR}</td>
              <td>{item.IDACTEUR}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  //           style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}

          {/* pagination */}
          {/* <Pagination onPageChange={onPageChange} totalPage={totalPage} /> */}

          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default CreateEtape;