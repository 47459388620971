import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getIlots = async (id) => {

    return await axios.post("setting/list/ilot_operimmo", id, config);

}

export const createIlot = async (d) => await axios.post("setting/create/ilot", d, config);


export const updateIlot = async (d) => {
    return await axios.post("setting/update/ilot", d, config);
};

export const deleteIlot = async (d) => {
    return await axios.post("setting/delete/ilot", d, config);
};