import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Alert from "../../components/Alert";
import Layout from "../../layout/Layout";
import Table from "../../othercomponents/Table";
import { createPaiementClient, invoice, getDossier, getSolde, invoicePdf } from "../../services/finances/paiementclient";
import { getBanque } from "../../services/banque";
import Pagination from "../../components/Pagination"
import Loading from "../../components/Loading";
import { pathUrl } from "../..";


const PaiementClient = () => {


  const [current, setCurrent] = useState(null);
  const [invoicepdf, setInvoicepdf] = useState(null);
  const [dossier, setDossier] = useState([]);
  const [banques, setBanques] = useState([]);
  const [sousFormVisible, setSousFormVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "" });
  const [tableData, setTableData] = useState({
    calculcout: [],
    lpaiement: []
  });
  const [search, setSearch] = useState(null);

  const [isActive, setIsActive] = useState(false);

  const formRef = useRef(null);

  var pdfTab = null;

  const [stateForm, setStateForm] = useState({
    iddossier: 0,
    montant: "",
    mode: "",
    liendocument: "",
    observ: "",
    total_a_payer: "",
    montant_encaisser: "",
    reste: "",
    banque: "",
    reference: "",
    deposant: "",
    emett: "",
    selectedRow: null
  });
  const [currentTableData, setCurrentTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  const titleForm = "Gestion des paiements des clients";

  let total_a_payer = tableData?.calculcout[0]?.MONTANTAPAYER ? tableData?.calculcout[0]?.MONTANTAPAYER : "";
  let total_encaiser = tableData?.calculcout[0]?.MONTANTPAYE ? tableData?.calculcout[0]?.MONTANTPAYE : "";
  let reste = total_a_payer && total_encaiser ? parseInt(total_a_payer) - parseInt(total_encaiser) : "";

  stateForm.reste = reste;
  stateForm.total_a_payer = total_a_payer;
  stateForm.total_encaiser = total_encaiser;
  // const test = tableData[0]?.MONTANTPAYE ? tableData[0] : {};
  // console.log(total_a_payer);
  const forms = [
    { label: "Total à payer", required: true, name: "total_a_payer", disabled: true, values: stateForm.total_a_payer, type: "text" },
    { label: "Montant versé", name: "montant", values: stateForm.montant, type: "text" },
    { label: "Total encaissé", name: "total_encaiser", disabled: true, values: stateForm.total_encaiser, type: "text" },
    { label: "Reste à payer", name: "reste", disabled: true, values: stateForm.reste, type: "text" },
    {
      label: "Mode", required: true, name: "mode", values: stateForm.mode,
      table: [{ LIBELLE: "Espèce", id: "Espèce" }, { LIBELLE: "Chèque", id: "Chèque" }, { LIBELLE: "Reçu", id: "Reçu" }, { LIBELLE: "Virement", id: "Virement" }, { LIBELLE: "Paiement mobile", id: "Paiement mobile" }]
    },
  ];

  const sousForm = [
    { label: "Référence", name: "reference", className: "mode", values: stateForm.reference, type: "text" },
    { label: "Déposant", name: "deposant", className: "mode", values: stateForm.deposant, type: "text" },
    { label: "Banque", name: "banque", className: "mode", values: stateForm.banque, table: banques },
    {
      label: "Date versement", name: "emett", className: "input-small- date-picker text-center",
      values: stateForm.emett, type: "date", format: "dd/mm/yyyy"
    },
  ];
  const [btnStyle, setBtnStyle] = useState([
    { value: "Enregistrer", type: "submit", className: "btn-success" },
    { value: "Annuler", type: "button", className: "btn-warning" },
  ]);

  const titleTable = "Listes des paiements";
  const headTable = [
    { name: "ID", with: "" }, { name: "DATE", width: "" }, { name: "MONTANT", width: "" },
    { name: "DATE EMISSION", width: "" }, { name: "MODE", width: "" }, { name: "DOCUMENT", width: "" }, { name: "" }
  ];
  const PageSize = 10;
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData.lpaiement);
    setCurrentTableData(d);
    // console.log(d);
  };

  const formatMode = (id_mode) => {
    const mode = ["Espèce", "Chèque", "Reçu", "Virement", "Paiement mobile"]
    if (typeof id_mode == 'number') return mode[id_mode - 1];
    else { return id_mode }
  }
  const listDossier = () => {
    getDossier()
      .then((res) => {
        const { data } = res;
        //console.log("DOSSIER : ", data);
        setDossier(data);
      })
      .catch();
  };

  const filterDossier = (e) => {
    const SEARCH = document.getElementById('search');
    getDossier({ search: SEARCH.value })
      .then(({ data }) => {
        setDossier(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listBanques = () => {
    getBanque()
      .then((res) => {
        const { data } = res;
        //console.log("BANQUES : ", data);
        setBanques(data);
      })
      .catch();
  };

  const showDoc = (path) => {
    if (path) {
      let dateCache = new Date()
      const source = pathUrl + path + "?" + dateCache.getTime();;
      window.open(source, '_blank');
    }
  }

  const onChangeGetSolde = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    var text = e.nativeEvent.target[index].text
    setIsLoading(true)
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
    getSolde({ iddossier: e.target.value })
      .then(({ data }) => {
        console.log(data);
        setFlash({ message: 'Dossier selectionné: ' + text, color: "success" });
        const d = page(1, data.lpaiement);
        setTableData({ ...tableData, ...data });
        setCurrentTableData(d);
        const coutPage = parseInt(data.lpaiement.length / PageSize);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      })
  };

  const onChange = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value })
  };

  const onPiece = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.files[0] })
  }

  const onChangeMode = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
    const ids = ["Chèque", "Reçu", "Virement"];
    if (ids.includes(e.target.value)) {
      setSousFormVisible(true);
    } else {
      setSousFormVisible(false);
    }
  };

  const enregistrementClient = async (d) => {
    setIsLoading(true);

    createPaiementClient(d)
      .then((data) => {
        console.log("data dans enregistrementClient", data);
        const isOk = data.success;
        const message = data.message;
        const color = isOk === undefined || isOk === "false" || isOk === false ?
          "danger" : "success";
        if (isOk) {
          // reset();
          setCurrent(null);
          setInvoicepdf(null);
          setFlash({ message: `Paiement effectué avec succès`, color: "success" });
          setStateForm({
            ...stateForm,
            // iddossier: stateForm.iddossier,
            montant: "",
            mode: "",
            liendocument: "",
            observ: "",
            banque: "",
            reference: "",
            deposant: "",
            emett: ""
          });
          getSolde({ iddossier: stateForm.iddossier })
            .then(({ data }) => {
              console.log(data);
              // setFlash({ message: `Solde du Dossier choisir`, color: "success" });
              const d = page(1, data.lpaiement);
              setTableData({ ...tableData, ...data });
              setCurrentTableData(d);
              const coutPage = parseInt(data.lpaiement.length / PageSize);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            })
        }
        setFlash({ message: message, color: color });
        setIsLoading(false)
      })
      .catch((err) => {
        console.log("ERREUR PRODUITE: ", err)
        setIsLoading(false);
      })
  };

  const onSubmit = async (data) => {
    data = { ...data, ...stateForm, reste: reste };
    if (data.mode.length == 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      setIsLoading(false);
      return false;
    }
    let formData = new FormData(formRef.current);
    formData.append('reste', reste);
    try {
      await enregistrementClient(formData);
    } catch (error) {
      console.log(error);
    }
  };

  const onclick = async (item) => {
    stateForm.selectedRow = item
    setFlash({ message: `Paiement selectionné avec succès`, color: "success" });
    const dataSent = { iddossier: stateForm.iddossier, idpaiement: item.ID };
    setInvoicepdf(dataSent);
    invoice(dataSent)
      .then((dataReceived) => {
        // console.log(dataReceived);
        const data = dataReceived.data;
        const message = dataReceived.message
        const color = "success";

        // stateForm.observ = data.infopaiement[0].NCHEQUE
        // stateForm.observ = data.infopaiement[0].DATEEMIS
        // stateForm.observ = data.infopaiement[0].OBSERV

        stateForm.emett = data.infopaiement[0].DATEEMIS
        stateForm.montant = data.infopaiement[0].MONTANT
        stateForm.mode = data.infopaiement[0].MODE
        stateForm.banque = data.infopaiement[0].BANQUE
        stateForm.observ = data.infopaiement[0].OBSERV
        stateForm.deposant = data.infopaiement[0].EMETT
        stateForm.reference = data.infopaiement[0].NCHEQUE

        setFlash({ message: message, color: color })

        if (btnStyle.length < 3) {
          setBtnStyle([...btnStyle, ...[
            // { value: "Modifier", type: "submit", className: "btn-secondary" },
            // { value: "Imprimer", type: "button", className: "btn-primary" },
          ]]);
        }

        // btnStyle = [
        //   {value: "Enregistrer", type:"submit", className: "btn-success"},

        //   {value: "Annuler", type:"button", className: "btn-warning"},
        // ];
      })
      .catch()
  }
  useEffect(() => {
    listBanques();
    listDossier();
  }, []);

  const getFactureClient = () => {
    if (invoicepdf == null || invoicepdf.iddossier.length === 0 || invoicepdf.idpaiement.length === 0) {
      setFlash({ color: 'danger', message: 'Veuillez selectionner un dossier et un paiement' });
      return false;
    }
    setIsLoading(true);
    invoicePdf(invoicepdf)
      .then(async (data) => {
        setIsLoading(false);
        if (data.success) {
          let dateCache = new Date();
          let source = pathUrl + data.data + "?" + dateCache.getTime();

          window.open(source, '_blank', "noopener,noreferrer");

        } else {
          // setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
          alert('Impossible d\'imprimer la facture')
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  const onClickBtnAnnuler = (e) => {
    if (e.target.value === "Annuler") {
      reset(); setCurrent(null); setInvoicepdf(null);
      setStateForm({
        ...stateForm,
        montant: "",
        mode: "",
        liendocument: "",
        observ: "",
        total_a_payer: "",
        montant_encaisser: "",
        reste: "",
        banque: "",
        reference: "",
        deposant: "",
        emett: ""
      });
      stateForm.reste = "";
      stateForm.total_a_payer = "";
      stateForm.total_encaiser = "";
      setBtnStyle(() => [
        { value: "Enregistrer", type: "submit", className: "btn-success" },
        { value: "Annuler", type: "button", className: "btn-warning" },
      ]);
    }
  }

  return (

    <Layout>

      <div className="row">
        <div className="col-xs-2" />
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">{titleForm}</h5>
                <div className="navbar-form navbar-right form-search">
                  <div
                    id="ContentPlaceHolder1_Panel1"
                    onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                  >
                    <span className="input-icon">
                      <input
                        name="search"
                        type="text"
                        id="search"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <button type="button" className="btn-info btn-sm" onClick={filterDossier}>Rechercher</button>
                    </span>
                  </div>
                </div>
              </div>

              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
                  <span id="ContentPlaceHolder1_LBINFO" style={{ display: "inline-block", width: "100%", textAlign: "center" }} />

                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">{"DOSSIER"}</label>
                    <select onChange={onChangeGetSolde} name="iddossier" required={true} className="form-control input-xxlarge">
                      <option value="" />
                      {dossier?.map((t, i) => (
                        <option key={i} value={t.ID}>{t.LIBELLE}</option>
                      ))}
                    </select>
                  </label>
                  {forms.map((item, index) => {
                    setValue(item.name, item.values);
                    if (Array.isArray(item?.table)) {
                      return (
                        <label key={index} className="block clearfix">
                          <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                          <select {...register(item.name)}
                            onChange={item?.label === "Mode" ? onChangeMode : onChange}
                            className="form-control input-xxlarge">
                            <option value="" />
                            {item.table.map((t, i) => (
                              <option key={i} value={t.id}>{t.LIBELLE}</option>
                            ))}
                          </select>
                        </label>
                      );
                    } else {
                      return (
                        <label key={index} className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <input style={{ width: item?.width }}
                              onChange={onChange}
                              name={item.name}
                              type={item.type}
                              value={item.values}
                              disabled={item.disabled}
                              className="form-control input-xxlarge"
                              placeholder=""
                            />
                          </span>
                        </label>
                      );
                    }
                  })}
                  {sousForm.map((item, key) => {
                    if (sousFormVisible) {
                      if (Array.isArray(item?.table)) {
                        return (
                          <label key={key} className="block clearfix">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <select {...register(item.name)}
                              onChange={onChange} required={item.required}
                              className="form-control input-xxlarge">
                              <option value="" />
                              {item.table.map((t, i) => (
                                <option key={i} value={t.LIBELLE}>{t.LIBELLE}</option>
                              ))}
                            </select>
                          </label>
                        )

                      } else {
                        return (<label key={key} className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <input style={{ width: item?.width }}
                              onChange={onChange}
                              name={item.name}
                              type={item.type}
                              required={item.required}
                              value={item.values}
                              disabled={item.disabled}
                              data-date-format={item.format}
                              className={`${item?.className}`}
                              placeholder=""
                            />
                          </span>
                        </label>
                        );
                      }

                    }
                  })}
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-2">{"Observation"}</label>
                      <textarea
                        onChange={onChange}
                        name={"observ"}
                        value={stateForm.observ}
                        className="form-control input-xxlarge"
                        placeholder=""
                      ></textarea>
                    </span>
                  </label>
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-2">{"Document numérisé (pdf)"}</label>
                      <input
                        onChange={onPiece}
                        name={"liendocument"}
                        type={"file"}
                        className="form-control input-xxlarge"
                        placeholder=""
                        accept=".pdf"
                      />
                    </span>
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    {btnStyle.map((btn, key) => {
                      return (<input
                        key={key}
                        type={`${btn.type}`}
                        value={`${btn.value}`}
                        disabled={isLoading}
                        className={`btn ${btn.className}`}
                        style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                        onClick={onClickBtnAnnuler}
                      />)
                    })}
                    {
                      invoicePdf !== null && (
                        <input
                          type="button"
                          onClick={getFactureClient}
                          value="Imprimer la facture"
                          tabIndex={35}
                          className="btn btn-info"
                          style={{ borderStyle: "None", width: 150 }}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {isLoading && <Loading isLoading={isLoading} />}
      </div>

      <Table title={titleTable} headTable={headTable} >
        {currentTableData?.map((item, key) => (
          <tr key={key} style={{ color: "Black", backgroundColor: stateForm.selectedRow == item ? 'red' : 'white', }}>
            <td className="text-center">{item?.ID}</td>
            <td className="text-center">{item?.DATE}</td>
            <td className="text-center">{item?.MONTANT}</td>
            <td className="text-center">{item?.DATEEMIS}</td>
            <td className="text-center">{formatMode(item?.MODE)}</td>
            <td className="text-center">
              <a href="#" onClick={(e) => {
                e.preventDefault();
                showDoc(item.LIENDOCUMENT);
                return false;
              }}>
                {item?.APPERCU}
              </a>
            </td>
            <td className="text-center" align="right" style={{ cursor: "pointer" }}>
              <img
                onClick={() => onclick(item)}
                style={{ width: 15, height: 10 }}
                src={"/Catef_files/modifier.png"}
              />
            </td>
          </tr>
        ))}
        <Pagination onPageChange={onPageChange} totalPage={totalPage} />
      </Table>
    </Layout>
  );
};

export default PaiementClient;
