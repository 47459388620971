import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import Table from "../../othercomponents/Table";
import LayoutClient from "./LayoutClient";
import { fillActeur } from "../../services/acteur/accueil";
import { detailDossierClient, dossierClient } from "../../services/client/accueil";

const TableBordClient = () => {
  const ID_CLIENT = localStorage.getItem("__user_client_id");
  const [current, setCurrent] = useState(null);
  const [dossiers, setDossiers] = useState([]);
  const [details, setDetails] = useState(false);
  const [detail_dossier, setDetailDossier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "" });
  const [stateForm, setStateForm] = useState({
    iddossier: 0,
    montant: "",
    mode: "",
    liendocument: "",
    observ: "",
    total_a_payer: "",
    montant_encaisser: "",
    reste_payer: "",
    banque: "",
    reference: "",
    deposant: "",
    emett: new Date()
  });
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  const title = "Liste de vos dossiers";
  const titleR = "Liste de vos paiements";
  const titleO = "Les documents que vous avez fournis aux acteurs";
  const titleG = "Les documents que vous avez obtenu";
  const headTable = [
    { name: "Date" },
    { name: "Num dossier" },
    { name: "Agence" },
    { name: "Num de lot" },
    { name: "Type de lot" },
    { name: "A payer" },
    { name: "Payé" },
    { name: "Reste" },
    { name: "" }
  ];
  const headTableO = [
    { name: "Date" },
    { name: "Type de document" },
    { name: "" }
  ]
  const headTableR = [
    { name: "Code" },
    { name: "Date" },
    { name: "N° reçu" },
    { name: "Mode paiement" },
    { name: "Montant" },
    { name: "Type de document" },
    { name: "" }
  ]
  const headTableG = [
    { name: "Date" },
    { name: "Type de document" },
    { name: "" }
  ]

  // const forms = [
  //   { label: "Matricule", name: "total_a_payer", values: "", type: "text" },
  //   { label: "Nom Client", name: "montant", values: "", type: "text" },
  //   { label: "Contacts Client", name: "total_encaiser", values: "", type: "text" },
  //   { label: "Code Serveur", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "Code Opération", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "Opération", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "Type de Lot", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "N° Ilot", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "N° Lot", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "Superficie", name: "reste_a_payer", values: "", type: "text" },
  //   { label: "Etat", name: "etat", values: stateForm.mode, table: [ {LIBELLE: "NON SOLDE", ID: 1}, {LIBELLE: "SOLDE", ID: 2} ] },
  // ];

  const btnStyle = [
    { value: "Ajouter", type: "submit", className: "btn-success" },
  ];

  const fetchDossierClient = () => {
    dossierClient({ idclient: ID_CLIENT, search: "" })
      .then(({ data, message }) => {
        setDossiers(() => data);
        setFlash({ message: message, color: "success" })
      })
      .catch((err) => {
        console.log(err);
      })
  }


  // const onChange = (e) => {
  //   setStateForm({ ...stateForm, [e.target.name]: e.target.value })
  // };

  // const enregistrementClient = async (d) => {
  // };

  // const onSubmit = async (data) => {
  //   data = { ...data, ...stateForm };
  //   console.log(data);
  //   try {
  //     await enregistrementClient(data);
  //     console.log("Enregistrement reussir avec succes !", data);
  //     reset();
  //     setCurrent(null);
  //     setStateForm({ ...stateForm,
  //       iddossier: 0,
  //       montant: "",
  //       mode: "",
  //       liendocument: "",
  //       observ: "",
  //       total_a_payer: "",
  //       montant_encaisser: "",
  //       reste_payer: "",
  //       banque: "",
  //       reference: "",
  //       deposant: "",
  //       emett: ""
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onViewDetails = (dossier) => {
    setDetails(() => !details);
    const d = { iddossier: dossier.ID, search: "" };
    detailDossierClient(d)
      .then(({ data, message }) => {
        setDetailDossier(() => data);
        // setFlash({ message: data.message, color:"success" })
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      })
    console.log("detail du dossier : ", dossier.ID);
  }

  useEffect(() => {
    fetchDossierClient();
  }, []);


  return (
    <LayoutClient>
      {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
      <Table title={title} headTable={headTable} size={true} search={true} >
        {dossiers?.map((dossier, key) => (
          <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
            <td className="text-center" align="right">{dossier.DATE}</td>
            <td className="text-center" align="right">{dossier.NUMDOSSIER}</td>
            <td className="text-center" align="right">{dossier.AGENCE}</td>
            <td className="text-center" align="right">{dossier.NUMLOT}</td>
            <td className="text-center" align="right">{dossier.TYPELOT}</td>
            <td className="text-center" align="right">{dossier.TOTAPAYER}</td>
            <td className="text-center" align="right">{dossier.TOTPAYE}</td>
            <td className="text-center" align="right">{dossier.RESTEAPAYE}</td>
            <td className="text-center" align="right" style={{ cursor: "pointer" }}>
              <img
                onClick={() => onViewDetails(dossier)}
                style={{ width: 15, height: 10 }}
                src={"/Catef_files/modifier.png"}
              />
            </td>

          </tr>
        ))}
      </Table>

      {details ? (
        <div>
          <Table title={titleR} headTable={headTableR} size={true} search={true} colorTable={"red"}>
            {detail_dossier?.client?.map((clt, key) => (
              <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                <td className="text-center" align="right">{clt?.ID}</td>
                <td className="text-center" align="right">{clt?.DATE}</td>
                <td className="text-center" align="right">{clt?.NRECU}</td>

                <td className="text-center" align="right">{clt?.MODE}</td>
                <td className="text-center" align="right">{clt?.MONTANT}</td>
                <td className="text-center" align="right">{clt?.APPERCU}</td>
                <td className="text-center" align="right" style={{ cursor: "pointer" }}>
                  <img
                    // onClick={() => onViewDetails(clt)}
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </td>

              </tr>
            ))}
          </Table>
          <Table title={titleO} headTable={headTableO} size={true} search={true} colorTable={"orange"}>
            {detail_dossier?.dossier_doc_clt?.map((clt, key) => (
              <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                <td className="text-center" align="right">{clt?.DATE}</td>
                <td className="text-center" align="right">{clt?.LIBELLE}</td>
                <td className="text-center" align="right" style={{ cursor: "pointer" }}>
                  <img
                    // onClick={() => onViewDetails(clt)}
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </td>

              </tr>
            ))}
          </Table>
          <Table title={titleG} headTable={headTableG} size={true} search={true} colorTable={"green"}>
            {detail_dossier?.dossier_doc_act?.map((clt, key) => (
              <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                <td className="text-center" align="right">{clt?.DATE}</td>
                <td className="text-center" align="right">{clt?.LIBELLE}</td>
                <td className="text-center" align="right" style={{ cursor: "pointer" }}>
                  <img
                    // onClick={() => onViewDetails(clt)}
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </td>

              </tr>
            ))}
          </Table>
        </div>
      ) : (
        <div></div>
      )}

    </LayoutClient>
  );
};

export default TableBordClient;
