import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getOperationImmobilieres } from "../../services/operationImmobilier";
// import { gettypeLogement } from "../../services/rubriquePaiement";
import { deleteRubriquePaiements } from "../../services/rubriquePaiement"
import ReactPaginate from "react-paginate";
import Alert from "../../components/Alert";
import { gettypeLogement } from "../../services/typeLogement.js";

// import Loading from "../../components/Loading";
import {
  createTypelogementOperation,
  listTypelogementOperation,
  updateTypelogementOperation,
} from "../../services/typeLogementOperation.js";
const TypeLogoementOperation = () => {
  const headTable = [{ name: "libellé" }, { name: "" }];
  // le current doit etre au dessus de const forms
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Libelle",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];
  const [operationImmobilieres, setOperationImmobilieres] = useState([]);
  const [values, setValues] = useState({
    idoperationimmo: "",
    libelle: "",
  });
  const fetchRubrique = () => {
    setIsLoading(true);
    getOperationImmobilieres()
      .then(({ data }) => {
        setOperationImmobilieres(data.data);
        setIsLoading(false);
        // setIlots(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // pagination code
  const [flash, setFlash] = useState({ message: null, color: "success" });
  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSelectOperationImmobiliere = async (e) => {
    setCurrentTableData([]);
    setValues({ ...values, idoperationimmo: e.target.value });
    gettypeLogement(e.target.value)
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        // setRubrique(data)
        setCurrentTableData(data);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setTableData(data);
        setFlash({ ...flash, message: res.message, color: "success" });
        // setRubrique(data);
      })
      .catch((err) => {
        // console.log(err);
      });

  };

  // const fetchTypelogementOperation = async () => {
  //   setIsLoading(true);
  //   listTypelogementOperation()
  //     .then(({ data }) => {
  //       const d = page(1, data.data);
  //       setTableData(data);
  //       setCurrentTableData(d);
  //       const coutPage = parseInt(data.length / PageSize);
  //       setTotalPage(coutPage);
  //       setFlash({ ...flash, message: data.message, color: "success" });
  //       // console.log(data);
  //       setIsLoading(false);
  //     })
  //     .catch(({ response }) => {
  //       setIsLoading(false);
  //       setFlash({ ...flash, message: response.data.message, color: "danger" });
  //       // console.log(response);
  //     });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);


  }

  const { mutate } = useMutation(createTypelogementOperation, {
    onSuccess: async ({ message }) => {
      // Invalidate and refetch
      setIsLoading(false);
      setFlash({ ...flash, message: message, color: "success" });
      listTypelogementOperation()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data.data);
          setTableData(data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      // console.log(res.response.data.message);
      setFlash({
        ...flash,
        message: res.response.data.message,
        color: "danger",
      });
    },
  });

  const { mutate: updateMutate } = useMutation(updateTypelogementOperation, {
    onSuccess: async ({ data, message }) => {
      // Invalidate and refetch
      setIsLoading(false);
      setFlash({ ...flash, message: message });
      listTypelogementOperation()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setTableData(data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      // console.log(res.response.data.message);
      setFlash({
        ...flash,
        message: res.response.data.message,
        color: "danger",
      });
    },
  });

  // const postTypelogementOperation = (data) => mutate(data);
  // const putTypelogementOperation = (data) => updateMutate(data);

  const onclick = (data) => {
    setCurrent(data);
  };

  const onDelete = async () => {
    if (window.confirm("Etre vous sur de vouloir supprimer le ilot?")) {
      const data = { id: current.ID, libelle: current.LIBELLE };
      deleteRubriquePaiements(data)
        .then(({ data }) => {
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          // gettypeLogement({ idoperationimmo: e.target.value })
          gettypeLogement({ idoperationimmo: values.idoperationimmo })
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              // setIsLoading(false)
              // setIsLoading(true);
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(false);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
        });
    }
  };


  useEffect(() => {
    fetchRubrique();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Saisie des opérations par type de paiement  </h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Operation (*)
                    </label>
                    <select
                      name="idoperationimmo"
                      className="form-control input-xxlarge"
                      onChange={onSelectOperationImmobiliere}
                    >
                      <option value="-1"></option>
                      {operationImmobilieres?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>

                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Valider"
                      className="btn btn-success "
                      disabled={isLoading ? true : false}
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        value="Supprimer"
                        className="btn btn-danger"
                        onClick={onDelete}
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    {/* <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => {
                        setCurrent(null);
                        setValues({ ...values, libelle: "" });
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table title={"Liste des Paiements Rubriques"} headTable={headTable}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.LIBELLE}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}
          {/* pgination */}
          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
        </Table>
      )}
    </Layout>
  );
};


export default TypeLogoementOperation;
