import React from "react";
import { useForm } from "react-hook-form";
import Alert from "../components/Alert";

const Formulaire = ({ 
  forms, title, cb=null, onChange = null, current=null,
  setCurrent = null, message = null, color = null, isLoading = false,
  search=false, buttonFooter=[], sousForm=null, colorForm="blue",
  formRef=null
}) => {
  
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();

  const onSubmit = async (data) => {
    data = current ? { ...data, id: current?.ID } : data;
    // console.log(data);
    try {
      await cb(data);
      // console.log("Enregistrement reussir avec succes !", data);
      reset();
      setCurrent(null);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="row">
      <div className="col-xs-2" />
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="form-horizontal col-xs-8 ui-sortable">
          <div className={`widget-box widget-color-${colorForm}`}>
            {/* Entete du cadre */}
            <div className="widget-header ui-sortable-handle">
              <h5 className="widget-title">{title}</h5>
              {search ? 
                <div className="navbar-form navbar-right form-search">
                  <div
                    id="ContentPlaceHolder1_Panel1"
                    onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                  >
                    <span className="input-icon">
                      <input
                        name="ctl00$ContentPlaceHolder1$TBRECH"
                        type="text"
                        id="TBRECH"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <input
                        type="submit"
                        name="ctl00$ContentPlaceHolder1$BTRECH"
                        defaultValue="OK"
                        id="ContentPlaceHolder1_BTRECH"
                        tabIndex={2}
                        className="btn-info btn-sm MasquerColonne"
                        style={{ borderStyle: "None" }}
                      />
                    </span>
                  </div>
                </div>
              : ""
              }
            </div>

            {/* Contenu du cadre */}
            <div className="widget-body">
              <div className="widget-main">
                {message !== null && <Alert color={color} message={message} />}
                <span id="ContentPlaceHolder1_LBINFO" style={{ display: "inline-block", width: "100%", textAlign: "center" }}/>
                {forms.map((item, index) => {
                  setValue(item.name, item.values);
                  if (Array.isArray(item?.table)) {
                    return (
                      <label key={index} className="block clearfix">
                        <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                        <select {...register(item.name)} onChange={onChange} className="form-control input-xxlarge">
                          <option value="" />
                          {item?.table?.map((t, i) => (
                            <option key={i} value={t.ID}>{t.LIBELLE}</option>
                          ))}
                        </select>
                      </label>
                    );
                  } else {
                    return (
                      <label key={index} className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                          <input style={{ width: item?.width }} {...register(item.name)}
                            onchange={onChange}
                            type={item.type}
                            className="form-control input-xxlarge"
                            placeholder=""
                          />
                        </span>
                      </label>
                    );
                  }
                })}
              </div>
              {/* Pied du cadre */}
              <div className="widget-toolbox padding-8 clearfix">
                <div className="pull-right">
                  {buttonFooter.map((btn, key) => {
                    return (<input
                      key={key}
                      type={`${btn.type}`}
                      value={`${btn.value}`}
                      disabled={isLoading}
                      className={`btn ${btn.className}`}
                      style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                      onClick={ btn.value === "Annuler" && (() => {reset(); setCurrent(null)})}
                   />)                  
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Formulaire;
