import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getEtapeFoncieres = async () => {
    try {
        const { data } = await axios.get("setting/list/etat_opt_pf", config);
        return data;
    } catch (error) {
        return error;
    }
};

export const createEtapeFonciere = async (d) => {
    return await axios.post("setting/create/etat_opt_pf", d, config);
};

export const updateEtapeFonciere = async (d) => {
    return await axios.post("setting/update/etat_opt_pf", d, config);
};

export const deleteEtapeFonciere = async (d) => {
    return await axios.post("setting/delete/etat_opt_pf", d, config);
};

