import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const getActeur = async () => {
  try {
    const { data } = await axios.get("finance/get/acteur", config);
    return { data };
  } catch (error) {
    return error;
  }
};

export const selectActeur = async (d) => {
  try {
    const { data } = await axios.post("finance/select/acteur", d, config);
    return { data };
  } catch (error) {
    return error;
  }
};

export const registerActeur = async (d) => {
  try {
    const { data } = await axios.post("finance/create/paie_acteur", d, config);
    return { data };
  } catch (error) {
    return error;
  }
};
