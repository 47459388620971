import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ onPageChange, totalPage = null }) => {
  return (
    <tr
      className="pagination-od"
      align="center"
      style={{
        color: "Black",
        backgroundColor: "#EFF3F8",
        fontWeight: "normal",
        whiteSpace: "nowrap",
      }}
    >
      <td colSpan={15}>
        <table>
          <tbody>
            <tr>
              <td>
                {totalPage != null && (
                  <ReactPaginate
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                    breakLabel="..."
                    nextLabel="suivant >"
                    onPageChange={onPageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPage + 1}
                    previousLabel="< Précédent"
                    renderOnZeroPageCount={null}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default Pagination;
