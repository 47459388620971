import React, { useState, useRef, useEffect } from "react";
import FormSaissirClient from "./FormSaissirClient";
import Alert from "../../../../components/Alert";
import {
  getListeClient,
  saissirDossierClient,
  updateSaisirDossierClient,
} from "../../../../services/dossier";
import TableSaisirClient from "./TableSaisirClient";
import Loading from "../../../../components/Loading";

const SaisirClient = ({
  setIdClient,
  currentTableData,
  setCurrentTableData,
  onPageChange,
  totalPage,
  setTotalPage,
  setOnglet,
  onglet,
  setIsloading,
  setTableData,
  setDataOnglet2,
  setDossiers,
  setIsLoadingGlobal,
}) => {
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(null);

  const formRef = useRef(null);
  const pieceRef = useRef(null);

  const session = localStorage.getItem("__dossier_client");
  let info;
  if (session) {
    info = JSON.parse(session);
    localStorage.setItem("idnaturecontrat", info.idnaturecontrat);
  }

  setTimeout(() => {
    localStorage.removeItem("__dossier_client");
  }, 5000);

  // //////////////////////
  let PageSize = 10;

  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  // ////////////////

  // console.log("current");
  // console.log(current);
  const [values, setValues] = useState({
    civilite: "Mademoiselle",
    situmatri: "Célibataire",
    regime: "",
    matricule: current?.MATRICULE ?? info?.matricule ?? "",
    nom: current?.NOM ?? info?.nom ?? "",
    idnationnalite: "",
    datenaiss: "",
    lieunaiss: "",
    idtpieceidentite: "",
    numpiece: "",
    localisation: "",
    fonction: "",
    tel: "",
    cel: current?.CEL ?? "",
    adresse: "",
    email: "",
    nomconjoint: "",
    natConjoint: "",
    dateNaissConjoint: "",
    lieuNaissConjoint: "",
    fonctionconjoint: "",
    piece: "",
  });

  const videForm = () => {
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        values[key] = "";
        setValues({ ...values });
      }
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData(formRef.current);

    setIsLoading(true);
    if (current) {
      // const update = { ...values, id: current.ID };
      formData.append("id", current.ID);
      const p = formData.get("piece");
      const pieceLien =
        p?.size === 0 && p?.name.length === 0 ? current.LIENPIECE : p;
      formData.set("piece", pieceLien);
      if (
        values.piece === null ||
        values.piece === "" ||
        values.matricule.length === 0 ||
        values.nom.length === 0
      ) {
        setFlash({
          message: "Veuillez saisir les champs requis",
          color: "danger",
        });
        setIsLoading(false);
        return false;
      }
      saissirDossierClient(formData)
        .then(({ data }) => {
          setFlash({ ...flash, message: data.message, color: "success" });
          listeClient(info?.matricule);
          videForm();
          formData.delete("piece");
          setIsLoading(false);
        })
        .catch();
    } else {
      if (
        values.piece === null ||
        values.piece === "" ||
        values.matricule.length === 0 ||
        values.nom.length === 0
      ) {
        setFlash({
          message: "Veuillez saisir les champs requis",
          color: "danger",
        });
        setIsLoading(false);
        return false;
      }
      console.log(formData)
      saissirDossierClient(formData)
        .then(({ data }) => {
          setFlash({ ...flash, message: data.message, color: "success" });
          listeClient(values?.matricule);
          console.log(values?.matricule);
          videForm();
          values.piece = "";
          localStorage.removeItem("__dossier_client");
          setIsLoading(false);
        })
        .catch((error) => {
          setFlash({
            ...flash,
            message: "Erreur de validation de la requete",
            color: "danger",
          });
          setIsLoading(false);
        });
    }
  };

  const listeClient = (id) => {
    getListeClient(id)
      .then(({ data }) => {
        // console.log(data.data);
        const d = page(1, data.data);
        setCurrentTableData(d);
        setTableData(data.data);
        // setFlash({ success: data.message });
        const coutPage = parseInt(data.data.length / PageSize);
        setTotalPage(coutPage);
        setIsloading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // useEffect(() => {
  //   console.log(formRef.current)
  //   let formData = new FormData(formRef.current);
  //   console.log(formData)
  // })
  return (
    <>
      {flash.message !== null && (
        <Alert color={flash.color} message={flash.message} />
      )}
      {isLoading && <Loading isLoading={isLoading} />}
      <form ref={formRef} onSubmit={_onSubmit}>
        <FormSaissirClient
          values={values}
          setValues={setValues}
          current={current}
          pieceRef={pieceRef}
        />

        <TableSaisirClient
          setIsLoadingGlobal={setIsLoadingGlobal}
          setIdClient={setIdClient}
          setCurrent={setCurrent}
          setOnglet={setOnglet}
          onglet={onglet}
          currentTableData={currentTableData}
          onPageChange={onPageChange}
          totalPage={totalPage}
          setValues={setValues}
          values={values}
          flash={flash}
          setFlash={setFlash}
          setDataOnglet2={setDataOnglet2}
          setDossiers={setDossiers}
        />

        {/*  button */}
        <div className="widget-toolbox padding-8 clearfix">
          <div className="pull-right">
            <input
              type="submit"
              value="Enregistrer"
              disabled={
                isLoading || (values.nom === "" && values.matricule === "")
                  ? true
                  : false
              }
              tabIndex={23}
              className="btn btn-success"
              style={{ borderStyle: "None", width: 100, margin: 2 }}
            />
            {current && (
              <input
                type="button"
                value="Supprimer"
                onClick={() => { }}
                tabIndex={24}
                className="btn btn-danger"
                style={{ borderStyle: "None", width: 100, margin: 2 }}
              />
            )}
            {current && (
              <input
                type="button"
                value="Annuler"
                onClick={() => {
                  setCurrent(null);
                }}
                tabIndex={25}
                className="btn btn-warning"
                style={{ borderStyle: "None", width: 100, margin: 2 }}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default SaisirClient;
