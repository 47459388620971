import React from "react";
import { useForm } from "react-hook-form";
import Alert from "./Alert";

const Formulaire = ({
  forms,
  title,
  cb,
  onChange = null,
  current,
  setCurrent = null,
  message = null,
  color = null,
  isLoading = false,
  onDelete = null,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    data = current ? { ...data, id: current?.ID } : data;
    // console.log(data);
    try {
      await cb(data);

      // console.log(data);
      reset();
      setCurrent(null);
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <div className="row">
      <div className="col-xs-2" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-horizontal col-xs-8 ui-sortable">
          <div className="widget-box widget-color-blue">
            {/* Entete du cadre */}
            <div className="widget-header ui-sortable-handle">
              <h5 className="widget-title">{title}</h5>
            </div>

            {/* Contenu du cadre */}
            <div className="widget-body">
              <div className="widget-main">
                {message !== null && <Alert color={color} message={message} />}
                <span
                  id="ContentPlaceHolder1_LBINFO"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "center",
                  }}
                />
                {forms.map((item, index) => {
                  setValue(item.name, item.values);
                  if (Array.isArray(item?.table)) {
                    return (
                      <label key={index} className="block clearfix">
                        <label htmlFor="text" className="col-lg-2">
                          {item?.label}
                        </label>
                        <select
                          {...register(item.name)}
                          onChange={onChange}
                          // tabIndex={1}
                          className="form-control input-xxlarge"
                        >
                          <option value="" />
                          {item.table.map((t, i) => (
                            <option key={i} value={t.id}>
                              {t.valeur}
                            </option>
                          ))}
                        </select>
                      </label>
                    );
                  } else {
                    return (
                      <label key={index} className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-2">
                            {item?.label}
                          </label>
                          <input
                            style={{ width: item?.width }}
                            {...register(item.name)}
                            // onchange={onchange}
                            type={item.type}
                            //   id="ContentPlaceHolder1_TBLIB"
                            //   tabIndex={2}
                            className="form-control input-xxlarge"
                            placeholder=""
                          />
                        </span>
                      </label>
                    );
                  }
                })}
              </div>
              {/* Pied du cadre */}
              <div className="widget-toolbox padding-8 clearfix">
                <div className="pull-right">
                  <input
                    type="submit"
                    value="Enregistrer"
                    disabled={isLoading}
                    className="btn btn-success"
                    style={{
                      borderStyle: "None",
                      width: 100,
                      marginRight: 2,
                      marginBottom: 2,
                    }}
                  />
                  {current && (
                    <input
                      type="button"
                      onClick={onDelete}
                      value="Supprimer"
                      className="btn btn-danger"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />
                  )}
                  <input
                    type="button"
                    value="Annuler"
                    className="btn btn-warning"
                    style={{ borderStyle: "None", width: 100, marginBottom: 2 }}
                    onClick={() => {
                      setCurrent(null);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Formulaire;
