import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const searchValidationPaiementActeur = async () => {
  try {
    const { data } = await axios.get("finance/search/paie_client", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getValidationPaiementActeur = async () => {
  try {
    const { data } = await axios.get("finance/get/valid_paie_acteur", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createValidationPaiementActeur = async (d) => {
  try {
    const { data } = await axios.post(
      "finance/create/valid_paie_acteur",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
