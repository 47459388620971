import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getObtentionFonciere = async () => {
  return await axios.get("setting/list/etat_opt_pf", config);
};

export const createObtentionFonciere = async (d) => {
  try {
    const { data } = await axios.post("setting/create/etat_opt_pf", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateObtentionFonciere = async (d) => {
  try {
    const { data } = await axios.post("setting/update/etat_opt_pf", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteObtentionFonciere = async (d) => {
  try {
    const { data } = await axios.post("setting/delete/etat_opt_pf", d, config);
    return data;
  } catch (error) {
    return error;
  }
};
