
import React from "react";

const Table = ({
  headTable,
  children,
  title,
  isSearch = false,
  search,
  setSearch,
  setDataSearched,
  cb,
}) => {
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onPressEnter = (e) => {
    if (e.key == "Enter") {
      cb(search);
    }
  };

  return (
    <div className="row">
      <div className="col-xs-2" />
      <div className="form-horizontal col-xs-8 ui-sortable">
        <div className="widget-box widget-color-blue">
          {/* Entete du cadre */}
          <div className="widget-header ui-sortable-handle">
            <h5 className="widget-title">{title}</h5>
            {isSearch && (
              <div
                className="navbar-form navbar-right form-search"
                style={{ marginLeft: 50 }}
              >
                <div>
                  <span className="input-icon">
                    <input
                      name="search"
                      value={search}
                      onChange={onChange}
                      onKeyUpCapture={onPressEnter}
                      type="text"
                      tabIndex={1}
                      className="nav-search-input"
                      placeholder="Recherche ..."
                    />
                    <i className="ace-icon fa fa-search nav-search-icon" />
                    <input
                      type="submit"
                      defaultValue="OK"
                      tabIndex={2}
                      className="btn-info btn-sm MasquerColonne"
                      style={{ borderStyle: "None" }}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* Contenu du cadre */}
          <div className="widget-body">
            <div className="widget-main">
              <label className="block clearfix">
                <span className="block">
                  <table
                    cellSpacing={0}
                    rules="all"
                    className="table-header table table-striped "
                    border={1}
                    id="ContentPlaceHolder1_GV"
                    style={{
                      borderColor: "#E1E1E1",
                      fontSize: "10pt",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr
                        align="center"
                        valign="middle"
                        style={{
                          color: "#707070",
                          backgroundColor: "#F1F1F1",
                          fontWeight: "normal",
                        }}
                      >
                        {headTable?.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className=""
                              scope="col"
                              style={{ width: item?.width }}
                            >
                              {item?.name}
                            </th>
                          );
                        })}
                      </tr>

                      {children}
                    </tbody>
                  </table>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;

