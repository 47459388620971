import React from "react";
import { Link } from "react-router-dom";

const NavbarActeur = () => {
  const NOM = localStorage.__user_acteur_nom;
  return (
    <div id="navbar" className="navbar navbar-default">
      <div className="navbar-container" id="navbar-container">
        <button
          type="button"
          className="navbar-toggle menu-toggler pull-left display"
          id="menu-toggler"
        >
          <span className="sr-only">Raccourcis</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <div className="navbar-header pull-left">
          <h4>
            <span className="white">CATEF SA ==&gt; ESPACE ACTEUR</span>
          </h4>
        </div>
        <div className="navbar-header pull-center">
          <h4>
            <span className="red" />
          </h4>
        </div>
        <div
          className="navbar-buttons navbar-header pull-right"
          role="navigation"
        >
          <ul className="nav ace-nav" style={{}}>
            <li className="light-blue">
              <a
                data-toggle="dropdown"
                href="http://bleibaulouh-001-site1.htempurl.com/Pays.aspx#"
                className="dropdown-toggle"
              >
                <img
                  alt=""
                  className="nav-user-photo"
                  src="./Catef_files/saved_resource"
                />
                <span className="user-info">
                  <small>Bienvenue</small>
                  {NOM.toUpperCase()}
                </span>
                <i className="ace-icon fa fa-caret-down" />
              </a>
              <ul className="user-menu dropdown-menu-right dropdown-menu dropdown-light-blue dropdown-caret dropdown-close">
                <li>
                  <a href="/acteur/modifier-password-acteur">
                    <span className="ace-icon fa fa-user" /> Changement mot de
                    passe
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <Link to="/logout">
                    <span className="ace-icon fa fa-power-off" /> Déconnexion
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavbarActeur;
