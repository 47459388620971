import React from "react";
import Layout from "../../layout/Layout";

const Error404 = () => {
  return (
    <Layout>
      <img src={"/Catef_files/error-404.png"} alt="" />
    </Layout>
  );
};

export default Error404;
