import React, { useEffect, useState } from "react";
import {
  listeAgence,
  listeIlot,
  listeLot,
  listeOperateurImmo,
} from "../../../../services/dossier";
import { getNatureContrats } from "../../../../services/natureContrat";

const FormDetailDossier = ({
  idClient,
  values,
  setValues,
  isLoading,
  setIsLoading,
  ilots,
  setIlots,
  lots,
  setLots,
}) => {
  const natureContrat = localStorage.getItem('__naturecontat');

  const [operations, setOperations] = useState([]);
  const [contrats, setContrats] = useState([]);
  const [agences, setAgences] = useState([]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const fetchListeOperateurImmo = () => {
    setIsLoading(true);
    listeOperateurImmo()
      .then(({ data }) => {
        setOperations(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  const onSelectOperation = (e) => {
    let natureCont = "";
    if (natureContrat) {
      natureCont = natureContrat;
      localStorage.removeItem('__naturecontat');
    }
    setValues({ ...values, operation: e.target.value, "natcont": natureCont });
    setIsLoading(true);
    listeIlot(e.target.value)
      .then(({ data }) => {
        setIlots(data.data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
      });
  };

  const onSelectIlot = (e) => {
    setValues({ ...values, ilot: e.target.value });
    setIsLoading(true);
    listeLot(e.target.value)
      .then(({ data }) => {
        setLots(data.data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
      });
  };

  const fetchNatureContrat = () => {
    setIsLoading(true);
    getNatureContrats()
      .then(({ data }) => {
        setContrats(data.data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
      });
  };

  const fetchAgence = () => {
    setIsLoading(true);
    listeAgence()
      .then(({ data }) => {
        setAgences(data.data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchListeOperateurImmo();
    fetchNatureContrat();
    fetchAgence();
  }, []);
  return (
    <>
      <input type="hidden" name="idclient" value={idClient} />
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-2">
            Cpte comptable *
          </label>
          <input
            onChange={onChange}
            name="ncptecomptable"
            value={values.ncptecomptable}
            type="text"
            tabIndex={25}
            className="form-control input-large text-uppercase"
            placeholder=""
          />
        </span>
      </label>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-2">
            Opération *
          </label>
          <select
            onChange={onSelectOperation}
            name="operation"
            value={values.operation}
            tabIndex={26}
            className="form-control input-xxlarge"
          >
            <option value={""}></option>
            {operations?.map((operation, key) => (
              <option key={key} value={operation.ID}>
                {operation.LIBELLE}
              </option>
            ))}
          </select>
        </span>
      </label>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-2">
            Îlot *
          </label>
          <select
            onChange={onSelectIlot}
            name="ilot"
            value={values.ilot}
            // defaultValue={values.ilot}
            tabIndex={27}
            className="form-control input-xxlarge"
          >
            <option value={""} />
            {ilots?.map((ilot, key) => (
              <option key={key} value={ilot.ID}>
                {ilot.LIBELLE}
              </option>
            ))}
          </select>
        </span>
      </label>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-2">
            Lot *
          </label>
          <select
            name="lot"
            value={values.lot}
            // defaultValue={values.lot}
            onChange={onChange}
            tabIndex={28}
            className="form-control input-xxlarge"
          >
            <option value={""} />
            {lots?.map((lot, key) => (
              <option key={key} value={lot.ID}>
                {lot.LIBELLE}
              </option>
            ))}
          </select>
        </span>
      </label>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-2">
            Nature contrat *
          </label>
          <select
            onChange={onChange}
            name="natcont"
            value={values.natcont}
            disabled="disabled"
            tabIndex={29}
            className="aspNetDisabled"
            required=""
          >
            <option value={""} />
            {contrats?.map((contrat, key) => {
              return (
                <option key={key} value={contrat.ID}>
                  {contrat.LIBELLE}
                </option>
              );
            })}
          </select>
        </span>
      </label>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-2">
            Agence *
          </label>
          <select
            onChange={onChange}
            name="agence"
            tabIndex={29}
            value={values.agence}
            className="form-control input-xxlarge"
          >
            <option value={""} />
            {agences?.map((agence, key) => (
              <option key={key} value={agence.ID}>
                {agence.LIBELLE}
              </option>
            ))}
            {/* <option value={1}>SIEGE DE CATEF</option> */}
          </select>
        </span>
      </label>
    </>
  );
};

export default FormDetailDossier;
