import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { listeChoix, printingListe } from "../../services/edition/liste";
import Pays from "./printing/Pays";
import Ville from "./printing/Ville";
import Loading from "../../components/Loading";

const Liste = () => {
  const [choix, setChoix] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({ id_item: "" });
  const [printing, setPrinting] = useState();
  const fetchChoix = () => {
    setIsLoading(true);
    listeChoix()
      .then(({ data }) => {
        setChoix(data.data);
        setIsLoading(false);
      })
      .catch();
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    printingListe(values.id_item).then(({ data }) => {
      let resultat = { ...data.data };
      // console.log(data);

      for (const key in resultat) {
        if (Object.hasOwnProperty.call(resultat, key)) {
          if (key !== "structures") {
            const element = resultat[key];
            resultat["data"] = resultat[key];
            delete resultat[key];
          }
        }
      }

      setPrinting(resultat);

      setIsLoading(false);
      console.log(resultat);
    });
  };

  useEffect(() => {
    fetchChoix();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={onSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Les listes</h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {isLoading && <Loading isLoading={isLoading} />}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Choix
                    </label>
                    <select
                      name="id_item"
                      onChange={onChange}
                      tabIndex={1}
                      className="form-control input-xxlarge"
                    >
                      <option value={""} />
                      {choix?.map((item, i) => (
                        <option value={item.ID}>{item.TITRE}</option>
                      ))}
                    </select>
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Imprimer"
                      tabIndex={2}
                      className="btn btn-danger"
                      style={{ borderStyle: "None", width: 100 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* printing pays =66*/}
      {values.id_item == 66 && (
        <Pays title={"LISTE DES PAYS"} printing={printing} />
      )}

      {/* printing ville =67*/}
      {values.id_item == 67 && (
        <Ville title={"LISTE DES VILLES"} printing={printing} />
      )}
    </Layout>
  );
};

export default Liste;
