import React from "react";

const Table = ({ headTable, children, title, search, footer, size, valider, colorTable='blue',changeSearch,verifySolde }) => {
  return (
    <div className="row">
      <div className={`col-xs-${size? 1 : 2}`}></div>
      <div className={`form-horizontal col-xs-${size? 12 : 8} ui-sortable`}>
        <div className={`widget-box widget-color-${colorTable}`}>
          {/* Entete du cadre */}
          <div className="widget-header ui-sortable-handle">
            <h5 className="widget-title">{title}</h5>
            {search? 
              <div className="navbar-form navbar-right form-search" style={{ marginLeft: 50 }}>
                <div
                  id="ContentPlaceHolder1_Panel1"
                  onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                >
                  <span className="input-icon">
                    <input
                      name="critere"
                      type="text"
                      id="TBRECH"
                      tabIndex={1}
                      className="nav-search-input"
                      placeholder="Recherche ..."
                      onChange={changeSearch}
                      onKeyUpCapture={verifySolde}
                    />
                    <i className="ace-icon fa fa-search nav-search-icon" />
                    <input
                      type="submit"
                      name="ctl00$ContentPlaceHolder1$BTRECH"
                      defaultValue="OK"
                      id="ContentPlaceHolder1_BTRECH"
                      tabIndex={2}
                      className="btn-info btn-sm MasquerColonne"
                      style={{ borderStyle: "None" }}
                    />
                  </span>
                </div>
              </div>
              : ""
            }
          </div>
          {/* Contenu du cadre */}
          <div className="widget-body">
            <div className="widget-main">
              <span
                id="ContentPlaceHolder1_LBINFO"
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "center"
                }}
              />
              <label className="block clearfix">
                <span className="block">
                <table
                    cellSpacing={0}
                    rules="all"
                    className="table-header table table-striped "
                    border={1}
                    id="ContentPlaceHolder1_GV"
                    style={{
                      borderColor: "#E1E1E1",
                      fontSize: "10pt",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr
                        align="center"
                        valign="middle"
                        style={{
                          color: "#707070",
                          backgroundColor: "#F1F1F1",
                          fontWeight: "normal",
                        }}
                      >
                        {headTable?.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="text-center"
                              scope="col"
                              style={{ width: item?.width }}
                            >
                              {item?.name}
                            </th>
                          );
                        })}
                      </tr>

                      {children}
                    </tbody>
                  </table>
                </span>
              </label>
            </div>
            {/* Pied du cadre */}
            {footer?.map((btn, key) => (
              <div className="widget-toolbox padding-8 clearfix" key={key}>
              <div className="pull-right">
                <input
                  type={btn.type}
                  name={btn.name}
                  value={btn.value}
                  id={btn.id}
                  className={`btn btn-${btn.className}`}
                  style={{ borderStyle: "None", width: 100 }}
                  onClick={() => valider()}
                />
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
);
}

export default Table;
