import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import { getPays } from "../../../../services/pays";
import { getTypePiece } from "../../../../services/typePiece";

const FormSaissirClient = ({ current, values, setValues, pieceRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pays, setPays] = useState([]);
  const [typepiece, setTypepiece] = useState([]);

  const session = localStorage.getItem("__dossier_client");
  if (session) {
    var info = JSON.parse(session);
  }

  const onPiece = (e) => {
    setValues({ ...values, piece: e.target.files[0] });
  };

  // console.log(errors);
  const fetchPays = async () => {
    setIsLoading(true);
    getPays()
      .then(({ data }) => {
        setIsLoading(false);
        setPays(data);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };

  const fetchTypePiece = () => {
    setIsLoading(true);
    getTypePiece()
      .then(({ data }) => {
        setTypepiece(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    fetchPays();
    fetchTypePiece();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <label className="block clearfix">
          <span className="block">
            <label className="block clearfix">
              <label htmlFor="text" className="col-lg-3">
                Civilité
              </label>
              <select
                onChange={onChange}
                value={values.civilite}
                name="civilite"
                tabIndex={1}
                className="form-control input-large"
              >
                <option value="Monsieur">Monsieur</option>
                <option value="Madame">Madame</option>
                <option selected={true} value="Mademoiselle">
                  Mademoiselle
                </option>
                <option value="Société">Société</option>
                <option value="Organisation">Organisation</option>
              </select>
            </label>
            <label className="block clearfix">
              <label htmlFor="text" className="col-lg-3">
                Situation matrimoniale
              </label>
              <select
                onChange={onChange}
                value={values.situmatri}
                name="situmatri"
                tabIndex={2}
                className="form-control input-large"
              >
                <option selected={true} value="Célibataire">
                  Célibataire
                </option>
                <option value="Marié(e)">Marié(e)</option>
                <option value="Divorce en instance">Divorce en instance</option>
                <option value="Divorcé(e)">Divorcé(e)</option>
                <option value="Veuf(ve)">Veuf(ve)</option>
              </select>
            </label>

            {values.situmatri === "Marié(e)" && (
              <label className="block clearfix">
                <label htmlFor="text" className="col-lg-3">
                  Régime
                </label>
                <select
                  onChange={onChange}
                  value={values.regime}
                  name="regime"
                  tabIndex={2}
                  className="form-control input-large"
                >
                  <option value=""></option>
                  <option value="Communauté de bien">Communauté de bien</option>
                  <option value="Séparation de biens"> Séparation de bien</option>
                </select>
              </label>
            )}
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Matricule *
                </label>
                <input
                  onChange={onChange}
                  value={values.matricule}
                  type="text"
                  name="matricule"
                  readOnly="readonly"
                  tabIndex={4}
                  className="form-control input-large text-uppercase"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Nom *
                </label>
                <input
                  type="text"
                  value={values.nom}
                  onChange={onChange}
                  name="nom"
                  readOnly="readonly"
                  tabIndex={5}
                  className="form-control input-xxlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <label htmlFor="text" className="col-lg-3">
                Nationalité
              </label>
              <select
                onChange={onChange}
                value={values.idnationnalite}
                name="idnationnalite"
                tabIndex={6}
                className="form-control input-xxlarge"
              >
                <option value={""} />
                {pays?.map((item, key) => (
                  <option key={key} value={item.ID}>
                    {item.NATIONNALITE}
                  </option>
                ))}
              </select>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Date de naissance
                </label>
                <input
                  value={values.datenaiss}
                  onChange={onChange}
                  name="datenaiss"
                  type="date"
                  tabIndex={20}
                  className="input-small- date-picker text-center"
                  data-date-format="dd/mm/yyyy"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Lieu de naissance
                </label>
                <input
                  value={values.lieunaiss}
                  onChange={onChange}
                  name="lieunaiss"
                  type="text"
                  defaultValue=""
                  tabIndex={8}
                  className="form-control input-xxlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <label htmlFor="text" className="col-lg-3">
                Type de pièce
              </label>
              <select
                onChange={onChange}
                value={values.idtpieceidentite}
                name="idtpieceidentite"
                tabIndex={9}
                className="form-control input-xxlarge"
              >
                <option value={""} />
                {typepiece?.map((item, key) => (
                  <option key={key} value={item.ID}>
                    {item.LIBELLE}
                  </option>
                ))}
              </select>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  N° de pièce
                </label>
                <input
                  value={values.numpiece}
                  onChange={onChange}
                  type="text"
                  name="numpiece"
                  tabIndex={10}
                  className="form-control input-xlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Localisation
                </label>
                <input
                  value={values.localisation}
                  onChange={onChange}
                  type="text"
                  name="localisation"
                  tabIndex={11}
                  className="form-control input-xxlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Fonction
                </label>
                <input
                  value={values.fonction}
                  onChange={onChange}
                  name="fonction"
                  type="text"
                  tabIndex={12}
                  className="form-control input-xxlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Téléphone
                </label>
                <input
                  value={values.tel}
                  onChange={onChange}
                  name="tel"
                  type="text"
                  tabIndex={13}
                  className="form-control input-xlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Célullaire
                </label>
                <input
                  value={values.cel}
                  onChange={onChange}
                  name="cel"
                  type="text"
                  tabIndex={14}
                  className="form-control input-xlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Adresse
                </label>
                <input
                  value={values.adresse}
                  onChange={onChange}
                  name="adresse"
                  type="text"
                  tabIndex={15}
                  className="form-control input-xxlarge"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Email
                </label>
                <input
                  value={values.email}
                  onChange={onChange}
                  name="email"
                  type="email"
                  tabIndex={16}
                  className="form-control input-xxlarge text-lowercase"
                  placeholder=""
                />
              </span>
            </label>
            <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Pièce numérisée(pdf) *
                </label>
                <input
                  type="file"
                  name="piece"
                  accept=".pdf"
                  onChange={onPiece}
                  tabIndex={17}
                />
              </span>
            </label>
            {/* information supplementaire */}
            {values.situmatri === "Marié(e)" && (
              <>
                <label
                  style={{ width: "100%" }}
                  className="alert alert-info text-center"
                >
                  INFORMATIONS SUR LE CONJOINT OU LA CONJOINTE
                </label>
                <label className="block clearfix">
                  <span className="block">
                    <label htmlFor="text" className="col-lg-3">
                      Nom
                    </label>
                    <input
                      value={values.nomconjoint}
                      type="text"
                      onChange={onChange}
                      name="nomconjoint"
                      tabIndex={18}
                      className="form-control input-xxlarge"
                      placeholder=""
                    />
                  </span>
                </label>
                <label className="block clearfix">
                  <label htmlFor="text" className="col-lg-3">
                    Nationalité
                  </label>
                  <select
                    value={values.natConjoint}
                    onChange={onChange}
                    name="natConjoint"
                    tabIndex={19}
                    className="form-control input-xxlarge"
                  >
                    <option value={""} />
                    {pays?.map((item, key) => (
                      <option key={key} value={item.ID}>
                        {item.NATIONNALITE}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="block clearfix">
                  <span className="block">
                    <label htmlFor="text" className="col-lg-3">
                      Date de naissance
                    </label>
                    <input
                      value={values.dateNaissConjoint}
                      onChange={onChange}
                      name="dateNaissConjoint"
                      type="date"
                      tabIndex={20}
                      className="forma-control date-picker text-center"
                      data-date-format="dd/mm/yyyy"
                      placeholder=""
                    />
                  </span>
                </label>
                <label className="block clearfix">
                  <span className="block">
                    <label htmlFor="text" className="col-lg-3">
                      Lieu de naissance
                    </label>
                    <input
                      onChange={onChange}
                      value={values.lieuNaissConjoint}
                      name="lieuNaissConjoint"
                      type="text"
                      tabIndex={21}
                      className="form-control input-xxlarge"
                      placeholder=""
                    />
                  </span>
                </label>
                <label className="block clearfix">
                  <span className="block">
                    <label htmlFor="text" className="col-lg-3">
                      Fonction
                    </label>
                    <input
                      value={values.fonctionconjoint}
                      onChange={onChange}
                      name="fonctionconjoint"
                      type="text"
                      tabIndex={22}
                      className="form-control input-xxlarge"
                      placeholder=""
                    />
                  </span>
                </label>
              </>
            )}

            {/* fin information supplementaire */}
          </span>
        </label>
      )}
    </>
  );
};

export default FormSaissirClient;
