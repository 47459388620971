import React, { useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import { getListeClient } from "../../../services/dossier";
import SaisirClient from "./saissirClient/SaisirClient";
import DocDelivreClient from "./DocDelivreClient";
import DetailDossierClient from "./detailDossierClient/DetailDossierClient";
import DocFournirClient from "./docFournirClient/DocFournirClient";
import { Navigate } from "react-router-dom";

const Dossier = ({ enableTable = false }) => {
  const [flash, setFlash] = useState({ success: null, error: null });
  const [search, setSearch] = useState({ search: "" });
  const [idClient, setIdClient] = useState(null);
  const [dossiers, setDossiers] = useState([]);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);

  // pagination code
  const [isLoading, setIsloading] = useState(false);
  let PageSize = 10;

  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  // pour les onglets
  const [onglet, setOnglet] = useState({
    one: "active",
    two: "",
    three: "",
    four: "",
  });

  const [dataOnglet2, setDataOnglet2] = useState([]);
  const [dataOnglet3, setDataOnglet3] = useState([]);

  const listeClient = async () => {
    setIsloading(true);
    getListeClient()
      .then(({ data }) => {
        const d = page(1, data.data);
        // console.log(data.data);
        setCurrentTableData(d);
        setTableData(data.data);
        setFlash({ success: data.message });
        const coutPage = parseInt(data.data.length / PageSize);
        setTotalPage(coutPage);
        setIsloading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };
  const onRecherche = (e) => {
    e.preventDefault();
    setIsloading(true);
    if (e.key === "Enter") {
      getListeClient(search.search)
        .then(({ data }) => {
          const d = page(1, data.data);
          // console.log(data.data);
          setCurrentTableData(d);
          setTableData(data.data);
          setFlash({ success: data.message });
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
          setIsloading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (enableTable) {
      listeClient();
    }
    console.log(onglet)
  }, [onglet.two]);
  return (
    <Layout>
      <section className="col-xs-12">
        <div className="row">
          {/* <div className="col-xs-1" /> */}
          <div className="form-horizontal col-xs-12 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Gestion des dossiers</h5>
                <div className="navbar-form navbar-right form-search">
                  <div id="ContentPlaceHolder1_Panel1">
                    <span className="input-icon">
                      <input
                        type="text"
                        value={search.search}
                        name="search"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                        onChange={onChangeSearch}
                        onKeyUpCapture={onRecherche}
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <input
                        type="submit"
                        name="ctl00$ContentPlaceHolder1$BTRECH"
                        value="OK"
                        id="ContentPlaceHolder1_BTRECH"
                        tabIndex={2}
                        className="btn-info btn-sm MasquerColonne"
                        style={{ borderStyle: "None" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  <ul id="Ul1" className="nav nav-tabs">
                    <li id="onglet_one"
                      className={onglet.one}
                      onClick={(e) => {
                        const el = document.getElementById('onglet_two');
                        const el_doss = document.getElementById('Dossier');
                        if(el != null && el.classList.contains("active")){
                          el.classList.remove('active');
                        }
                        if(el_doss!= null && el_doss.classList.contains("active")){
                          el_doss.classList.remove('active');
                        }
                        setOnglet({
                          ...onglet,
                          one: "active",
                          two: "",
                          three: "",
                          four: "",
                        })
                      }
                      }
                    >
                      <a data-toggle="tab" href="/dossier-client/dossierclt#Client">
                        Saisie du client
                      </a>
                    </li>
                    {onglet.two === "active" && (
                      <>
                        <li id = "onglet_two" className={onglet.two}>
                          <a data-toggle="tab" href="#Dossier">
                            Détails du dossier du client
                          </a>
                        </li>
                      </>
                    )}
                    {onglet.three === "active" && (
                      <>
                        <li className={onglet.two}>
                          <a data-toggle="tab" href="#Dossier">
                            Détails du dossier du client
                          </a>
                        </li>
                        <li className={onglet.three}>
                          <a data-toggle="tab" href="#Piececlt">
                            Documents fournis par le client
                          </a>
                        </li>
                        <li className={onglet.four}>
                          <a data-toggle="tab" href="#Pieceacteur">
                            Documents délivrés au client
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="tab-content">
                    <div id="Client" className={`tab-pane ${onglet.one}`}>
                      {/* saissir client  */}
                      <SaisirClient
                        setIsLoadingGlobal={setIsLoadingGlobal}
                        setIdClient={setIdClient}
                        setOnglet={setOnglet}
                        onglet={onglet}
                        currentTableData={currentTableData}
                        onPageChange={onPageChange}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        setIsloading={setIsloading}
                        setCurrentTableData={setCurrentTableData}
                        setTableData={setTableData}
                        setDataOnglet2={setDataOnglet2}
                        setDossiers={setDossiers}
                      />
                    </div>
                    <div id="Dossier" className={`tab-pane ${onglet.two}`}>
                      <DetailDossierClient
                        isLoadingGlobal={isLoadingGlobal}
                        setIsLoadingGlobal={setIsLoadingGlobal}
                        idClient={idClient}
                        dataOnglet2={dataOnglet2}
                        setDataOnglet2={setDataOnglet2}
                        setDataOnglet3={setDataOnglet3}
                        onglet={onglet}
                        setOnglet={setOnglet}
                        dossiers={dossiers}
                        setDossiers={setDossiers}
                      />
                    </div>
                    <div id="Piececlt" className={`tab-pane ${onglet.three}`}>
                      <DocFournirClient
                        isLoadingGlobal={isLoadingGlobal}
                        setIsLoadingGlobal={setIsLoadingGlobal}
                        dataOnglet3={dataOnglet3}
                        setOnglet={setOnglet}
                      />
                    </div>
                    <div id="Pieceacteur" className={`tab-pane ${onglet.four}`}>
                      <DocDelivreClient setOnglet={setOnglet} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dossier;
