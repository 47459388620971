import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getCommuneByVille } from "../../services/commune";
import { getPays } from "../../services/pays";
import { getQuartierByCommune } from "../../services/quartier";
import { getSecteurByQuartier } from "../../services/secteur";
import { getOperateurImmobiliers } from "../../services/operateurImmobilier";
// import { getOperateurImmobilier } from "../../services/operateurImmobilier";
import { getCirconscriptionFoncier } from "../../services/circonscriptionFonciers";
import { getVillesByPays } from "../../services/ville";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import {
  getOperationImmobiliere,
  createOperationImmobiliere,
  updateOperationImmobiliere,
  deleteOperationImmobiliere
} from "../../services/operationImmobilier";
// import axios from "axios";
const OperationImmobiliers = () => {
  const headTable = [
    { name: "Code", },
    { name: "Nom", },
    { name: "Nbre Lots", },
    { name: "", with: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const [operateurImmobiliers, setOperateurImmobiliers] = useState([]);
  const [pays, setPays] = useState([]);
  const [villes, setVilles] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [quartiers, setQuartiers] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [circonscriptionFonciers, setCirconscriptionFonciers] = useState([]);
  const [operationImmobilieres, setOperationImmobilieres] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "success" });

  const _getPays = async () => {
    const { data } = await getPays();
    setPays(data);
  };

  const handleChange = (e) => {

    setValues({ ...values, [e.target.name]: e.target.value });

  }

  const [values, setValues] = useState({
    idoperateurimmo: "",
    idpays: "",
    idville: "",
    idcommune: "",
    idquartier: "",
    idsecteur: "",
    idcfonciere: "",
    code: "",
    eventheure: "",
    nbrelot: "",
    event: "",
    LIBELLE: "",
    libelle: "",
  });

  const _getOperateurImmobiliers = async () => {
    const { data } = await getOperateurImmobiliers();
    setOperateurImmobiliers(data);
    _getPays();
  };


  // pagination code
  let PageSize = 10;

  const [currentTableData, setCurrentTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), operationImmobilieres);
    setCurrentTableData(d);
    // console.log(d);
  };
  // setOperateurImmobiliers
  // fin code pagination
  // selectionner le pays pour  afficher la ville

  // selectionner l'opérateur immobilier pour afficher le pays
  const onSelectOperateurImmobilier = async (e) => {
    setIsLoading(true);
    setValues({ ...values, idoperateurimmo: e.target.value });
    setCurrentTableData([]);
    const id = e.target.value
    getOperationImmobiliere(id)
      .then((res) => {
        console.log(res);
        const { data } = res;
        setFlash({ message: res.message, color: "success" });
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setOperationImmobilieres(data);
        setIsLoading(false);

      })
      .catch((err) => {
        console.log('error');

        console.log(err);

      });
  };
  const onSelectPays = async (e) => {
    setIsLoading(true);
    setValues({ ...values, idpays: e.target.value });
    // setCurrentTableData([]);
    setVilles([]);
    setCirconscriptionFonciers([]);
    setSecteurs([]);
    setQuartiers([]);
    setCommunes([]);
    setQuartiers([]);

    getVillesByPays({ id: e.target.value })
      .then((res) => {
        const { data } = res;
        setVilles(data);
        setIsLoading(false);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // selectionner la ville pour  afficher la commune
  const onSelectVille = (e) => {
    setIsLoading(true);
    setValues({ ...values, idville: e.target.value });
    setCirconscriptionFonciers([]);
    setSecteurs([]);
    setQuartiers([]);
    setCommunes([]);
    setQuartiers([]);
    getCommuneByVille({ idville: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setCommunes(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // selectionner la commune  pour  afficher le quartier
  const onSelectCommune = (e) => {
    setIsLoading(true);
    setValues({ ...values, idcommune: e.target.value });
    setCirconscriptionFonciers([]);
    setSecteurs([]);
    setQuartiers([]);
    setQuartiers([]);

    getQuartierByCommune({ idcommune: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setQuartiers(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // selectionner le quartier pour  afficher le secteur
  const onSelectQuartier = (e) => {
    setIsLoading(true);
    setValues({ ...values, idquartier: e.target.value });
    setSecteurs([]);
    setCirconscriptionFonciers([]);
    setSecteurs([]);
    getSecteurByQuartier({ idquartier: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setSecteurs(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // selectionner le secteur pour afficher la circonscription
  const onSelectSecteur = (e) => {
    setIsLoading(true);
    setValues({ ...values, idsecteur: e.target.value });
    setCirconscriptionFonciers([]);
    getCirconscriptionFoncier({ idsecteur: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setCirconscriptionFonciers(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectCirconscriptionFoncier = (e) => {

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // getOperationImmobiliere,
    // console.log(isLoading);
    if (current) {
      // update

      if (values.idoperateurimmo.length === 0 || values.code.length === 0 || values.id.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      updateOperationImmobiliere(values)
        .then((res) => {
          setFlash({ message: res.data.message, color: "success" });
          setValues({ ...values, code: "", libelle: "", nbrelot: "" });
          getOperationImmobiliere(values.idoperateurimmo)
            .then((res) => {
              console.log(res);
              const { data } = res;
              setFlash({ message: res.message, color: "success" });
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setOperationImmobilieres(data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log('error');

              console.log(err);

            });
        })
        .catch(({ response }) => {
          setFlash({ message: response.data.message, color: "danger" });
        });
    } else {
      //  create
      if (values.idoperateurimmo.length === 0 || values.code.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      createOperationImmobiliere(values)
        .then((res) => {
          setFlash({ message: res.data.message, color: "success" })
          setValues({ ...values, code: "", libelle: "", nbrelot: "" });
          setCurrentTableData([]);
          getOperationImmobiliere(values.idoperateurimmo)
            .then((res) => {
              console.log(res);
              const { data } = res;
              setFlash({ message: res.message, color: "success" });
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setOperationImmobilieres(data);
              setIsLoading(false);

            })
            .catch((err) => {
              console.log('error');

              console.log(err);

            });
        })
        .catch((response) => {
          console.log("error messaage")
          console.log(response)
          //setFlash({ message: response.data.message, color: "danger" });
        });
    }
  };

  // const [_idCirconscriptionFonciere, setIdCirconscriptionFonciere] = useState(null);
  // const onclick = (data) => {
  //   // console.log(data);
  //   setCurrent(data);
  //   setIdCirconscriptionFonciere(data.IdCIRCONSCRIPTIONFONCIERE);
  //   setValues({ ...values, libelle: data.LIBELLE, code: data.CONTACTS });
  // };

  // const [_idcfonciere, setidcfonciere] = useState(null);
  const onclick = (data) => {
    // console.log(data);
    setCurrent(data);
    // setidcfonciere(data.IDCFONCIERE);
    setValues({
      ...values, id: data.ID, idoperateurimmo: data.IDOPERATEURIMMO, idpays: data.IDPAYS, idville: data.IDVILLE, idcommune: data.IDCOMMUNE,
      idquartier: data.IDQUARTIER, idsecteur: data.IDSECTEUR, code: data.CODE, libelle: data.LIBELLE, nbrelot: data.NBRELOT
    });
  };

  const onDelete = async () => {
    // console.log('current');
    if (window.confirm("Voulez vous supprimer?")) {
      const data = { id: current.ID, libelle: current.LIBELLE, code: current.CODE, nbrelot: current.NBRELOT };
      // console.log(idcfonciere);
      deleteOperationImmobiliere(data)
        .then(({ data }) => {
          setIsLoading(true);
          setFlash({ ...flash, color: "success", message: data.message });
          setValues({ ...values, code: "", libelle: "", nbrelot: "" });
          setCurrentTableData([]);
          getOperationImmobiliere(values.idoperateurimmo)
            .then((res) => {
              console.log(res);
              const { data } = res;
              setFlash({ message: res.message, color: "success" });
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setOperationImmobilieres(data);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              setFlash({
                ...flash,
                color: "danger",
                message: err.response.data.message,
              });

            });
        })
        .catch((error) => {
          setIsLoading(false);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
        });
    }
  };
  //fin de delete
  useEffect(() => {
    _getOperateurImmobiliers();
    getPays();
    // textImmobilier();
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Saisie des opérations immobilières</h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      opérateur
                    </label>
                    <select
                      name=" idoperateurimmo"
                      className="form-control input-xxlarge"
                      onChange={onSelectOperateurImmobilier}
                    >
                      <option value="-1"></option>
                      {operateurImmobiliers?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Pays
                    </label>
                    <select
                      name="idpays"
                      className="form-control input-xxlarge"
                      onChange={onSelectPays}
                    >
                      <option value="-1"></option>
                      {pays?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.NOM}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Ville
                    </label>
                    <select
                      name="idville"
                      className="form-control input-xxlarge"
                      onChange={onSelectVille}
                    >
                      <option value="-1"></option>
                      {villes?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Commune
                    </label>
                    <select
                      name="idcommune"
                      className="form-control input-xxlarge"
                      onChange={onSelectCommune}
                    >
                      <option value="-1"></option>
                      {communes?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Quartier
                    </label>
                    <select
                      name="idquartier"
                      className="form-control input-xxlarge"
                      onChange={onSelectQuartier}
                    >
                      <option value="-1"></option>
                      {quartiers?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Secteur
                    </label>
                    <select
                      name="idsecteur"
                      className="form-control input-xxlarge"
                      onChange={onSelectSecteur}
                    >
                      <option value="-1"></option>
                      {secteurs?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Circonscription Fonciere
                    </label>
                    <select
                      name="idsecteur"
                      className="form-control input-xxlarge"
                      onChange={onSelectCirconscriptionFoncier}
                    >
                      <option value="-1"></option>
                      {circonscriptionFonciers?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Code
                    </label>
                    <input
                      value={values.code}
                      onChange={handleChange}
                      name="code"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Libellé
                    </label>
                    <input
                      value={values.libelle}
                      onChange={handleChange}
                      name="libelle"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Nbre de lot
                    </label>
                    <input
                      value={values.nbrelot}
                      onChange={handleChange}
                      name="nbrelot"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Enregistrer"
                      className="btn btn-success"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        onClick={onDelete}
                        value="Supprimer"
                        className="btn btn-danger"
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => { }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table title={"Liste des opérations immobilières"} headTable={headTable}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.CODE}</td>
              <td>{item.LIBELLE}</td>
              <td>{item.NBRELOT}</td>
              <td className="text-center" align="right">
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
              </td>
            </tr>
          ))}
          {/* <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr> */}
          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default OperationImmobiliers;
