import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const ListeRubriquePaiementsLogements = async (d) => {

    return await axios.post("setting/list/rubriquePaie", d, config);

};
export const createRubriquePaiementsLogements = async (d) => {

    return await axios.post("setting/create/rubriquePaie", d, config);
    // try {
    //     const { data } = await axios.post("setting/create/rubriquePaie", d, config);
    //     return data;&
    // } catch (error) {
    //     return error;
    // }
};

export const updateRubriquePaiementsLogements = async (d) => {
    try {
        const { data } = await axios.post("setting/update/rubriquePaie", d, config);
        return data;
    } catch (error) {
        return error;
    }
};

export const getRubriquePaiementsLogements = async () => {

    return await axios.list("setting/list/rubriquePaie", config);
    // try {
    //     const { data } = await axios.post("setting/list/rubriquePaie", config);
    //     return data;
    // } catch (error) {
    //     return error;
    // }
};

export const deleteRubriquePaiementLogement = async () => {

    return await axios.post("setting/delete/rubriquePaie", config);
    // try {
    //     return data;
    // } catch (error) {
    //     return error;
    // }

};