import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};
export const getUtilisateurLogements = async () => {
    try {
        const { data } = await axios.get("setting/list/type_utlisation_log", config);
        return data;
    } catch (error) {
        return error;
    }
};

export const createUtilisateurLogement = async (d) => {
    try {
        const { data } = await axios.post("setting/create/type_utlisation_log", d, config);
        return data;
    } catch (error) {
        return error;
    }
};

export const updateUtilisateurLogement = async (d) => {
    try {
        const { data } = await axios.post("setting/update/type_utlisation_log", d, config);
        return data;
    } catch (error) {
        return error;
    }
};

export const deleteUtilisateurLogement = async (data) =>
    await axios.post("setting/delete/type_utiiisation_log", data, config);
