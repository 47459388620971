import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getNatureContrats = async () => await axios.get("setting/list/natcont", config);
// {

//   return await axios.get("setting/list/natcont", config);
//   try {
//     const { data } = await axios.get("setting/list/natcont", config);
//     return data;
//   } catch (error) {
//     return error;
//   }
// };

export const createNatureContrat = async (d) => {
  return await axios.post("setting/create/natcont", d, config);
  // try {
  //   return data;
  // } catch (error) {
  //   return error;
  // }
};

export const updateNatureContrat = async (d) => {
  return await axios.post("setting/update/natcont", d, config);
  // try {
  //   return data;
  // } catch (error) {
  //   return error;
  // }
};

export const deleteNatureContrat = async (d) => {
  await axios.post("setting/delete/natcont", d, config);
}