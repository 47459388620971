import React from "react";
import NavbarActeur from "./NavBarActeur";
import SidebarActeur from "./SideBarActeur";

const LayoutActeur = ({ children }) => {
  return (
    <>
      {/* navbar */}
      <NavbarActeur />
      <div className="main-container" id="main-container">
        {/* Sidebar */}
        <SidebarActeur />
        <div className="main-content" id="haut">
          <div className="breadcrumbs" id="breadcrumbs">
            <ul className="breadcrumb">
              <li>
                <i className="ace-icon fa fa-home home-icon orange" />
                <span className="orange">
                  SYSTEME DE GESTION DES TITRES DE PROPRIETES FONCIERES
                </span>
              </li>
            </ul>
          </div>
          {/* Zone d'affichage des pages */}
          <div className="page-content">
            <header className="masterentete"></header>
            {/* content */}
            <div className="row">
              <section className="col-xs-12">
                <div>{children}</div>
              </section>
            </div>
          </div>
        </div>
        {/* Pied de page */}
        <div className="footer">
          <div className="footer-inner">
            <div className="footer-content">
              <span className="bigger-120">
                <span className="green bolder">©</span> Copyright 2022 - Catef
                SA tous droits réservés
              </span>
            </div>
          </div>
        </div>
        <a
          className="btn-scroll-up btn btn-sm btn-inverse display"
          id="btn-scroll-up"
          href="#haut"
        >
          <i className="ace-icon fa fa-angle-double-up icon-only bigger-110" />
        </a>
      </div>
    </>
  );
};

export default LayoutActeur;
