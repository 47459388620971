import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import LayoutActeur from "./LayoutActeur";
import { registerClient, rechercheClient, updateClient } from "../../services/acteur/ajoutClient"
import { SpinnerCircular } from "spinners-react";

const AjouterNewClient = () => {
  const [current, setCurrent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "" });
  const [titlebtn, settitletitlebtn] = useState("Ajouter");
  const [titleForm, settitleForm] = useState("Ajouter un nouveau client");
  const ID_ACTEUR = localStorage.getItem("__user_acteur_id");
  let id_customer_update = localStorage.getItem('__id_customer_update')
  // console.log(ID_ACTEUR);
  const [stateForm, setStateForm] = useState({
    idacteur: parseInt(ID_ACTEUR),
    codeclient: "",
    nomclient: "",
    anciennom: "",
    codesecteur: "",
    codeoperat: "",
    operation: "",
    typelot: "",
    contactsclient: "",
    ilot: "",
    typelogement: "",
    lot: "",
    ancienlot: "",
    superficie: null,
    etat: "",
    codeprojet: "",
    nature: "",
    anciennom: ""
  });
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  // let titleForm = "Ajouter un nouveau client";

  let forms = [];
  if (id_customer_update) {
    forms = [
      { label: "Matricule", name: "codeclient", values: stateForm.codeclient, type: "text", disabled: true },
      { label: "Nom Client", name: "nomclient", values: stateForm.nomclient, type: "text", disabled: false },
      { label: "Contacts Client", name: "contactsclient", values: stateForm.contactsclient, type: "text", disabled: true },
      { label: "Code Secteur", name: "codesecteur", values: stateForm.codesecteur, type: "text", disabled: true },
      { label: "Code Opération", name: "codeoperat", values: stateForm.codeoperat, type: "text", disabled: true },
      { label: "Opération", name: "operation", values: stateForm.operation, type: "text", disabled: true },
      { label: "Type de Lot", name: "typelot", values: stateForm.typelot, type: "text", disabled: true },
      { label: "N° Ilot", name: "ilot", values: stateForm.ilot, type: "text", disabled: true },
      { label: "N° Lot", name: "lot", values: stateForm.lot, type: "text", disabled: false },
      { label: "Superficie", name: "superficie", values: stateForm.superficie, type: "number", disabled: true },
      {
        label: "Nature Contrat", name: "nature", values: stateForm.nature, disabled: true,
        table: [{ LIBELLE: "VENTE DE PATRIMOINE LOCATIF", ID: 1 }, { LIBELLE: "LOCATION VENTE (LV)", ID: 2 }]
      },
      { label: "Etat", name: "etat", values: stateForm.etat, disabled: false, table: [{ LIBELLE: "NON SOLDE", ID: 1 }, { LIBELLE: "SOLDE", ID: 2 }] },
    ];
  } else {
    forms = [
      { label: "Matricule", name: "codeclient", values: stateForm.codeclient, type: "text", disabled: false },
      { label: "Nom Client", name: "nomclient", values: stateForm.nomclient, type: "text", disabled: false },
      { label: "Contacts Client", name: "contactsclient", values: stateForm.contactsclient, type: "text", disabled: false },
      { label: "Code Secteur", name: "codesecteur", values: stateForm.codesecteur, type: "text", disabled: false },
      { label: "Code Opération", name: "codeoperat", values: stateForm.codeoperat, type: "text", disabled: false },
      { label: "Opération", name: "operation", values: stateForm.operation, type: "text", disabled: false },
      { label: "Type de Lot", name: "typelot", values: stateForm.typelot, type: "text", disabled: false },
      { label: "N° Ilot", name: "ilot", values: stateForm.ilot, type: "text", disabled: false },
      { label: "N° Lot", name: "lot", values: stateForm.lot, type: "text", disabled: false },
      { label: "Superficie", name: "superficie", values: stateForm.superficie, type: "number", disabled: false },
      {
        label: "Nature Contrat", name: "nature", values: stateForm.nature, disabled: false,
        table: [{ LIBELLE: "VENTE DE PATRIMOINE LOCATIF", ID: 1 }, { LIBELLE: "LOCATION VENTE (LV)", ID: 2 }]
      },
      { label: "Etat", name: "etat", values: stateForm.etat, disabled: false, table: [{ LIBELLE: "NON SOLDE", ID: 1 }, { LIBELLE: "SOLDE", ID: 2 }] },
    ];
  }


  const btnStyle = [
    { value: titlebtn, type: "submit", className: "btn-success" },
    { value: "Annuler", type: "button", className: "btn-warning" },
  ];

  const onChange = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value })
  };

  const enregistrementClient = async (d) => {
    setIsLoading(true);
    // console.log(d);
    if (!id_customer_update) {
      registerCustomer(d)
    } else {
      updateCustomer(d)
    }
  };

  const rechercheCustomer = async () => {
    setIsLoading(true);
    let data = { codeclient: id_customer_update }
    rechercheClient(data)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          reset();
          setCurrent(res.data);
          let val = res.data[0]
          setStateForm({
            ...stateForm,
            codeclient: id_customer_update,
            nomclient: val.NOMCLIENT,
            anciennom: val.NOMCLIENT,
            codesecteur: "",
            codeoperat: val.CODEPROJET,
            operation: val.NOMPROJET,
            typelot: val.TYPELOGEMENT,
            contactsclient: val.CONTACTSCLIENT,
            ilot: val.ILOT,
            typelogement: val.TYPELOGEMENT,
            lot: val.LOT,
            ancienlot: val.LOT,
            superficie: val.SUPERFICIE,
            etat: val.ETAT == null || val.ETAT == "" ? "NON SOLDE" : val.ETAT,
            codeprojet: val.CODEPROJET,
            nature: ""
          });
        } else {
          setFlash({ message: data.message, color: "danger" })
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setFlash({ message: "Une erreur s'est produite. Veuillez réessayer", color: "danger" })
        console.log(err);
      })
    setIsLoading(false);
  };

  const registerCustomer = (d) => {
    registerClient(d)
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setFlash({ message: data.message, color: "success" })
          reset();
          setCurrent(null);
          localStorage.removeItem('__id_customer_update')
          setStateForm({
            ...stateForm,
            codeclient: "",
            nomclient: "",
            codesecteur: "",
            codeoperat: "",
            operation: "",
            typelot: "",
            contactsclient: "",
            ilot: "",
            typelogement: "",
            lot: "",
            superficie: null,
            etat: "",
            codeprojet: "",
            nature: ""
          });
        } else {
          setFlash({ message: data.message, color: "danger" })
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setFlash({ message: "Une erreur s'est produite. Veuillez réessayer", color: "danger" })
        console.log(err);
      })
    setIsLoading(false);
  }

  const updateCustomer = (d) => {
    updateClient(d)
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setFlash({ message: data.message, color: "success" })
          reset();
          setCurrent(null);
          localStorage.removeItem('__id_customer_update')
          settitleForm("Ajouter un nouveau client")
          id_customer_update = null
          setStateForm({
            ...stateForm,
            codeclient: "",
            nomclient: "",
            codesecteur: "",
            codeoperat: "",
            operation: "",
            typelot: "",
            contactsclient: "",
            ilot: "",
            typelogement: "",
            lot: "",
            superficie: null,
            etat: "",
            codeprojet: "",
            nature: ""
          });
        } else {
          setFlash({ message: data.message, color: "danger" })
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setFlash({ message: "Une erreur s'est produite. Veuillez réessayer", color: "danger" })
        console.log(err);
      })
    setIsLoading(false);
  }

  const onSubmit = async (data) => {
    if (!id_customer_update) {
      if (data.codeclient.length === 0 || data.nomclient.length === 0 ||
        data.codeoperat.length === 0 || data.operation.length === 0 ||
        data.lot.length === 0 || data.nature.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" })
        return false;
      }
    } else {
      if (data.codeclient.length == 0 || data.nomclient.length === 0 || data.lot.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" })
        return false;
      }
    }
    try {
      const datas = await enregistrementClient(data);
      console.log("ENREGISTREMENT DATA", datas);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (id_customer_update) {
      settitleForm('Modifier le nom ou le numéro de compte d\'un client')
      settitletitlebtn("Modifier")
      rechercheCustomer()
    }
  }, []);


  return (
    <LayoutActeur>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">{titleForm}</h5>
              </div>

              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  <SpinnerCircular style={{ color: "#1B6AAA" }} enabled={isLoading} />
                  {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
                  {forms.map((item, index) => {
                    setValue(item.name, item.values);
                    if (Array.isArray(item?.table)) {
                      return (
                        <label key={index} className="block clearfix">
                          <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                          <select {...register(item.name)}
                            onChange={onChange}
                            className="form-control input-xxlarge">
                            <option value="" />
                            {item.table.map((t, i) => (
                              <option key={i} value={t.ID}>{t.LIBELLE}</option>
                            ))}
                          </select>
                        </label>
                      );
                    } else {
                      return (
                        <label key={index} className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <input style={{ width: item?.width }}
                              onChange={onChange}
                              name={item.name}
                              type={item.type}
                              value={item.values}
                              disabled={item.disabled}
                              className="form-control input-xxlarge"
                              placeholder=""
                            />
                          </span>
                        </label>
                      );
                    }
                  })}
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    {btnStyle.map((btn, key) => {
                      return (<input
                        key={key}
                        type={`${btn.type}`}
                        value={`${btn.value}`}
                        disabled={isLoading}
                        className={`btn ${btn.className}`}
                        style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                        onClick={btn.value === "Annuler" && (() => { reset(); setCurrent(null); localStorage.removeItem('__id_customer_update') })}
                      />)
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </LayoutActeur>
  );
};

export default AjouterNewClient;
