import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { getOperationImmobilieres } from "../../services/operationImmobilier";
import { getIlots } from "../../services/ilots";
import { gettypeLogement } from "../../services/typeLogement";
import { getUtilisateurLogements } from "../../services/TypeUtilisateurLogement";
import ReactPaginate from "react-paginate";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import {
  listlot,
  createlot,
  updatelot,
  deletelot,
  Searchelistlot,
} from "../../services/lots";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

const Lots = () => {
  const headTable = [
    { name: "Type logement", width: "60%" },
    { name: "Type utilisation", width: "30%" },
    { name: " N° ilot cadastre" },
    { name: "N° lot cadastre" },
    { name: "N° lot", width: "5%" },
    { name: " Superficie" },
    { name: "", with: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [operationImmobiliers, setOperationImmobiliers] = useState([]);
  const [lots, setLots] = useState([]);
  const [ilots, setIlots] = useState([]);
  const [typeLogements, setTypeLogements] = useState([]);
  const [typeUtilisateurLogements, settypeUtilisateurLogements] = useState([]);
  const [dataFound, setDataFound] = useState([]);
  const [search, setSearch] = useState([]);
  const [values, setValues] = useState({
    idilot: "",
    idoperationimmo: "",
    idtypelogement: "",
    idtypeutilisatlog: "",
    idlot: "",
    numilotcadastre: "",
    numlotcadastr: "",
    surperficie: "",
    numlot: "",
  });

  const _getOperationImmobilieres = async () => {
    const { data } = await getOperationImmobilieres();
    setOperationImmobiliers(data.data);
  };

  const _gettypeLogement = async () => {
    const { data } = await gettypeLogement();
    setTypeLogements(data);
  };

  const _getUtilisateurLogements = async () => {
    const { data } = await getUtilisateurLogements();
    settypeUtilisateurLogements(data);
  };

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeSearch = (e) => {
    setSearch({ ...values, search: e.target.value });
  };

  const onRecherche = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (e.key === "Enter") {
      listlot(values.search)
        .then((res) => {
          const { data } = res;
          setLots(data.data);
          setTableData(data.data);
          const d = page(1, data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
          setFlash({ message: res.data.message, color: "success" });
          setIsLoading(false);
          // console.log(data.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    Searchelistlot(values).then((res) => {
      // console.log(res);
      setFlash({ message: res.data.message, color: "success" });
      setValues({
        ...values,
        numilotcadastre: "",
        numlotcadastr: "",
        surperficie: "",
        numlot: "",
        idtypeutilisatlog: "",
        idtypelogement: "",
      });
      listlot(values.search)
        .then((res) => {
          const { data } = res;
          setLots(data.data);
          setTableData(data.data);
          const d = page(1, data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
          setFlash({ message: res.data.message, color: "success" });
          setIsLoading(false);
          // console.log(data.data);
        })
        .catch((err) => {
          // console.log(err);
        });
      setValues({
        ...values,
        numilotcadastre: "",
        numlotcadastr: "",
        surperficie: "",
        numlot: "",
        idtypeutilisatlog: "",
        idtypelogement: "",
      });
    });
  };
  // selection operationImmobiliers

  const onSelectOperation = async (e) => {
    setIsLoading(true);
    setValues({ ...values, idoperationimmo: e.target.value });
    setCurrentTableData([]);
    setIlots([]);
    getIlots({ idoperationimmo: e.target.value })
      .then((res) => {
        const { data } = res;
        setIlots(data.data);
        setIsLoading(false);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setIsLoading(true);
  };

  // selection de liste ilots
  const onSelectIlot = (e) => {
    setValues({ ...values, idilot: e.target.value });
    setCurrentTableData([]);
    setIsLoading(true);
    listlot(e.target.value)
      .then((res) => {
        const { data } = res;
        setLots(data.data);
        setTableData(data.data);
        const d = page(1, data.data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.data.length / PageSize);
        setTotalPage(coutPage);
        setFlash({ message: res.data.message, color: "success" });
        setIsLoading(false);
        // console.log(data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  //type logement
  const onSelectTypeLogement = (e) => {
    setIsLoading(true);
    setValues({ ...values, idtypelogement: e.target.value });
  };

  const onSelectTypeUtilisateurLogement = (e) => {
    // setIsLoading(true);
    setValues({ ...values, idtypeutilisatlog: e.target.value });
    setCurrentTableData([]);
    getUtilisateurLogements({ idtypeutilisatlog: e.target.value })
      .then((res) => {
        const { data } = res;
        settypeUtilisateurLogements(data);
        setFlash({ message: res.data.message, color: "success" });
        setIsLoading(false);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // console.log(isLoading);
    if (current) {
      // update
      // let data = { ...values, id: current?.ID };
      // if (values.idtypeutilisatlog.length === 0 || values.idtypelogement.length === 0 || values.numilotcadastre.length === 0 || values.numlotcadastr.length === 0 || values.surperficie.length === 0 || values.numlot.length === 0) {
      //   setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      //   setIsLoading(false);
      //   return false;
      // }
      // console.log(data);
      updatelot(values)
        .then((res) => {
          // setFlash({ message: res.values.message, color: "success" });
          setValues({
            ...values,
            numilotcadastre: "",
            numlotcadastr: "",
            surperficie: "",
            numlot: "",
            idtypeutilisatlog: "",
            idtypelogement: "",
          });
          listlot(values.idtypeutilisatlog).then((res) => {
            const { data } = res;
            // console.log(data);
            setLots(data.data);
            setTableData(data.data);
            const d = page(1, data.data);
            setCurrentTableData(d);
            const coutPage = parseInt(data.data.length / PageSize);
            setTotalPage(coutPage);
            setFlash({ message: res.data.message, color: "success" });
            setIsLoading(false);
            // console.log(data.data);
          });
        })
        .catch((err) => {
          //console.log(err);
        });
      setCurrent(null);
      setValues({
        ...values,
        numilotcadastre: "",
        numlotcadastr: "",
        surperficie: "",
        numlot: "",
        idtypeutilisatlog: "",
        idtypelogement: "",
      });
    } else {
      //  create
      // console.log(values);
      createlot(values)
        .then((res) => {
          // console.log(res);
          setFlash({ message: res.data.message, color: "success" });
          setValues({
            ...values,
            numilotcadastre: "",
            numlotcadastr: "",
            surperficie: "",
            numlot: "",
            idtypeutilisatlog: "",
            idtypelogement: "",
          });
          listlot(values.idtypeutilisatlog)
            .then((res) => {
              const { data } = res;
              // console.log(data);
              setLots(data.data);
              setTableData(data.data);
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setFlash({ message: res.data.message, color: "success" });
              setIsLoading(false);
              // console.log(data.data);
            })
            .catch((err) => {
              // console.log(err);
            });
          setValues({
            ...values,
            numilotcadastre: "",
            numlotcadastr: "",
            surperficie: "",
            numlot: "",
            idtypeutilisatlog: "",
            idtypelogement: "",
          });
        })
        .catch(({ response }) => {
          setFlash({ message: response.data.message, color: "danger" });
        });
    }
  };
  // const [_idTypeutilisatlog, setTypeutilisatlog] = useState(null);
  const onclick = (data) => {
    // console.log(data);
    setCurrent(data);
    // setTypeutilisatlog(data.IDTYPEUTILISALOG);
    setValues({
      ...values,
      numilotcadastre: data.IDILOT,
      numlotcadastr: data.NUMLOTCADASTRE,
      surperficie: data.SUPERFICIE,
      numlot: data.NUMLOT,
      idtypelogement: data.IDTYPELOGEMENT,
      idtypeutilisatlog: data.IDTYPEUTILISATLOG,
    });
  };

  const onDelete = async () => {
    console.log("delete");
    if (window.confirm("Voulez vous supprimer?")) {
      const delet = {
        id: current.ID,
        libelle: current.LIBELLE,
        numilotcadastre: current.NUMILOTCADASTRE,
        numlotcadastr: current.NUMLOTCADASTR,
        surperficie: current.SURPERFICIE,
      };
      // console.log(_idTypeutilisatlog)
      deletelot(delet)
        .then(({ data }) => {
          setIsLoading(true);
          setFlash({ ...flash, color: "success", message: data.message });
          // setCommunes([]);
          setValues({
            ...values,
            libelle: "",
            numilotcadastre: "",
            numlotcadastr: "",
            surperficie: "",
            numlot: "",
            idilot: "",
            idoperationimmo: "",
            idtypelogement: "",
            idtypeutilisatlog: "",
          });
          getIlots(values.idtypeutilisatlog);
          // getOperateurImmobilier({ idquartier: _idQuartier })
          //   .then((res) => {
          //     // setCurrent(null);
          //     const { data, message } = res;
          //     console.log(res);
          //     const d = page(1, data);
          //     setCurrentTableData(d);
          //     // setFlash({ message, color: "success" });
          //     const coutPage = parseInt(data.length / PageSize);
          //     setTotalPage(coutPage);
          //     setOperateurImmobiliers(data);
          //     setIsLoading(false);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);

          // setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          setLots([]);
          // setValues({ ...values, libelle: "", numilotcadastre: "", numlotcadastr: "", surperficie: "", numlot: "" });
          setValues({
            ...values,
            libelle: "",
            numilotcadastre: "",
            numlotcadastr: "",
            surperficie: "",
            numlot: "",
            idilot: "",
            idoperationimmo: "",
            idtypelogement: "",
            idtypeutilisatlog: "",
          });
          getIlots(values.idtypeutilisatlog);
          // getOperateurImmobilier({ idquartier: values.idquartier })
          //   .then((res) => {
          //     // console.log(res);
          //     const { data } = res;
          //     const d = page(1, data);
          //     setCurrentTableData(d);
          //     // setFlash({ message, color: "success" });
          //     const coutPage = parseInt(data.length / PageSize);
          //     setTotalPage(coutPage);
          //     setOperateurImmobiliers(data);
          //     setIsLoading(false);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        });
    }
  };

  const onSearch = (id) => {
    setIsLoading(true);
    Searchelistlot(id).then(({ data }) => {
      // console.log(data);
      const d = page(1, data.data);
      setTableData(data.data);
      setCurrentTableData(d);
      const coutPage = parseInt(data.data.length / PageSize);
      setTotalPage(coutPage);
      setFlash({ message: data.message, color: "success" });

      setIsLoading(false);
    });
  };

  // console.log(dataFound);

  useEffect(() => {
    _getOperationImmobilieres();
    _gettypeLogement();
    _getUtilisateurLogements();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              {/* <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title"> Saisie des Lots</h5>
                <div className="navbar-form navbar-right form-search">
                  <div id="ContentPlaceHolder1_Panel1">
                    <span className="input-icon">
                      <input
                        type="text"
                        value={search.search}
                        name="search"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                        onChange={onChangeSearch}
                        onKeyUpCapture={onRecherche}
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <input
                        type="submit"
                        name="ctl00$ContentPlaceHolder1$BTRECH"
                        value="OK"
                        id="ContentPlaceHolder1_BTRECH"
                        tabIndex={2}
                        className="btn-info btn-sm MasquerColonne"
                        style={{ borderStyle: "None" }}
                      />
                    </span>
                  </div>
                </div>
              </div> */}
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash.message !== null && (
                    <Alert color={flash.color} message={flash.message} />
                  )}
                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Opération (*)
                    </label>
                    <select
                      name="idoperationimmo"
                      className="form-control input-xxlarge"
                      onChange={onSelectOperation}
                    >
                      <option value="-1"></option>
                      {operationImmobiliers?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Ilots
                    </label>
                    <select
                      name="idilot"
                      className="form-control input-xxlarge"
                      onChange={onSelectIlot}
                    >
                      <option selected value=""></option>
                      {ilots?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Type lot
                    </label>
                    <select
                      name="idtypelogement"
                      className="form-control input-xxlarge"
                      onChange={onSelectTypeLogement}
                      value={values.idtypelogement}
                    >
                      <option value=""></option>
                      {typeLogements?.map((item, key) => {
                        // console.log(item);
                        return (
                          <option key={key} value={item.ID}>
                            {item.LIBELLE}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Utilisation
                    </label>
                    <select
                      name="idtypeutilisatlog"
                      className="form-control input-xxlarge"
                      onChange={onSelectTypeUtilisateurLogement}
                      value={values.idtypeutilisatlog}
                    >
                      <option value=""></option>
                      {typeUtilisateurLogements?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      N° ilot cadastre
                    </label>
                    <input
                      name="numilotcadastre"
                      value={values.numilotcadastre}
                      onChange={handleChange}
                      type="text"
                      style={{ width: "30%" }}
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      N° lot cadastre
                    </label>
                    <input
                      name="numlotcadastr"
                      value={values.numlotcadastr}
                      onChange={handleChange}
                      type="text"
                      style={{ width: "30%" }}
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      N° lot
                    </label>
                    <input
                      name="numlot"
                      value={values.numlot}
                      onChange={handleChange}
                      type="text"
                      style={{ width: "15%" }}
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Superficie(M²)
                    </label>
                    <input
                      name="surperficie"
                      value={values.surperficie}
                      onChange={handleChange}
                      type="text"
                      style={{ width: "15%" }}
                      className="form-control input-xxlarge"
                    />
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Enregistrer"
                      className="btn btn-success"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        value="Supprimer"
                        onDelete={onDelete}
                        className="btn btn-danger"
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => {
                        setCurrent(null);
                        setValues({
                          ...values,
                          numilotcadastre: "",
                          numlotcadastr: "",
                          surperficie: "",
                          numlot: "",
                          idilot: "",
                          idoperationimmo: "",
                          idtypelogement: "",
                          idtypeutilisatlog: "",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        // <Table title={"Liste des Lots"} headTable={headTable} isSearch={true} setTableData={setSearch}>
        //   {currentTableData?.map((item, key) => (
        //     <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
        //       <td>{item.TYPELOGEMENT}</td>
        //       <td>{item.TYPEUTILISATION}</td>
        //       <td>{item.NUMILOTCADASTRE}</td>
        //       <td>{item.NUMLOTCADASTRE}</td>
        //       <td>{item.NUMLOT}</td>
        //       <td>{item.SUPERFICIE}</td>
        //       <td className="text-center" align="right">
        //         <img
        //           onClick={() => onclick(item)}
        //           style={{ width: 15, height: 10 }}
        //           src={"/Catef_files/modifier.png"}
        //         />
        //       </td>
        //     </tr>
        //   ))}
        //   <tr
        //     className="pagination-od"
        //     align="center"
        //     style={{
        //       color: "Black",
        //       backgroundColor: "#EFF3F8",
        //       fontWeight: "normal",
        //       whiteSpace: "nowrap",
        //     }}
        //   >
        //     <td colSpan={5}>
        //       {totalPage != null && (
        //         <ReactPaginate
        //           breakClassName={"page-item"}
        //           breakLinkClassName={"page-link"}
        //           containerClassName={"pagination"}
        //           pageClassName={"page-item"}
        //           pageLinkClassName={"page-link"}
        //           previousClassName={"page-item"}
        //           previousLinkClassName={"page-link"}
        //           nextClassName={"page-item"}
        //           nextLinkClassName={"page-link"}
        //           activeClassName={"active"}
        //           breakLabel="..."
        //           nextLabel="suivant >"
        //           onPageChange={onPageChange}
        //           pageRangeDisplayed={5}
        //           pageCount={totalPage + 1}
        //           previousLabel="< précédent"
        //           renderOnZeroPageCount={null}
        //         />
        //       )}
        //     </td>
        //   </tr>
        // </Table>

        <Table
          title={"Liste des lots"}
          headTable={headTable}
          isSearch={true}
          setSearch={setSearch}
          search={search}
          cb={onSearch}
          setDataSearched={setDataFound}
        >
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.TYPELOGEMENT}</td>
              <td>{item.TYPEUTILISATION}</td>
              <td>{item.NUMILOTCADASTRE}</td>
              <td>{item.NUMLOTCADASTRE}</td>
              <td>{item.NUMLOT}</td>
              <td>{item.SUPERFICIE}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  //           style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}

          {/* pagination */}
          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default Lots;

