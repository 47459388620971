import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Formulaire from "../../components/Formulaire";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
  createCirconscriptionFoncier,
  getCirconscriptionFoncier,
  updateCirconscriptionFoncier,
  deleteCirconscriptionFoncier,
} from "../../services/circonscriptionFonciers";

const CirconscriptionFoncieres = () => {
  const headTable = [
    { name: "Libelle", width: "100%" },
    { name: "", width: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Libelle (*)",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const { mutate } = useMutation(createCirconscriptionFoncier, {
    onSuccess: ({ message }) => {
      setFlash({ message, color: "success" });
      setIsLoading(true);
      getCirconscriptionFoncier()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setIsLoading(false);
        })
        .catch(({ response }) => { });
      // Invalidate and refetch
      // queryClient.invalidateQueries("circonscription-fonciers");
    },
  });

  const { mutate: updateMutate } = useMutation(updateCirconscriptionFoncier, {
    onSuccess: ({ message }) => {
      setFlash({ message, color: "success" });
      setIsLoading(true);
      getCirconscriptionFoncier()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setIsLoading(false);
        })
        .catch(({ response }) => { });
      // Invalidate and refetch
      // queryClient.invalidateQueries("circonscription-fonciers");
    },
  });

  const post = (data) => {
    if (data.libelle.length === 0) {
      setIsLoading(false);
      setFlash({ ...flash, message: "Veuillez renseigner les champs requis", color: "danger" });
      return false;
    }
    mutate(data)
  };
  const put = (data) => {
    if (data.libelle.length === 0) {
      setIsLoading(false);
      setFlash({ ...flash, message: "Veuillez renseigner les champs requis", color: "danger" });
      return false;
    }
    updateMutate(data);
  }
  const onclick = (data) => {
    setCurrent(data);
  };

  // delete

  const onDelete = async () => {
    if (window.confirm("Voulez vous supprimé")) {
      const data = { id: current.ID, nom: current.NOM };
      deleteCirconscriptionFoncier(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getCirconscriptionFoncier()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          getCirconscriptionFoncier()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  // delete

  useEffect(() => {
    setIsLoading(true);
    getCirconscriptionFoncier()
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setTableData(data);
        setFlash({ message, color: "success" });
        setIsLoading(false);
      })
      .catch(({ response }) => { });
  }, []);
  return (
    <Layout>
      <Formulaire
        current={current}
        setCurrent={setCurrent}
        cb={current ? put : post}
        forms={forms}
        title="Gestion des circonscriptions foncières"
        color={flash?.color}
        isLoading={isLoading}
        message={flash?.message}
        onDelete={onDelete}
      />

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table
          headTable={headTable}
          title={"Liste des circonscriptions foncieres"}
        >
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item?.LIBELLE}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}

          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default CirconscriptionFoncieres;
