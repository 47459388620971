import React, { useState } from "react";
import { useEffect } from "react";

const FormDocFournirClient = ({
  dataOnglet3,
  comboDocClient,
  values,
  setValues,
  buttonDisabled,
  setButtonDisabled
}) => {
  const onchange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangePiece = (e) => {
    setValues({ ...values, piece: e.target.files[0] });
  };

  useEffect(() => {
    // comboDocClient;
  }, []);
  return (
    <>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-3">
            Type document *
          </label>
          <select
            onChange={onchange}
            name="typedoc"
            tabIndex={37}
            className="form-control input-xxlarge"
          >
            <option selected="selected" value={""} />
            {comboDocClient?.map((item, key) => {
              return (
                <option key={key} value={item.ID}>
                  {item.LIBELLE}
                </option>
              );
            })}
          </select>
        </span>
      </label>
      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-3">
            N° Document *
          </label>
          <input
            onChange={onchange}
            value={values?.numordre}
            name="numordre"
            type="text"
            tabIndex={38}
            className="form-control input-medium text-uppercase"
            placeholder=""
          />
        </span>
      </label>

      <label className="block clearfix">
        <span className="block">
          <label htmlFor="text" className="col-lg-3">
            Document numérisé(pdf) *
          </label>
          <input
            type="file"
            accept=".pdf"
            onChange={onChangePiece}
            name={"piece"}
            className="form-control-file"
          //   tabIndex={44}
          />
        </span>
      </label>
      {values.piece?.size > 0 && dataOnglet3?.verifClotureDoss == "Non" && (
        <label className="block clearfix">
          <span className="block">
            <label htmlFor="text" className="col-lg-3" />
            <input
              type="submit"
              disabled={buttonDisabled}
              className="btn btn-danger"
              value={"Télécharger"}
              tabIndex={44}
            />
          </span>
        </label>
      )}
    </>
  );
};

export default FormDocFournirClient;
