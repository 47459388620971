import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getDocument } from "../../services/document";
import { getTypeActeurs } from "../../services/typeActeur";
// import {
//   getDocumentTransmettre,
//   createDocumentTransmettre,
//   updateDocumentTransmettre,
// } from "../../services/documentTransmettre.JS";
// import ReactPaginate from "react-paginate";
// import Loading from "../../components/Loading";
// import Alert from "../../components/Alert";
const DocEtapesObtentionProFonc = () => {
  // const headTable = [
  //   { name: "Type d'acteur", width: "95%" },
  //   { name: "", with: "5%" },
  // ];

  // const [values, setValues] = useState({
  //   idtypact: "",
  //   libelle: "",
  // });
  // const [flash, setFlash] = useState({ message: null, color: "success" });
  const [documents, setDocuments] = useState([]);
  // const [current, setCurrent] = useState(null);
  const [typeActeurs, setTypeActeurs] = useState([]);
  const _getDocuments = async () => {
    getDocument().then(res => {
      setDocuments(res.data)
    }).catch(err => {
      // console.log(err);
    })
  };

  // // pagination code
  // let PageSize = 10;
  // const [isLoading, setIsLoading] = useState(false);
  // const [currentTableData, setCurrentTableData] = useState([]);

  // const [totalPage, setTotalPage] = useState(null);
  // const page = (currentPage, datas) => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;

  //   return datas.slice(firstPageIndex, lastPageIndex);
  // };

  // const onPageChange = (e) => {
  //   const d = page(parseInt(e.selected + 1), villes);
  //   setCurrentTableData(d);
  //   // console.log(d);
  // };
  // fin code pagination

  // const onSelectDocuments = async (e) => {
  //   setIsLoading(true);
  //   setValues({ ...values, idpays: e.target.value });
  //   getTypeActeurs({ id: e.target.value })
  //     .then((res) => {
  //       const { data, message } = res;
  //       // console.log(message);
  //       const d = page(1, data);
  //       setFlash({ ...flash, message: message, color: "success" });
  //       setTypeActeurs(data);
  //       setCurrentTableData(d);
  //       const coutPage = parseInt(data.length / PageSize);
  //       // console.log(coutPage);
  //       setTotalPage(coutPage);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   // console.log(isLoading);
  //   if (current) {
  //     const data = {
  //       idtypact: values.idtypact,
  //       libelle: values.libelle,
  //       id: current.ID,
  //     };

  //     updateVille(data)
  //       .then((res) => {
  //         setFlash({ ...flash, message: res.message, color: "success" });
  //         getVillesByPays({ id: values.idpays })
  //           .then((res) => {
  //             const { data } = res;
  //             const d = page(1, data);
  //             setVilles(data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.length / PageSize);
  //             // console.log(coutPage);
  //             setTotalPage(coutPage);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //         setValues({ ...values, idpays: "", libelle: "" });
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     // newCity(values)
  //     //   .then((res) => {
  //     //     console.log(res);
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log(err);
  //     //   });
  //     createDocumentTransmettre(values)
  //       .then((resultat) => {
  //         setFlash({ ...flash, message: resultat.message, color: "success" });
  //         getVillesByPays({ id: values.idtypact })
  //           .then((res) => {
  //             const { data } = res;
  //             const d = page(1, data);
  //             setVilles(data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.length / PageSize);
  //             // console.log(coutPage);
  //             setTotalPage(coutPage);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //         setValues({ ...values, idtypact: "", libelle: "" });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setFlash({
  //           ...flash,
  //           message: "Erreur de création",
  //           color: "danger",
  //         });

  //         getVillesByPays({ id: values.idpays })
  //           .then((res) => {
  //             const { data } = res;
  //             const d = page(1, data);
  //             setVilles(data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.length / PageSize);
  //             // console.log(coutPage);
  //             setTotalPage(coutPage);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       });
  //   }
  // };

  // const onclick = (data) => {
  //   setCurrent(data);
  //   setValues({ ...values, idpays: data.IDPAYS, libelle: data.LIBELLE });
  // };

  // const onDelete = async () => {
  //   if (window.confirm("Voulez vous supprimé")) {
  //     const data = { id: current.ID, nom: current.NOM };
  //     deleteVille(data)
  //       .then(({ data }) => {
  //         setIsLoading(true);
  //         setCurrent(null);
  //         setFlash({ ...flash, message: data.message });
  //         getVillesByPays({ id: values.idpays })
  //           .then((res) => {
  //             const { data } = res;
  //             const d = page(1, data);
  //             setVilles(data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.length / PageSize);
  //             // console.log(coutPage);
  //             setTotalPage(coutPage);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((error) => {
  //         // console.log(error.response);
  //         setIsLoading(true);
  //         setCurrent(null);
  //         setFlash({
  //           ...flash,
  //           color: "danger",
  //           message: error.response.data.message,
  //         });
  //         getVillesByPays({ id: values.idpays })
  //           .then((res) => {
  //             const { data } = res;
  //             const d = page(1, data);
  //             setVilles(data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.length / PageSize);
  //             // console.log(coutPage);
  //             setTotalPage(coutPage);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       });
  //   }
  // };

  const [values, setValues] = useState({
    iddocument: ""
  })

  const [check, setCheck] = useState(false)

  const [dataChoix, setDataChoix] = useState([])


  const onSelectDocument = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
    setTypeActeurs([])
    setDataChoix([])
    getTypeActeurs().then(res => {
      setTypeActeurs(res.data)
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(dataChoix);
  }

  useEffect(() => {
    _getDocuments();
  }, []);



  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={onSubmit} >
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Saisie des documents à obtenir par étapes d’obtention du titre de propriété foncière</h5>
              </div>
              <div className="widget-body">
                <div className="widget-main">
                  <span id="" style={{ display: 'inline-block', width: '100%', textAlign: 'center' }} />
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">Profil</label>
                    <input className="form-control input-xxlarge " name="iddocument" />
                  
                  </label>


                </div>
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input type="button" value="Valider" tabIndex={3} className="btn btn-success" style={{ borderStyle: 'None', width: 100 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>

      {/* <Table
        headTable={[]}
        title={"Liste des étapes d’obtention du titre de propriété foncière"}
      ></Table> */}
    </Layout>
  );
};

export default DocEtapesObtentionProFonc;
