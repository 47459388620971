import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateEtape from "../pages/EtapeDossier/CreateEtape";

const EtapeDossierRoute = () => {
  return (
    <Routes>
      <Route path="create" element={<CreateEtape />} />
      <Route path="*" element={<Navigate to={"/error-page"} replace />} />
    </Routes>
  );
};

export default EtapeDossierRoute;
