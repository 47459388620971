import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getDocument } from "../../services/document";
import {
  createDocumentAffecte,
  deleteDocumentAffecte,
  getTypeActeurs,
  listeAAffecte,
  listeDocumentDejaAffecte,
} from "../../services/typeActeur";

const DocumentTransmettre = () => {
  const [documents, setDocuments] = useState([]);
  const [typeActeurs, setTypeActeurs] = useState([]);
  const [dejaAffecte, setDejaAffecte] = useState([]);
  const [aAffecte, setAAffecte] = useState([]);

  const _getDocuments = async () => {
    getDocument()
      .then((res) => {
        setCheck(false)
        setDocuments(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const [values, setValues] = useState({
    iddocument: "",
  });

  const [check, setCheck] = useState(false);

  const [dataChoix, setDataChoix] = useState([]);

  const onSelectDocument = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setTypeActeurs([]);
    setDataChoix([]);
    setDejaAffecte([]);
    dejaAffectes(e.target.value);
    

    // getTypeActeurs().then((res) => {
    //   setTypeActeurs(res.data);
    // });
  };

  const dejaAffectes = (e) => {
    listeDocumentDejaAffecte(e)
      .then(({ data }) => {
        setDejaAffecte(data.data);
      })
      .catch();
  }

  const acteursAAffecter = (e) => {
    listeAAffecte(e).then(({ data }) => {
      setTypeActeurs(data.data);
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const create = { listDocument: [...dataChoix] };
    createDocumentAffecte(create).then(({ data }) => {
      // console.log(data);
      setTypeActeurs([]);
      getTypeActeurs().then((res) => {
        setTypeActeurs(res.data);
      });
      setDataChoix([]);
    });
  };

  const onDelete = (d) => {
    deleteDocumentAffecte(values.iddocument, d.IDTACTEURFONCIER)
      .then(({ data }) => {
        const copy = [...dejaAffecte];
        const update = copy.filter(
          (item) => item.IDTACTEURFONCIER != d.IDTACTEURFONCIER
        );
        setDejaAffecte(update);
        // console.log(data);
      })
      .catch();
  };

  useEffect(() => {
    _getDocuments();
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={onSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">
                  Saisie des documents à transmettre aux acteurs
                </h5>
              </div>
              <div className="widget-body">
                <div className="widget-main">
                  <span
                    id=""
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Document
                    </label>
                    <select name="iddocument" onChange={onSelectDocument}>
                      <option value={""} />
                      {documents?.map((item, i) => {
                        return (
                          <option key={i} value={item.ID}>
                            {item.LIBELLE}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  {/* table choix */}
                  {dejaAffecte.length !== 0 && check == false ? (
                    <label className="block clearfix">
                      <span className="block">
                        <div>
                          <table
                            cellSpacing={0}
                            rules="all"
                            className="table-header"
                            border={1}
                            id="ContentPlaceHolder1_GV"
                            style={{
                              borderColor: "#E1E1E1",
                              fontSize: "10pt",
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tbody>
                              <tr
                                align="center"
                                valign="middle"
                                style={{
                                  color: "#707070",
                                  backgroundColor: "#F1F1F1",
                                  fontWeight: "normal",
                                }}
                              >
                                <th
                                  className="MasquerColonne"
                                  scope="col"
                                  style={{ width: "90px" }}
                                >
                                  Code
                                </th>
                                <th scope="col">Type d'acteur</th>
                                <th scope="col" style={{ width: "30px" }}>
                                  &nbsp;
                                </th>
                                <th
                                  className="MasquerColonne"
                                  scope="col"
                                  style={{ width: "30px" }}
                                >
                                  &nbsp;
                                </th>
                              </tr>

                              <>
                                {dejaAffecte?.map((item, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      style={{
                                        color: "Black",
                                        backgroundColor: "White",
                                      }}
                                    >
                                      <td>{item.LIBELLE}</td>
                                      <td className="text-center">
                                        <span onClick={() => onDelete(item)}>
                                          <img
                                            src="/Catef_files/fermer.png"
                                            alt=""
                                          />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </span>
                    </label>
                  ) : (
                    <>
                      {typeActeurs?.length !== 0 && (
                        <label className="block clearfix">
                          <span className="block">
                            <div>
                              <table
                                cellSpacing={0}
                                rules="all"
                                className="table-header"
                                border={1}
                                id="ContentPlaceHolder1_GV"
                                style={{
                                  borderColor: "#E1E1E1",
                                  fontSize: "10pt",
                                  width: "100%",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    style={{
                                      color: "#707070",
                                      backgroundColor: "#F1F1F1",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    <th
                                      className="MasquerColonne"
                                      scope="col"
                                      style={{ width: "90px" }}
                                    >
                                      Code
                                    </th>
                                    <th scope="col">Type d'acteur</th>
                                    <th scope="col" style={{ width: "30px" }}>
                                      &nbsp;
                                    </th>
                                    <th
                                      className="MasquerColonne"
                                      scope="col"
                                      style={{ width: "30px" }}
                                    >
                                      &nbsp;
                                    </th>
                                  </tr>
                                  {typeActeurs?.map((item, i) => {
                                    return (
                                      <tr
                                        key={i}
                                        style={{
                                          color: "Black",
                                          backgroundColor: "White",
                                        }}
                                      >
                                        <td className="MasquerColonne">1006</td>
                                        <td>{item.LIBELLE}</td>
                                        <td className="text-center">
                                          <input
                                            id="choix"
                                            value={item.ID}
                                            type="checkbox"
                                            name={"choix" + item.ID}
                                            onChange={(e) => {
                                              const verify = dataChoix.find(
                                                (i) =>
                                                  i?.idtypact === e.target.value
                                              );
                                              if (
                                                typeof verify == "undefined"
                                              ) {
                                                const data = {
                                                  iddocument: values.iddocument,
                                                  idtypact: e.target.value,
                                                };
                                                setDataChoix([
                                                  ...dataChoix,
                                                  data,
                                                ]);
                                              } else {
                                                const copy = dataChoix;
                                                const update = copy.filter(
                                                  (i) =>
                                                    i?.idtypact !=
                                                    e.target.value
                                                );
                                                setDataChoix(update);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td
                                          className="MasquerColonne"
                                          align="right"
                                        >
                                          <input
                                            type="image"
                                            src="Images/fermer.png"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </span>
                        </label>
                      )}
                    </>
                  )}

                  {/* case a cochez */}
                  {values.iddocument != "" && (
                    <>
                      <input
                        type="checkbox"
                        id="check"
                        name="check"
                        onChange={(e) => {
                          setCheck(e.target.checked);
                          if(e.target.checked){
                            setDejaAffecte([]);
                            acteursAAffecter(values.iddocument)
                          }else{
                            setTypeActeurs([]);
                            dejaAffectes(values.iddocument)
                          }
                        }}
                        tabIndex={2}
                      />
                      <label htmlFor="check">Ajouter des types d'acteur</label>
                    </>
                  )}
                </div>
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Valider"
                      tabIndex={3}
                      className="btn btn-success"
                      style={{ borderStyle: "None", width: 100 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* <Table
        headTable={[]}
        title={"Liste des documents à transmettre aux acteurs"}
      ></Table> */}
    </Layout>
  );
};

export default DocumentTransmettre;
