import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getTypePiece = async () => {
  try {
    const { data } = await axios.get("setting/list/type_piece", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createTypePiece = async (d) => {
  return await axios.post("setting/create/type_piece", d, config);
};

export const updateTypePiece = async (d) => {
  try {
    const { data } = await axios.post("setting/update/type_piece", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteTypePiece = async (data) =>
  await axios.post("setting/delete/type_piece", data, config);
