import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getCommuneByVille = async (id) => {
  try {
    const { data } = await axios.post("setting/list/communes", id, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createCommune = async (d) => {
  return await axios.post("setting/create/commune", d, config);
};

export const updateCommune = async (d) => {
  try {
    const { data } = await axios.post("setting/update/commune", d, config);
    return data;
  } catch (error) {
    return console.error();
  }
};

export const deleteCommune = async (d) =>
  await axios.post("setting/delete/commune", d, config);
