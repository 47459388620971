import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};
export const fillActeur = async (d) => {
  try {
    let url = `acteur/get/dossier_acteur?idacteur=${d.idacteur}`;
    if (d.search.trim() != "") {
      url += `&search=${d.search}`;
    }
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fillDocActeur = async ({ search }) => {
  try {
    let url = `acteur/get/dossier_acteur`;
    if (search.trim() != "") {
      url += `?search=${search}`;
    }
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fillPaiement = async ({ search }) => {
  try {
    let url = `acteur/get/grille_paie`;
    if (search.trim() != "") {
      url += `?search=${search}`;
    }
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fillCountDossier = async () => {
  try {
    let url = `acteur/get/dossier/count`;
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fillDetails = async (d) => {
  try {
    let url = `acteur/get/dossier/details?iddossier=${d.iddossier}`;
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fillActeurSave = async (d) => {
  try {
    const { data } = await axios.post('acteur/save/doc', d, config);
    return data;
  } catch (error) {
    return error;
  }
};





export const fillActeura = async (d) => {
  try {
    let url = `acteur/get/dossier_acteur?idacteur=${d.idacteur}`;
    if (d.search.trim() != "") {
      url += `&search=${d.search}`;
    }
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};
