import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import Table from "../../othercomponents/Table";
import LayoutClient from "./LayoutClient";
import { fillDocUrgence, fillDocClient, registerClient } from "../../services/acteur/soldeClient";
import Formulaire from "../../othercomponents/Formulaire";

const PaiementClient = () => {
    const [current, setCurrent] = useState(null);
    const [currentUrgence, setCurrentUrgence] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [flash, setFlash] = useState(
        { message1: "La liste des dossier à traiter en urgence", color1: "danger",
        message2: "La liste des clients présents dans la base de données ainsi que le statut des soldes", col2: "primary" 
    });
    const [stateForm, setStateForm] = useState({ 
      iddossier: 0,
      montant: "",
      mode: "",
      liendocument: "",
      observ: "",
      total_a_payer: "",
      montant_encaisser: "",
      reste_payer: "",
      banque: "",
      reference: "",
      deposant: "",
      emett: new Date()
    });
    const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
    const title = "Liste des clients";
    const forms = [
      {label: "Num de lot", name: "lot", table: []},
      {label: "montant", name: "lot", type: "number"}
    ];
    const headTable = [
        {name: "Client"}, {name: "Contact"}, {name: "Opération"}, {name: "Type Logement"}, {name: "Ilot"}, {name: "Lot"},
        {name: "Superficie"}, {name: "Etat"},
    ];
    const footer = [
      {value: "Valider", type:"submit", className: "btn-success"},
    ];
  
    const fetchDocUrgence = () => {
      fillDocUrgence()
        .then((data) => {
          console.log(data);
          setCurrentUrgence(data);
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const fetchDocClient = () => {
      fillDocClient()
        .then((data) => {
          console.log(data);
          setCurrentDoc(data);
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const onChange = (e) => {
      setStateForm({ ...stateForm, [e.target.name]: e.target.value })
    };
  
    const enregistrementClient = async (d) => {
      registerClient(d)
        .then((data) => {
          console.log(data);
          setCurrentUrgence(data);
        })
        .catch((err) => {
          console.log(err);
        })
    };
  
    const onSubmit = async (data) => {
      data = { ...data, ...stateForm };
      console.log(data);
      try {
        await enregistrementClient(data);
        console.log("Enregistrement reussir avec succes !", data);
        reset();
        setCurrent(null);
        setStateForm({ ...stateForm,
          iddossier: 0,
          montant: "",
          mode: "",
          liendocument: "",
          observ: "",
          total_a_payer: "",
          montant_encaisser: "",
          reste_payer: "",
          banque: "",
          reference: "",
          deposant: "",
          emett: ""
        });
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
    }, []);
  
  
  return (
    <LayoutClient>

      <Formulaire forms={forms} title={"Pocesseurs de paiement"} >

      </Formulaire>

    </LayoutClient>
  );
};

export default PaiementClient;
