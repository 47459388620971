import React, { useEffect, useState, useRef } from "react";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import Table from "../../othercomponents/Table";
import LayoutActeur from "./LayoutActeur";
import { fillActeur, fillDocActeur, fillPaiement, fillCountDossier, fillDetails, fillActeurSave } from "../../services/acteur/accueil";
import Pagination from "../../components/Pagination"
import Formulaire from "../../othercomponents/Formulaire";
import { SpinnerCircular } from "spinners-react";
import { pathUrl } from "../../index";

const TableBordActeur = () => {
  const formRef = useRef(null);

  const [current_liste_dossier, setCurrentListeDossier] = useState(null);
  const [current_liste_paiement, setCurrentListePaiement] = useState(null);

  const [liste_dossier, setListeDossier] = useState({ grille: [], totalpercu: 0 });
  const [liste_paiement, setListePaiement] = useState([]);
  const [total_page_dossier, setTotalPageDossier] = useState(null);
  const [total_page_paiement, setTotalPagePaiement] = useState(null);
  const [dossclt, setDssClt] = useState([]);
  const [combodocact, setComboDocAct] = useState([]);
  const [iddossier, setIdDossier] = useState(null);
  const [count_dossier_urgence, setCountDossierUrgence] = useState(0);
  const [search, setSearch] = useState({ search: "" });

  const [isLoading, setIsLoading] = useState(false);
  const [visible_table, setVisibleTable] = useState(false);
  const [upload_file, setUploadFile] = useState("");

  const [flash, setFlash] = useState({ message: null, color: "danger" });
  const [flashupload, setFlashUpload] = useState({ message: null, color: "danger" });
  const [stateForm, setStateForm] = useState({
    typedoc: "",
    numordre: "",
    piece: ""
  });

  const showDoc = (path) => {
    if (path) {
      const source = pathUrl + path;
      window.open(source, '_blank');
    }
  }

  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  const title1 = "Liste de vos dossiers";
  const title2 = `Liste de paiements que vous avez reçus [Total perçu jusqu'à ce jour : ${liste_dossier?.totalpercu} Fcfa]`;
  const btnStyle = [
    { value: "Enregistrer", type: "submit", className: "btn-success" }
  ]
  const headTable1 = [
    { name: "Code" }, { name: "Date" }, { name: "N° dossier" },
    { name: "Client" }, { name: "N° de lot" }, { name: "Type de lot" },
    { name: "A payer" }, { name: "Payé" }, { name: "Reste" }, { name: "" },
  ];

  const headTable2 = [
    { name: "Code" }, { name: "Date" }, { name: "N° reçu" }, { name: "Mode paiement" },
    { name: "Montant" }, { name: "LIENDOCUMENT" },
  ];

  const forms = [
    { label: "Type de document", name: "typedoc", value: stateForm.typedoc, table: combodocact },
    { label: "Référence document", name: "numordre", value: stateForm.numordre, type: "text" },
  ];

  const PageSize = 10;
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChangeDossier = (e) => {
    const d = page(parseInt(e.selected + 1), liste_dossier?.grille);
    setCurrentListeDossier(d);
  };

  const onPageChangePaiement = (e) => {
    const d = page(parseInt(e.selected + 1), current_liste_paiement);
    setCurrentListePaiement(d);
  };

  const idacteur = localStorage.getItem("__user_acteur_id");

  const fetchActeur = () => {
    fillActeur({ idacteur })
      .then(({ data }) => {
        console.log(data);
        setCurrentListeDossier(data.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const fetchDocActeur = (d) => {
    setIsLoading(true)
    fillDocActeur(d)
      .then((data) => {
        // setListeDossier(...liste_dossier, ...data.data)
        setListeDossier(data.data);
        console.log(liste_dossier)
        const dossiers = page(1, data.data.grille);
        setCurrentListeDossier(dossiers);
        const cout_page_dossier = parseInt(data.data.grille.length / PageSize);
        setTotalPageDossier(cout_page_dossier);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const fetchPaiement = () => {
    setIsLoading(true)
    fillPaiement({ search: " " })
      .then((data) => {
        setListePaiement(data.data);
        const paiement = page(1, data.data);
        setCurrentListePaiement(paiement);
        const cout_page_paiement = parseInt(data.data.length / PageSize);
        setTotalPagePaiement(cout_page_paiement);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const formatMode = (id_mode) => {
    const mode = ["Espèce", "Chèque", "Reçu", "Virement", "Paiement mobile"]
    if (mode.includes(id_mode)) return id_mode;
    return mode[id_mode - 1];
  }

  const fetchCountDossier = () => {
    fillCountDossier()
      .then((data) => {
        // console.log(data)
        if (data.data[0].NOMBRE > 0) {
          const message = `Vous avez ${data.data[0].NOMBRE} dossier(s) à traiter urgemment afin de permettre aux clients concernés d'entamer la procédure d'établissement de leur titre de propriété foncière`;
          const color = "danger"
          setFlash({ message, color });
        } else if (data.data[0].NOMBRE == 0) {
          const message = `Vous avez aucun dossier à traiter urgemment`;
          const color = "success"
          setFlash({ message, color });
        } else {
          setFlash({ message: null });
        }

      })
      .catch((err) => {
        console.log(err);
      })
  }


  const onViewDetails = async (dossier, key) => {
    // let id = `item-${key}`;
    // const item = document.getElementById(id);

    // console.log(item, key, id);
    // item.style.backgroundColor = item.style.backgroundColor === "white" ?
    // "#ff0000" : "white";

    // console.log(dossier);
    fillDetails({ iddossier: dossier?.ID })
      .then((data) => {
        // console.log(data.data);
        setDssClt(() => data?.data?.dossclt);
        setComboDocAct(() => data?.data?.combodocact);
        setUploadFile(() => data?.data?.cloturedoss);
      })
    setVisibleTable(() => !visible_table);
    setIdDossier(() => dossier?.ID);
  };

  const onChange = (e) => {
    const target = e.target;

    if (target.type === "file") {
      setStateForm({ ...stateForm, [target.name]: target.files[0] });
    } else {
      setStateForm({ ...stateForm, [target.name]: target.value });
    }
  }

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
    fetchDocActeur(e.target.value)
  };

  const onVerifySolde = (e) => {
    e.preventDefault();
    const data = { search: search.search };
    if (e.key == "Enter") {
      fetchDocActeur(data);
    }
  };

  const onSubmit = () => {
    let formData = new FormData(formRef.current);

    // formData.append("total_a_payer", total_a_payer);
    // formData.append("reste", reste);
    // formData.append("total_encaiser", total_encaiser);
    // formData.append("listdoc", JSON.stringify(dossierData));

    formData.append("iddossier", iddossier);
    console.log(formData.get('piece'));
    setIsLoading(true);

    fillActeurSave(formData)
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        if (data.success) {
          alert('Fichier chargé avec succès')
        } else {
          alert('Impossible de charger le fichier')
        }

      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);

      })
  }

  useEffect(() => {
    // fetchActeur();
    var d = { search: "" }
    fetchDocActeur(d);
    fetchPaiement();
    fetchCountDossier();
  }, []);


  return (
    <LayoutActeur>
      {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
      <Table title={title1} headTable={headTable1} size={true} search={true} changeSearch={onChangeSearch} verifySolde={onVerifySolde}>
        {current_liste_dossier?.map((item, key) => (
          <tr key={key} id={`ìtem-${key}`} style={{ color: "Black", backgroundColor: "White" }}>
            <td className="text-center">{item?.ID}</td>
            <td className="text-center">{item?.DATE}</td>
            <td className="text-center">{item?.NUMDOSSIER}</td>
            <td className="text-center">{item?.NOM}</td>
            <td className="text-center">{item?.NUMLOT}</td>
            <td className="text-center">{item?.TYPELOT}</td>
            <td className="text-center">{item?.TOTAPAYER}</td>
            <td className="text-center">{item?.TOTPAYE}</td>
            <td className="text-center">{item?.RESTEAPAYE}</td>
            <td className="text-center" align="right" style={{ cursor: "pointer" }}>
              <img
                onClick={() => onViewDetails(item, key)}
                style={{ width: 15, height: 10 }}
                src={"/Catef_files/modifier.png"}
              />
            </td>
          </tr>
        ))}
        <Pagination onPageChange={onPageChangeDossier} totalPage={total_page_dossier} />
      </Table>

      {visible_table &&
        <>
          <Table title={"Les documents fournis par le client"} headTable={[{ name: "Date" }, { name: "Type de document" }]} size={false} search={false} colorTable={'orange'}>
            {dossclt?.map((doss, key) => (
              <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                <td className="text-center">{doss?.DATE}</td>
                <td className="text-center">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    showDoc(doss?.LIENDOCUMENT);
                    return false;
                  }}>
                    {doss?.LIBELLE}
                  </a>
                </td>
              </tr>
            ))}
          </Table>

          {/* <Formulaire
            title={""}
            forms={forms}
            colorForm={"green"}
            onChange={onChange}
            formRef={formRef}
          >
          </Formulaire> */}

          <div className="row">
            <div className="col-xs-2" />
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <div className="form-horizontal col-xs-8 ui-sortable">
                <div className={`widget-box widget-color-green`}>
                  {/* Entete du cadre */}
                  <div className="widget-header ui-sortable-handle">
                    <h5 className="widget-title">Les documents que vous avez fournis au client</h5>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SpinnerCircular
                      style={{ color: "#1B6AAA" }}
                      enabled={isLoading}
                    />
                  </div>

                  {/* Contenu du cadre */}
                  <div className="widget-body">
                    <div className="widget-main">
                      {/* {message !== null && <Alert color={color} message={message} />} */}
                      <span id="ContentPlaceHolder1_LBINFO" style={{ display: "inline-block", width: "100%", textAlign: "center" }} />
                      {forms.map((item, index) => {
                        setValue(item.name, item.values);
                        if (Array.isArray(item?.table)) {
                          return (
                            <label key={index} className="block clearfix">
                              <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                              <select
                                value={item.value}
                                name={item.name}
                                onChange={onChange}
                                className="form-control input-xxlarge"
                              >
                                <option value="" />
                                {item?.table?.map((t, i) => (
                                  <option key={i} value={t.ID}>{t.LIBELLE}</option>
                                ))}
                              </select>
                            </label>
                          );
                        } else {
                          return (
                            <label key={index} className="block clearfix">
                              <span className="block">
                                <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                                <input style={{ width: item?.width }}
                                  name={item.name}
                                  value={item.value}
                                  onChange={onChange}
                                  type={item.type}
                                  className="form-control input-xxlarge"
                                  placeholder=""
                                />
                              </span>
                            </label>
                          );
                        }
                      })}

                      <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-2">Document numérisé</label>
                          <input
                            name={'piece'}
                            onChange={onChange}
                            type={'file'}
                            accept={'.pdf'}
                            className="form-control input-xxlarge"
                          />
                        </span>
                      </label>
                    </div>
                    {/* Pied du cadre */}
                    {upload_file === "Non" && (<div className="widget-toolbox padding-8 clearfix">
                      <div className="pull-right">
                        {btnStyle?.map((btn, key) => {
                          return (<input
                            key={key}
                            type={`${btn.type}`}
                            value={`${btn.value}`}
                            disabled={isLoading}
                            className={`btn ${btn.className}`}
                            style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                          // onClick={ btn.value === "Annuler" && (() => {reset(); setCurrent(null)})}
                          />)
                        })}
                      </div>
                    </div>)}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      }

      <Table title={title2} headTable={headTable2} size={true} search={false} colorTable={'red'}>
        {current_liste_paiement?.map((item, key) => (
          <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
            <td className="text-center">{item?.ID}</td>
            <td className="text-center">{item?.DATE}</td>
            <td className="text-center">{item?.NRECU}</td>
            <td className="text-center">{formatMode(item?.MODE)}</td>
            <td className="text-center">{item?.MONTANT}</td>
            <td className="text-center">
              <a href="#" onClick={(e) => {
                e.preventDefault();
                showDoc(item?.LIENDOCUMENT);
                return false;
              }}>
                {item?.APPERCU}
              </a>
            </td>
          </tr>
        ))}
        <Pagination onPageChange={onPageChangePaiement} totalPage={total_page_paiement} />

      </Table>
    </LayoutActeur>
  );
};

export default TableBordActeur;
