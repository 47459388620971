import React from "react";
import Dossier from "../dossier/Dossier";

const DossierRoute = () => {
  return (
    <div>
      <Dossier enableTable={true} />
    </div>
  );
};

export default DossierRoute;
