import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { createValidationPaiementActeur, getValidationPaiementActeur } from "../../services/finances/validation-paiement-acteur"
import Alert from "../../components/Alert";

const ValidationPaiementActeur = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [flash, setFlash] = useState({ message: null, color: "" });
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [validPaie, setValidPaie] = useState([]);

  const titleTable = "Validation des paiements aux acteur";
  const headTable = [
    {name:"Date", width: ""},
    {name:"Nom du acteur", width: ""},
    {name:"Montant", width: ""},
    {name:"Mode", width: ""},
    {name:"N° du chèque", width: ""},
    {name:"N° de reçu", width: ""},
    {name:"Date emise", width: ""},
    {name:"Document", width: ""},
    {name:"", width: ""},

  ];
  const footer = [
    {value: "Valider", type: "submit", className: "success"}
  ];
  const mode = ["Espèce", "Chèque", "Reçu", "Virement", "Paiement mobile"];
  const fetchGetValidationPaiementActeur = () => {
    getValidationPaiementActeur()
      .then(({data}) => {
        setIsLoading(false)
        setFlash({ message: "Validation des paiements aux acteurs", color: "success" });
        setValidPaie(() => data);
      })
      .catch((err) => {
        setFlash({ message: "Erreur dû au chargement...", color: "danger" });
        setIsLoading(false)
      })
  };

  const onChangeChechAll = (e) => {
    const checked = e.target.checked;
    const nameAll = document.querySelectorAll(".check");
    if(checked) {
      nameAll.forEach((name) => {
        name.checked = true;
      });
    } else {
      nameAll.forEach((name) => {
        name.checked = false;
      });
    }
  };
  
  const valideDossier = async () => {
    await valideDossierRecharge();
    const nameAll = document.querySelectorAll(".check");
    nameAll.forEach((name) => {
      name.checked = false;
    });
    setIsLoadingPage(() => !isLoadingPage);
    //window.location.reload(true);
  }

  const valideDossierRecharge = async () => {
    setIsLoading(true);
    const check_all = document.querySelectorAll('.check');
    const data = [];
    let i = 0;
    check_all.forEach((check) => {
      i++;
      if(check.checked) {
        data.push(
          {
            id: validPaie[i - 1].ID,
            idacteur: validPaie[i - 1].IDACTEUR,  
            montant: validPaie[i-1].MONTANT,
            mode: validPaie[i-1].MODE,
            cheque: validPaie[i-1].NCHEQUE,
            observ: validPaie[i-1].OBSERV,  
            banque: validPaie[i-1].BANQUE,
            emetteur: validPaie[i-1].EMETT,  
            dateemiss: validPaie[i-1].DATEEMIS,  
            piece: validPaie[i-1].LIENDOCUMENT,  
          }
        );
      }
    })
    console.log(data)
    createValidationPaiementActeur({ datas: data })
      .then(({ data }) => {
        setIsLoading(false);
        if(data.success){
          setFlash({ message: data.message, color: "success" });
          getValidationPaiementActeur()
          .then(({data}) => {
            setIsLoading(false)
            // setFlash({ message: "Validation des paiements aux acteurs", color: "success" });
            setValidPaie(() => data);
          })
          .catch((err) => {
            setFlash({ message: "Erreur dû au chargement...", color: "danger" });
            setIsLoading(false)
          })
        }else{
          setFlash({ message: data.message, color: "danger" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setFlash({ message: "Error...", color: "danger" });

    })
  };

  useEffect(() => {
    fetchGetValidationPaiementActeur();
  }, [isLoadingPage]);
  return (
    <Layout>
      <div className="row">
      <div className={`col-xs-1`}></div>
      <div className={`form-horizontal col-xs-10 ui-sortable`}>
        <div className="widget-box widget-color-blue">
          {/* Entete du cadre */}
          <div className="widget-header ui-sortable-handle">
            <h5 className="widget-title">{titleTable}</h5>
            <div className="navbar-form navbar-right form-search" style={{ marginLeft: 50 }}>
                <div
                  id="ContentPlaceHolder1_Panel1"
                  onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                >
                  <span className="input-icon">
                    <input
                      name="critere"
                      type="text"
                      id="TBRECH"
                      tabIndex={1}
                      className="nav-search-input"
                      placeholder="Recherche ..."
                    />
                    <i className="ace-icon fa fa-search nav-search-icon" />
                    <input
                      type="submit"
                      name="ctl00$ContentPlaceHolder1$BTRECH"
                      defaultValue="OK"
                      id="ContentPlaceHolder1_BTRECH"
                      tabIndex={2}
                      className="btn-info btn-sm MasquerColonne"
                      style={{ borderStyle: "None" }}
                    />
                  </span>
                </div>
              </div>
          </div>
          {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
          {/* Contenu du cadre */}
          <div className="widget-body">
            <div className="widget-main">
              <span
                id="ContentPlaceHolder1_LBINFO"
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "center"
                }}
              />
              <label className="block clearfix">
                <span className="block">
                <table
                    cellSpacing={0}
                    rules="all"
                    className="table-header table table-striped "
                    border={1}
                    id="ContentPlaceHolder1_GV"
                    style={{
                      borderColor: "#E1E1E1",
                      fontSize: "10pt",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr
                        align="center"
                        valign="middle"
                        style={{
                          color: "#707070",
                          backgroundColor: "#F1F1F1",
                          fontWeight: "normal",
                        }}
                      >
                        {headTable?.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="text-center"
                              scope="col"
                              style={{ width: item?.width }}
                            >
                              {item?.name}
                            </th>
                          );
                        })}
                      </tr>

                      {validPaie?.map((item, key) => (
                      <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                        <td>{item?.DATE}</td>
                        <td>{item?.NOM}</td>
                        <td>{item?.MONTANT}</td>
                        <td>{mode[parseInt(item?.MODE) - 1]}</td>
                        <td>{item?.NCHEQUE}</td>
                        <td>{item?.NRECU}</td>
                        <td>{item?.DATEEMIS}</td>
                        <td>{item?.APPERCU}</td>
                        <td>
                          <input type="checkbox" className="check" id={`check-${key}`} />
                        </td>
                      </tr>
                  ))}
                      <div className="row ml-4">
                        <div className="px-3"></div>
                        <input type="checkbox" name="check-all" id="check-all" onChange={onChangeChechAll} />
                      <label htmlFor="check-all">Cocher tout</label>
                      </div>
                    </tbody>
                  </table>
                </span>
              </label>
            </div>
            {/* Pied du cadre */}
            {footer?.map((btn, key) => (
              <div className="widget-toolbox padding-8 clearfix" key={key}>
              <div className="pull-right">
                <input
                  type={btn.type}
                  name={btn.name}
                  value={btn.value}
                  id={btn.id}
                  disabled={isLoading}
                  className={`btn btn-${btn.className}`}
                  style={{ borderStyle: "None", width: 100 }}
                  onClick={() => valideDossier()}
                />
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default ValidationPaiementActeur;
