import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Formulaire from "../../components/Formulaire";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
  createPays,
  deletePays,
  getPays,
  updatePays,
} from "../../services/pays";

const Pays = () => {
  const headTable = [
    { name: "pays" },
    { name: "Nationalité" },
    // { name: "taux tva" },
    { name: "" },
  ];

  // le current doit etre au dessus de const forms
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Pays (*)",
      type: "text",
      name: "nom",
      values: current?.NOM ?? "",
    },
    {
      label: "Nationalité (*)",
      type: "text",
      name: "nationnalite",
      values: current?.NATIONNALITE ?? "",
    },
    // {
    //   label: "Taux tva (%)",
    //   type: "text",
    //   name: "taux",
    //   values: current?.TAUXTVA ?? "",
    //   width: "10%",
    // },
  ];
  const queryClient = useQueryClient();

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const fetchPays = async () => {
    setIsLoading(true);
    getPays()
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        setTableData(data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setFlash({ ...flash, message: message, color: "success" });

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { mutate } = useMutation(createPays, {
    onSuccess: async ({ message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: message, color: "success" });
      getPays()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setTableData(data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      // console.log(res.response.data.message);
      setFlash({
        ...flash,
        message: res.response.data.message,
        color: "danger",
      });
    },
  });

  const { mutate: updateMutate } = useMutation(updatePays, {
    onSuccess: async ({ data, message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: message });
      getPays()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setTableData(data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      // console.log(res.response.data.message);
      setFlash({
        ...flash,
        message: res.response.data.message,
        color: "danger",
      });
    },
  });

  const postPays = (data) => mutate(data);
  const put = (data) => updateMutate(data);

  const onclick = (data) => {
    setCurrent(data);
  };

  const onDelete = async () => {
    if (window.confirm("Voulez vous supprimer le pays?")) {
      const data = { id: current.ID, nom: current.NOM };
      deletePays(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getPays()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          getPays()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };

  useEffect(() => {
    fetchPays();
  }, []);
  return (
    <Layout>
      <Formulaire
        message={flash?.message}
        color={flash?.color}
        current={current}
        setCurrent={setCurrent}
        cb={current ? put : postPays}
        forms={forms}
        title="Saisie des pays"
        isLoading={isLoading}
        onDelete={onDelete}
      />

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table headTable={headTable} title={"Liste des pays"}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              {/* <td className="MasquerColonne"></td> */}
              <td>{item.NOM}</td>
              <td>{item.NATIONNALITE}</td>
              {/* <td>{item.TAUXTVA}</td> */}
              <td className="text-center" align="right">
                {/* <button onClick={() => onclick(item)}> */}
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
                {/* </button> */}
              </td>
            </tr>
          ))}

          {/* pagination */}
          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default Pays;
