import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { _AuthContext } from "../context/AuthContext";
import moment from "moment";

const ProtectedRoute = ({ children }) => {
  let { auth } = useContext(_AuthContext);

  const expired = localStorage.getItem("__expired_myTeken");
  const now = Date.now();
  const current = new Date(expired).getTime();

  // console.log(now < current);

  if (now < current) {
    if (localStorage.getItem("__myTeken")) {
      return children;
    }
  } else {
    // localStorage.removeItem("__myTeken");
    localStorage.clear();
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
