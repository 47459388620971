import { Navigate, Route, Routes } from "react-router-dom";
import EtatActeur from "../pages/Edition/EtatActeur";
import EtatClient from "../pages/Edition/EtatClient";
import Liste from "../pages/Edition/Liste";

const EditionRoute = () => (
  <Routes>
    <Route path="listes" element={<Liste />} />
    <Route path="etats-client" element={<EtatClient />} />
    <Route path="etats-acteur" element={<EtatActeur />} />
    <Route path="*" element={<Navigate to={"/error-page"} replace />} />
  </Routes>
);

export default EditionRoute;
