import React, { useState } from "react";
import { useEffect } from "react";
// import { get } from "react-hook-form";
import Loading from "../../components/Loading";
import Layout from "../../layout/Layout";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import { utilisateurs, utilisateursActeurs, utilisateursClient, reinitUserMdp } from "../../services/securite/reinit";

const ReinitialisationMdp = () => {
  const headTable = [
    { name: "Email ou Matricule", width: "40%" },
    { name: "Nom", width: "40" },
    { name: "Profil", width: "20" },
    { name: "", width: "5%" },
  ];
  const [users, setUsers] = useState([]);

  const [current, setCurrent] = useState({ target: "Utilisateurs" });
  const [isLoading, setIsLoading] = useState(false);
  // const [typeActeurs, setTypeActeurs] = useState([]);

  const [values, setValues] = useState({

  });

  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [search, setSearch] = useState({ search: "" });

  // pagination code
  // pagination code
  let PageSize = 10;
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), users);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onChange = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    if (e.target.value === "Utilisateur" && e.target.name === "choix") {
      fetchUtilisateurs();
    } else if (e.target.value === "Client" && e.target.name === "choix") {
      fetchUtilisateursCls()
    } else if (e.target.value === "Acteur" && e.target.name === "choix") {
      fetchUtilisateursAcs()
    }
    setCurrent({ target: e.target.value })
    // setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };


  const onclick = async (item) => {
    setCurrent(item);
  }



  // afficher la liste de acteur en function de type acteur

  const fetchUtilisateurs = async () => {
    setIsLoading(true);
    unchecked()
    utilisateurs(search.search)
      .then(({ data }) => {
        setUsers(data);
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };
  const fetchUtilisateursAcs = async () => {
    setIsLoading(true);
    unchecked()
    utilisateursActeurs(search.search)
      .then(({ data }) => {
        setUsers(data);
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };

  const reinitUsers = async (data) => {
    setIsLoading(true);
    reinitUserMdp(data)
      .then((response) => {
        if (!response.success) {
          alert('Impossible de modifier le mot de passe');
          return false;
        }
        alert("le mot de passe des utilisateurs selectionnés ont été modifiés avec succès")
        const check_all = document.querySelectorAll('.check');
        unchecked()
        if (data.target === "Utilisateur") {
          fetchUtilisateurs();
        } else if (data.target === "Client") {
          fetchUtilisateursCls()
        } else if (data.target === "Acteur") {
          fetchUtilisateursAcs()
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };
  const fetchUtilisateursCls = async () => {
    setIsLoading(true);
    unchecked()
    utilisateursClient(search.search)
      .then(({ data }) => {
        setUsers(data);
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };


  const unchecked = () => {
    const check_all = document.querySelectorAll('.check');
    check_all.forEach((check) => {
      if (check.checked) {
        check.checked = false
      }
    });
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const check_all = document.querySelectorAll('.check');
    const data = [];
    let i = 0;
    check_all.forEach((check) => {
      i++;
      if (check.checked) {
        data.push(
          {
            id: users[i - 1].ID,
            nom: users[i - 1].NOM,
            email: users[i - 1].EMAIL,
            libelle: users[i - 1].LIBELLE,
          }
        );
      }
    })
    let dateSend = {
      "users": data,
      "target": current.target
    }
    reinitUsers(dateSend)
  };
  useEffect(() => {
    fetchUtilisateurs();
  }, []);
  return (
    <Layout>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <section className="col-xs-12">
            <div id="ContentPlaceHolder1_UpdatePanel1">
              <div className="row">
                <div className="form-horizontal col-xs-12 ui-sortable">
                  <div className="widget-box widget-color-blue">
                    {/* Entete du cadre */}
                    <div className="widget-header ui-sortable-handle">
                      <h5 className="widget-title">Réinitialisation de mot de passe</h5>
                      <div className="navbar-form navbar-right form-search">
                        <div id="ContentPlaceHolder1_Panel1">
                          <span className="input-icon">
                            <input
                              onChange={onChange}
                              onKeyUpCapture={onChange}
                              name="search"
                              type="text"
                              tabIndex={1}
                              className="nav-search-input"
                              placeholder="Recherche ..."
                            />
                            <i className="ace-icon fa fa-search nav-search-icon" />
                            {/* <input
                              type="submit"
                              name="ctl00$ContentPlaceHolder1$BTRECH"
                              defaultValue="OK"
                              id="ContentPlaceHolder1_BTRECH"
                              tabIndex={2}
                              className="btn-info btn-sm MasquerColonne"
                              style={{ borderStyle: "None" }}
                            /> */}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Contenu du cadre */}
                    <div className="widget-body">
                      <div className="widget-main">
                        {flash.message && <Alert message={flash.message} color={flash.color} />}

                        {isLoading && <Loading isLoading={isLoading} />}
                        <span
                          id="ContentPlaceHolder1_LBINFO"
                          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
                        />
                        <label className="block clearfix">
                          <label htmlFor="text" className="col-lg-3">
                            Choix
                          </label>
                          <select
                            onChange={onChange}
                            value={values.statut}
                            name="choix"
                            tabIndex={9}
                            className="form-control input-xxlarge"
                          >
                            <option selected={true} value="Utilisateur">Utilisateur</option>
                            <option value="Acteur">Acteur</option>
                            <option value="Client">Client</option>
                          </select>
                        </label>
                        <label className="block clearfix">
                          <span className="block">
                            <div>
                              <table
                                cellSpacing={0}
                                rules="all"
                                className="table-header"
                                border={1}
                                id="ContentPlaceHolder1_GV"
                                style={{
                                  borderColor: "#E1E1E1",
                                  fontSize: "10pt",
                                  width: "100%",
                                  borderCollapse: "collapse"
                                }}
                              >
                                <tbody>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    style={{
                                      color: "#707070",
                                      backgroundColor: "#F1F1F1",
                                      fontWeight: "normal"
                                    }}
                                  >

                                    <th scope="col" >
                                      Email ou Matricule
                                    </th>
                                    <th scope="col" >
                                      Nom
                                    </th>
                                    <th scope="col">Profil</th>
                                    <th scope="col">
                                      &nbsp;
                                    </th>
                                  </tr>
                                  {currentTableData?.map((item, i) => {

                                    return <tr key={i} style={{ color: "Black", backgroundColor: "White" }}>
                                      <td>{item.EMAIL}</td>
                                      <td>{item.NOM}</td>
                                      <td>{item.LIBELLE}</td>
                                      <td className="ace text-center">
                                        <input
                                          type="checkbox"
                                          className="check" id={`check-${i}`}
                                        />
                                      </td>
                                    </tr>
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </span>
                        </label>
                        {/* pagination */}
                        <div className="widget-toolbox clearfix" style={{}}>
                          <center>
                            <Pagination onPageChange={onPageChange} totalPage={totalPage} />
                          </center>
                        </div>
                      </div>

                      {/* Pied du cadre */}
                      <div className="widget-toolbox padding-8 clearfix">
                        <div className="pull-right">
                          <input
                            type="submit"
                            value="Valider"
                            tabIndex={2}
                            className="btn btn-success"
                            style={{ borderStyle: "None", width: 100 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </Layout>
  );
};

export default ReinitialisationMdp;