import React, { useState, useEffect } from "react";
import Alert from "../../../../components/Alert";
import { ajoutPiece } from "../../../../services/dossier";
import FormDocFournirClient from "./FormDocFournirClient";
import TableDocFourniClient from "./TableDocFourniClient";
import Loading from "../../../../components/Loading";

const DocFournirClient = ({
  dataOnglet3,
  isLoadingGlobal,
  setIsLoadingGlobal,
}) => {
  const session = localStorage.getItem("__info_onglet3");
  const [flash, setFlash] = useState({ message: null, color: "success" });
  let iddossier;
  if (session) {
    iddossier = session;
    // console.log(iddossier);
  }

  const [isLoading, setIsloading] = useState(false);
  const [grilleDocClient, setGrilleDocClient] = useState([]);

  const [comboDocClient, setComboDocClient] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);


  // console.log(dataOnglet3);

  const [values, setValues] = useState({
    iddossier: iddossier,
    typedoc: "",
    numordre: "",
    piece: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    setIsLoadingGlobal(false)

    const formData = new FormData();
    formData.append("iddossier", iddossier);
    formData.append("typedoc", values.typedoc);
    formData.append("numordre", values.numordre);
    formData.append("piece", values.piece);
    setButtonDisabled(true);
    ajoutPiece(formData)
      .then(({ data }) => {
        // console.log(data);
        setButtonDisabled(false);
        setGrilleDocClient(data?.data?.grilleDocClient);
        setComboDocClient(data?.data?.comboDocClient);
        setValues({ ...values, typedoc: "", numordre: "", piece: "" });
        formData.set("iddossier", "");
        formData.set("typedoc", "");
        formData.set("numordre", "");
        formData.set("piece", "");
        setFlash({ message: data.message, color: "success" });
        setIsloading(false);
      })
      .catch(({ response }) => {
        setFlash({ color: "danger", message: response.data.message });
        setIsloading(false);
      });
  };
  // console.log(dataOnglet3);
  useEffect(() => {
    setGrilleDocClient(dataOnglet3?.grilleDocClient);
    setComboDocClient(dataOnglet3?.comboDocClient);
  }, [dataOnglet3?.comboDocClient, dataOnglet3?.grilleDocClient]);

  return (
    <>
      <form onSubmit={onSubmit}>
        {flash.message && <Alert message={flash.message} color={flash.color} />}
        {isLoading && <Loading isLoading={isLoading} />}

        {isLoadingGlobal && <Loading isLoading={isLoadingGlobal} />}

        <FormDocFournirClient
          dataOnglet3={dataOnglet3}
          comboDocClient={comboDocClient}
          setGrilleDocClient={setGrilleDocClient}
          values={values}
          setValues={setValues}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
        />

        <TableDocFourniClient grilleDocClient={grilleDocClient} />
      </form>
    </>
  );
};

export default DocFournirClient;
