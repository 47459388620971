import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const registerClient = async (d) => {
  try {
    const { data } = await axios.post("acteur/register/acteur/client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const updateClient = async (d) => {
  try {
    const { data } = await axios.post("acteur/update/client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const rechercheClient = async (d) => {
  try {
    const { data } = await axios.post("acteur/get/client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};