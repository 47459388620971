import React from "react";
import logo from "../images/logo-catef.jpg";
import moment from "moment";
import "moment/locale/fr";

export const HeaderPDF = ({ title = null }) => {
  // console.log(title);
  return (
    <div>
      <section
        style={{
          width: "100%",
          display: "flex",
          // justifyContent: "space-between",
          gap: 5,
        }}
      >
        <div style={{ width: "15%", border: "0px solid black" }}>
          <img
            src={logo}
            alt="logo catef"
            style={{ width: "100%", height: 60 }}
          />
        </div>
        <div
          style={{
            width: "70%",
            border: "1px solid black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: 20 }}>
            {title ? title : "FACTURE"}
          </p>
        </div>
        <div
          style={{
            width: "15%",
            // height: "100%",
            justifyItems: "center",
            border: "1px solid black",
            borderRadius: 5,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: 0,
          }}
        >
          {/* date */}
          <div
            style={{ display: "flex", width: "100%", margin: 0, padding: 0 }}
          >
            <div
              style={{
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              DATE
            </div>
            <div
              style={{
                borderBottom: "1px solid black",
                width: "60%",
              }}
            >
              {moment(Date.now()).format("DD/MM/YYYY")}
            </div>
          </div>

          {/* heure */}
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              HEURE
            </div>
            <div style={{ borderBottom: "1px solid black", width: "60%" }}>
              {moment(Date.now()).format("HH:mm")}
            </div>
          </div>

          {/* Page */}
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                // borderBottom: "1px solid black",
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              PAGE
            </div>
            <div style={{}}>1/1</div>
          </div>
        </div>
      </section>
      <div style={{ borderBottom: "2px solid #51B9F7", marginTop: 5 }} />
    </div>
  );
};

export const FooterPDF = () => {
  return (
    <div>
      <div style={{ borderBottom: "2px solid #51B9F7", marginTop: 5 }} />
      <section>
        <p style={{ textAlign: "center", fontSize: 12, marginTop: 10 }}>
          CATEF - II PLATEAUX SOCOCE - 01 BP 8495 ABIDJAN 01 - TEL : 20 00 00 00
          - EMAIL : info@catef.ci - RC : 2222222222 - CC : 1111111111
        </p>{" "}
      </section>
    </div>
  );
};
