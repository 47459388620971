import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const listChoix = async () => {
  try {
    const { data } = await axios.get("edition/etat-client/liste", config);
    return data;
  } catch (error) {
    return error;
  }
};





export const getChoix = async () => {
  try {
    const { data } = await axios.get("edition/get/list_choix", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getClient = async () => {
  try {
    const { data } = await axios.get(
      "edition/get/etat_acteur_client_list",
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getDossier = async () => {
  try {
    const { data } = await axios.get("edition/get/list_dossier", config);
    return data;
  } catch (error) {
    return error;
  }
};


export const printPdf = async (datas) => {
  try {
    const { data } = await axios.post("edition/etat-client/" + datas.choix + "/selection", datas, config);
    return data;
  } catch (error) {
    return error;
  }
};

// export const pdf
// edition / etat - client / 105 / selection