import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getDocument = async () => {
  try {
    const { data } = await axios.get("setting/list/doc_a_fournir", config);
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteDocument = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/delete/doc_a_fournir", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createDocument = async (d) => {
  return await axios.post(
    "setting/create/doc_a_fournir", d, config);
  // try {
  //   return data;
  // } catch (error) {
  //   return error;
  // }
};

export const updateDocument = async (d) => {

  return await axios.post("setting/update/doc_a_fournir", d, config);
  // try {
  //   const { data } = await axios.post(
  //     "setting/update/doc_a_fournir",
  //     d,
  //     config
  //   );
  //   return data;
  // } catch (error) {
  //   return error;
  // }
};
