import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getTypeActeurs = async () => {
  try {
    const { data } = await axios.get("setting/list/type_acteur", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createTypeActeur = async (d) => {
  return await axios.post("setting/create/type_acteur", d, config);
};

export const updateTypeActeur = async (d) => {
  return await axios.post("setting/update/type_acteur", d, config);
};

export const deleteTypeActeur = async (d) => {
  return await axios.post("setting/delete/type_acteur", d, config);
};

/**
 * liste document affecté
 * url: http://url/setting/list/doc/daffecte
 * method post
 * parameter 
      'iddocument' =>'required'
 */
export const listeDocumentDejaAffecte = async (iddocument) => {
  const data = { iddocument };
  return await axios.post("setting/list/doc/daffecte", data, config);
};

export const listeDocumentAffecte = async (iddocument) => {
  return null;
};

/**
 *  url: http://url/setting/delete/doc/affecte
 *  method post
 *  parameter 
     'iddocument' =>'required',
      'idtypact' =>'required',
 */
export const deleteDocumentAffecte = async (iddocument, idtypact) => {
  const data = { iddocument, idtypact };
  return await axios.post("setting/delete/doc/affecte", data, config);
};

/**
 * url http://url/setting/create/doc/affecte
 *  parameter 
     'listDocument' =>'required',
 
 * exaample data
     {
    "listDocument": [
        {   "iddocument":5,
            "idtypact": 1002
        },
        {   "iddocument":5,
              "idtypact": 1002
        },
      ]
} 
 */
export const createDocumentAffecte = async (data) => {
  return await axios.post("setting/create/doc/affecte", data, config);
};

/**
 * liste a affecter
 * url http://url/setting/list/doc/aaffecte
 * parameter
 *  'iddocument' =>'required',
 */
export const listeAAffecte = async (iddocument) => {
  const data = { iddocument };
  return await axios.post("setting/list/doc/aaffecte", data, config);
};
