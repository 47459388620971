import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getQuartierByCommune = async (id) => {
  try {
    const { data } = await axios.post("setting/list/quartiers", id, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createQuartier = async (d) =>
  await axios.post("setting/create/quartier", d, config);

export const updateQuartier = async (d) =>
  await axios.post("setting/update/quartier", d, config);

export const deleteQuartier = async (d) =>
  await axios.post("setting/delete/quartier", d, config);