import React, { useState } from "react";
import { useEffect } from "react";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Layout from "../../layout/Layout";
import { listeDossierCloture } from "../../services/clotureDossier";
import { dossierACloture, saveClotureDossier } from "../../services/dossier";

const ClotureDossier = () => {
  const [search, setSearch] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [choiceAll, setChoiceAll] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [clotureDossier, setClotureDossier] = useState([])
  const [value, setValue] = useState({
    listdoss: [
      // {
      //   id: 1044,
      // },
    ],
  });

  // pagination code
  let PageSize = 10;

  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);

  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onChecked = (current, i) => {
    const updatedCheckedState = checkedState.map((check, index) =>
      index === i ? !check : check
    );

    setCheckedState(updatedCheckedState);
    setChoiceAll(false);

    const copy = [...value.listdoss];
    const find = copy.find((liste) => liste.id == current.ID);
    if (typeof find !== "undefined") {
      setChoiceAll(false);
      const update = copy.filter((liste) => liste.id != current.ID);
      setValue({
        ...value,
        listdoss: [...update],
      });
    } else {
      const add = { id: current.ID };
      copy.push(add);
      setValue({
        ...value,
        listdoss: [...copy],
      });
    }
  };


  const onChechedAll = (e) => {
    const copy = [...value.listdoss];
    setChoiceAll(e.target.checked);

    if (e.target.checked) {
      const check = new Array(clotureDossier.length).fill(true);
      setCheckedState(check);
      for (const item of tableData) {
        let find = copy.find((liste) => liste.id == item.ID);
        if (typeof find !== "undefined") {
          let update = copy.filter((liste) => liste.id != item.ID);
          setValue({
            ...value,
            listdoss: [...update],
          });
        } else {
          let add = { id: item.ID };
          copy.push(add);
          setValue({
            ...value,
            listdoss: [...copy],
          });
        }
      }
    } else {
      const check = new Array(clotureDossier.length).fill(false);
      setCheckedState(check);
      setValue({
        ...value,
        listdoss: [],
      });
    }
  };

  const fetchDossierACloture = () => {
    setIsLoading(true)
    dossierACloture().then(({ data }) => {
      setClotureDossier(data.data)

      const checked = new Array(data.data.length).fill(false);
      setCheckedState(checked);

      const d = page(1, data.data);
      setCurrentTableData(d);
      setTableData(data.data);
      const coutPage = parseInt(data.data.length / PageSize);
      setTotalPage(coutPage);

      setIsLoading(!true)
    }).catch(({ response }) => {
      setIsLoading(!true)
    })
  }

  const onRecherche = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(e.target.value);
    if (e.key == "Enter") {

      dossierACloture(e.target.value)
        .then(({ data }) => {
          const d = page(1, data.data);
          setCurrentTableData(d);
          setTableData(data.data);
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    saveClotureDossier(value)
      .then(({ data }) => {
        // console.log(data);
        setFlash({ message: data.message, color: "success" });
        setValue({ ...value, listdoss: [] });
        const check = new Array(tableData.length).fill(false);
        fetchDossierACloture()
        setCheckedState(check);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setFlash({ message: response.data.message, color: "danger" });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDossierACloture();
  }, [])

  return (
    <Layout>
      <div className="row">
        <form onSubmit={onSubmit}>
          <section className="col-xs-12">
            <div id="ContentPlaceHolder1_UpdatePanel1">
              <div className="row">
                <div className="form-horizontal col-xs-12 ui-sortable">
                  <div className="widget-box widget-color-blue">
                    {/* Entete du cadre */}
                    <div className="widget-header ui-sortable-handle">
                      <h5 className="widget-title">Clôture de dossiers</h5>
                      <div className="navbar-form navbar-right form-search">
                        <div id="ContentPlaceHolder1_Panel1">
                          <span className="input-icon">
                            <input
                              onChange={onChange}
                              onKeyUpCapture={onRecherche}
                              name="search"
                              type="text"
                              tabIndex={1}
                              className="nav-search-input"
                              placeholder="Recherche ..."
                            />
                            <i className="ace-icon fa fa-search nav-search-icon" />
                            {/* <input
                              type="submit"
                              name="ctl00$ContentPlaceHolder1$BTRECH"
                              defaultValue="OK"
                              id="ContentPlaceHolder1_BTRECH"
                              tabIndex={2}
                              className="btn-info btn-sm MasquerColonne"
                              style={{ borderStyle: "None" }}
                            /> */}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Contenu du cadre */}
                    <div className="widget-body">
                      <div className="widget-main">
                        {flash.message && <Alert message={flash.message} color={flash.color} />}

                        {isLoading && <Loading isLoading={isLoading} />}
                        <span
                          id="ContentPlaceHolder1_LBINFO"
                          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
                        />
                        <label className="block clearfix">
                          <span className="block">
                            <div>
                              <table
                                cellSpacing={0}
                                rules="all"
                                className="table-header"
                                border={1}
                                id="ContentPlaceHolder1_GV"
                                style={{
                                  borderColor: "#E1E1E1",
                                  fontSize: "10pt",
                                  width: "100%",
                                  borderCollapse: "collapse"
                                }}
                              >
                                <tbody>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    style={{
                                      color: "#707070",
                                      backgroundColor: "#F1F1F1",
                                      fontWeight: "normal"
                                    }}
                                  >

                                    <th scope="col" style={{ width: 80 }}>
                                      Date
                                    </th>
                                    <th scope="col" style={{ width: 100 }}>
                                      N° matricule
                                    </th>
                                    <th scope="col">Nom du client</th>
                                    <th scope="col">Agence</th>
                                    <th scope="col" style={{ width: 100 }}>
                                      N° dossier
                                    </th>
                                    <th scope="col" style={{ width: 100 }}>
                                      N° de lot
                                    </th>
                                    <th scope="col">Type de logement</th>
                                    <th scope="col" style={{ width: 30 }}>
                                      &nbsp;
                                    </th>
                                  </tr>
                                  {currentTableData?.map((item, i) => {

                                    return <tr key={i} style={{ color: "Black", backgroundColor: "White" }}>
                                      <td>{item.DATE}</td>
                                      <td>{item.MATRICULE}</td>
                                      <td>{item.NOM}</td>
                                      <td>{item.AGENCE}</td>
                                      <td>{item.NUMDOSSIER}</td>
                                      <td>{item.NUMLOT}</td>
                                      <td>{item.TYPELOGEMENT}</td>
                                      <td className="ace text-center">
                                        <input
                                          type="checkbox"
                                          checked={checkedState[i]}
                                          name=""
                                          onChange={() => onChecked(item, i)}
                                        />
                                      </td>
                                    </tr>
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </span>
                        </label>
                        <span className="ace">
                          <input
                            id="all"
                            type="checkbox"
                            name=""
                            checked={choiceAll}
                            onClick={onChechedAll}
                            tabIndex={1}
                          />
                          <label htmlFor="all">Cocher tout</label>
                        </span>

                        {/* pagination */}
                        <div className="widget-toolbox clearfix" style={{}}>
                          <center>
                            <Pagination onPageChange={onPageChange} totalPage={totalPage} />
                          </center>
                        </div>
                      </div>

                      {/* Pied du cadre */}
                      <div className="widget-toolbox padding-8 clearfix">
                        <div className="pull-right">
                          <input
                            type="submit"
                            value="Valider"
                            tabIndex={2}
                            className="btn btn-success"
                            style={{ borderStyle: "None", width: 100 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </Layout>
  );
};

export default ClotureDossier;
