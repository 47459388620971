import React, { useEffect, useRef, useState } from "react";
import "./style-facture.css";
import "moment/locale/fr";
import { factureClient } from "../../../../services/dossier";
import Layout from "../../../../layout/Layout";
import Loading from "../../../../components/Loading";
import Alert from "../../../../components/Alert";
import { pathUrl } from "../../../..";

const FactureClient = () => {
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [facture, setFacture] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  let iddossier = null;
  const get_info = localStorage.getItem("__info_facture");
  if (get_info) {
    iddossier = get_info;
  }

  // setTimeout(() => {
  //   localStorage.removeItem("__info_facture");
  // }, 5000);



  const getFactureClient = () => {
    setIsLoading(true);
    factureClient(iddossier)
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          let dateCache = new Date()
          const source = pathUrl + data.data + "?" + dateCache.getTime();
          window.open(source, '_blank');
        } else {
          setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    getFactureClient();
  }, []);

  return (
    <Layout>
      <section className="col-xs-12">
        {isLoading && <Loading isLoading={isLoading} />}
        {flash.message && <Alert message={flash.message} color={flash.color} />}
      </section>
    </Layout>
  );
};

export default FactureClient;
