import React from "react";
import Pagination from "../../../../components/Pagination";
import { editOnglet1 } from "../../../../services/dossier";
import moment from 'moment';

const TableSaisirClient = ({
  currentTableData,
  setIdClient,
  onPageChange,
  totalPage,
  setOnglet,
  onglet,
  setCurrent,
  setValues,
  values,
  setFlash,
  flash,
  setDataOnglet2,
  setDossiers,
  setIsLoadingGlobal,
}) => {
  const onClick = async (d) => {
    setCurrent(d);
    // console.log(d);
    setIsLoadingGlobal(true);
    localStorage.setItem("__info_fiche", d.ID);
    localStorage.setItem("__info_onglet3", d.ID);
    setIdClient(d.ID);

    setDataOnglet2([]);
    setDossiers([]);
    editOnglet1({ id: d.ID })
      .then(({ data }) => {
        setDataOnglet2(data?.data);
        setDossiers(data?.data?.dossier);
        // console.log(data);
        const {
          CEL,
          MATRICULE,
          NOM,
          IDNATIONALITE,
          DATENAISS,
          LIEUNAISS,
          ADRESSE,
          CIVILITE,
          TEL,
          DATENAISSCONJOINT,
          EMAIL,
          FONCTION,
          FONCTIONCONJOINT,
          IDNATIONALITECONJOINT,
          LIEUNAISSCONJOINT,
          LOCALISATION,
          IDTPIECEIDENTITE,
          NOMCONJOINT,
          NUMPIECE,
          REGIME,
          SITUMATRI,
          LIENPIECE,
        } = data.data.client[0];

        setValues({
          ...values,
          civilite: CIVILITE ?? "",
          situmatri: SITUMATRI ?? "",
          regime: REGIME ?? "",
          matricule: MATRICULE ?? "",
          nom: NOM ?? "",
          idnationnalite: IDNATIONALITE ?? "",
          datenaiss: moment(DATENAISS).format("YYYY-MM-DD") ?? "",
          lieunaiss: LIEUNAISS ?? "",
          idtpieceidentite: IDTPIECEIDENTITE ?? "",
          numpiece: NUMPIECE ?? "",
          localisation: LOCALISATION ?? "",
          fonction: FONCTION ?? "",
          tel: TEL ?? "",
          cel: CEL ?? "",
          adresse: ADRESSE ?? "",
          email: EMAIL ?? "",
          nomconjoint: NOMCONJOINT ?? "",
          natConjoint: IDNATIONALITECONJOINT ?? "",
          dateNaissConjoint: moment(DATENAISSCONJOINT).format("YYYY-MM-DD") ?? "",
          lieuNaissConjoint: LIEUNAISSCONJOINT ?? "",
          fonctionconjoint: FONCTIONCONJOINT ?? "",
          piece: LIENPIECE ?? "",
        });
        setIsLoadingGlobal(false);
      })
      .catch((err) => {
        // console.log(err);
        setFlash({
          ...flash,
          message: err.response.data.message,
          color: "danger",
        });
        setIsLoadingGlobal(false);
      });
  };
  return (
    <>
      {currentTableData?.length !== 0 && (
        <div>
          <table
            cellSpacing={0}
            rules="all"
            className="table-header"
            border={1}
            id="ContentPlaceHolder1_GV"
            style={{
              borderColor: "#E1E1E1",
              fontSize: "10pt",
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr
                align="center"
                valign="middle"
                style={{
                  color: "#707070",
                  backgroundColor: "#F1F1F1",
                  fontWeight: "normal",
                }}
              >
                <th
                  className="MasquerColonne"
                  scope="col"
                  style={{ width: 90 }}
                >
                  Code
                </th>
                <th scope="col" style={{ width: 100 }}>
                  Matricule
                </th>
                <th scope="col">Nom</th>
                <th scope="col" style={{ width: 180 }}>
                  Contacts
                </th>
                <th scope="col" style={{ width: 30 }}>
                  &nbsp;
                </th>
              </tr>
              {currentTableData?.map((item, key) => (
                <tr
                  key={key}
                  style={{
                    color: "Black",
                    backgroundColor: "White",
                  }}
                >
                  <td className="MasquerColonne"></td>
                  <td>{item.MATRICULE}</td>
                  <td>{item.NOM}</td>
                  <td>{item.CEL}</td>
                  <td className="text-center" align="right">
                    <a href="#navbar">
                      <img
                        onClick={() => {
                          setOnglet({
                            ...onglet,
                            one: "",
                            two: "active",
                            three: "",
                            four: "",
                          });
                          const el = document.getElementById('onglet_one');
                          const el_doss = document.getElementById('Client');
                          if (el != null && el.classList.contains("active")) {
                            el.classList.remove('active');
                          }
                          if (el_doss != null && el_doss.classList.contains("active")) {
                            el_doss.classList.remove('active');
                          }
                          // console.log(onglet);
                          setCurrent(item);
                          localStorage.removeItem("__dossier_client");

                          onClick(item);
                        }}
                        // className="img img-fluid"

                        src={"/Catef_files/modifier.png"}
                      />
                    </a>
                  </td>
                </tr>
              ))}

              <Pagination onPageChange={onPageChange} totalPage={totalPage} />
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TableSaisirClient;
