import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const entites = async () => {
  try {
    const { data } = await axios.get("utilisateur/entite/all", config);
    return data;
  } catch (error) {
    return error;
  }
};
export const agences = async () => {
  try {
    const { data } = await axios.get("utilisateur/agence/all", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const profils = async () => {
  try {
    const { data } = await axios.get("utilisateur/profil/all", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const utilisateurs = async (d) => {
  try {
    const { data } = await axios.get("utilisateur/list?search=" + d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const getUtilisateur = async (d) => {
  try {
    const { data } = await axios.get("utilisateur/get/user?id=" + d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const creerUtilisateur = async (d) => {
  try {
    const { data } = await axios.post("utilisateur/create", d, config);
    return data;
  } catch (error) {
    return error;
  }
};





export const getChoix = async () => {
  try {
    const { data } = await axios.get("edition/get/list_choix", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getClient = async () => {
  try {
    const { data } = await axios.get(
      "edition/get/etat_acteur_client_list",
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getDossier = async () => {
  try {
    const { data } = await axios.get("edition/get/list_dossier", config);
    return data;
  } catch (error) {
    return error;
  }
};


export const printPdf = async (datas) => {
  try {
    const { data } = await axios.post("edition/etat-client/" + datas.choix + "/selection", datas, config);
    return data;
  } catch (error) {
    return error;
  }
};

// export const pdf
// edition / etat - client / 105 / selection