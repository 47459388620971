import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import Table from "../../othercomponents/Table";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import LayoutActeur from "./LayoutActeur";
import { fillDocUrgence, fillDocClient, registerClient } from "../../services/acteur/soldeClient";

const SoldeClient = () => {
  const [current, setCurrent] = useState(null);
  const [currentUrgence, setCurrentUrgence] = useState([]);
  const [currentDoc, setCurrentDoc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUrge, setIsLoadingUrge] = useState(false);

  const [flash, setFlash] = useState(
    {
      message1: "La liste des dossier à traiter en urgence", color1: "danger",
      message2: "La liste des clients présents dans la base de données ainsi que le statut des soldes", col2: "primary"
    });
  const [stateForm, setStateForm] = useState({
    iddossier: 0,
    montant: "",
    mode: "",
    liendocument: "",
    observ: "",
    total_a_payer: "",
    montant_encaisser: "",
    reste_payer: "",
    banque: "",
    reference: "",
    deposant: "",
    emett: new Date()
  });
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  const title = "Liste des clients";

  // pagination code
  let PageSize = 10;

  const [currentTableDataUrge, setCurrentTableDataUrge] = useState([]);
  const [tableDataUrge, setTableDataUrge] = useState([]);
  const [totalPageUrge, setTotalPageUrge] = useState(null);
  const [search, setSearch] = useState({ search: "" });

  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);

  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };

  const onVerifySolde = (e) => {
    e.preventDefault();
    const data = { search: search.search, idacteur: id };
    if (e.key == "Enter") {
      fetchDocClient(data)
    }
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };

  const onPageChangeUrge = (e) => {
    const d = page(parseInt(e.selected + 1), tableDataUrge);
    setCurrentTableDataUrge(d);
    // console.log(d);
  };
  // fin code pagination

  const headTable = [
    { name: "Client" }, { name: "Contact" }, { name: "Opération" }, { name: "Type Logement" }, { name: "Ilot" }, { name: "Lot" },
    { name: "Superficie" }, { name: "Etat" },
  ];
  const footer = [
    { value: "Valider", type: "submit", className: "btn-success" },
  ];


  const id = localStorage.getItem('__user_acteur_id')

  const fetchDocUrgence = () => {
    setIsLoadingUrge(true)
    var data = { idacteur: id }
    fillDocUrgence(data)
      .then((data) => {
        console.log(data);
        setCurrentUrgence(data.data);
        const d = page(1, data?.data);
        setCurrentTableDataUrge(d);
        setTableDataUrge(data.data);
        const coutPage = parseInt(data.data.length / PageSize);
        setTotalPage(coutPage);
        setIsLoadingUrge(false)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const fetchDocClient = (data) => {
    setIsLoading(true)
    fillDocClient(data)
      .then((res) => {
        setIsLoading(false)
        setCurrentDoc(res?.data);
        const d = page(1, res?.data);
        setCurrentTableData(d);
        setTableData(res.data);
        setFlash({ color: "success", message: "La liste des clients présents dans la base de données ainsi que le statut des soldes" });
        const coutPage = parseInt(res.data.length / PageSize);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const editCustomer = (id) => {
    console.log(id)
    localStorage.setItem('__id_customer_update', id)

  }

  const onChange = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value })
  };

  const enregistrementClient = async (d) => {
    setIsLoading(true)
    registerClient(d)
      .then((data) => {
        console.log(data);
        setCurrentUrgence(data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const onSubmit = async (data) => {
    data = { ...data, ...stateForm };
    console.log(data);
    try {
      await enregistrementClient(data);
      console.log("Enregistrement reussir avec succes !", data);
      reset();
      setCurrent(null);
      setStateForm({
        ...stateForm,
        iddossier: 0,
        montant: "",
        mode: "",
        liendocument: "",
        observ: "",
        total_a_payer: "",
        montant_encaisser: "",
        reste_payer: "",
        banque: "",
        reference: "",
        deposant: "",
        emett: ""
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDocUrgence();
    // fetchDocClient();
  }, []);


  return (
    <LayoutActeur>

      <div className="row">
        <div className={`col-xs-1`}></div>
        <div className={`form-horizontal col-xs-12 ui-sortable`}>
          <div className={`widget-box widget-color-blue`}>

            {/* Entete du cadre */}
            <div className="widget-header ui-sortable-handle">
              <h5 className="widget-title">{title}</h5>
              <div className="navbar-form navbar-right form-search" style={{ marginLeft: 50 }}>
                <div
                  id="ContentPlaceHolder1_Panel1"
                  onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                >
                  <span className="input-icon">
                    <input
                      name="critere"
                      type="text"
                      id="TBRECH"
                      tabIndex={1}
                      className="nav-search-input"
                      placeholder="Recherche ..."
                    />
                    <i className="ace-icon fa fa-search nav-search-icon" />
                    <input
                      type="submit"
                      name="ctl00$ContentPlaceHolder1$BTRECH"
                      defaultValue="OK"
                      id="ContentPlaceHolder1_BTRECH"
                      tabIndex={2}
                      className="btn-info btn-sm MasquerColonne"
                      style={{ borderStyle: "None" }}
                    />
                  </span>
                </div>
              </div>
            </div>
            {flash.message1 !== null && <Alert color={flash.color1} message={flash.message1} />}
            {isLoading && <Loading isLoading={isLoadingUrge} />}
            {/* Contenu du cadre */}
            <div className="widget-body">
              <div className="widget-main">
                <span
                  id="ContentPlaceHolder1_LBINFO"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "center"
                  }}
                />
                <label className="block clearfix">
                  <span className="block">
                    <table
                      cellSpacing={0}
                      rules="all"
                      className="table-header table table-striped "
                      border={1}
                      id="ContentPlaceHolder1_GV"
                      style={{
                        borderColor: "#E1E1E1",
                        fontSize: "10pt",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr
                          align="center"
                          valign="middle"
                          style={{
                            color: "#707070",
                            backgroundColor: "#F1F1F1",
                            fontWeight: "normal",
                          }}
                        >
                          {headTable?.map((item, index) => {
                            return (
                              <th
                                key={index}
                                className="text-center"
                                scope="col"
                              // style={{ width: item?.width }}
                              >
                                {item?.name}
                              </th>
                            );
                          })}
                        </tr>

                        {currentUrgence?.map((item, key) => (
                          <tr style={{
                            backgroundColor: item.Etat === "SOLDE" ? 'LightGreen' : 'LightSkyBlue',
                            color: "black"
                          }}
                            key={key}>
                            <td>{item.NOMCLIENT}</td>
                            <td>{item.CONTACTSCLIENT}</td>
                            <td>{item.NOMPROJET}</td>
                            <td>{item.TYPELOGEMENT}</td>
                            <td>{item.ILOT}</td>
                            <td>{item.LOT}</td>
                            <td>{item.SUPERFICIE}</td>
                            <td>{item.Etat}</td>
                          </tr>
                        ))}
                        <Pagination onPageChange={onPageChangeUrge} totalPage={totalPageUrge} />
                      </tbody>
                    </table>
                  </span>
                </label>
              </div>

            </div>

            {/* Pied du cadre */}
            {footer?.map((btn, key) => (
              <div className="widget-toolbox padding-8 clearfix" key={key}>
                <div className="pull-right">
                  <input
                    type={btn.type}
                    name={btn.name}
                    value={btn.value}
                    id={btn.id}
                    className={`btn btn-${btn.className}`}
                    style={{ borderStyle: "None", width: 100 }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="row">
        <div className={`col-xs-1`}></div>
        <div className={`form-horizontal col-xs-12 ui-sortable`}>
          <div className={`widget-box widget-color-blue`}>

            {/* Entete du cadre */}
            <div className="widget-header ui-sortable-handle">
              <h5 className="widget-title">{title}</h5>
              <div className="navbar-form navbar-right form-search" style={{ marginLeft: 50 }}>
                <div
                  id="ContentPlaceHolder1_Panel1"
                  onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                >
                  <span className="input-icon">
                    <input
                      name="critere"
                      type="text"
                      id="TBRECH"
                      tabIndex={1}
                      className="nav-search-input"
                      placeholder="Recherche ..."
                      onChange={onChangeSearch}
                      onKeyUpCapture={onVerifySolde}
                    />
                    <i className="ace-icon fa fa-search nav-search-icon" />
                    <input
                      type="submit"
                      name="ctl00$ContentPlaceHolder1$BTRECH"
                      defaultValue="OK"
                      id="ContentPlaceHolder1_BTRECH"
                      tabIndex={2}
                      className="btn-info btn-sm MasquerColonne"
                      style={{ borderStyle: "None" }}
                    />
                  </span>
                </div>
              </div>
            </div>

            {flash.message2 !== null && <Alert color={flash.color2} message={flash.message2} />}
            {isLoading && <Loading isLoading={isLoading} />}
            {/* Contenu du cadre */}
            <div className="widget-body">
              <div className="widget-main">
                <span
                  id="ContentPlaceHolder1_LBINFO"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "center"
                  }}
                />
                <label className="block clearfix">
                  <span className="block">
                    <table
                      cellSpacing={0}
                      rules="all"
                      className="table-header table table-striped "
                      border={1}
                      id="ContentPlaceHolder1_GV"
                      style={{
                        borderColor: "#E1E1E1",
                        fontSize: "10pt",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr
                          align="center"
                          valign="middle"
                          style={{
                            color: "#707070",
                            backgroundColor: "#F1F1F1",
                            fontWeight: "normal",
                          }}
                        >
                          {headTable?.map((item, index) => {
                            return (
                              <th
                                key={index}
                                className="text-center"
                                scope="col"
                              // style={{ width: item?.width }}
                              >
                                {item?.name}
                              </th>
                            );
                          })}
                        </tr>
                        {currentTableData?.map((item, key) => (
                          <tr
                            style={{
                              backgroundColor: item.Etat === "SOLDE" ? 'LightGreen' : 'LightSkyBlue',
                              color: "black"
                            }}
                            key={key}>
                            <td>{item.NOMCLIENT}</td>
                            <td>{item.CONTACTSCLIENT}</td>
                            <td>{item.NOMPROJET}</td>
                            <td>{item.TYPELOGEMENT}</td>
                            <td>{item.ILOT}</td>
                            <td>{item.LOT}</td>
                            <td>{item.SUPERFICIE}</td>
                            <td>
                              <select value={item.ETAT} class="form-control">
                                <option value="INCONNU">INCONNU</option>
                                <option value="NON SOLDE">NON SOLDE</option>
                                <option value="SOLDE">SOLDE</option>
                              </select>
                              <Link
                                to="/acteur/ajouter-nouveau-client"
                                onClick={() => {
                                  // console.log(item);
                                  localStorage.setItem(
                                    "__id_customer_update",
                                    item.ID
                                  );
                                }}
                              >
                                <input
                                  type="button"
                                  defaultValue="Modifier"
                                  className="btn-danger btn"
                                  style={{ borderStyle: "None" }}
                                />
                              </Link>
                            </td>
                          </tr>
                        ))}
                        <Pagination onPageChange={onPageChange} totalPage={totalPage} />
                      </tbody>
                    </table>
                  </span>
                </label>
              </div>

            </div>

            {/* Pied du cadre */}
            {footer?.map((btn, key) => (
              <div className="widget-toolbox padding-8 clearfix" key={key}>
                <div className="pull-right">
                  <input
                    type={btn.type}
                    name={btn.name}
                    value={btn.value}
                    id={btn.id}
                    className={`btn btn-${btn.className}`}
                    style={{ borderStyle: "None", width: 100 }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </LayoutActeur>
  );
};

export default SoldeClient;
