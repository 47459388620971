import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

/**
 * Liste des etape
 * Api : http://127.0.0.1:9090/catef_backend/public/api/etape/list/etape
 * Verb: Post
 * parameter = idetape = non requis
 */
export const getListeEtape = async (iddossier) => {
  const data = { iddossier: iddossier };
  return await axios.post("etape/list/etape", data, config);
};


export const getListeEtapeDossier = async (data) => {

  return await axios.post("etape/list/dossier", data, config);

};


export const createEtapeDossier = async (id) => {
  try {
    const { data } = await axios.post("etape/create/etape", id, config);
    return data;
  }
  catch (error) {
    return error;
  }
};

export const deleteEtapeDossier = async (id) => {
  try {
    const { data } = await axios.post("etape/delete/etape", id, config);
    return data;
  }
  catch (error) {
    return error;
  }
};


export const listeEtapeDossier = async (d) => {

  const data = { iddossier: d };
  return await axios.post("etape/list/etape/dossier", data, config);

};