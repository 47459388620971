import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getPays } from "../../services/pays";
import {
  createVille,
  deleteVille,
  getVillesByPays,
  updateVille,
} from "../../services/ville";
import ReactPaginate from "react-paginate";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";

const Villes = () => {
  const headTable = [
    { name: "Libellé ", width: "95%" },
    { name: "", with: "5%" },
  ];

  const [values, setValues] = useState({
    idpays: "",
    libelle: "",
  });
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [pays, setPays] = useState([]);
  const [current, setCurrent] = useState(null);
  const [villes, setVilles] = useState([]);
  const _getPays = async () => {
    const { data } = await getPays();
    setPays(data);
  };

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), villes);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onSelectPays = async (e) => {
    setIsLoading(true);
    setValues({ ...values, idpays: e.target.value });
    getVillesByPays({ id: e.target.value })
      .then((res) => {
        const { data, message } = res;
        // console.log(message);
        const d = page(1, data);
        setFlash({ ...flash, message: message, color: "success" });
        setVilles(data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        // console.log(coutPage);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch((response) => {
        setIsLoading(false);
        setVilles([]);
        setFlash({ ...flash, message: response.response.data.message, color: "danger" });
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(isLoading);
    if (current) {
      if (values.idpays.length === 0 || values.libelle.length === 0) {
        setIsLoading(false);
        setFlash({ ...flash, message: "Veuillez saisir les champs requis", color: "danger" });
        return false;
      }
      const data = {
        idpays: values.idpays,
        libelle: values.libelle,
        id: current.ID,
      };

      updateVille(data)
        .then((res) => {
          console.log(res)
          setFlash({ ...flash, message: res.message, color: "success" });
          getVillesByPays({ id: values.idpays })
            .then((res) => {
              const { data } = res;
              const d = page(1, data);
              setVilles(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
          setValues({ ...values, libelle: "" });
          setIsLoading(false);
        })
        .catch((response) => {
          setFlash({ ...flash, message: response.response.data.message, color: "danger" });
          setIsLoading(false);
        });
    } else {
      createVille(values)
        .then((resultat) => {
          setFlash({ ...flash, message: resultat.data.message, color: "success" });
          getVillesByPays({ id: values.idpays })
            .then((res) => {
              const { data } = res;
              const d = page(1, data);
              setVilles(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
          setValues({ ...values, libelle: "" });
        })
        .catch((response) => {
          console.log(response);
          setFlash({ ...flash, message: response.response.data.message, color: "danger" });
          setIsLoading(false);
          getVillesByPays({ id: values.idpays })
            .then((res) => {
              const { data } = res;
              const d = page(1, data);
              setVilles(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };

  const onclick = (data) => {
    setCurrent(data);
    setValues({ ...values, idpays: data.IDPAYS, libelle: data.LIBELLE });
  };

  const onDelete = async () => {
    if (window.confirm("Voulez vous supprimé")) {
      const data = { id: current.ID, nom: current.NOM };
      deleteVille(data)
        .then(({ data }) => {
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getVillesByPays({ id: values.idpays })
            .then((res) => {
              const { data } = res;
              const d = page(1, data);
              setVilles(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          getVillesByPays({ id: values.idpays })
            .then((res) => {
              const { data } = res;
              const d = page(1, data);
              setVilles(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              // console.log(coutPage);
              setTotalPage(coutPage);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };

  useEffect(() => {
    _getPays();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Saisie des villes</h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Pays (*)
                    </label>
                    <select
                      name="idpays"
                      className="form-control input-xxlarge"
                      onChange={onSelectPays}
                    >
                      <option value="-1"></option>
                      {pays?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.NOM}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Ville (*)
                    </label>
                    <input
                      value={values.libelle}
                      onChange={handleChange}
                      name="libelle"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Enregistrer"
                      className="btn btn-success "
                      disabled={isLoading ? true : false}
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        value="Supprimer"
                        className="btn btn-danger"
                        onClick={onDelete}
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => {
                        setCurrent(null);
                        setValues({ ...values, libelle: "" });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table title={"Liste des villes"} headTable={headTable}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.LIBELLE}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}
          {/* pgination */}
          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
        </Table>
      )}
    </Layout>
  );
};
export default Villes;
