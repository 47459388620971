import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

/**
 * Menu Affectation
 * Liste des operateurs
 */
export const listeOperateur = async () => {
  return await axios.get("dossier/affectation/list/operateur", config);
};

/**
 * Liste des operations immo
 * Api: http://127.0.0.1:9090/catef_backend/public/api/dossier/affectation/list/operation
 * Verb : GET
 * Parameter : idoperateurimmo ===> required
 */
export const listeOperationImmo = async (idoperateurimmo) => {
  const data = { idoperateurimmo };
  return await axios.post("dossier/affectation/list/operation", data, config);
};

/**
 * Remplir la grille 
 * Api: http://127.0.0.1:9090/catef_backend/public/api/dossier/affectation/list/grille
 * Verb : POST 
 * Parameter :
    idoperation: =====> required 
    idtacteur     ======> not required
 */
export const listeGrille = async (idoperation, idtacteur = null) => {
  let data;
  if (idtacteur == null || idtacteur == "") {
    data = { idoperation };
  } else {
    data = { idoperation, idtacteur };
  }
  return await axios.post("dossier/affectation/list/grille", data, config);
};

/**
 * Liste Type Acteur :
 * Api : http://127.0.0.1:9090/catef_backend/public/api/dossier/affectation/list/type_acteur
 * Verb: Get
 */
export const listeTypeActteur = async () => {
  return await axios.get("dossier/affectation/list/type_acteur", config);
};

/**
 * Liste des Acteurs
 * Api : http://127.0.0.1:9090/catef_backend/public/api/dossier/affectation/list/acteur
 * Verb : POST
 * Parameter : idtacteurfoncier =====> required
 */
export const listeActeur = async (idtacteurfoncier) => {
  const data = { idtacteurfoncier };
  return await axios.post("dossier/affectation/list/acteur", data, config);
};

/**
 * Affecter un dossier
 * Api : http://127.0.0.1:9090/catef_backend/public/api/dossier/get/create_aff_doss_act
 * Verb : POST
 */
export const createAffectation = async (data) => {
  return await axios.post("dossier/get/create_aff_doss_act", data, config);
};
