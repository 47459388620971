import * as React from "react";
import { Link, Outlet } from "react-router-dom";
const SidebarActeur = () => {

  const profil = localStorage.getItem("__myProfil");
  // console.log(profil)

  return (
    <div id="sidebar" className="sidebar responsive display">
      {/* Menu Tableau de bord */}
      {profil === "acteur" &&
        (<ul className="nav nav-list" style={{ top: 0 }}>
          <li className="active">
            <Link to="/acteur/accueil">
              <i className="menu-icon fa fa-tachometer blue" />
              <span className="menu-text"> Tableau de bord </span>
            </Link>
            <b className="arrow" />
          </li>
          <li className>
            <Link to={"/acteur/ajouter-nouveau-client"}>
              <i className="menu-icon fa fa-print blue" />
              <span className="menu-text">Ajouter un client</span>
              <b className="arrow" />
            </Link>
          </li>
          <li className>
            <Link to={"/acteur/solde-client"}>
              <i className="menu-icon fa fa-print blue" />
              <span className="menu-text">Solde client</span>
              <b className="arrow" />
            </Link>
          </li>
        </ul>)}
      {profil === "foncier" &&
        (<ul className="nav nav-list" style={{ top: 0 }}>
          <li className="active">
            <Link to="/acteur/accueil">
              <i className="menu-icon fa fa-tachometer blue" />
              <span className="menu-text"> Tableau de bord </span>
            </Link>
            <b className="arrow" />
          </li>
          <li className>
            {/* <Link to={"/acteur/solde-client"}>
            <i className="menu-icon fa fa-users blue" />
            <span className="menu-text">Utilisateurs</span>
            <b className="arrow" />
        </Link> */}
          </li>
        </ul>)}
      {/* Barre de navigation */}
      <div className="sidebar-toggle sidebar-collapse" id="sidebar-collapse">
        <i
          className="ace-icon fa fa-angle-double-left"
          data-icon1="ace-icon fa fa-angle-double-left"
          data-icon2="ace-icon fa fa-angle-double-right"
        />
      </div>
      <Outlet />
    </div>
  );
};

export default SidebarActeur;
