import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

/**
 * Liste des dossiers a cloturer 
 * Api: http://127.0.0.1:9090/catef_backend/public/api/dossier/get/doss/cloture
 * Verb : Post 
 * parameter : 
    search ====> not required
 */
export const listeDossierCloture = async (search) => {
  const data = { search };
  return await axios.post("dossier/get/doss/cloture", data, config);
};
