import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const getListeClient = async (id = null) => {
  if (id != null) {
    return await axios.get("dossier/doss/list/client?recherche=" + id, config);
  }
  return await axios.get("dossier/doss/list/client", config);
};

export const saissirDossierClient = async (d) =>
  await axios.post("dossier/create/client", d, config);

export const updateSaisirDossierClient = async (d) =>
  await axios.post("dossier/update/client", d, config);

/**
 * @description liste du dossier client
 * @method Get
 * @param -id du client =====> champ requis
 * @returns
 */
export const listeDossierClient = async (id) => {
  return await axios.get("dossier/list/dossier_client?idclient=" + id, config);
};

export const editOnglet1 = async (id) =>
  await axios.post("dossier/search/client/dossier", id, config);

// Liste des operations Immobilieres dans details dossiers
export const listeOperateurImmo = async () => {
  return await axios.get("dossier/detail/liste/operationimmo", config);
};
// Liste des Ilots
export const listeIlot = async (idopimmo) => {
  const data = { idopimmo };
  return await axios.post("dossier/detail/liste/ilot", data, config);
};
// Liste des lots dans details dossier client
export const listeLot = async (idilot) => {
  const data = { idilot };
  return await axios.post("dossier/detail/liste/lot", data, config);
};
// Liste des agences
export const listeAgence = async () => {
  return await axios.get("dossier/detail/liste/agences", config);
};

// Api a appeler lorsquon clique sur le crayon dans l'onglet detail du dossier client
export const editOnglet2 = async (iddossier) => {
  const data = { iddossier };
  return await axios.post("dossier/detail/client/dossier", data, config);
};

// Api permettant la creation et la modification du dossier client
export const createOrUpdateDossier = async (data) => {
  return await axios.post("dossier/create/dossier_client", data, config);
};
// Api de suppression de dosseir
export const deleteDossier = async (iddossier) => {
  const data = { iddossier };
  return await axios.post("dossier/delete/dossier_client", data, config);
};

// Generer facture client
export const factureClient = async (iddossier) => {
  const data = { iddossier };
  return await axios.post("dossier/invoice/dossier_client", data, config);
};

// Generation de la fiche client
export const ficheClient = async (idclient) => {
  const data = { idclient };
  return await axios.post("dossier/customer_card/dossier_client", data, config);
};

/* Api pour le chargement des types de documents
 * http://bleibaulouh-001-site1.htempurl.com/catef_backend/public/api/dossier/piece/client/save
 * Verb : POST
 * parametre = 
   iddossier ===>required
   typedoc ====> required,
   numordre ====> required
*/
export const ajoutPiece = async (data) => {
  return await axios.post("dossier/piece/client/save", data, config);
};

/**
 * @description Api telechargement doc Acteur
 * @host https:..../dossier/piece/acteur/save
 * @method Verb ; post
 * @param parametre:
      'iddossier' => 'required',
      "typedoc"   =>  'required',
      "numordre"   =>  'required',
      "idacteur"   =>  'required',
      "idtypacteur"   =>  'required',
  * @returns
 */
export const ajoutPieceOnglet4 = async (data) => {
  return await axios.post("dossier/piece/acteur/save", data, config);
};

/**
 * Listes des dossiers a cloturer
 * @host https:....../dossier/get/doss/cloture
 * @method verb : post
 * @param parameter : search ===> not required
 * @returns
 */
export const dossierACloture = async (search = null) => {
  const data = search == null ? { search } : { search };
  return await axios.post("dossier/get/doss/cloture", data, config);
};

/**
 * Api de cloture de dossier
 * @host https:....../dossier/doss/cloture
 * @method Verb: post
 * @param parametre :
 *    listdoss ==> requis
 *    exple de listdoss
 *    listdoss : [
 *         {"id":1},
 *         {"id":2}
 *     ]
 *
 * @returns
 */
export const saveClotureDossier = async (data) => {
  return await axios.post("dossier/doss/cloture", data, config);
};
