import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

axios.defaults.baseURL = "https://catef-sa.com/catef_backend/public/api/";
//  axios.defaults.baseURL = "http://bleibaulouh-001-site1.htempurl.com/catef_backend/public/api/";
// axios.defaults.baseURL = "http://127.0.0.1/catef_backend/public/api/";
// axios.defaults.baseURL = "http://127.0.0.1/catef_backend/public/api/";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

export const pathUrl = "https://catef-sa.com/catef_backend/public/";
//  export const pathUrl = "http://bleibaulouh-001-site1.htempurl.com/catef_backend/public/";
// export const pathUrl = "http://127.0.0.1/catef_backend/public/";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
