import React, { useState } from "react";
import {
  createOrUpdateDossier,
  deleteDossier,
  editOnglet1,
  listeDossierClient,
  factureClient,
  ficheClient
} from "../../../../services/dossier";
import FormDetailDossier from "./FormDetailDossier";
import TableDetailDossier from "./TableDetailDossier";
import Alert from "../../../../components/Alert";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading";
import { pathUrl } from "../../../..";



const DetailDossierClient = ({
  dataOnglet2,
  idClient,
  setDataOnglet3,
  setOnglet,
  onglet,
  dossiers,
  setDossiers,
  isLoadingGlobal,
  setIsLoadingGlobal,
}) => {
  let natureContrat = "";
  if ('contrat' in dataOnglet2 && 'IDNATURECONTRAT' in dataOnglet2.contrat[0]) {
    const ID_NATURE_CONTURE = dataOnglet2.contrat[0].IDNATURECONTRAT
    natureContrat = ID_NATURE_CONTURE;
    localStorage.setItem('__naturecontat', natureContrat)
  }

  const info_facture = localStorage.getItem("__info_facture");
  const infoFiche = localStorage.getItem("__info_fiche");


  const getFactureClient = () => {
    if (!info_facture) {
      setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
    }
    setIsLoading(true);
    factureClient(info_facture)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.success) {
          let dateCache = new Date()
          const source = pathUrl + data.data + "?" + dateCache.getTime();
          window.open(source, '_blank');
        } else {
          setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  const getFicheClient = () => {
    if (!infoFiche) {
      setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
    }
    setIsLoading(true);
    ficheClient(infoFiche)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.success) {
          let dateCache = new Date()
          const source = pathUrl + data.data + "?" + dateCache.getTime();
          window.open(source, '_blank');
        } else {
          setFlash({ color: 'danger', message: 'Aucun fichier disponible' })
        }
      })
      .catch(({ response }) => {
        setIsLoading(false);
      });
  };

  const [values, setValues] = useState({
    operation: "",
    ilot: "",
    lot: "",
    ncptecomptable: "",
    natcont: natureContrat,
    agence: "",
    idclient: idClient ?? "",
  });

  const [flash, setFlash] = useState({ color: "success", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [ilots, setIlots] = useState([]);
  const [lots, setLots] = useState([]);
  const [clickCrayon, setClickCrayon] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    let sendData = { ...values, idclient: idClient };
    if (
      sendData.idclient.length === 0 ||
      sendData.ncptecomptable.length === 0 ||
      sendData.operation.length === 0 ||
      sendData.lot.length === 0 ||
      sendData.ilot.length === 0 ||
      sendData.agence.length === 0 ||
      sendData.natcont.length === 0
    ) {
      setFlash({
        message: "Veuillez saisir les champs requis",
        color: "danger",
      });
      setIsLoading(false);
      // return false;
    } else {
      setButtonDisabled(true);
      console.log(buttonDisabled)
      setIsLoading(true);
      createOrUpdateDossier(sendData)
        .then(({ data }) => {
          setIsLoading(false);
          setButtonDisabled(false);
          console.log(buttonDisabled)
          // console.log(idClient);
          sendData = null;
          fetchListeDosssierClient(idClient);
          // vider les champs
          setValues({
            ...values,
            idclient: "",
            agence: "",
            ncptecomptable: "",
            operation: "",
            lot: "",
            ilot: "",
          });
          setFlash({ message: data.message, color: "success" });
        })
        .catch(({ response }) => {
          setFlash({
            ...flash,
            color: "danger",
            message: response.data.message,
          });
        });
    }
  };

  const onDelete = () => {
    if (window.confirm("Etes vous sur de vouloir supprimer?")) {
      deleteDossier(info_facture)
        .then(({ data }) => {
          fetchListeDosssierClient(idClient);
          // vider les champs
          setValues({
            ...values,
            idclient: "",
            agence: "",
            ncptecomptable: "",
            operation: "",
            lot: "",
            ilot: "",
          });
          setFlash({ message: data.message, color: "success" });
        })
        .catch(({ response }) => {
          setFlash({ ...flash, color: "danger", message: response.data.message });
        });
    }
  };

  const fetchListeDosssierClient = (id) => {
    editOnglet1({ id }).then(({ data }) => {
      setDossiers(data?.data?.dossier);
      // console.log(data?.data?.dossier);
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        {flash.message && <Alert message={flash.message} color={flash.color} />}

        <FormDetailDossier
          idClient={idClient}
          values={values}
          setValues={setValues}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          ilots={ilots}
          setIlots={setIlots}
          lots={lots}
          setLots={setLots}
        />

        {isLoading && <Loading isLoading={isLoading} />}
        {isLoadingGlobal && <Loading isLoading={isLoadingGlobal} />}

        {!isLoadingGlobal && (
          <TableDetailDossier
            dossiers={dossiers}
            dataOnglet2={dataOnglet2}
            setDataOnglet3={setDataOnglet3}
            onglet={onglet}
            setOnglet={setOnglet}
            values={values}
            setValues={setValues}
            ilots={ilots}
            setIlots={setIlots}
            lots={lots}
            setLots={setLots}
            setIsLoading={setIsLoading}
            setIsLoadingGlobal={setIsLoadingGlobal}
            setClickCrayon={setClickCrayon}
          />
        )}

        {/* button pour les actions */}
        <div className="widget-toolbox padding-8 clearfix">
          <div className="pull-right">
            <input
              type="submit"
              disabled={buttonDisabled}
              value="Enregistrer"
              tabIndex={30}
              className="btn btn-success"
              style={{ borderStyle: "None", width: 100 }}
            />
            {clickCrayon && (<input
              type="button"
              onClick={onDelete}
              value="Supprimer"
              tabIndex={31}
              className="btn btn-danger"
              style={{ borderStyle: "None", width: 100 }}
            />)}
            <input
              type="button"
              onClick={() => {
                setValues({
                  ...values,
                  idclient: "",
                  agence: "",
                  ncptecomptable: "",
                  operation: "",
                  lot: "",
                  ilot: "",
                });
              }}
              value="Annuler"
              id="ContentPlaceHolder1_BTANDO"
              tabIndex={32}
              className="btn btn-warning"
              style={{ borderStyle: "None", width: 100 }}
            />
            {clickCrayon &&
              (<input
                type="button"
                onClick={getFactureClient}
                value="Imprimer la facture"
                tabIndex={35}
                className="btn btn-info"
                style={{ borderStyle: "None", width: 150 }}
              />)}
            {clickCrayon && (
              <input
                type="button"
                name=""
                onClick={getFicheClient}
                value="Imprimer la fiche client"
                tabIndex={36}
                className="btn btn-info"
                style={{ borderStyle: "None", width: 180 }}
              />)}
          </div>
        </div>
      </form>
    </>
  );
};

export default DetailDossierClient;
