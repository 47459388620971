import axios from "axios";

let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getDocumentAuclient = async (id) => {
    try {
        const { data } = await axios.post("setting/list/docopclt", id, config);
        return data;
    } catch (error) {
        return error;
    }
};

export const createDocumentAuclient = async (d) =>
    await axios.post("setting/create/docopclt", d, config);

export const updateDocumentAuclient = async (d) => {
    try {
        const { data } = await axios.post("setting/update/docopclt", d, config);
        return data;
    } catch (error) {
        return error;
    }
};

export const deleteDocumentAuclient = async (data) =>
    await axios.post("setting/delete/docopclt", data, config);
