/**
 * @description peut prendre la liste => ville
 * @returns jsx
 */
import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { FooterPDF, HeaderPDF } from "../../../components/headerAndFooterOfPDF";
const Ville = ({ title, printing }) => {
  const componentRef = useRef();
  const _title = <div>{title}</div>;

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="btn btn-primary" style={{ marginBottom: 10 }}>
            Imprimer
          </button>
        )}
        content={() => componentRef.current}
      />

      <div style={{ display: "" }} className="row">
        <div className="col-md-8" ref={componentRef}>
          <div
            style={{
              height: "26cm",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div id="longueur">
              <HeaderPDF title={_title} />
              <div style={{ marginTop: 20 }}>
                <table
                  border={1}
                  style={{ width: "100%", border: "1px solid black" }}
                >
                  {/* {printing?.data?.map((item, i) => {
                    return (
                      <Fragment>
                        <tr key={i}>
                          <td>Pays {item.NOM}</td>
                        </tr>
                        <tr>ville</tr>
                        <tr>
                          <td>{item.LIBELLE}</td>
                        </tr>
                      </Fragment>
                    );
                  })} */}
                </table>
              </div>
            </div>

            <FooterPDF />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ville;
