import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};
export const getPays = async () => {
  try {
    const { data } = await axios.get("setting/list/pays", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createPays = async (d) => {
  try {
    const { data } = await axios.post("setting/create/pays", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updatePays = async (d) => {
  try {
    const { data } = await axios.post("setting/update/pays", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deletePays = async (data) =>
  await axios.post("setting/delete/pays", data, config);
