import { Navigate, Route, Routes } from "react-router-dom";
import AffectationDossiers from "../pages/DossierClient/AffectationDossiers";
import ClotureDossier from "../pages/DossierClient/ClotureDossier";
import DossierRoute from "../pages/DossierClient/dossier-route/DossierRoute";
import Dossier from "../pages/DossierClient/dossier/Dossier";
import FactureClient from "../pages/DossierClient/dossier/pdf-print/FactureClient";
import FicheClient from "../pages/DossierClient/dossier/pdf-print/FicheClient";
import VerificationSolde from "../pages/DossierClient/VerificationSolde";

const DossierClientRoute = () => (
  <Routes>
    {/*  */}
    <Route path="verificationsolde" element={<VerificationSolde />} />
    <Route path="dossier" element={<Dossier />} />
    <Route path="dossierclt" element={<DossierRoute />} />
    <Route path="facture-client" element={<FactureClient />} />
    <Route path="fiche-client" element={<FicheClient />} />
    <Route path="affectation-dossiers" element={<AffectationDossiers />} />
    <Route path="cloture-dossiers" element={<ClotureDossier />} />
    <Route path="*" element={<Navigate to={"/error-page"} replace />} />
  </Routes>
);

export default DossierClientRoute;
