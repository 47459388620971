import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Formulaire from "../../components/Formulaire";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
  createDocument,
  getDocument,
  updateDocument,
  deleteDocument
} from "../../services/document.js";

const Document = () => {
  const headTable = [
    { name: "libellé" },
    { name: "" }
  ];

  // le current doit etre au dessus de const forms
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Libelle (*)",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];
  const queryClient = useQueryClient();

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination
  // fin code pagination
  const fetchDocuments = async (isMessage = false) => {
    setIsLoading(true);
    getDocument()
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        setTableData(data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setFlash({ ...flash, message: message, color: "success" });

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchDocument = () => {
  //   getDocument()
  //     .then((res) => {
  //       const { data } = res;
  //       const d = page(1, data);
  //       setCurrentTableData(d);
  //       const coutPage = parseInt(data.length / PageSize);
  //       setTotalPage(coutPage);
  //       setTableData(data);
  //       // console.log(data);
  //     })
  //     .catch();

  // };

  const { mutate } = useMutation(createDocument, {
    onSuccess: (resultat) => {
      // Invalidate and refetch
      // console.log(resultat);
      setFlash({ ...flash, message: resultat.message, color: "success" });
      setIsLoading(true);
      fetchDocuments();

    },

    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: updateMutate } = useMutation(updateDocument, {
    onSuccess: (resultat) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: resultat.message });
      console.log(resultat);
      getDocument()
        .then((res) => {
          const { data } = res;
          const d = page(1, data);
          setTableData(data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setFlash({ ...flash, message: res.message, color: "success" });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSettled: (res) => {
      console.log(res.data.message);
      setFlash({
        ...flash,
        message: res.data.message,
        color: "danger",
      });
    },
  });

  const postDocument = (data) => {
    if (data.libelle.length === 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      setIsLoading(false);
      return false;
    }
    mutate(data);
  }
  const putDocument = (data) => {
    if (data.libelle.length === 0 || data.id.length === 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      setIsLoading(false);
      return false;
    }
    updateMutate(data);
  }
  const onclick = (data) => {
    setCurrent(data);
  };


  const onDelete = async () => {
    if (window.confirm(" Etre vous sur de vouloir supprimer le Document  à fournir par le client ?")) {
      const data = { id: current.ID, libelle: current.LIBELLE };
      deleteDocument(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getDocument()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
        });
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <Layout>
      <Formulaire
        forms={forms}
        title={"Gestion des documents à fournir par le client"}
        cb={current ? putDocument : postDocument}
        current={current}
        setCurrent={setCurrent}
        isLoading={isLoading}
        message={flash?.message}
        color={flash?.color}
        onDelete={onDelete}
      />
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table headTable={headTable} title={"Liste  des documents à fournir par le client"}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item?.LIBELLE}</td>
              <td className="text-center" align="right">
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // className="img img-fluid"
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
              </td>
            </tr>
          ))}

          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default Document;
