import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { getCommuneByVille } from "../../services/commune";
import { getPays } from "../../services/pays";
import { getQuartierByCommune } from "../../services/quartier";
import { getVillesByPays } from "../../services/ville";
import ReactPaginate from "react-paginate";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import { createAgence, getAgence, updateAgence, deleteAgence } from "../../services/agences";
const Agences = () => {
  const headTable = [
    { name: "Libellé", },
    { name: "contacts", },
    { name: "", with: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const [communes, setCommunes] = useState([]);
  const [quartiers, setQuartiers] = useState([]);
  const [pays, setPays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [villes, setVilles] = useState([]);
  const [agences, setAgences] = useState([]);
  const [flash, setFlash] = useState({ message: null, color: "success" });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [values, setValues] = useState({
    idpays: "",
    idville: "",
    idcommune: "",
    libelle: "",
    idquartier: "",
    contacts: "",
    localisation: "",
    responsable: "",
    contactsResponsable: "",
  });
  const _getPays = async () => {
    const { data } = await getPays();
    setPays(data);
  };


  // const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;

  const [currentTableData, setCurrentTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), agences);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination
  const onSelectPays = async (e) => {
    setIsLoading(true);
    setValues({ ...values, idpays: e.target.value });
    setCurrentTableData([]);
    setVilles([]);
    setCommunes([]);
    setQuartiers([]);
    getVillesByPays({ id: e.target.value })
      .then((res) => {
        const { data } = res;
        setVilles(data);
        setIsLoading(false);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSelectVille = (e) => {
    setIsLoading(true);
    setValues({ ...values, idville: e.target.value });
    setCommunes([]);
    setQuartiers([]);
    getCommuneByVille({ idville: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setCommunes(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectCommune = (e) => {
    setIsLoading(true);
    setValues({ ...values, idcommune: e.target.value });
    setQuartiers([]);
    getQuartierByCommune({ idcommune: e.target.value })
      .then((res) => {
        // console.log(res);
        const { data } = res;
        setQuartiers(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectQuartier = (e) => {
    setIsLoading(true);
    setValues({ ...values, idquartier: e.target.value });
    setAgences([]);
    getAgence(e.target.value)
      .then((res) => {
        console.log(res);
        const { data } = res;
        setFlash({ message: data.message, color: "success" });
        const d = page(1, data.data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.data.length / PageSize);
        setTotalPage(coutPage);
        setAgences(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(isLoading);
    if (current) {
      // update
      let data = values;
      data["id"] = current.ID;
      // console.log(data);
      if (data.idquartier.length === 0 || data.libelle.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      updateAgence(data)
        .then((res) => {
          setFlash({ message: res.data.message, color: "success" });
          getAgence(data.idquartier)
            .then((res) => {
              // console.log(res);
              console.log(res);
              const { data } = res;
              setFlash({ message: data.message, color: "success" });
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setAgences(data.data);
              setIsLoading(false);
              setCurrent(null);
              setValues({ ...values, libelle: "", contacts: "", localisation: "", responsable: "", contactsResponsable: "", });
              // for (const value in values) {
              //   if (Object.hasOwnProperty.call(values, value)) {
              //     values[value] = "";
              //     setValues({ values });
              //   }
              // }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(({ response }) => {
          setFlash({ message: response.data.message, color: "danger" });
        });
    } else {
      //  create
      // console.log(values);
      if (values.idquartier.length === 0 || values.libelle.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      createAgence(values)
        .then((res) => {
          // console.log(res);
          setFlash({ message: res.data.message, color: "success" });
          getAgence(values.idquartier)
            .then((res) => {
              console.log(res);
              const { data } = res;
              setFlash({ message: data.message, color: "success" });
              const d = page(1, data.data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setAgences(data.data);
              setIsLoading(false);
              //
              setValues({ ...values, libelle: "", contacts: "", localisation: "", responsable: "", contactsResponsable: "", });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(({ response }) => {
          setFlash({ message: response.data.message, color: "danger" });
        });
    }
  };

  const [_idQuartier, setIdQuartier] = useState(null);
  const onclick = (data) => {
    // console.log(data);
    setCurrent(data);
    setIdQuartier(data.IDQUARTIER);
    setValues({ ...values, libelle: data.LIBELLE, contacts: data.CONTACTS });
  };

  // delete agences
  // const onDelete = async () => {
  //   if (window.confirm("Voulez vous supprimer ?")) {
  //     const data = { id: current.ID, libelle: current.LIBELLE };
  //     console.log(_idQuartier);
  //     deleteAgence(data)
  //       .then(({ data }) => {
  //         setIsLoading(true);
  //         setFlash({ ...flash, color: "success", message: data.message });
  //         setValues({ ...values, libelle: "" });
  //         getAgence(_idQuartier)
  //           .then((res) => {
  //             console.log(res);
  //             const { data } = res;
  //             setFlash({ message: data.message, color: "success" });
  //             const d = page(1, data.data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.data.length / PageSize);
  //             setTotalPage(coutPage);
  //             setAgences(data.data);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((error) => {

  //         setIsLoading(true);
  //         setFlash({
  //           ...flash,
  //           color: "danger",
  //           message: error.response.data.message,
  //         });
  //         setAgences([]);
  //         setValues({ ...values, libelle: "" });
  //         getAgence(_idQuartier)
  //           .then((res) => {
  //             console.log(res);
  //             const { data } = res;
  //             setFlash({ message: data.message, color: "success" });
  //             const d = page(1, data.data);
  //             setCurrentTableData(d);
  //             const coutPage = parseInt(data.data.length / PageSize);
  //             setTotalPage(coutPage);
  //             setAgences(data.data);
  //             setIsLoading(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       });
  //   }
  // };

  const onDelete = async () => {
    // console.log(current);
    if (window.confirm("Voulez vous supprimer?")) {
      const delet = { id: current.ID, libelle: current.LIBELLE, contacts: current.CONTACTS };
      deleteAgence(delet)
        .then(({ data }) => {
          setIsLoading(true);
          setFlash({ ...flash, color: "success", message: data.message });
          setCurrent(null);
          // setCommunes([]);
          setValues({ ...values, libelle: "", contacts: "", localisation: "", responsable: "", contactsResponsable: "", });
          getAgence(values.idquartier)
            .then((res) => {
              // setCurrent(null);
              const { data } = res;
              console.log(res);
              const d = page(1, data.data);
              setCurrentTableData(d);
              // setFlash({ message, color: "success" });
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setAgences(data.data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);

          // setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          setAgences([]);
          setValues({ ...values, libelle: "" });
          getAgence(_idQuartier)
            .then((res) => {
              // console.log(res);
              const { data } = res;
              const d = page(1, data.data);
              setCurrentTableData(d);
              // setFlash({ message, color: "success" });
              const coutPage = parseInt(data.data.length / PageSize);
              setTotalPage(coutPage);
              setAgences(data.data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  //fin de delete
  useEffect(() => {
    _getPays();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Saisie des agences</h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Pays (*)
                    </label>
                    <select
                      name="idpays"
                      className="form-control input-xxlarge"
                      onChange={onSelectPays}
                    >
                      <option value="-1"></option>
                      {pays?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.NOM}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Ville (*)
                    </label>
                    <select
                      name="idville"
                      className="form-control input-xxlarge"
                      onChange={onSelectVille}
                    >
                      <option value="-1"></option>
                      {villes?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Commune (*)
                    </label>
                    <select
                      name="idcommune"
                      className="form-control input-xxlarge"
                      onChange={onSelectCommune}
                    >
                      <option value="-1"></option>
                      {communes?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Quartier (*)
                    </label>
                    <select
                      name="idquartier"
                      className="form-control input-xxlarge"
                      onChange={onSelectQuartier}
                    >
                      <option value="-1"></option>
                      {quartiers?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Libellé (*)
                    </label>
                    <input
                      value={values.libelle}
                      onChange={handleChange}
                      name="libelle"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Contacts (*)
                    </label>
                    <input
                      value={values.contacts}
                      onChange={handleChange}
                      name="contacts"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Localisation (*)
                    </label>
                    <input
                      value={values.localisation}
                      onChange={handleChange}
                      name="localisation"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Responsable (*)
                    </label>
                    <input
                      value={values.responsable}
                      onChange={handleChange}
                      name="responsable"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Contacts responsable (*)
                    </label>
                    <input
                      value={values.contactsResponsable}
                      onChange={handleChange}
                      name="contactsResponsable"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Enregistrer"
                      className="btn btn-success"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        onClick={onDelete}
                        value="Supprimer"
                        className="btn btn-danger"
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => { }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table title={"Liste des Agences"} headTable={headTable}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.LIBELLE}</td>
              <td>{item.CONTACTS}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}
          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
        </Table>
      )}
    </Layout>
  );
};

export default Agences;
