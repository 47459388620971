import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getVillesByPays = async (id) => {
  try {
    const { data } = await axios.post("setting/list/villes", id, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createVille = async (d) =>
  await axios.post("setting/create/ville", d, config);

export const updateVille = async (d) => {
  try {
    const { data } = await axios.post("setting/update/ville", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteVille = async (data) =>
  await axios.post("setting/delete/ville", data, config);
