import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import Layout from "../../layout/Layout";
import { modif_password } from "../../services/acteur/resetpassword";

const ResetPassword = () => {
  const TYPE_ACTEUR = 'admin';
  const PROFIL = 'admin';
  const EMAIL_ACTEUR = localStorage.getItem("__user_email");
  const ID_ACTEUR = localStorage.getItem("__user_id");

  const [current, setCurrent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [flash, setFlash] = useState({ message: null, color: "" });
  const [stateForm, setStateForm] = useState({
    opassword: "",
    npassword: "",
    cpassword: "",
  });
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  const titleForm = "Changement de mot de passe";

  const forms = [
    { label: "Ancien  passe", name: "opassword", values: stateForm.opassword, type: "password" },
    { label: "Nouveau passe", name: "npassword", values: stateForm.npassword, type: "password" },
    { label: "Nouveau  passe", name: "cpassword", values: stateForm.cpassword, type: "password" },
  ];

  const btnStyle = [
    { value: "Enregister", type: "submit", className: "btn-success" },
    { value: "Annuler", type: "submit", className: "btn-warning" },
  ];

  const onChange = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value })
  };

  const modificationPassword = async (d) => {
    setIsLoading(true)
    modif_password(d)
      .then((data) => {
        setIsLoading(false)
        if (data.success) {
          reset();
          setCurrent(null);
          setStateForm({
            ...stateForm,
            opassword: "",
            npassword: "",
            cpassword: "",
          });
          alert("Mot de passe modifié avec succès")
        } else {
          alert(data.message)
        }
      })
      .then((err) => {
        console.log("ERREUR", err);
      })
  };

  const onSubmit = async (data) => {
    data = { ...data, idacteur: ID_ACTEUR, type: TYPE_ACTEUR, email: EMAIL_ACTEUR, profil: PROFIL };
    console.log(data);

    try {
      await modificationPassword(data);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
  }, []);


  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">{titleForm}</h5>
              </div>

              {/* Contenu du cadre */}
              {isLoading ? <Loading isLoading={isLoading} /> : <></>}
              <div className="widget-body">
                <div className="widget-main">
                  {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
                  {forms.map((item, index) => {
                    setValue(item.name, item.values);
                    if (Array.isArray(item?.table)) {
                      return (
                        <label key={index} className="block clearfix">
                          <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                          <select {...register(item.name)}
                            onChange={onChange}
                            className="form-control input-xxlarge">
                            <option value="" />
                            {item.table.map((t, i) => (
                              <option key={i} value={t.ID}>{t.LIBELLE}</option>
                            ))}
                          </select>
                        </label>
                      );
                    } else {
                      return (
                        <label key={index} className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <input style={{ width: item?.width }}
                              onChange={onChange}
                              name={item.name}
                              type={item.type}
                              value={item.values}
                              disabled={item.disabled}
                              className="form-control input-xxlarge"
                              placeholder=""
                            />
                          </span>
                        </label>
                      );
                    }
                  })}
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    {btnStyle.map((btn, key) => {
                      return (<input
                        key={key}
                        type={`${btn.type}`}
                        value={`${btn.value}`}
                        disabled={isLoading}
                        className={`btn ${btn.className}`}
                        style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                        onClick={btn.value === "Annuler" && (() => { reset(); setCurrent(null) })}
                      />)
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ResetPassword;
