import React, { useState } from "react";
import { useEffect } from "react";
// import { get } from "react-hook-form";
import Loading from "../../components/Loading";
import Layout from "../../layout/Layout";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import { entites,agences,profils,utilisateurs, creerUtilisateur, getUtilisateur } from "../../services/securite/utilisateur";

const Utilisateur = () => {
  const headTable = [
    { name: "Nom", width: "40%" },
    { name: "Profil", width: "20" },
    { name: "Contact", width: "20" },
    { name: "Statut", width: "20" },
    { name: "", width: "5%" },
  ];
  const [entite, setEntite] = useState([]);
  const [agence, setAgence] = useState([]);
  const [profil, setProfil] = useState([]);
  const [users, setUsers] = useState([]);

  const [current, setCurrent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [typeActeurs, setTypeActeurs] = useState([]);

  const [values, setValues] = useState({
    idsuccursale: current?.idsuccursale ?? "Operateurs",
    idprofil:current?.idprofil ?? "",
    identite: current?.identite ?? "",
    nom: current?.nom ?? "",
    email: current?.email ?? "",
    statut:current?.statut ?? "Actif",
    tel: current?.tel ?? "",
    id: current?.ID ?? "",
  });

  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [search, setSearch] = useState({ search: "" });

  // pagination code
  // pagination code
  let PageSize = 10;
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), users);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };


  const onclick = async (item) => {
     setCurrent(item);
    getUser(item);
  }



  // afficher la liste de acteur en function de type acteur

  const fecthEntites = async () => {
    setIsLoading(true);
    entites()
      .then(({ data }) => {
        setEntite(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };
  const fecthAgences = async () => {
    setIsLoading(true);
    agences()
      .then(({ data }) => {
        setAgence(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };

  const getUser = async (d) => {
    setIsLoading(true);
    getUtilisateur(d.ID)
      .then(( data ) => {
        setIsLoading(false);
        if(!data.success){
          alert('Impossible de recuperer les infos de l\'utilisateur');
          return false;
        }
        setValues({ ...values,  idsuccursale: data.data[0].IDSUCCURSALE, 
          idprofil: data.data[0].IDPROFIL, 
          identite: data.data[0].IDENTITE,
          nom: data.data[0].NOM
          ,email: data.data[0].EMAIL,
          tel: data.data[0].TEL,
          id: d.ID,
          statut: data.data[0].STATUT }); 
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };
  const fetchUtilisateurs = async () => {
    setIsLoading(true);
    utilisateurs(search.search)
      .then(({ data }) => {
        setUsers(data);
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };
  const fetchProfils = async () => {
    setIsLoading(true);
    profils()
      .then(({ data }) => {
        setProfil(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // console.log("err");
      });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.idprofil.length === 0 || values.idsuccursale.length === 0 || values.identite.length === 0 
         || values.nom === 0 || values.email.length === 0 || values.statut.length === 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      return false;
    }
    creerUtilisateur(values)
      .then((response) => {
       if(!response.success){
        setFlash({ message: response.message, color: "danger" });
        return false;
       }
        setFlash({ message: response.message, color: "success" });
        fetchUtilisateurs();
        setIsLoading(false);
        setValues({ ...values, idsuccursale: "", idprofil: "", identite: "",nom: "",email: "",tel: "",statut: "Actif",id: "" });
      })
      .catch(({ response }) => {
        setFlash({ message: response.message, color: "danger" });
      });
  };
  useEffect(() => {
    fecthAgences();
    fetchProfils();
    fecthEntites();
    fetchUtilisateurs();
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">Gestion des utilisateurs</h5>
                <div className="navbar-form navbar-right form-search">
                  <div id="ContentPlaceHolder1_Panel1">
                    <span className="input-icon">
                      <input
                        type="text"
                        value={search.search}
                        name="search"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                        onChange={onChangeSearch}
                         onKeyUpCapture={fetchUtilisateurs}
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <input
                        type="submit"
                        name="ctl00$ContentPlaceHolder1$BTRECH"
                        value="OK"
                        id="ContentPlaceHolder1_BTRECH"
                        tabIndex={2}
                        className="btn-info btn-sm MasquerColonne"
                        style={{ borderStyle: "None" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash?.message !== null && (
                    <Alert color={flash?.color} message={flash?.message} />
                  )}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Profil
                    </label>
                    <select
                      name="idprofil"
                      tabIndex={3}
                      onChange={onChange}
                      value={values.idprofil}
                      className="form-control input-xxlarge"
                    >
                      <option value={""} />
                      {profil?.map((item, i) => (
                        <option key={i} value={item.ID}>{item.LIBELLE}</option>
                      ))}
                    </select>
                  </label>

                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Entité
                    </label>
                    <select
                      name="identite"
                      value={values.identite}
                      onChange={onChange}
                      tabIndex={4}
                      className="form-control input-xxlarge"
                    >
                      <option value={""} />
                      {entite?.map((item, i) => (
                        <option value={item.ID}>{item.LIBELLE}</option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Agence
                    </label>
                    <select
                      onChange={onChange}
                      name="idsuccursale"
                      tabIndex={5}
                      value={values.idsuccursale}
                      className="form-control input-xxlarge"
                    >
                      <option value={""} />
                      {agence?.map((item, i) => (
                        <option value={item.ID}>{item.LIBELLE}</option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-3">
                        Nom 
                      </label>
                      <input
                        type="text"
                        value={values.nom}
                        onChange={onChange}
                        name="nom"
                        tabIndex={6}
                        className="form-control input-xxlarge"
                        placeholder=""
                      />
                    </span>
                  </label>
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-3">
                        Contacts 
                      </label>
                      <input
                        type="text"
                        value={values.tel}
                        onChange={onChange}
                        name="tel"
                        tabIndex={7}
                        className="form-control input-xxlarge"
                        placeholder=""
                      />
                    </span>
                  </label>
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-3">
                        Email 
                      </label>
                      <input
                        type="text"
                        value={values.email}
                        onChange={onChange}
                        name="email"
                        tabIndex={8}
                        className="form-control input-xxlarge"
                        placeholder=""
                      />
                    </span>
                  </label>
                  <label className="block clearfix">
                <label htmlFor="text" className="col-lg-3">
                  Statut
                </label>
                <select
                  onChange={onChange}
                  value={values.statut}
                  name="statut"
                  tabIndex={9}
                  className="form-control input-large"
                >
                  <option selected={true} value="Actif">Actif</option>
                  <option value="Non actif"> Non actif</option>
                </select>
              </label>
              <label className="block clearfix">
              <span className="block">
                <label htmlFor="text" className="col-lg-3">
                  Photo 
                </label>
                <input
                  type="file"
                  name="piece"
                  accept=".pdf"
                  // onChange={onPiece}
                  tabIndex={17}
                />
              </span>
            </label>
                </div>
                {/* pieds de page */}
                <div className="widget-toolbox padding-8- clearfix">
                  <div className="pull-right">
                    {isLoading ? (
                      <Loading isLoading={isLoading} />
                    ) : (
                      <input
                        type="submit"
                        value="Enregistrer"
                        className="btn btn-success"
                        tabIndex={8}
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                     {current && (
                      <input
                        type="button"
                        value="Annuler"
                        onClick={() => {
                          setCurrent(null);
                          setValues({ ...values, idsuccursale: "", idprofil: "", identite: "",nom: "",email: "",tel: "",statut: "Actif",id: "" });
                        }}
                        tabIndex={25}
                        className="btn btn-warning"
                        style={{ borderStyle: "None", width: 100, margin: 2 }}
                      />
                    )}
                     {/* {current && (
                      <input
                        type="button"
                        value="Supprimer"
                        onClick={() => {
                          // onDelete();
                        }}
                        tabIndex={25}
                        className="btn btn-danger"
                        style={{ borderStyle: "None", width: 100, margin: 2 }}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (

        <Table
          title={"Liste des utilisateurs"}
          headTable={headTable}
        >
          {users?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.NOM}</td>
              <td>{item.LIBELLE}</td>
              <td>{item.TEL}</td>
              <td>{item.STATUT}</td>
              <td className="text-center" align="right">
                <img
                  onClick={() => onclick(item)}
                  //           style={{ width: 15, height: 10 }}
                  src={"/Catef_files/modifier.png"}
                />
              </td>
            </tr>
          ))}

          {/* pagination */}
          {/* <Pagination onPageChange={onPageChange} totalPage={totalPage} /> */}

          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default Utilisateur;