import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const gettypeLogement = async () => {
  try {
    const { data } = await axios.get("setting/list/type_logement", config);
    return data;
  } catch (error) {
    return error;
  }
};


export const createtypeLogement = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/create/type_logement",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updatetypeLogement = async (d) => {
  try {
    const { data } = await axios.post(
      "setting/update/type_logement",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const deletetypeLogement = async (d) =>
  await axios.post("setting/delete/type_logement", d, config);


