import React, { useState } from "react";

const InputFile = ({ label = "", name, values, setValues }) => {
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  return (
    <label className="block clearfix">
      <span className="block">
        <label htmlFor="text" className="col-lg-3">
          {label}
        </label>
        <input
          type="file"
          accept=".pdf"
          onChange={onChange}
          name={name}
          className="form-control-file"
          //   tabIndex={44}
        />
      </span>
    </label>
  );
};

export default InputFile;
