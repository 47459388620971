import React, { useEffect, useState, useRef } from "react";
import Layout from "../../layout/Layout";
import { getActeur, selectActeur, registerActeur } from "../../services/finances/paiementacteur";
import Alert from "../../components/Alert";
import { useForm } from "react-hook-form";
import Pagination from "../../components/Pagination"
import { getBanque } from "../../services/banque";
import ReactPaginate from "react-paginate";

const PaiementActeur = () => {
  const [currentTablePaiement, setCurrentTablePaiement] = useState([]);
  const [acteurs, setActeurs] = useState([]);
  const [banques, setBanques] = useState([]);
  const [selectActeurs, setselectActeurs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sousFormVisible, setSousFormVisible] = useState(false);
  const formRef = useRef(null);
  const [stateForm, setStateForm] = useState({
    idacteur: 0,
    montant: "",
    mode: "",
    piece: "",
    observ: "",
    total_a_payer: "",
    montant_encaisser: "",
    reste: "",
    banque: "",
    reference: "",
    deposant: "",
    emett: ""
  });
  const [currentTableData, setCurrentTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [totalPagePaiement, setTotalPagePaiement] = useState(null);

  const [flash, setFlash] = useState({ message: null, color: "success" });
  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();
  const [tableData, setTableData] = useState({
    calculcout: [],
    grilledossier: [],
    paiements: []
  });

  const titleForm = "Gestion des paiements aux acteurs";
  const total_a_payer = tableData?.calculcout[0]?.MONTANTAPAYER ? tableData?.calculcout[0]?.MONTANTAPAYER : "";
  const total_encaiser = tableData?.calculcout[0]?.MONTANTPAYE ? tableData?.calculcout[0]?.MONTANTPAYE : "";
  const reste = total_a_payer && total_encaiser ? total_a_payer - total_encaiser : "";
  // let grilledossier = tableData?.grilledossier ? tableData?.grilledossier : [];

  //console.table(grilledossier)
  const forms = [
    { label: "Total à payer", name: "total_a_payer", disabled: true, values: total_a_payer, type: "text" },
    { label: "Montant versé", name: "montant", values: stateForm.montant, type: "text" },
    { label: "Total encaissé", name: "montant_encaisser", disabled: true, values: total_encaiser, type: "text" },
    { label: "Reste à payer", name: "reste", disabled: true, values: reste, type: "text" },
    {
      label: "Mode", name: "mode", values: stateForm.mode, id: "mode",
      table: [{ LIBELLE: "Espèce", id: "Espèce" }, { LIBELLE: "Chèque", id: "Chèque" }, { LIBELLE: "Reçu", id: "Reçu" }, { LIBELLE: "Virement", id: "Virement" }, { LIBELLE: "Paiement mobile", id: "Paiement mobile" }]
    },

  ];

  const sousForm = [
    { label: "Référence", name: "reference", className: "mode", values: stateForm.reference, type: "text" },
    { label: "Déposant", name: "deposant", className: "mode", values: stateForm.deposant, type: "text" },
    { label: "Banque", name: "banque", className: "mode", values: stateForm.banque, table: banques },
    {
      label: "Date versement", name: "emett", className: "mode input-small- date-picker text-center",
      values: stateForm.emett, type: "date", format: "dd/mm/yyyy"
    },
  ];
  const titleTable = "Listes des paiements";
  const headTable = [
    { name: "DATE", width: "" }, { name: "NUM RECU", width: "" },
    { name: "OBSERVATION", width: "" }, { name: "MODE", width: "" },
    { name: "MONTANT", width: "" }, { name: "DOCUMENT", width: "" },
    { name: "", width: "" }
  ];
  const headTable2 = [
    { name: "CLIENT" }, { name: "AGENCE" }, { name: "NUM DOSSIER" }, { name: "NUM LOT" }, 
    { name: "MONTANT" }, { name: "" },
  ]
  const btnStyle = [
    { value: "Enregistrer", type: "submit", className: "btn-success" },
    { value: "Annuler", type: "button", className: "btn-warning" },
  ];

  const PageSize = 10;
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData.grilledossier);
    setCurrentTableData(d);
    // console.log(d);
  };

  const onPageChangePaiement = (e) => {
    const d = page(parseInt(e.selected + 1), tableData.paiements);
    setCurrentTablePaiement(d);
    // console.log(d);
  };
  const fetchGetActeur = () => {
    setIsLoading(true);
    getActeur()
      .then(({ data }) => {
        setActeurs(data.data);
        setFlash({ message: data.message, color: "success" })
        setIsLoading(false);
      })
      .catch((err) => {
        setFlash({ message: `Erreur lors du chargement... ${err}`, color: "danger" })
        setIsLoading(false);
        // console.log("err");
      });
  };

  const fetchGetBanques = () => {
    getBanque()
      .then((res) => {
        const { data } = res;
        setBanques(data);
      })
      .catch();
  };

  const unChechBox = () => {
    const nameAll = document.querySelectorAll(".check");
      nameAll.forEach((name) => {
        if(name.checked){
          name.checked = false;
        }
      });
  };


  const onSelectDossier = () => {
    const check_all = document.querySelectorAll('.check');
    const data = [];
    let i = 0;
    check_all.forEach((check) => {
      i++;
      if (check.checked) {
        data.push({ iddossier: currentTableData[i - 1].ID, montant: currentTableData[i - 1].MONTANT });
      }
    })
    return data
  }

  const onSubmit = async (data) => {
    setIsLoading(true);
    const dossierData = onSelectDossier();

    data = { ...stateForm, ...data, reste: reste, listdoc: dossierData };
    let formData = new FormData(formRef.current);
    // const p = formData.get("piece");
    // const pieceLien = p?.size === 0 && p?.name.length === 0 ? stateForm.piece : p;

    // formData.set("piece", pieceLien);
    formData.append("total_a_payer", total_a_payer);
    formData.append("reste", reste);
    formData.append("total_encaiser", total_encaiser);
    formData.append("listdoc", JSON.stringify(dossierData));

    console.log(formData)
    registerActeur(formData)
      .then(({data}) => {
        console.log("data dans enregistrementActeur", data)
        setIsLoading(false);
        if(data.success) {
          setFlash({ message: data.message, color: "success" });

          const d = page(1, data.data.grilledossier);
          const p = page(1, data.data.paiements);
          setTableData({ ...tableData, ...data.data });
          setCurrentTableData(d);
          setCurrentTablePaiement(p);
          const coutPage = parseInt(data.data.grilledossier.length / PageSize);
          const coutPagePaiement = parseInt(data.data.paiements.length / PageSize);
          setTotalPage(coutPage);
          setTotalPagePaiement(coutPagePaiement);
          setStateForm({
            ...stateForm,
            montant: "",
            mode: "",
            liendocument: "",
            observ: "",
            banque: "",
            reference: "",
            deposant: "",
            emett: ""
          });
          unChechBox();
          // reset();
          // setTableData(null);
        } else {
          setFlash({ message: data.message, color: "danger" });
        } 
      })
      .catch((err) => {
        setIsLoading(false)
      })
   
  };

  const onChange = (e) => {
    console.log(e.target.name)
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
  };

  const onChangeMode = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
    const ids = ["Chèque", "Reçu", "Virement"];
    if (ids.includes(e.target.value)) {
      setSousFormVisible(true);
    } else {
      setSousFormVisible(false);
    }
  };

  const onChangeActeur = async (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
    setIsLoading(true);
    selectActeur({ idacteur: e.target.value })
      .then(({ data }) => {
        setFlash({ message: data.message, color: "success" });
        const d = page(1, data.data.grilledossier);
        const p = page(1, data.data.paiements);
        console.log(data.data)
        setTableData({ ...tableData, ...data.data });
        setCurrentTableData(d);
        setCurrentTablePaiement(p);
        const coutPage = parseInt(data.data.grilledossier.length / PageSize);
        const coutPagePaiement = parseInt(data.data.paiements.length / PageSize);
        setTotalPage(coutPage);
        setTotalPagePaiement(coutPagePaiement);
        setIsLoading(false);
      })
      .catch((err) => {
        setFlash({ message: err, color: "danger" })
        //console.log(err);
      });
  }

  const onPiece = (e) => {
    setStateForm({ ...stateForm, [e.target.name]: e.target.files[0] })
  }

  const filterDossier = () => {
    const SEARCH = document.getElementById('search');
    const ID_ACTEUR = document.getElementById('idacteur');

    if (!ID_ACTEUR.value) {
      setFlash({ message: "Aucun acteur sélectionner", color: "danger" });
      return false
    }
    console.log(ID_ACTEUR.value, SEARCH.value)
    selectActeur({ idacteur: ID_ACTEUR.value, search: SEARCH.value })
      .then(({ data }) => {
        console.log(data.data)
        setTableData(() => data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const onClickBtnForm = (e) => {
    const btn = e.target;
    btn.value === "Annuler" ?
      setTableData({
        calculcout: [],
        grilledossier: [],
        paiements: []
      }) : console.log("None");

  }

  useEffect(() => {
    fetchGetActeur();
    fetchGetBanques();
  }, []);

  return (

    <Layout>


      <div className="row">
        <div className="col-xs-2" />
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">{titleForm}</h5>
                <div className="navbar-form navbar-right form-search">
                  <div
                    id="ContentPlaceHolder1_Panel1"
                    onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                  >
                    <span className="input-icon">
                      <input
                        name="ctl00$ContentPlaceHolder1$TBRECH"
                        type="text"
                        id="TBRECH"
                        tabIndex={1}
                        className="nav-search-input"
                        placeholder="Recherche ..."
                      />
                      <i className="ace-icon fa fa-search nav-search-icon" />
                      <input
                        type="submit"
                        name="ctl00$ContentPlaceHolder1$BTRECH"
                        defaultValue="OK"
                        id="ContentPlaceHolder1_BTRECH"
                        tabIndex={2}
                        className="btn-info btn-sm MasquerColonne"
                        style={{ borderStyle: "None" }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash.message !== null && <Alert color={flash.color} message={flash.message} />}
                  <span id="ContentPlaceHolder1_LBINFO" style={{ display: "inline-block", width: "100%", textAlign: "center" }} />
                  <label className="block clearfix">
                    <label htmlFor="idacteur" className="col-lg-2">{"Acteur"}</label>
                    <select onChange={onChangeActeur} name="idacteur" id="idacteur" className="form-control input-xxlarge">
                      <option value="" />
                      {acteurs.map((el, key) => (
                        <option key={key} value={el.ID}>
                          {el.NOM}
                        </option>
                      ))}
                    </select>
                  </label>
                  {forms.map((item, index) => {
                    setValue(item.name, item.values);
                    if (Array.isArray(item?.table)) {
                      return (
                        <label key={index} className="block clearfix">
                          <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                          <select {...register(item.name)} id={item.id} onChange={onChangeMode} className="form-control input-xxlarge">
                            <option value="" />
                            {item.table.map((el, key) => (
                              <option key={key} value={el.id}>
                                {el.LIBELLE}
                              </option>
                            ))}
                          </select>
                        </label>
                      );
                    } else {
                      return (
                        <label key={index} className={`block clearfix`} style={{ display: "none" }}>
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <input style={{ width: item?.width }}
                              name={item.name}
                              value={item.values}
                              onChange={onChange}
                              disabled={item.disabled}
                              type={item.type}
                              className="form-control input-xxlarge"
                              placeholder=""
                            />
                          </span>
                        </label>
                      );
                    }
                  })}
                  {sousForm.map((item, key) => {
                    if (sousFormVisible) {
                      if (Array.isArray(item?.table)) {
                        return (
                          <label key={key} className="block clearfix">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <select {...register(item.name)}
                              onChange={onChange}
                              className="form-control input-xxlarge">
                              <option value="" />
                              {item.table.map((t, i) => (
                                <option key={i} value={t.ID}>{t.LIBELLE}</option>
                              ))}
                            </select>
                          </label>
                        )

                      } else {
                        return (<label key={key} className="block clearfix">
                          <span className="block">
                            <label htmlFor="text" className="col-lg-2">{item?.label}</label>
                            <input style={{ width: item?.width }}
                              onChange={onChange}
                              name={item.name}
                              type={item.type}
                              value={item.values}
                              disabled={item.disabled}
                              data-date-format={item.format}
                              className={`${item?.className}`}
                              placeholder=""
                            />
                          </span>
                        </label>
                        );
                      }

                    }
                  })}
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-2">{"Observation"}</label>
                      <textarea
                        onChange={onChange}
                        name={"observ"}
                        value={stateForm.observ}
                        className="form-control input-xxlarge"
                        placeholder=""
                      ></textarea>
                    </span>
                  </label>
                  <label className="block clearfix">
                    <span className="block">
                      <label htmlFor="text" className="col-lg-2">{"Document numérisé (pdf)"}</label>
                      <input
                        onChange={onPiece}
                        name={"liendocument"}
                        type={"file"}
                        className="form-control input-xxlarge"
                        placeholder=""
                        accept=".pdf"
                      />
                    </span>
                  </label>
                </div>


                <div className="widget-body">
              <div className="widget-main">
                <span
                  id="ContentPlaceHolder1_LBINFO"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "center"
                  }}
                />
                <label className="block clearfix">
                  <span className="block">
                    <table
                      cellSpacing={0}
                      rules="all"
                      className="table-header table table-striped "
                      border={1}
                      id="ContentPlaceHolder1_GV"
                      style={{
                        borderColor: "#E1E1E1",
                        fontSize: "10pt",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr
                          align="center"
                          valign="middle"
                          style={{
                            color: "#707070",
                            backgroundColor: "#F1F1F1",
                            fontWeight: "normal",
                          }}
                        >
                          {headTable2?.map((item, index) => {
                            return (
                              <th
                                key={index}
                                className="text-center"
                                scope="col"
                                style={{ width: item?.width }}
                              >
                                {item?.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {currentTableData?.map((item, key) => (
                          <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                            <td className="text-center">{item.NOM}</td>
                            <td className="text-center">{item.LIBELLE}</td>
                            <td className="text-center">{item.NUMDOSSIER}</td>
                            <td className="text-center">{item.NUMLOT}</td>
                            <td className="text-center">{item.MONTANT}</td>
                            <td className="text-center"
                            // onClick={() => onSelectDossier(item)}
                            >
                              <input
                                type="checkbox"
                                className="check"
                                id={`paiement-${key}`}
                              // onChange={onSelectDossier}
                              />
                            </td>
                          </tr>
                        ))}
                        {/* Pied du cadre */}
                        <tr
                          className="pagination-od"
                          align="center"
                          style={{
                            color: "Black",
                            backgroundColor: "#EFF3F8",
                            fontWeight: "normal",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <td colSpan={15}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    {totalPage != null && (
                                      <ReactPaginate
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        breakLabel="..."
                                        nextLabel="suivant >"
                                        onPageChange={onPageChange}
                                        pageRangeDisplayed={5}
                                        pageCount={totalPage + 1}
                                        previousLabel="< Précédent"
                                        renderOnZeroPageCount={null}
                                      />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                </label>
              </div>
            </div>


                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    {btnStyle.map((btn, key) => {
                      return (<input
                        key={key}
                        type={`${btn.type}`}
                        value={`${btn.value}`}
                        disabled={isLoading}
                        className={`btn ${btn.className}`}
                        style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                        onClick={onClickBtnForm}
                      />)
                    })}
                  </div>
                </div>
              </div>


              
            </div>
          </div>
        </form>
      </div>


      <div className="row">
        <div className={`col-xs-2`}></div>
        <div className={`form-horizontal col-xs-8 ui-sortable`}>
          <div className="widget-box widget-color-blue">
            {/* Entete du cadre */}
            <div className="widget-header ui-sortable-handle">
              <h5 className="widget-title">{titleTable}</h5>
              <div className="navbar-form navbar-right form-search">
                <div
                  id="ContentPlaceHolder1_Panel1"
                  onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ContentPlaceHolder1_BTRECH')"
                >
                  <span className="input-icon">
                    <input
                      name="search"
                      type="text"
                      id="search"
                      tabIndex={1}
                      className="nav-search-input"
                      placeholder="Recherche ..."
                    />
                    <i className="ace-icon fa fa-search nav-search-icon" />
                    <button type="button" className="btn-info btn-sm" onClick={filterDossier}>Rechercher</button>
                  </span>
                </div>
              </div>
            </div>
            {/* Contenu du cadre */}
            <div className="widget-body">
              <div className="widget-main">
                <span
                  id="ContentPlaceHolder1_LBINFO"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "center"
                  }}
                />
                <label className="block clearfix">
                  <span className="block">
                    <table
                      cellSpacing={0}
                      rules="all"
                      className="table-header table table-striped "
                      border={1}
                      id="ContentPlaceHolder1_GV"
                      style={{
                        borderColor: "#E1E1E1",
                        fontSize: "10pt",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr
                          align="center"
                          valign="middle"
                          style={{
                            color: "#707070",
                            backgroundColor: "#F1F1F1",
                            fontWeight: "normal",
                          }}
                        >
                          {headTable?.map((item, index) => {
                            return (
                              <th
                                key={index}
                                className="text-center"
                                scope="col"
                                style={{ width: item?.width }}
                              >
                                {item?.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {currentTablePaiement?.map((item, key) => (
                          <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                            <td className="text-center">{item.DATE}</td>
                            <td className="text-center">{item.NRECU}</td>
                            <td className="text-center">{item.OBSERV}</td>
                            <td className="text-center">{item.MODE}</td>
                            <td className="text-center">{item.MONTANT}</td>
                            <td className="text-center">{item.APPERCU}</td>
                            <td className="text-center"
                            // onClick={() => onSelectDossier(item)}
                            >
                              <input
                                type="checkbox"
                                className="check"
                                id={`paiement-${key}`}
                              // onChange={onSelectDossier}
                              />
                            </td>
                          </tr>
                        ))}
                        {/* Pied du cadre */}
                        <tr
                          className="pagination-od"
                          align="center"
                          style={{
                            color: "Black",
                            backgroundColor: "#EFF3F8",
                            fontWeight: "normal",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <td colSpan={15}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    {totalPagePaiement != null && (
                                      <ReactPaginate
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        breakLabel="..."
                                        nextLabel="suivant >"
                                        onPageChange={onPageChangePaiement}
                                        pageRangeDisplayed={5}
                                        pageCount={totalPagePaiement + 1}
                                        previousLabel="< Précédent"
                                        renderOnZeroPageCount={null}
                                      />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default PaiementActeur;
