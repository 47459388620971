import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
  createEtapeFonciere,
  getEtapeFoncieres,
  updateEtapeFonciere,
  deleteEtapeFonciere
} from "../../services/etapefoncier";
import Formulaire from "../../components/Formulaire";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
const EtapesObtentionProFonc = () => {
  const headTable = [
    { name: "libelle", width: "100%" },
    { name: "", width: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Etapes (*)",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];

  const queryClient = useQueryClient();

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    console.log(d);
  };
  // fin code pagination

  const fetchEtapeFonciere = () => {
    setIsLoading(true);
    getEtapeFoncieres()
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        setTableData(data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setFlash({ ...flash, message: res.message, color: "success" });
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const { mutate } = useMutation(createEtapeFonciere, {
    onSuccess: async ({ message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: message, color: "success" });
      getEtapeFoncieres()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setTableData(data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setFlash({ ...flash, message: res.message, color: "success" });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log('error');
          console.log(err);

        })
        .catch((response) => {
          console.log("error messaage")
          // console.log(response)
          setFlash({ message: response.data.message, color: "danger" });
        });
    }
  });

  const { mutate: updateMutate } = useMutation(updateEtapeFonciere, {
    onSuccess: async ({ data, message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message, color: "success" });
      getEtapeFoncieres()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setIsLoading(false);
        })
        .catch();
    },
    onSettled: ({ response: { data } }) => {
      setFlash({ ...flash, message: data.message, color: "danger" });
      // console.log(data.message);
    },
  }
  );

  // const post = (data) => {
  //   if (data.libelle.length === 0) {
  //     setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
  //     setIsLoading(false);
  //     return false;
  //   }
  //   mutate(data)
  // };
  // const put = (data) => {
  //   if (data.libelle.length === 0 || data.id.length === 0) {
  //     setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
  //     setIsLoading(false);
  //     return false;
  //   }
  //   updateMutate(data)
  // };

  const postEtapeFonciere = (data) => {
    if (data.libelle.length == 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      return false;
    }
    mutate(data);
  }
  const putEtapeFonciere = (data) => {
    if (data.libelle.length == 0) {
      setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
      return false;
    }
    updateMutate(data);
  }
  const onclick = (data) => {
    setCurrent(data);
    // setIsLoading(false);
  };



  const onDelete = async () => {
    if (window.confirm("Voulez vous supprimer?")) {
      const data = { id: current.ID, libelle: current.LIBELLE };
      deleteEtapeFonciere(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getEtapeFoncieres()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          getEtapeFoncieres()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  useEffect(() => {
    fetchEtapeFonciere();
  }, []);



  return (
    <Layout>
      <Formulaire
        forms={forms}
        title={"Gestion des étapes d’obtention du titre de propriété foncière"}
        cb={current ? putEtapeFonciere : postEtapeFonciere}
        current={current}
        setCurrent={setCurrent}
        color={flash.color}
        isLoading={isLoading}
        message={flash.message}
        onDelete={onDelete}
      />
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table
          headTable={headTable}
          title={"Liste des étapes d’obtention du titre de propriété foncière"}
        >
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item?.LIBELLE}</td>
              <td className="text-center" align="right">
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
              </td>
            </tr>
          ))}

          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default EtapesObtentionProFonc;
