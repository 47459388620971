import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getAgence = async (id) => {
  return await axios.get("setting/list/agence?idquartier=" + id, config);
};

export const createAgence = async (d) => {
  try {
    const { data } = await axios.post("setting/create/agence", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateAgence = async (d) => {
  try {
    const { data } = await axios.post("setting/update/agence", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteAgence = async (d) =>
  await axios.post("setting/delete/agence", d, config);
