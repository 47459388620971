import React, { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthContext, { _AuthContext } from "./context/AuthContext";
import Login from "./pages/Auth/Login";
import Error404 from "./pages/Error/Error404";
import MainRoute from "./routes/mainRoute";

function App() {
  return (
    <AuthContext>
      <BrowserRouter>
        {/* <React.Suspense children={<>ok</>}> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<MainRoute />} />
          <Route path="/error-page" element={<Error404 />} />
        </Routes>
        {/* </React.Suspense> */}
      </BrowserRouter>
    </AuthContext>
  );
}

export default App;
