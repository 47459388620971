import React, { createContext, useState } from "react";

export const _AuthContext = createContext({ auth: null, setAuth: () => {} });

const AuthContext = ({ children }) => {
  const [auth, setAuth] = useState(null);

  return (
    <_AuthContext.Provider value={{ auth: auth, setAuth: setAuth }}>
      {children}
    </_AuthContext.Provider>
  );
};

export default AuthContext;
