import * as React from "react";
import { Link, Outlet } from "react-router-dom";
const Sidebar = () => {

  const profil = localStorage.getItem("__myProfil");
  console.log("user profil : " + profil)
  ///menu Administrateur Logiciel ==> profil = 1
  const menus_administrateur = [
    {
      name: "Table",
      icon: "fa-file-text-o",
      sous_menus: [
        { route: "setting/pays", name: "Pays" },
        { route: "setting/villes", name: "Villes" },
        { route: "setting/communes", name: "Communes" },
        { route: "setting/quartiers", name: "Quartiers" },
        { route: "setting/secteurs", name: "Secteurs" },
        { route: "setting/societes", name: "Societes" },
        { route: "setting/entites", name: "Entites" },
        { route: "setting/agences", name: "Agences" },

        {
          route: "setting/circonscription-foncieres",
          name: "Circonscription foncières",
        },
        { route: "setting/type-pieces", name: "Type pièces d'identité" },
        {
          route: "setting/operateur-immobiliers",
          name: "Opérateur Immobiliers",
        },
        { route: "setting/type-acteurs", name: "Type d'acteurs du foncier" },
        { route: "setting/acteur-fonciers", name: "Acteur du fonciers" },
        { route: "setting/rubrique-paiement", name: "Rubriques de paiement" },
        { route: "setting/naturecontrat", name: "Nature de Contrat" },
        {
          route: "setting/document-fournir",
          name: "Documents à fournir par le client",
        },
        {
          route: "setting/documenttransmettre",
          name: " Document à transmettre aux acteurs",
        },
        {
          route: "setting/documentattributeclient",
          name: "Documents à fournir au client",
        },
        {
          route: "setting/Etapetpf",
          name: "Etapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/Docetape",
          name: "Documents à obtenir par étapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/typeutilisateurlogement",
          name: "Types d’utilisation des logements",
        },
        {
          route: "setting/operationimmobiliers",
          name: "Opérations Immobilieres",
        },
        { route: "setting/ilots", name: "Ilots" },
        { route: "setting/typelogement", name: "Types de logement" },
        {
          route: "setting/typelogoementoperation",
          name: "Type de logement par operation",
        },
        {
          route: "setting/rubriquepaiementlogement",
          name: "Rubriques de paiement par type de logement",
        },
        {
          route: "setting/lots",
          name: "Lots",
        },
        {
          route: "setting/banque",
          name: "Banque",
        },
      ],
    },

    {
      name: "Dossiers clients",
      icon: "fa-female",
      sous_menus: [
        {
          route: "dossier-client/verificationsolde",
          name: "Vérification de solde",
        },
        {
          route: "dossier-client/dossierclt",
          name: "Dossier",
        },
        {
          route: "dossier-client/affectation-dossiers",
          name: "Affectation des dossiers aux acteurs",
        },
        {
          route: "dossier-client/cloture-dossiers",
          name: "Clôture de dossiers",
        },
      ],
    },

    {
      name: "Finances",
      icon: "fa-money",
      sous_menus: [
        { route: "finances/paiement-client", name: "Paiement client" },
        {
          route: "finances/validation-paiement-client",
          name: "Validation paiement client",
        },
        { route: "finances/paiement-acteur", name: "Paiement acteur" },
        {
          route: "finances/validation-paiement-acteur",
          name: "Validation paiement acteur",
        },
      ],
    },
    {
      name: "Etape dossier",
      icon: "fa-female",
      sous_menus: [{ route: "etape/create", name: "Créer une Etape" }],
    },

    {
      name: "Edition",
      icon: "fa-print",
      sous_menus: [
        { route: "edition/listes", name: "Les listes" },
        { route: "edition/etats-client", name: "Les états client" },
        { route: "edition/etats-acteur", name: "Les états acteur" },
      ],
    },
    {
      name: "Sécurite",
      icon: "fa fa-key blue",
      sous_menus: [
        { route: "securite/reinit", name: "Réinitialisation de mot de passe" },
        { route: "securite/utilisateur", name: "Utilisateurs" },
      ],
    },
  ];
  //menu Direction Generale ===> profil => 4
  const menus_direction_generale = [
    {
      name: "Table",
      icon: "fa-file-text-o",
      sous_menus: [
        { route: "setting/pays", name: "Pays" },
        { route: "setting/villes", name: "Villes" },
        { route: "setting/communes", name: "Communes" },
        { route: "setting/quartiers", name: "Quartiers" },
        { route: "setting/secteurs", name: "Secteurs" },
        { route: "setting/societes", name: "Societes" },
        { route: "setting/entites", name: "Entites" },
        { route: "setting/agences", name: "Agences" },

        {
          route: "setting/circonscription-foncieres",
          name: "Circonscription foncières",
        },
        { route: "setting/type-pieces", name: "Type pièces d'identité" },
        {
          route: "setting/operateur-immobiliers",
          name: "Opérateur Immobiliers",
        },
        { route: "setting/type-acteurs", name: "Type d'acteurs du foncier" },
        { route: "setting/acteur-fonciers", name: "Acteur du fonciers" },
        { route: "setting/rubrique-paiement", name: "Rubriques de paiement" },
        { route: "setting/naturecontrat", name: "Nature de Contrat" },
        {
          route: "setting/document-fournir",
          name: "Documents à fournir par le client",
        },
        {
          route: "setting/documenttransmettre",
          name: " Document à transmettre aux acteurs",
        },
        {
          route: "setting/documentattributeclient",
          name: "Documents à fournir au client",
        },
        {
          route: "setting/Etapetpf",
          name: "Etapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/Docetape",
          name: "Documents à obtenir par étapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/typeutilisateurlogement",
          name: "Types d’utilisation des logements",
        },
        {
          route: "setting/operationimmobiliers",
          name: "Opérations Immobilieres",
        },
        { route: "setting/ilots", name: "Ilots" },
        { route: "setting/typelogement", name: "Types de logement" },
        {
          route: "setting/typelogoementoperation",
          name: "Type de logement par operation",
        },
        {
          route: "setting/rubriquepaiementlogement",
          name: "Rubriques de paiement par type de logement",
        },
        {
          route: "setting/lots",
          name: "Lots",
        },
        {
          route: "setting/banque",
          name: "Banque",
        },
      ],
    },

    {
      name: "Dossiers clients",
      icon: "fa-female",
      sous_menus: [
        {
          route: "dossier-client/verificationsolde",
          name: "Vérification de solde",
        },
        {
          route: "dossier-client/dossierclt",
          name: "Dossier",
        },
        {
          route: "dossier-client/affectation-dossiers",
          name: "Affectation des dossiers aux acteurs",
        },
        {
          route: "dossier-client/cloture-dossiers",
          name: "Clôture de dossiers",
        },
      ],
    },

    {
      name: "Finances",
      icon: "fa-money",
      sous_menus: [
        { route: "finances/paiement-client", name: "Paiement client" },
        {
          route: "finances/validation-paiement-client",
          name: "Validation paiement client",
        },
        { route: "finances/paiement-acteur", name: "Paiement acteur" },
        {
          route: "finances/validation-paiement-acteur",
          name: "Validation paiement acteur",
        },
      ],
    },
    {
      name: "Etape dossier",
      icon: "fa-female",
      sous_menus: [{ route: "etape/create", name: "Créer une Etape" }],
    },

    {
      name: "Edition",
      icon: "fa-print",
      sous_menus: [
        { route: "edition/listes", name: "Les listes" },
        { route: "edition/etats-client", name: "Les états client" },
        { route: "edition/etats-acteur", name: "Les états acteur" },
      ],
    },
    {
      name: "Sécurite",
      icon: "fa fa-key blue",
      sous_menus: [
        { route: "securite/reinit", name: "Réinitialisation de mot de passe" },
        { route: "securite/utilisateur", name: "Utilisateurs" },
      ],
    },
  ];
  ////menu agent ====> profil = 5
  const menus_agent = [
    {
      name: "Dossiers clients",
      icon: "fa-female",
      sous_menus: [
        {
          route: "dossier-client/verificationsolde",
          name: "Vérification de solde",
        },
        {
          route: "dossier-client/dossierclt",
          name: "Dossier",
        },
        {
          route: "dossier-client/affectation-dossiers",
          name: "Affectation des dossiers aux acteurs",
        },
      ],
    },
    {
      name: "Finances",
      icon: "fa-money",
      sous_menus: [
        { route: "finances/paiement-client", name: "Paiement client" },
      ],
    },
    {
      name: "Etape dossier",
      icon: "fa-female",
      sous_menus: [{ route: "etape/create", name: "Créer une Etape" }],
    },
    // {
    //   name: "Edition",
    //   icon: "fa-print",
    //   sous_menus: [
    //     // { route: "edition/listes", name: "Les listes" },
    //     { route: "edition/etats-client", name: "Les états client" },
    //     // { route: "edition/etats-acteur", name: "Les états acteur" },
    //   ],
    // },
  ];
  ////menu daf ====> profil = 6
  const menus_daf = [
    {
      name: "Table",
      icon: "fa-file-text-o",
      sous_menus: [
        { route: "setting/pays", name: "Pays" },
        { route: "setting/villes", name: "Villes" },
        { route: "setting/communes", name: "Communes" },
        { route: "setting/quartiers", name: "Quartiers" },
        { route: "setting/secteurs", name: "Secteurs" },
        { route: "setting/societes", name: "Societes" },
        { route: "setting/entites", name: "Entites" },
        { route: "setting/agences", name: "Agences" },

        {
          route: "setting/circonscription-foncieres",
          name: "Circonscription foncières",
        },
        { route: "setting/type-pieces", name: "Type pièces d'identité" },
        {
          route: "setting/operateur-immobiliers",
          name: "Opérateur Immobiliers",
        },
        { route: "setting/type-acteurs", name: "Type d'acteurs du foncier" },
        { route: "setting/acteur-fonciers", name: "Acteur du fonciers" },
        { route: "setting/rubrique-paiement", name: "Rubriques de paiement" },
        { route: "setting/naturecontrat", name: "Nature de Contrat" },
        {
          route: "setting/document-fournir",
          name: "Documents à fournir par le client",
        },
        {
          route: "setting/documenttransmettre",
          name: " Document à transmettre aux acteurs",
        },
        {
          route: "setting/documentattributeclient",
          name: "Documents à fournir au client",
        },
        {
          route: "setting/Etapetpf",
          name: "Etapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/Docetape",
          name: "Documents à obtenir par étapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/typeutilisateurlogement",
          name: "Types d’utilisation des logements",
        },
        {
          route: "setting/operationimmobiliers",
          name: "Opérations Immobilieres",
        },
        { route: "setting/ilots", name: "Ilots" },
        { route: "setting/typelogement", name: "Types de logement" },
        {
          route: "setting/typelogoementoperation",
          name: "Type de logement par operation",
        },
        {
          route: "setting/rubriquepaiementlogement",
          name: "Rubriques de paiement par type de logement",
        },
        {
          route: "setting/lots",
          name: "Lots",
        },
        {
          route: "setting/banque",
          name: "Banque",
        },
      ],
    },
    {
      name: "Dossiers clients",
      icon: "fa-female",
      sous_menus: [
        {
          route: "dossier-client/verificationsolde",
          name: "Vérification de solde",
        },
        {
          route: "dossier-client/dossierclt",
          name: "Dossier",
        },
        {
          route: "dossier-client/affectation-dossiers",
          name: "Affectation des dossiers aux acteurs",
        },
        {
          route: "dossier-client/cloture-dossiers",
          name: "Clôture de dossiers",
        },
      ],
    },
    {
      name: "Finances",
      icon: "fa-money",
      sous_menus: [
        { route: "finances/paiement-client", name: "Paiement client" },
        {
          route: "finances/validation-paiement-client",
          name: "Validation paiement client",
        },
        { route: "finances/paiement-acteur", name: "Paiement acteur" },
        {
          route: "finances/validation-paiement-acteur",
          name: "Validation paiement acteur",
        },
      ],
    },
    {
      name: "Etape dossier",
      icon: "fa-female",
      sous_menus: [{ route: "etape/create", name: "Créer une Etape" }],
    },
    {
      name: "Edition",
      icon: "fa-print",
      sous_menus: [
        { route: "edition/listes", name: "Les listes" },
        { route: "edition/etats-client", name: "Les états client" },
        { route: "edition/etats-acteur", name: "Les états acteur" },
      ],
    },
    {
      name: "Sécurite",
      icon: "fa fa-key blue",
      sous_menus: [
        { route: "securite/reinit", name: "Réinitialisation de mot de passe" },
        { route: "securite/utilisateur", name: "Utilisateurs" },
      ],
    },
  ];
  ///menu informaticien ====> profil = 7
  const menus_informaticien = [
    {
      name: "Table",
      icon: "fa-file-text-o",
      sous_menus: [
        { route: "setting/pays", name: "Pays" },
        { route: "setting/villes", name: "Villes" },
        { route: "setting/communes", name: "Communes" },
        { route: "setting/quartiers", name: "Quartiers" },
        { route: "setting/secteurs", name: "Secteurs" },
        // { route: "setting/societes", name: "Societes" },
        { route: "setting/entites", name: "Entites" },
        { route: "setting/agences", name: "Agences" },

        // {
        //   route: "setting/circonscription-foncieres",
        //   name: "Circonscription foncières",
        // },
        { route: "setting/type-pieces", name: "Type pièces d'identité" },
        // {
        //   route: "setting/operateur-immobiliers",
        //   name: "Opérateur Immobiliers",
        // },
        { route: "setting/type-acteurs", name: "Type d'acteurs du foncier" },
        { route: "setting/acteur-fonciers", name: "Acteur du fonciers" },
        // { route: "setting/rubrique-paiement", name: "Rubriques de paiement" },
        // { route: "setting/naturecontrat", name: "Nature de Contrat" },
        {
          route: "setting/document-fournir",
          name: "Documents à fournir par le client",
        },
        {
          route: "setting/documenttransmettre",
          name: " Document à transmettre aux acteurs",
        },
        {
          route: "setting/documentattributeclient",
          name: "Documents à fournir au client",
        },
        {
          route: "setting/Etapetpf",
          name: "Etapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/Docetape",
          name: "Documents à obtenir par étapes d’obtention du titre de propriété foncière",
        },
        {
          route: "setting/typeutilisateurlogement",
          name: "Types d’utilisation des logements",
        },
        // {
        //   route: "setting/operationimmobiliers",
        //   name: "Opérations Immobilieres",
        // },
        // { route: "setting/ilots", name: "Ilots" },
        // { route: "setting/typelogement", name: "Types de logement" },
        // {
        //   route: "setting/typelogoementoperation",
        //   name: "Type de logement par operation",
        // },
        // {
        //   route: "setting/rubriquepaiementlogement",
        //   name: "Rubriques de paiement par type de logement",
        // },
        // {
        //   route: "setting/lots",
        //   name: "Lots",
        // },
        {
          route: "setting/banque",
          name: "Banque",
        },
      ],
    },

    {
      name: "Dossiers clients",
      icon: "fa-female",
      sous_menus: [
        {
          route: "dossier-client/verificationsolde",
          name: "Vérification de solde",
        },
        {
          route: "dossier-client/dossierclt",
          name: "Dossier",
        },
        {
          route: "dossier-client/affectation-dossiers",
          name: "Affectation des dossiers aux acteurs",
        },
        {
          route: "dossier-client/cloture-dossiers",
          name: "Clôture de dossiers",
        },
      ],
    },

    // {
    //   name: "Finances",
    //   icon: "fa-money",
    //   sous_menus: [
    //     { route: "finances/paiement-client", name: "Paiement client" },
    //     {
    //       route: "finances/validation-paiement-client",
    //       name: "Validation paiement client",
    //     },
    //     { route: "finances/paiement-acteur", name: "Paiement acteur" },
    //     {
    //       route: "finances/validation-paiement-acteur",
    //       name: "Validation paiement acteur",
    //     },
    //   ],
    // },
    {
      name: "Etape dossier",
      icon: "fa-female",
      sous_menus: [{ route: "etape/create", name: "Créer une Etape" }],
    },
    {
      name: "Edition",
      icon: "fa-print",
      sous_menus: [
        // { route: "edition/listes", name: "Les listes" },
        { route: "edition/etats-client", name: "Les états client" },
        // { route: "edition/etats-acteur", name: "Les états acteur" },
      ],
    },
    {
      name: "Sécurite",
      icon: "fa fa-key blue",
      sous_menus: [
        { route: "securite/reinit", name: "Réinitialisation de mot de passe" },
        { route: "securite/utilisateur", name: "Utilisateurs" },
      ],
    },
  ];

  var menus = [];
  var prof = parseInt(profil)
  switch (prof) {
    case 1:
      menus = menus_administrateur;
      break;
    case 4:
      menus = menus_direction_generale;
      break;
    case 5:
      menus = menus_agent;
      break;
    case 6:
      menus = menus_daf;
      break;
    case 7:
      menus = menus_informaticien;
      break;
    default:
      menus = menus_agent;
  }

  // const menus = [
  //   {
  //     name: "Table",
  //     icon: "fa-file-text-o",
  //     sous_menus: [
  //       { route: "setting/pays", name: "Pays" },
  //       { route: "setting/villes", name: "Villes" },
  //       { route: "setting/communes", name: "Communes" },
  //       { route: "setting/quartiers", name: "Quartiers" },
  //       { route: "setting/secteurs", name: "Secteurs" },
  //       { route: "setting/societes", name: "Societes" },
  //       { route: "setting/entites", name: "Entites" },
  //       { route: "setting/agences", name: "Agences" },

  //       {
  //         route: "setting/circonscription-foncieres",
  //         name: "Circonscription foncières",
  //       },
  //       { route: "setting/type-pieces", name: "Type pièces d'identité" },
  //       {
  //         route: "setting/operateur-immobiliers",
  //         name: "Opérateur Immobiliers",
  //       },
  //       { route: "setting/type-acteurs", name: "Type d'acteurs du foncier" },
  //       { route: "setting/acteur-fonciers", name: "Acteur du fonciers" },
  //       { route: "setting/rubrique-paiement", name: "Rubriques de paiement" },
  //       { route: "setting/naturecontrat", name: "Nature de Contrat" },
  //       {
  //         route: "setting/document-fournir",
  //         name: "Documents à fournir par le client",
  //       },
  //       {
  //         route: "setting/documenttransmettre",
  //         name: " Document à transmettre aux acteurs",
  //       },
  //       {
  //         route: "setting/documentattributeclient",
  //         name: "Documents à fournir au client",
  //       },
  //       {
  //         route: "setting/Etapetpf",
  //         name: "Etapes d’obtention du titre de propriété foncière",
  //       },
  //       {
  //         route: "setting/Docetape",
  //         name: "Documents à obtenir par étapes d’obtention du titre de propriété foncière",
  //       },
  //       {
  //         route: "setting/typeutilisateurlogement",
  //         name: "Types d’utilisation des logements",
  //       },
  //       {
  //         route: "setting/operationimmobiliers",
  //         name: "Opérations Immobilieres",
  //       },
  //       { route: "setting/ilots", name: "Ilots" },
  //       { route: "setting/typelogement", name: "Types de logement" },
  //       {
  //         route: "setting/typelogoementoperation",
  //         name: "Type de logement par operation",
  //       },
  //       {
  //         route: "setting/rubriquepaiementlogement",
  //         name: "Rubriques de paiement par type de logement",
  //       },
  //       {
  //         route: "setting/lots",
  //         name: "Lots",
  //       },
  //       {
  //         route: "setting/banque",
  //         name: "Banque",
  //       },
  //     ],
  //   },

  //   {
  //     name: "Dossiers clients",
  //     icon: "fa-female",
  //     sous_menus: [
  //       {
  //         route: "dossier-client/verificationsolde",
  //         name: "Vérification de solde",
  //       },
  //       {
  //         route: "dossier-client/dossierclt",
  //         name: "Dossier",
  //       },
  //       {
  //         route: "dossier-client/affectation-dossiers",
  //         name: "Affectation des dossiers aux acteurs",
  //       },
  //       {
  //         route: "dossier-client/cloture-dossiers",
  //         name: "Clôture de dossiers",
  //       },
  //     ],
  //   },

  //   {
  //     name: "Finances",
  //     icon: "fa-money",
  //     sous_menus: [
  //       { route: "finances/paiement-client", name: "Paiement client" },
  //       {
  //         route: "finances/validation-paiement-client",
  //         name: "Validation paiement client",
  //       },
  //       { route: "finances/paiement-acteur", name: "Paiement acteur" },
  //       {
  //         route: "finances/validation-paiement-acteur",
  //         name: "Validation paiement acteur",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Etape dossier",
  //     icon: "fa-female",
  //     sous_menus: [{ route: "etape/create", name: "Créer une Etape" }],
  //   },

  //   {
  //     name: "Edition",
  //     icon: "fa-print",
  //     sous_menus: [
  //       { route: "edition/listes", name: "Les listes" },
  //       { route: "edition/etats-client", name: "Les états client" },
  //       { route: "edition/etats-acteur", name: "Les états acteur" },
  //     ],
  //   },
  // ];

  return (
    <div id="sidebar" className="sidebar responsive display">
      {/* Menu Tableau de bord */}
      <ul className="nav nav-list" style={{ top: 0 }}>
        <li className="active">
          <Link to="/">
            <i className="menu-icon fa fa-tachometer blue" />
            <span className="menu-text"> Accueil </span>
          </Link>
          <b className="arrow" />
        </li>
        {/* Création des menus et sous-menu */}
        {menus.map((item, key) => (
          <ItemMenu menu={item} key={key} />
        ))}
        {/* <li className>
          <a href="" target="_blank">
            <i className="menu-icon fa fa-question orange" />
            <span className="menu-text">Aide</span>
          </a>
          <b className="arrow" />
        </li> */}
      </ul>
      {/* Barre de navigation */}
      <div className="sidebar-toggle sidebar-collapse" id="sidebar-collapse">
        <i
          className="ace-icon fa fa-angle-double-left"
          data-icon1="ace-icon fa fa-angle-double-left"
          data-icon2="ace-icon fa fa-angle-double-right"
        />
      </div>
      <Outlet />
    </div>
  );
};

export default Sidebar;

const ItemMenu = ({ menu }) => (
  <li className="hsub">
    <a href="#" className="dropdown-toggle">
      <i className={`menu-icon fa ${menu?.icon} blue`} />
      <span className="menu-text">{menu?.name}</span>
      <b className="arrow fa fa-angle-down" />
    </a>
    <b className="arrow" />
    <ul className="submenu nav-hide" style={{ display: "none" }}>
      {menu?.sous_menus?.map((item, i) => (
        <SousMenu key={i} sousMenu={item} />
      ))}
    </ul>
  </li>
);

const SousMenu = ({ sousMenu }) => (
  <li className="hsub">
    <Link to={"/" + sousMenu.route}>{sousMenu.name}</Link>
    <b className="arrow" />
  </li>
);
