import React, { useEffect } from "react";
import { useState } from "react";
import { pathUrl } from "../../../..";

const TableDocFourniClient = ({ grilleDocClient }) => {


  const showDoc = (path) => {
    if(path){
      const source = pathUrl + path;
      window.open(source, '_blank');
    }
  }
  // console.log(grilleDocClient);
  useEffect(() => { }, []);
  return (
    <label className="block clearfix">
      <span className="block">
        <div>
          <table
            cellSpacing={0}
            rules="all"
            className="table-header table-striped-"
            border={1}
            id="ContentPlaceHolder1_GVPCLT"
            style={{
              borderColor: "#E1E1E1",
              fontSize: "10pt",
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr
                align="center"
                valign="middle"
                style={{
                  color: "#707070",
                  backgroundColor: "#F1F1F1",
                  fontWeight: "normal",
                }}
              >
                <th scope="col" style={{ width: 100 }}>
                  N° Document
                </th>
                <th scope="col" style={{ width: 80 }}>
                  Date
                </th>
                <th scope="col">Type de document</th>
              </tr>
              {grilleDocClient?.map((item, i) => {
                return (
                  <tr key={i} style={{ backgroundColor: "#fff" }}>
                    <td style={{ color: "#000" }}>{item.NUMDOC}</td>
                    <td style={{ color: "#000" }}>{item.DATE}</td>
                    <td>
                      <a href="#"  onClick={(e) => {
                        e.preventDefault();
                        showDoc(item.LIENDOCUMENT);
                        return false;
                         }}>
                        {item.LIBELLE}
                      </a>
                     
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </span>
    </label>
  );
};

export default TableDocFourniClient;
