import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { logout } from "../../services/auth";

const Logout = () => {
  const deconnexion = async () => {
    logout()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  localStorage.clear();
  localStorage.clear();
  // window.location.href = "/login";
  // useEffect(() => {
  //   deconnexion();
  // }, []);
  const id = localStorage.getItem('__user_acteur_id')
  const id_client = localStorage.getItem('__user_client_id')
  if (id != null && id.length !== 0) {
    return <Navigate to="/login/acteur" replace />;
  } else if (id_client != null && id_client.length !== 0) {
    return <Navigate to="/login/client" replace />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default Logout;
