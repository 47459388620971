import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};
export const fillDocUrgence = async (d) => {
  try {
    const { data } = await axios.post("acteur/remplir/solde/dossier-urgence", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fillDocClient = async (d) => {
  try {
    const { data } = await axios.post("acteur/remplir/solde/dossier-client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const registerClient = async (d) => {
  try {
    const { data } = await axios.post("acteur/enregistrement/solde/client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};