import { Navigate, Route, Routes } from "react-router-dom";
import PaiementClient from "../pages/Client/PaiementClient";
import ResetPasswordClient from "../pages/Client/ResetPasswordClient";
import TableBordClient from "../pages/Client/TableBordClient";

const ClientInterfaceRoute = () => (
  <Routes>
    <Route path="accueil" element={<TableBordClient />} />
    <Route path="paiement-client" element={<PaiementClient />} />
    <Route path="modifier-password-acteur" element={<ResetPasswordClient />} />
    <Route path="*" element={<Navigate to={"/error-page"} replace />} />
  </Routes>
);

export default ClientInterfaceRoute;
