import { Navigate, Route, Routes } from "react-router-dom";

import Utilisateur from "../pages/Securite/Utilisateur";
import ReinitailiserMdp from "../pages/Securite/ReinitailiserMdp";

const SecuriteRoute = () => (
  <Routes>
    {/*  */}
    <Route path="utilisateur" element={<Utilisateur />} />
    <Route path="reinit" element={<ReinitailiserMdp />} />
    
    <Route path="*" element={<Navigate to={"/error-page"} replace />} />
  </Routes>
);

export default SecuriteRoute;
