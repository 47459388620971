import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const login = async (d) => {
  try {
    const { data } = await axios.post("utilisateur/connexion", d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const logout = async () =>
  await axios.post("utilisateur/deconnexion", config);
