import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { _AuthContext } from "../../context/AuthContext";

const LoginActeur = () => {
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
    defaultValues: { email: "", password: "" },
  });

  useEffect(() => {
    localStorage.removeItem("__myProfil");
  }, []);

  let navigate = useNavigate();
  const { setAuth } = useContext(_AuthContext);
  const [isLoading, setIsloading] = useState(false);

  const onSubmit = (data) => {
    setIsloading(true);
    //console.log(data)
    axios
      .post("utilisateur/acteur/connexion", data)
      .then((res) => {
        const { data: datas } = res;
        console.log(datas);
        if ("IDACTEUR" in datas.data.user) {
          localStorage.setItem("__myProfil", "acteur");
        } else {
          localStorage.setItem("__myProfil", "foncier");
        }
        localStorage.setItem("__myTeken", datas.data.token);
        localStorage.setItem("__user_acteur_nom", datas.data.user.NOM);
        localStorage.setItem("__user_acteur_id", datas.data.user.ID);
        localStorage.setItem("__user_type", "acteur");
        localStorage.setItem("__user_acteur_email", datas.data.user.EMAIL);

        const expired = new Date();
        expired.setDate(expired.getDate() + 1);
        localStorage.setItem("__expired_myTeken", expired);

        reset({ email: "", password: "" });
        setAuth(datas.token);
        setIsloading(false);
        // alert("Bloquer");

        window.location.href = datas.data.redirect === "OUI" ? "/acteur/modifier-password-acteur" : "/acteur/accueil";
        // if(datas.data.redirect === "OUI") { window.location.href = "/acteur/modifier-password-acteur"; }
        // else { window.location.href = "/acteur/accueil"; }
      })
      .catch((error) => {
        const { data } = error.response;
        setIsloading(false);
        setError("email", { type: "custom", message: data.message });
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  return (
    <div className="login-layout" style={{ height: window.innerHeight - 0.2 }}>
      <div className="main-container">
        <div className="main-content">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="login-container">
                <div className="center">
                  <h1>
                    <span className="red">CATEF</span>
                    <br />
                    <span className="blue">ESPACE ACTEUR</span>
                  </h1>
                  <h4 className="blue" id="id-company-text" />
                </div>
                <div className="space-6" />
                <div className="position-relative">
                  <div
                    id="login-box"
                    className="login-box visible widget-box no-border"
                  >
                    <div className="widget-body">
                      <div className="widget-main">
                        <h4 className="header blue lighter bigger">
                          <i className="ace-icon fa fa-coffee green" />
                          Entrer vos identifiants
                        </h4>
                        <form onSubmit={handleSubmit(onSubmit)} id="form2">
                          <label className="block clearfix">
                            <span className="block input-icon input-icon-right">
                              <input
                                maxLength={50}
                                className="form-control text-center"
                                placeholder="Email"
                                {...register("email", {
                                  required: {
                                    message: "Ce champs est requis",
                                    value: true,
                                  },
                                })}
                              />
                              <i className="ace-icon fa fa-user" />
                            </span>
                            {errors.email?.message && (
                              <span className="text-danger">
                                {errors.email.message}
                              </span>
                            )}
                          </label>
                          <label className="block clearfix">
                            <span className="block input-icon input-icon-right">
                              <input
                                name="TBPASS"
                                type="password"
                                {...register("password", {
                                  required: {
                                    message: "Ce champs est requis",
                                    value: true,
                                  },
                                })}
                                className="form-control text-center"
                                placeholder="Mot de passe"
                              />
                              <i className="ace-icon fa fa-lock" />
                            </span>
                            {errors.password?.message && (
                              <span className="text-danger">
                                {errors.password.message}
                              </span>
                            )}
                          </label>
                          <div className="clearfix">
                            <input
                              type="submit"
                              //   name="BTENR"
                              defaultValue="Connecter"
                              disabled={isLoading}
                              //   id="BTENR"
                              //   tabIndex={3}
                              className="btn btn-primary btn-lg btn-block"
                              style={{ borderStyle: "None" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <SpinnerCircular
                                style={{ color: "#1B6AAA" }}
                                enabled={isLoading}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginActeur;
