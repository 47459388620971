import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { gettypeLogement } from "../../services/typeLogement";
import { getRubriquePaiements } from "../../services/rubriquePaiement";
// import { getOperationImmobilieres } from "../../services/operationImmobilier";
import { getOperationImmobilieres } from "../../services/operationImmobilier";
import { getNatureContrats } from "../../services/natureContrat";
import {
    ListeRubriquePaiementsLogements,
    getRubriquePaiementsLogements,
    // updateRubriquePaiementsLogements,
    createRubriquePaiementsLogements,
    deleteRubriquePaiementLogement
} from "../../services/RubriquePaiementLogement";

import ReactPaginate from "react-paginate";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
const RubriquePaiementLogement = () => {
    const headTable = [
        { name: "Rubrique de paiement", width: "45%" },
        { name: "montant", width: "45%" },
        { name: "", with: "5%" },
    ];
    const [current, setCurrent] = useState(null);
    const [typeLogements, setTypeLogements] = useState([]);
    const [operationImmobiliers, setOperationImmobiliers] = useState([]);
    const [natureContrats, setNatureContrats] = useState([]);
    const [rubriquePaiementLogements, setRubriquePaiementLogements] = useState([]);
    const [rubriquePaiement, setRubriquePaiements] = useState([]);

    const [values, setValues] = useState({
        idoperationimmo: "",
        idtypelogement: "",
        idnaturecontrat: "",
        // idrubriquepaiement: "",
        // operimmo: "",
        // natcont: "",
        // typelog: "",
        // listRubrique: []
        // montant: ""
    });

    const [listRubrique, setListeRubrique] = useState([])
    const _getOperationImmobilieres = async () => {
        const { data } = await getOperationImmobilieres();
        setOperationImmobiliers(data.data);
    };
    const _getNatureContrats = async () => {
        const { data } = await getNatureContrats();
        setNatureContrats(data.data);
    };

    // pagination code

    // pagination code
    const [flash, setFlash] = useState({ message: null, color: "success" });

    // pagination code
    let PageSize = 10;
    const [isLoading, setIsLoading] = useState(false);
    const [currentTableData, setCurrentTableData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [totalPage, setTotalPage] = useState(null);
    const page = (currentPage, datas) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        return datas.slice(firstPageIndex, lastPageIndex);
    };

    const onPageChange = (e) => {
        const d = page(parseInt(e.selected + 1), tableData);
        setCurrentTableData(d);
        // console.log(d);
    };
    // fin code pagination

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const onSelectOperation = (e) => {
        setIsLoading(true);
        setValues({ ...values, idoperationimmo: e.target.value });
        setCurrentTableData([]);
        setTypeLogements([]);
        gettypeLogement({ id: e.target.value })
            .then((res) => {
                const { data } = res;
                setTypeLogements(data);
                setIsLoading(false);
                // console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(true);

        ListeRubriquePaiementsLogements({ operimmo: e.target.value, natcont: values.idnaturecontrat, typelog: values.idtypelogement })
            .then((res) => {
                const { data } = res;

                setCurrentTableData(data.data);
                // values.listRubrique = []
                setListeRubrique(data.data)
                setFlash({ message: res.data.message, color: "success" });
                setIsLoading(false);

            })
            .catch((err) => {
                console.log(err);
            });

    };
    const onSelectTypeLogement = (e) => {
        setValues({ ...values, idtypelogement: e.target.value });
        let param = { typelog: e.target.value, operimmo: values.idoperationimmo, natcont: values.idnaturecontrat }

        ListeRubriquePaiementsLogements(param)
            .then((res) => {
                const { data } = res;
                setCurrentTableData(data.data);

                setListeRubrique(data.data)
                setFlash({ message: res.data.message, color: "success" });
                setIsLoading(false);
                // console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });

    };
    const onSelectNatureContrat = (e) => {
        setValues({ ...values, idnaturecontrat: e.target.value });
        let param = { natcont: e.target.value, typelog: values.idtypelogement, operimmo: values.idoperationimmo }
        ListeRubriquePaiementsLogements(param)
            .then((res) => {
                const { data } = res;
                setCurrentTableData(data.data);
                setListeRubrique(data.data)
                setFlash({ message: res.data.message, color: "success" });
                setIsLoading(false);
                // console.log(data);

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSubmit_old = (e) => {
        e.preventDefault();
        console.log(values);
        console.log(listRubrique);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // if (current) {
        //     // update
        //     let data = values;
        //     data["id"] = current.ID;
        //     //   console.log(data);
        //     updateRubriquePaiementsLogements(data)
        //         .then((res) => {
        //             setFlash({ message: res.data.message, color: "success" });
        //             // console.log(res);
        //             getRubriquePaiementsLogements({ idnaturecontrat: current.IDNATURECONTRAT })
        //                 .then((res) => {
        //                     // console.log(res);
        //                     const { data } = res;
        //                     const d = page(1, data);
        //                     setCurrentTableData(d);
        //                     const coutPage = parseInt(data.length / PageSize);
        //                     setTotalPage(coutPage);
        //                     setRubriquePaiementLogements(data);
        //                     setIsLoading(false);
        //                     setCurrent(null);
        //                     setValues({ ...values, libelle: "" });
        //                 })
        //                 .catch((err) => {
        //                     console.log(err);
        //                 });
        //             setValues({ ...values, libelle: "" });
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // } 
        // else
        {
            //  create
            if (values.idnaturecontrat.length == 0 || values.idoperationimmo.length == 0 || values.idtypelogement.length == 0) {
                setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
                return false;
            }
            let params = [];
            listRubrique.forEach(element => {
                let data = { idoperimmo: values.idoperationimmo, idtyplog: values.idtypelogement, natcont: values.idnaturecontrat, rubriqpaie: element.IDRUBRIQUEPAIEMENT, montant: element.MONTANT }
                params.push(data)
            });
            createRubriquePaiementsLogements({ listrubpaie: params })
                .then((response) => {
                    console.log(response);
                    setFlash({ message: response.data.message, color: "success" });
                    getRubriquePaiementsLogements(values)
                        .then((res) => {
                            console.log(res);
                            const { data } = res;
                            setFlash({ message: res.data.message, color: "success" });
                            const d = page(1, data.data);
                            setCurrentTableData(d);
                            const coutPage = parseInt(data.data.length / PageSize);
                            setTotalPage(coutPage);
                            setRubriquePaiementLogements(data.data);
                            setIsLoading(false);
                            //
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setValues({ ...values, libelle: "" });
                })
                .catch(({ response }) => {
                    setFlash({ message: response.data.message, color: "danger" });
                });
        }
    };

    const [_idNatureContrat, setIdNatureContrat] = useState(null);
    const onclick = (data) => {
        // console.log(data);
        setCurrent(data);
        setIdNatureContrat(data.IDNATURECONTRAT);
        setValues({ ...values, libelle: data.LIBELLE, });
    };

    // delete 
    const onDelete = async () => {
        // console.log(current);
        if (window.confirm("Voulez vous supprimer ?")) {
            const delet = { id: current.ID, libelle: current.LIBELLE };
            console.log(_idNatureContrat);
            deleteRubriquePaiementLogement(delet)
                .then(({ data }) => {
                    setIsLoading(true);
                    setFlash({ ...flash, color: "success", message: data.message });
                    // setCommunes([]);
                    setValues({ ...values, libelle: "" });
                    getRubriquePaiementsLogements({ idnaturecontrat: _idNatureContrat })
                        .then((res) => {
                            // setCurrent(null);
                            const { data, message } = res;
                            console.log(res);
                            const d = page(1, data);
                            setCurrentTableData(d);
                            // setFlash({ message, color: "success" });
                            const coutPage = parseInt(data.length / PageSize);
                            setTotalPage(coutPage);
                            setRubriquePaiementLogements(data);
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((error) => {
                    // console.log(error.response);
                    setIsLoading(true);

                    // setCurrent(null);
                    setFlash({
                        ...flash,
                        color: "danger",
                        message: error.response.data.message,
                    });
                    setRubriquePaiementLogements([]);
                    setValues({ ...values, libelle: "" });
                    getRubriquePaiementsLogements({ idnaturecontrat: _idNatureContrat })
                        .then((res) => {
                            // console.log(res);
                            const { data } = res;
                            const d = page(1, data);
                            setCurrentTableData(d);
                            // setFlash({ message, color: "success" });
                            const coutPage = parseInt(data.length / PageSize);
                            setTotalPage(coutPage);
                            setRubriquePaiementLogements(data);
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        }
    };
    // fin delete
    useEffect(() => {
        _getOperationImmobilieres();
        _getNatureContrats();
    }, []);
    return (
        <Layout>
            <div className="row">
                <div className="col-xs-2" />
                <form onSubmit={handleSubmit}>
                    <div className="form-horizontal col-xs-8 ui-sortable">
                        <div className="widget-box widget-color-blue">
                            {/* Entete du cadre */}
                            <div className="widget-header ui-sortable-handle">
                                <h5 className="widget-title">Saisie des rubriques de paiement par type de logement</h5>
                            </div>
                            {/* Contenu du cadre */}
                            <div className="widget-body">
                                <div className="widget-main">
                                    {flash?.message !== null && (
                                        <Alert color={flash?.color} message={flash?.message} />
                                    )}
                                    <span
                                        id="ContentPlaceHolder1_LBINFO"
                                        style={{
                                            display: "inline-block",
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    />

                                    {/* code form */}
                                    <label className="block clearfix">
                                        <label htmlFor="text" className="col-lg-3">
                                            Opération (*)
                                        </label>
                                        <select
                                            name="idoperationimmo"
                                            className="form-control input-xxlarge"
                                            onChange={onSelectOperation}
                                        >
                                            <option value=""></option>
                                            {operationImmobiliers?.map((item, key) => (
                                                <option key={key} value={item.ID}>
                                                    {item.LIBELLE}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className="block clearfix">
                                        <label htmlFor="text" className="col-lg-3">
                                            Type Logement
                                        </label>
                                        <select
                                            name="idtypelogement"
                                            className="form-control input-xxlarge"
                                            onChange={onSelectTypeLogement}
                                        >
                                            <option value="-1"></option>
                                            {typeLogements?.map((item, key) => (
                                                <option key={key} value={item.ID}>
                                                    {item.LIBELLE}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className="block clearfix">
                                        <label htmlFor="text" className="col-lg-3">
                                            Nature Contrat
                                        </label>
                                        <select
                                            name="idnaturecontrat"
                                            className="form-control input-xxlarge"
                                            onChange={onSelectNatureContrat}
                                        >
                                            <option value=""></option>
                                            {natureContrats?.map((item, key) => (
                                                <option key={key} value={item.ID}>
                                                    {item.LIBELLE}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    {/* <label className="block clearfix">
                                        <label htmlFor="text" className="col-lg-2">
                                            Quartier
                                        </label>
                                        <input
                                            value={values.libelle}
                                            onChange={handleChange}
                                            name="libelle"
                                            type="text"
                                            className="form-control input-xxlarge"
                                        />
                                    </label> */}

                                    {
                                        currentTableData.length != 0 &&
                                        <label className="block clearfix">
                                            <span className="block">
                                                <div>
                                                    <table
                                                        cellSpacing={0}
                                                        rules="all"
                                                        className="table-header"
                                                        border={1}
                                                        id="ContentPlaceHolder1_GV"
                                                        style={{
                                                            borderColor: "#E1E1E1",
                                                            fontSize: "10pt",
                                                            width: "100%",
                                                            borderCollapse: "collapse"
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                align="center"
                                                                valign="middle"
                                                                style={{
                                                                    color: "#707070",
                                                                    backgroundColor: "#F1F1F1",
                                                                    fontWeight: "normal"
                                                                }}
                                                            >
                                                                <th scope="col">Rubrique de paiement</th>
                                                                <th className="text-center" scope="col" style={{ width: 100 }}>
                                                                    Montant
                                                                </th>
                                                                <th scope="col" style={{ width: 30 }}>
                                                                    &nbsp;
                                                                </th>
                                                            </tr>

                                                            {currentTableData?.map((item, i) => {
                                                                // console.log(item);
                                                                return <tr key={i} style={{ color: "Black", backgroundColor: "White" }}>

                                                                    <td>{item.LIBELLE}</td>
                                                                    <td>
                                                                        <input
                                                                            name=""
                                                                            type="text"
                                                                            value={item.MONTANT}

                                                                            className="text-center"
                                                                            style={{ borderStyle: "None", width: 100 }}
                                                                        />
                                                                    </td>
                                                                    <td className="text-center" align="right">
                                                                        <input
                                                                            type="image"
                                                                            src="/Catef_files/fermer.png"
                                                                            onClick={() => { }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </span>
                                        </label>
                                    }
                                </div>

                                {/* Pied du cadre */}
                                <div className="widget-toolbox padding-8 clearfix">
                                    <div className="pull-right">
                                        <input
                                            type="submit"
                                            value="Enregistrer"
                                            className="btn btn-success"
                                            style={{
                                                borderStyle: "None",
                                                width: 100,
                                                marginRight: 2,
                                                marginBottom: 2,
                                            }}
                                        />

                                        {current && (
                                            <input
                                                type="button"
                                                onClick={onDelete}
                                                value="Supprimer"
                                                className="btn btn-danger"
                                                style={{
                                                    borderStyle: "None",
                                                    width: 100,
                                                    marginRight: 2,
                                                    marginBottom: 2,
                                                }}
                                            />
                                        )}
                                        <input
                                            type="button"
                                            value="Annuler"
                                            className="btn btn-warning"
                                            style={{
                                                borderStyle: "None",
                                                width: 100,
                                                marginBottom: 2,
                                            }}
                                            onClick={() => {
                                                setCurrent(null);
                                                setValues({ ...values, libelle: "" });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </Layout>
    );
};

export default RubriquePaiementLogement;
