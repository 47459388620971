import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { _AuthContext } from "../../context/AuthContext";

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    // defaultValues: { email: "blei@dev.com", password: "password" },
  });

  let navigate = useNavigate();
  const { setAuth } = useContext(_AuthContext);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    localStorage.removeItem("__myProfil");
  }, []);

  const onSubmit = (data) => {
    setIsloading(true);
    axios
      .post("utilisateur/connexion", data)
      .then((res) => {
        const { data: datas } = res;

        const user = {
          nom: datas?.data?.user?.NOM,
          email: datas?.data?.user?.EMAIL,
          id: datas?.data.user?.ID,
        };

        localStorage.setItem("__user", JSON.stringify(user));
        localStorage.setItem("__myTeken", datas.data.token);
        localStorage.setItem("__myProfil", datas?.data?.user?.IDPROFIL);
        localStorage.setItem("__user_email", datas?.data?.user?.EMAIL);
        localStorage.setItem("__user_id", datas?.data?.user?.ID);

        const expired = new Date();
        expired.setDate(expired.getDate() + 1);
        localStorage.setItem("__expired_myTeken", expired);
        reset({ email: "", password: "" });
        setAuth(datas.token);
        setIsloading(false);

        window.location.href = "/";
      })
      .catch((error) => {
        const { data } = error.response;
        setIsloading(false);
        setError("email", { type: "custom", message: data.message });
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  return (
    <div className="login-layout" style={{ height: window.innerHeight - 0.2 }}>
      <div className="main-container">
        <div className="main-content">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="login-container">
                <div className="center">
                  <h1>
                    <span className="red">CATEF</span>
                  </h1>
                  <h4 className="blue" id="id-company-text" />
                </div>
                <div className="space-6" />
                <div className="position-relative">
                  <div
                    id="login-box"
                    className="login-box visible widget-box no-border"
                  >
                    <div className="widget-body">
                      <div className="widget-main">
                        <h4 className="header blue lighter bigger">
                          <i className="ace-icon fa fa-coffee green" />
                          Entrer vos identifiants
                        </h4>
                        <form onSubmit={handleSubmit(onSubmit)} id="form2">
                          <label className="block clearfix">
                            <span className="block input-icon input-icon-right">
                              <input
                                maxLength={50}
                                className="form-control text-center"
                                placeholder="Email"
                                {...register("email", {
                                  required: {
                                    message: "Ce champs est requis",
                                    value: true,
                                  },
                                })}
                              />
                              <i className="ace-icon fa fa-user" />
                            </span>
                            {errors.email?.message && (
                              <span className="text-danger">
                                {errors.email.message}
                              </span>
                            )}
                          </label>
                          <label className="block clearfix">
                            <span className="block input-icon input-icon-right">
                              <input
                                name="TBPASS"
                                type="password"
                                {...register("password", {
                                  required: {
                                    message: "Ce champs est requis",
                                    value: true,
                                  },
                                })}
                                className="form-control text-center"
                                placeholder="Mot de passe"
                              />
                              <i className="ace-icon fa fa-lock" />
                            </span>
                            {errors.password?.message && (
                              <span className="text-danger">
                                {errors.password.message}
                              </span>
                            )}
                          </label>
                          <div className="clearfix">
                            <input
                              type="submit"
                              //   name="BTENR"
                              defaultValue="Connecter"
                              disabled={isLoading}
                              //   id="BTENR"
                              //   tabIndex={3}
                              className="btn btn-primary btn-lg btn-block"
                              style={{ borderStyle: "None" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <SpinnerCircular
                                style={{ color: "#1B6AAA" }}
                                enabled={isLoading}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="toolbar clearfix">
                        <div>
                          <Link to={"/login/client"}>
                            <span className="forgot-password-link">
                              <i className="ace-icon fa fa-arrow-left" />
                              Espace Client
                            </span>
                          </Link>
                        </div>
                        <div>
                          <Link to={"/login/acteur"} >
                            <span className="forgot-password-link">
                              Espace Acteur
                              <i className="ace-icon fa fa-arrow-right" />
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
