import React, { useEffect, useState } from "react";
import { get } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Formulaire from "../../components/Formulaire";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
    getUtilisateurLogements,
    createUtilisateurLogement,
    updateUtilisateurLogement,
    deleteUtilisateurLogement
} from "../../services/TypeUtilisateurLogement";

const TypeUtilisateurLogement = () => {

    const headTable = [
        { name: "Type Utilisateur Logement" },

    ];

    // le current doit etre au dessus de const forms
    const [current, setCurrent] = useState(null);
    const forms = [
        {
            label: "Libelle (*)",
            type: "text",
            name: "libelle",
            values: current?.LIBELLE ?? "",
        },
        // {
        //     label: "Nationalité",
        //     type: "text",
        //     name: "nationnalite",
        //     values: current?.NATIONNALITE ?? "",
        // },
        // {
        //     label: "Taux tva (%)",
        //     type: "text",
        //     name: "taux",
        //     values: current?.TAUXTVA ?? "",
        //     width: "10%",
        // },
    ];
    const queryClient = useQueryClient();

    const [flash, setFlash] = useState({ message: null, color: "success" });

    // pagination code
    let PageSize = 10;
    const [isLoading, setIsLoading] = useState(false);
    const [currentTableData, setCurrentTableData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [totalPage, setTotalPage] = useState(null);
    const page = (currentPage, datas) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        return datas.slice(firstPageIndex, lastPageIndex);
    };

    const onPageChange = (e) => {
        const d = page(parseInt(e.selected + 1), tableData);
        setCurrentTableData(d);
        // console.log(d);
    };
    // fin code pagination


    const fetchUtilisateurLogements = async () => {
        setIsLoading(true);
        getUtilisateurLogements()
            .then((res) => {
                const { data, message } = res;
                const d = page(1, data);
                setTableData(data);
                setCurrentTableData(d);
                const coutPage = parseInt(data.length / PageSize);
                setTotalPage(coutPage);
                setFlash({ ...flash, message: message, color: "success" });
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const { mutate } = useMutation(createUtilisateurLogement, {
        onSuccess: async ({ message }) => {
            // Invalidate and refetch
            setIsLoading(true);
            setFlash({ ...flash, message: message, color: "success" });
            getUtilisateurLogements()
                .then((res) => {
                    const { data, message } = res;
                    const d = page(1, data);
                    setTableData(data);
                    setCurrentTableData(d);
                    const coutPage = parseInt(data.length / PageSize);
                    setTotalPage(coutPage);
                    // setFlash({ ...flash, message: message });

                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        onSettled: (res) => {
            // console.log(res.response.data.message);
            setFlash({
                ...flash,
                message: res.response.data.message,
                color: "danger",
            });
        },
    });

    const { mutate: updateMutate } = useMutation(updateUtilisateurLogement, {
        onSuccess: async ({ data, message }) => {
            // Invalidate and refetch
            setIsLoading(true);
            setFlash({ ...flash, message: message });
            getUtilisateurLogements()
                .then((res) => {
                    const { data, message } = res;
                    const d = page(1, data);
                    setTableData(data);
                    setCurrentTableData(d);
                    const coutPage = parseInt(data.length / PageSize);
                    setTotalPage(coutPage);
                    // setFlash({ ...flash, message: message });

                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        onSettled: (res) => {
            // console.log(res.response.data.message);
            setFlash({
                ...flash,
                message: res.response.data.message,
                color: "danger",
            });
        },
    });

    const postUserLogement = (data) => {
        if (data.libelle.length === 0) {
            setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
            setIsLoading(false);
            return false;
        }
        mutate(data);
    }
    const put = (data) => {
        if (data.libelle.length === 0 || data.id.length === 0) {
            setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
            setIsLoading(false);
            return false;
        }
        updateMutate(data)
    };

    const onclick = (data) => {
        setCurrent(data);
    };
    const onDelete = async () => {
        if (window.confirm("Etre vous sur de vouloir supprimer le type utilisateur de ce logement ?")) {
            const data = { id: current.ID, libelle: current.LIBELLE };
            deleteUtilisateurLogement(data)
                .then(({ data }) => {
                    console.log();
                    setIsLoading(true);
                    setCurrent(null);
                    setFlash({ ...flash, message: data.message });
                    getUtilisateurLogements()
                        .then((res) => {
                            const { data, message } = res;
                            const d = page(1, data);
                            setTableData(data);
                            setCurrentTableData(d);
                            const coutPage = parseInt(data.length / PageSize);
                            setTotalPage(coutPage);
                            // setFlash({ ...flash, message: message });

                            setIsLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((error) => {
                    // console.log(error.response);
                    setIsLoading(true);
                    setCurrent(null);
                    setFlash({
                        ...flash,
                        color: "danger",
                        message: error.response.data.message,
                    });
                    getUtilisateurLogements()
                        .then((res) => {
                            const { data, message } = res;
                            const d = page(1, data);
                            setTableData(data);
                            setCurrentTableData(d);
                            const coutPage = parseInt(data.length / PageSize);
                            setTotalPage(coutPage);
                            // setFlash({ ...flash, message: message });

                            setIsLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        }
    };


    useEffect(() => {
        fetchUtilisateurLogements();
    }, []);
    return (
        <Layout>
            <Formulaire
                message={flash?.message}
                color={flash?.color}
                current={current}
                setCurrent={setCurrent}
                cb={current ? put : postUserLogement}
                forms={forms}
                title="Saisie de Type utilisateur Logement"
                isLoading={isLoading}
                onDelete={onDelete}
            />

            {isLoading ? (
                <Loading isLoading={isLoading} />
            ) : (
                <Table headTable={headTable} title={"Liste de Type utilisateur  Logement"}>
                    {currentTableData?.map((item, key) => (
                        <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
                            {/* <td className="MasquerColonne"></td> */}
                            <td>{item.LIBELLE}</td>
                            <td className="text-center" align="right">
                                {/* <button onClick={() => onclick(item)}> */}
                                <a href="#navbar">
                                    <img
                                        onClick={() => onclick(item)}
                                        // style={{ width: 15, height: 10 }}
                                        src={"/Catef_files/modifier.png"}
                                    />
                                </a>
                                {/* </button> */}
                            </td>
                        </tr>
                    ))}

                    {/* pagination */}
                    <Pagination onPageChange={onPageChange} totalPage={totalPage} />
                </Table>
            )}
        </Layout>
    );
};

export default TypeUtilisateurLogement;