import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const utilisateurs = async (d) => {
  try {
    const { data } = await axios.get("utilisateur/reinit/mdp?search=" + d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const utilisateursActeurs = async (d) => {
  try {
    const { data } = await axios.get("utilisateur/reinit/acteur/mdp?search=" + d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const utilisateursClient = async (d) => {
  try {
    const { data } = await axios.get("utilisateur/reinit/client/mdp?search=" + d, config);
    return data;
  } catch (error) {
    return error;
  }
};
export const reinitUserMdp = async (d) => {
  try {
    const { data } = await axios.post("utilisateur/save/reinit/client/mdp", d, config);
    return data;
  } catch (error) {
    return error;
  }
};







// export const pdf
// edition / etat - client / 105 / selection