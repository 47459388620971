import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Formulaire from "../../components/Formulaire";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";

import {
  createBanque,
  getBanque,
  updateBanque,
  deleteBanque,
} from "../../services/banque";
import Loading from "../../components/Loading";
const Banque = () => {
  const headTable = [
    { name: "Libellé", width: "100%" },
    { name: "", width: "5%" },
  ];
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Libellé",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  // createBanque

  const { mutate } = useMutation(createBanque, {
    onSuccess: ({ message }) => {
      setFlash({ message, color: "success" });
      setIsLoading(true);
      getBanque()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setIsLoading(false);
        })
        .catch(({ response }) => { });
    },
    onSettled: ({ data: { message } }) => {
      setFlash({ color: "success", message });
    },
    onError: ({ response }) => {
      setIsLoading(true);
      getBanque()
        .then((res) => {
          const { data } = res;
          // setFlash({ color: "success", message });
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setIsLoading(false);
          // console.log(data);
        })
        .catch();
      setFlash({ color: "danger", message: response.data.message });
    },
  });

  //  fin de creation createBanque

  // debut updateEntite

  const { mutate: updateMutate } = useMutation(updateBanque, {
    onSuccess: ({ message }) => {
      // Invalidate and refetch
      setFlash({ color: "success", message });
      setIsLoading(true);
      getBanque()
        .then((res) => {
          const { data, message } = res;
          // setFlash({ color: "success", message });
          const d = page(1, data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data);
          setIsLoading(false);
          // console.log(data);
        })
        .catch();
      // queryClient.invalidateQueries("typepieces");
    },
  });

  // fin updateBanque

  const postBanques = (data) => mutate(data);
  const putBanques = (data) => updateMutate(data);
  const onclick = (data) => {
    setCurrent(data);
  };
  const onDelete = async () => {
    if (window.confirm("Etes vous sur de vouloir supprimer?")) {
      const data = { id: current.ID, nom: current.NOM };
      deleteBanque(data)
        .then(({ data }) => {
          console.log();
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getBanque()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          getBanque()
            .then((res) => {
              const { data, message } = res;
              const d = page(1, data);
              setTableData(data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              // setFlash({ ...flash, message: message });

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  // delete

  useEffect(() => {
    setIsLoading(true);
    getBanque()
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setTableData(data);
        setFlash({ message, color: "success" });
        setIsLoading(false);
      })
      .catch(({ response }) => { });
  }, []);

  return (
    <Layout>
      <Formulaire
        forms={forms}
        title={"Gestion des banques"}
        cb={current ? putBanques : postBanques}
        current={current}
        setCurrent={setCurrent}
        color={flash?.color}
        isLoading={isLoading}
        message={flash?.message}
        onDelete={onDelete}
      />

      <Table headTable={headTable} title={"Liste des banques"}>
        {currentTableData?.map((item, key) => (
          <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
            <td>{item?.LIBELLE}</td>
            <td className="text-center" align="right">
              <img
                onClick={() => onclick(item)}
                style={{ width: 15, height: 10 }}
                src={"/Catef_files/modifier.png"}
              />
            </td>
          </tr>
        ))}

        {/* pgination */}

        <tr
          className="pagination-od"
          align="center"
          style={{
            color: "Black",
            backgroundColor: "#EFF3F8",
            fontWeight: "normal",
            whiteSpace: "nowrap",
          }}
        >
          <td colSpan={5}>
            {totalPage != null && (
              <ReactPaginate
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                breakLabel="..."
                nextLabel="suivant >"
                onPageChange={onPageChange}
                pageRangeDisplayed={5}
                pageCount={totalPage + 1}
                previousLabel="< précédent"
                renderOnZeroPageCount={null}
              />
            )}
          </td>
        </tr>
      </Table>
    </Layout>
  );
};

export default Banque;
