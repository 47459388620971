import React from "react";

const Alert = ({ message, color = "success" }) => {
  return (
    <span
      id="ContentPlaceHolder1_LBINFO"
      className={`alert alert-${color} alert-dismissible`}
      style={{
        display: "inline-block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      {message}
    </span>
  );
};

export default Alert;
