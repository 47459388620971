import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const searchValidationPaiementClient = async (d) => {
  try {
    const { data } = await axios.post(
      "finance/get/valid_paie_client",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getValidationPaiementClient = async (d) => {
  try {
    const { data } = await axios.post("finance/get/valid_paie_client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createValidationPaiementClient = async (d) => {
  try {
    const { data } = await axios.post(
      "finance/create/valid_paie_client",
      d,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
