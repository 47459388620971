import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { MultiSelect } from "react-multi-select-component";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Layout from "../../layout/Layout";
import Formulaire from "../../othercomponents/Formulaire";
import { useForm } from "react-hook-form";
import { listChoix, printPdf } from "../../services/edition/etat-acteur-client";
import Loading from "../../components/Loading";
import { pathUrl } from "../..";


const EtatClient = () => {

  const [agents, setAgents] = useState([]);
  const [selected, setSelected] = useState([]);
  const [choix, setchoix] = useState([]);
  const [operation, setOperation] = useState([]);
  const [sous_form, setSousForm] = useState(false);
  const [sous_form2, setSousForm2] = useState(false);       
  const [sous_form3, setSousForm3] = useState(false);       
  const [isLoading, setIsLoading] = useState(false);


  const { register, handleSubmit, formState: { isSubmitting }, setValue, reset } = useForm();

  // const titleForm = "Les listes";

  const [stateForm, setStateForm] = useState({
    idchoix: "",
    idoperation: [],
    debut: "",
    fin: "",
    idacteur: "",
    nb1: "",
    nb2: ""
  })

  const btnStyle = [
    {value: "Imprimer", type:"submit", className: "btn-danger"},
  ];

  const fetchChoix = () => {
    listChoix()
      .then(({data}) => {
          let choices = [];
          data?.operations.forEach((element) => {
            let choice = {label: element.LIBELLE, value: element.ID};
            choices.push(choice)
          });
          console.log(choices);
          setchoix(() => data?.elements);
          setOperation(() => choices);
          setAgents(() => data?.agents);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const sousFormVisible = (id_choix) => {
    let visible = id_choix === "109" || id_choix === "118" ?
      true : false;
    let visible2 = id_choix === "118" ?
      true : false;
    let visible3 = id_choix === "105" ?
      true : false;
    setSousForm(() => visible);
    setSousForm2(() => visible2);
    setSousForm3(() => visible3);
  }

  const onChangeChoix = (e) => {
    e.preventDefault();
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
    sousFormVisible(e.target.value)
  }

  const onchange = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    setStateForm({ ...stateForm, [e.target.name]: e.target.value });
  }

  const onSubmit = () => {
    if(stateForm.idchoix.length == 0 ){
      return false;
    }
    if((stateForm.idchoix == 118 &&  selected.length == 0)&& (stateForm.idacteur.length == 0 &&  selected.length == 0)){
     return false;
    }
    if((stateForm.idchoix == 109 ||  stateForm.idchoix == 118)){
      if(stateForm.debut.length == 0 || stateForm.fin.length == 0 ||  selected.length == 0){
        return false;
      }
    }
    if((stateForm.idchoix == 105)){
      if(stateForm.nb1.length == 0 || stateForm.nb2.length == 0 ||  selected.length == 0){
        return false;
      }
      let diff = stateForm.nb2 - stateForm.nb1;
      console.log(diff)
      if(diff < 0 || diff > 100){
        return false;
      }
    }
    setIsLoading(true);
    let operations = [];
    selected.forEach((operation)=>{
      operations.push(operation.value)
    })
    let data = {choix: stateForm.idchoix,operations: operations, date_debut: stateForm.debut, date_fin: stateForm.fin,
       idacteur: stateForm.idacteur,start: stateForm.nb1, end: stateForm.nb2};
    printPdf(data)
         .then((response) => {
          setIsLoading(false);
          if (response.success) {
            let dateCache = new Date()
            const source = pathUrl + response.data+"?"+dateCache.getTime();
            window.open(source, '_blank');
          } else {
            alert('Impossible de generer le rapport.Veuilez réessayer')
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err);
        });
  }
  useEffect(() => {
    fetchChoix();
  },[])
  
  return (
    
    <Layout>
    <div className="row">
      <div className="col-xs-2" />
      <form 
      onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-horizontal col-xs-8 ui-sortable">
          <div className={`widget-box widget-color-blue`}>
            {/* Entete du cadre */}
            <div className="widget-header ui-sortable-handle">
              <h5 className="widget-title">Les etats client</h5>
            </div>

            {/* Contenu du cadre */}
            <div className="widget-body">
              <div className="widget-main">
                {/* {message !== null && <Alert color={color} message={message} />} */}
                <span id="ContentPlaceHolder1_LBINFO" style={{ display: "inline-block", width: "100%", textAlign: "center" }}/>
               
                <label className="block clearfix">
                  <label htmlFor="text" className="col-lg-3">Choix</label>
                 <div class="col-lg-8">
                    <select {...register('idchoix')} onChange={onChangeChoix} className=" form-control input-xxlarge">
                        <option value="" />
                        {choix?.map((t, i) => (
                          <option key={i} value={t.ID}>{t.TITRE}</option>
                        ))}
                      </select>
                 </div>
                </label>
                {sous_form &&
                  <>
                  <span className="text-center">Période</span>
                    
                    <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">Du</label>
                          <div class="col-lg-5">
                              <input 
                                name="debut"
                                onChange={onchange}
                                type="date"
                                className="mode input-small- date-picker text-center "
                                data-date-format={"dd/mm/yyyy"}
                                placeholder=""
                              />
                          </div>
                        </span>
                    </label>
                    <label className="block clearfix">
                      <span className="block">
                        <label htmlFor="text" className="col-lg-3">Au</label>
                         <div class="col-lg-5">
                          <input
                            name="fin"
                            onChange={onchange}
                            type="date"
                            data-date-format="dd/mm/yyyy"
                            className="mode input-small- date-picker text-center "
                            placeholder=""
                          />
                         </div>
                      </span>
                    </label>
                  </>
                }
                {sous_form3 &&
                  <>
                  <span className="text-center">Nombre</span>
                    
                    <label className="block clearfix">
                        <span className="block">
                          <label htmlFor="text" className="col-lg-3">De</label>
                          <div class="col-lg-5">
                              <input 
                                name="nb1"
                                onChange={onchange}
                                type="number"
                                className="mode input-small-  text-center "
                                placeholder=""
                              />
                          </div>
                        </span>
                    </label>
                    <label className="block clearfix">
                      <span className="block">
                        <label htmlFor="text" className="col-lg-3">Au</label>
                        <div class="col-lg-5">
                              <input 
                                name="nb2"
                                onChange={onchange}
                                type="number"
                                className="mode input-small-  text-center "
                                placeholder=""
                              />
                          </div>
                      </span>
                    </label>
                  </>
                }
                <label className="block clearfix">
                  <label htmlFor="text" className="col-lg-3">Opération</label>
                  {/* <select multiple="true" id="idoperation" {...register('idoperation')} onChange={onChangeOperation} className="form-control input-xxlarge">
                    <option value="" />
                    {operation?.map((t, i) => (
                      <option key={i} value={t.ID}>{t.LIBELLE}</option>
                    ))}
                  </select> */}
                  <MultiSelect
                    options={operation}
                    value={selected}
                    className="col-lg-8"
                    hasSelectAll={true}
                    onChange={setSelected}
                    labelledBy="Opération"
                    overrideStrings = {{
                      allItemsAreSelected: "Toutes les opérations sont selectionnées",
                      clearSearch: "Supprimer la recherche",
                      clearSelected: "Supprimer lopération selectionnée",
                      noOptions: "Aucun élément",
                      search: "Recherche",
                      selectAll: "Cocher Tout",
                      selectAllFiltered: "Cocher Tout",
                      selectSomeItems: "Selectionnez une ou plusieurs opération...",
                    }}
                  />
                </label>
                {sous_form2 &&
                  <label className="block clearfix">
                  <label htmlFor="text" className="col-lg-3">Choix</label>
                  <div class="col-lg-5">
                    <select {...register('idacteur')} onChange={onchange} className="form-control input-xxlarge ">
                      <option value="" />
                      {agents?.map((t, i) => (
                        <option key={i} value={t.ID}>{t.NOM}</option>
                      ))}
                    </select>
                  </div>
                </label>
                }
              </div>
              {/* Pied du cadre */}
              <div className="widget-toolbox padding-8 clearfix">
                <div className="pull-right">
                  {btnStyle.map((btn, key) => {
                    return (<input
                      key={key}
                      type={`${btn.type}`}
                      value={`${btn.value}`}
                      // disabled={isLoading}
                      className={`btn ${btn.className}`}
                      style={{ borderStyle: "None", width: 100, marginRight: 2, marginBottom: 2 }}
                      // onClick={ btn.value === "Annuler" && (() => {reset(); setCurrent(null)})}
                   />)                  
                  })}
                </div>
              </div>
              {isLoading && <Loading isLoading={isLoading} />}
            </div>
          </div>
        </div>
      </form>
    </div>
    </Layout>
  );
};

export default EtatClient;
