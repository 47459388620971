import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const createEntite = async (d) => {
  try {
    const { data } = await axios.post("setting/create/entite", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateEntite = async (d) => {
  try {
    const { data } = await axios.post("setting/update/entite", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getEntite = async () => {
  try {
    const { data } = await axios.get("setting/list/entite", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteEntite = async (data) =>
  await axios.post("setting/delete/entite", data, config);
