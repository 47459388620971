import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Formulaire from "../../components/Formulaire";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import { createObtentionFonciere, deleteObtentionFonciere, getObtentionFonciere, updateObtentionFonciere } from "../../services/obtentionFoncieres.js";

const TypeFoncier = () => {
  const headTable = [{ name: "libellé (*)" }, { name: "" }];

  // le current doit etre au dessus de const forms
  const [current, setCurrent] = useState(null);
  const forms = [
    {
      label: "Profil",
      type: "text",
      name: "libelle",
      values: current?.LIBELLE ?? "",
    },
  ];
  const queryClient = useQueryClient();

  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const fetchObtentionFonciere = async () => {
    setIsLoading(true);
    getObtentionFonciere()
      .then(({ data }) => {
        const d = page(1, data.data);
        setTableData(data);
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setFlash({ ...flash, message: data.message, color: "success" });
        // console.log(data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        setFlash({ ...flash, message: response.data.message, color: "danger" });
        // console.log(response);
      });
  };

  const { mutate } = useMutation(createObtentionFonciere, {
    onSuccess: async ({ message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: message, color: "success" });
      getObtentionFonciere()
        .then((res) => {
          const { data, message } = res;
          const d = page(1, data);
          setTableData(data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      // console.log(res.response.data.message);
      setFlash({
        ...flash,
        message: res.response.data.message,
        color: "danger",
      });
    },
  });

  const { mutate: updateMutate } = useMutation(updateObtentionFonciere, {
    onSuccess: async ({ data, message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: message });
      getObtentionFonciere()
        .then((res) => {
          const { data } = res.data;
          console.log(data)
          const d = page(1, data);
          setTableData(data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      console.log(res.data.message);
      setFlash({
        ...flash,
        message: res.data.message,
        color: "danger",
      });
    },
  });

  const { mutate: deleteMutate } = useMutation(deleteObtentionFonciere, {
    onSuccess: async ({ data, message }) => {
      // Invalidate and refetch
      setIsLoading(true);
      setFlash({ ...flash, message: message });
      getObtentionFonciere()
        .then((res) => {
          const { data } = res.data;
          console.log(data)
          const d = page(1, data);
          setTableData(data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.length / PageSize);
          setTotalPage(coutPage);
          // setFlash({ ...flash, message: message });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onSettled: (res) => {
      console.log(res.data.message);
      setFlash({
        ...flash,
        message: res.data.message,
        color: "danger",
      });
    },
  });

  const postObtentionFonciere = (data) => mutate(data);
  const putObtentionFonciere = (data) => updateMutate(data);
  const delObtentionFonciere = (data) => deleteMutate(data);

  const onclick = (data) => {
    setCurrent(data);
  };

 

  useEffect(() => {
    fetchObtentionFonciere();
  }, []);
  return (
    <Layout>
      <Formulaire
        message={flash?.message}
        color={flash?.color}
        current={current}
        setCurrent={setCurrent}
        cb={current ? putObtentionFonciere : postObtentionFonciere}
        forms={forms}
        title="Gestion des documents à fournir par le client"
        isLoading={isLoading}
        onDelete={delObtentionFonciere}
      />

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table headTable={headTable} title={"Liste des Documents"}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              {/* <td className="MasquerColonne"></td> */}
              <td>{item.LIBELLE}</td>
              <td className="text-center" align="right">
                {/* <button onClick={() => onclick(item)}> */}
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // className="img img-fluid"
                    style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
                {/* </button> */}
              </td>
            </tr>
          ))}

          {/* pagination */}
          <Pagination onPageChange={onPageChange} totalPage={totalPage} />
        </Table>
      )}
    </Layout>
  );
};

export default TypeFoncier;
