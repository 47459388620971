import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};

export const getBanque = async () => {
  try {
    const { data } = await axios.get("setting/list/banque", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createBanque = async (d) => {
  try {
    const { data } = await axios.post("setting/create/banque", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateBanque = async (d) => {
  try {
    const { data } = await axios.post("setting/update/banque", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteBanque = async (data) =>
  await axios.post("setting/delete/banque", data, config);
