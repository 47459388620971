import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useMutation, useQueryClient } from "react-query";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import Layout from "../../layout/Layout";
import {
  createRubriquePaiement,
  getRubriquePaiements,
  updateRubriquePaiement,
  deleteRubriquePaiements
} from "../../services/rubriquePaiement";
import { getTypeActeurs } from "../../services/typeActeur";

const RubriquePaiement = () => {
  const headTable = [
    { name: "Libellé", width: "95%" },
    { name: "", with: "5%" },
  ];

  const [rubriques, setRubrique] = useState([]);
  const [typeActeur, setTypeActeur] = useState([]);
  const [current, setCurrent] = useState(null);
  const [option, setOption] = useState("fixe");
  const [values, setValues] = useState({
    idtacteurfoncier: "",
    libelle: "",
    option: "",
    taux: "0.00",
  });

  const fetchTypeActeurs = () => {
    setIsLoading(true);
    getTypeActeurs()
      .then(({ data, message }) => {
        setTypeActeur(data);
        setIsLoading(false);
      })
      .catch();
  };



  const [flash, setFlash] = useState({ message: null, color: "success" });

  // pagination code
  let PageSize = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalPage, setTotalPage] = useState(null);
  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSelectTypeActeur = (e) => {
    setCurrentTableData([]);
    setValues({ ...values, idtacteurfoncier: e.target.value });
    getRubriquePaiements(e.target.value)
      .then((res) => {
        const { data, message } = res;
        const d = page(1, data);
        // setRubrique(data)
        setCurrentTableData(d);
        const coutPage = parseInt(data.length / PageSize);
        setTotalPage(coutPage);
        setTableData(data);
        setFlash({ ...flash, message, color: "success" });
        // setRubrique(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    if (current) {
      let data = { ...values, id: current?.ID };
      if (data.idtacteurfoncier.length === 0 || data.libelle.length === 0 || data.option.length === 0 || data.id.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      updateRubriquePaiement(data).then((res) => {
        setFlash({ ...flash, message: res.message, color: "success" });
        setIsLoading(false);
        getRubriquePaiements(values.idtacteurfoncier)
          .then((res) => {
            const { data, message } = res;
            const d = page(1, data);
            setCurrentTableData(d);
            const coutPage = parseInt(data.length / PageSize);
            setTotalPage(coutPage);
            setTableData(data);
            setFlash({ ...flash, message, color: "success" });
            // setRubrique(data);
          })
          .catch((err) => {
            console.log(err);
          });
        setCurrent(null);
        setValues({ ...values, libelle: ""  });
      });
    } else {
      if (values.idtacteurfoncier.length === 0 || values.libelle.length === 0 || values.option.length === 0) {
        setFlash({ message: "Veuillez saisir les champs requis", color: "danger" });
        setIsLoading(false);
        return false;
      }
      createRubriquePaiement(values)
        .then((res) => {
          setFlash({ ...flash, message: res.data.message, color: "success" });
          setValues({ ...values, idtacteurfoncier: "", libelle: "", option: "" });
          setIsLoading(false);
          getRubriquePaiements(values.idtacteurfoncier)
            .then((res) => {
              console.log(res);
              const { data } = res;
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setTableData(data);
              setFlash({ ...flash, message: "Liste des rubriques de paiements", color: "success" });
              setIsLoading(false);
              setValues({ ...values, libelle: "", option: "" });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((res) => {
          //  console.log(err)
          setFlash({ message: res.data.message, color: "danger" });
        });
    }
  };

  const [_idActeurfoncier, setIdActeurfoncier] = useState(null);
  const onclick = (data) => {
    // console.log(data);
    setCurrent(data);
    setIdActeurfoncier(data.IDACTEURFONCIER);
    setValues({ ...values, libelle: data.LIBELLE, option: data.OPTIONCALCUL, taux: data.TAUX });
  };
  console.log(values)


  //delete rubrique paiement

  const onDelete = async () => {
    if (window.confirm("Etre vous sur de vouloir supprimer ")) {
      const data = { id: current.ID, libelle: current.LIBELLE };
      deleteRubriquePaiements(data)
        .then(({ data }) => {
          setIsLoading(true);
          setCurrent(null);
          setFlash({ ...flash, message: data.message });
          getRubriquePaiements(values.idtacteurfoncier)
            .then((res) => {
              console.log(res);
              const { data } = res;
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setTableData(data);
              setFlash({ ...flash, message: "Liste des rubriques de paiements", color: "success" });
              setIsLoading(false);
              setValues({ ...values, libelle: "", option: "" });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          // console.log(error.response);
          setIsLoading(true);
          setCurrent(null);
          setFlash({
            ...flash,
            color: "danger",
            message: error.response.data.message,
          });
          getRubriquePaiements(values.idtacteurfoncier)
            .then((res) => {
              console.log(res);
              const { data } = res;
              const d = page(1, data);
              setCurrentTableData(d);
              const coutPage = parseInt(data.length / PageSize);
              setTotalPage(coutPage);
              setTableData(data);
              setFlash({ ...flash, message: "Liste des rubriques de paiements", color: "success" });
              setIsLoading(false);
              setValues({ ...values, libelle: "", option: "" });
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
  // fin rubrique paiement
  useEffect(() => {
    fetchTypeActeurs();
  }, []);
  return (
    <Layout>
      {/* Formulaire */}
      <div className="row">
        <div className="col-xs-2" />
        <form onSubmit={handleSubmit}>
          <div className="form-horizontal col-xs-8 ui-sortable">
            <div className="widget-box widget-color-blue">
              {/* Entete du cadre */}
              <div className="widget-header ui-sortable-handle">
                <h5 className="widget-title">
                  Saisie des rubriques de paiement
                </h5>
              </div>
              {/* Contenu du cadre */}
              <div className="widget-body">
                <div className="widget-main">
                  {flash.message !== null && (
                    <Alert color={flash.color} message={flash.message} />
                  )}

                  <span
                    id="ContentPlaceHolder1_LBINFO"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  />

                  {/* code form */}
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Type acteur(*)
                    </label>
                    <select
                      name="idtacteurfoncier"
                      className="form-control input-xxlarge"
                      onChange={onSelectTypeActeur}
                    >
                      <option value="-1"></option>
                      {typeActeur?.map((item, key) => (
                        <option key={key} value={item.ID}>
                          {item.LIBELLE}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Libellé(*)
                    </label>
                    <input
                      value={values.libelle}
                      onChange={handleChange}
                      name="libelle"
                      type="text"
                      className="form-control input-xxlarge"
                    />
                  </label>

                  <label className="block clearfix">
                    <label htmlFor="text" className="col-lg-2">
                      Option(*)
                    </label>
                    <select
                      name="option"
                      className="form-control input-xxlarge"
                      onChange={(e) => {
                        setOption(e.target.value);
                      }}
                    >
                      <option value={"fixe"}>
                        Le calcul se fait sur une valeur fixe
                      </option>
                      <option value={"variable"}>
                        Le calcul se fait sur le pourcentage du coût de cession
                        du logement
                      </option>
                    </select>

                    {option === "variable" && (
                      <span className="block clearfix" style={{ marginTop: 5 }}>
                        <label htmlFor="text" className="col-lg-2">
                          Taux(%)
                        </label>
                        <input
                          name="option"
                          type="text"
                          value={values.option}
                          onChange={handleChange}
                          className="form-control input-small text-center"
                        />
                      </span>
                    )}
                  </label>
                </div>
                {/* Pied du cadre */}
                <div className="widget-toolbox padding-8 clearfix">
                  <div className="pull-right">
                    <input
                      type="submit"
                      value="Enregistrer"
                      className="btn btn-success"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginRight: 2,
                        marginBottom: 2,
                      }}
                    />

                    {current && (
                      <input
                        type="button"
                        onClick={onDelete}
                        value="Supprimer"
                        className="btn btn-danger"
                        style={{
                          borderStyle: "None",
                          width: 100,
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <input
                      type="button"
                      value="Annuler"
                      className="btn btn-warning"
                      style={{
                        borderStyle: "None",
                        width: 100,
                        marginBottom: 2,
                      }}
                      onClick={() => {
                        setCurrent(null);
                        setValues({ ...values, libelle: "" });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/*  liste */}

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Table headTable={headTable} title={"Liste des rubriques de paiement"}>
          {currentTableData?.map((item, key) => (
            <tr key={key} style={{ color: "Black", backgroundColor: "White" }}>
              <td>{item.LIBELLE}</td>
              <td className="text-center" align="right">
                <a href="#navbar">
                  <img
                    onClick={() => onclick(item)}
                    // style={{ width: 15, height: 10 }}
                    src={"/Catef_files/modifier.png"}
                  />
                </a>
              </td>
            </tr>
          ))}

          {/* pagination */}
          <tr
            className="pagination-od"
            align="center"
            style={{
              color: "Black",
              backgroundColor: "#EFF3F8",
              fontWeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            <td colSpan={5}>
              {totalPage != null && (
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="suivant >"
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPage + 1}
                  previousLabel="< précédent"
                  renderOnZeroPageCount={null}
                />
              )}
            </td>
          </tr>
          {/* <Pagination onPageChange={onPageChange} totalPage={totalPage} /> */}
        </Table>
      )}
    </Layout>
  );
};

export default RubriquePaiement;
