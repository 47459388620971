import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};

export const getChoix = async () => {
  try {
    const { data } = await axios.get("edition/get/list_choix", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getClient = async () => {
  try {
    const { data } = await axios.get("edition/get/list_client", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getDossier = async () => {
  try {
    const { data } = await axios.get("edition/get/list_dossier", config);
    return data;
  } catch (error) {
    return error;
  }
};

// //////////////////////////////////////////////////

/**
 *
 * @returns liste des choix pour l'impression
 */
export const listeChoix = async () => {
  return await axios.get("edition/liste", config);
};

/**
 * @host http://url//{id_item}/details
 * @method post
 * @param id_item
 * @description Si id_item == 116, vous devez envoyer id_client(post)
 * @description si id_item == 117, vous devez envoyer id_dossier(post)
 * @returns data[]
 */
export const printingListe = async (id_item) => {
  const data = {};
  return await axios.post("edition/" + id_item + "/details", data, config);
};
