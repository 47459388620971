import axios from "axios";

let config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("__myTeken")}`,
  },
};
export const searchPaiement = async () => {
  try {
    const { data } = await axios.get("finance/search/paie_client", config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getDossier = async (d) => {
  try {
    const { data } = await axios.post("finance/get/dossier", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const invoice = async (d) => {
  try {
    const { data } = await axios.post("finance/invoice/paie_client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const invoicePdf = async (d) => {
  try {
    const { data } = await axios.post("finance/invoice/paie_client/pdf", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const createPaiementClient = async (d) => {
  try {
    const { data } = await axios.post("finance/create/paie_client", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getSolde = async (d) => {
  try {
    const { data } = await axios.post("finance/get/solde/dossier", d, config);
    return data;
  } catch (error) {
    return error;
  }
};
