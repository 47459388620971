import axios from "axios";

let config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("__myTeken")}` },
};
export const createRubriquePaiement = async (d) => {
  try {
    const { data } = await axios.post("setting/create/rpaiement", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const updateRubriquePaiement = async (d) => {
  try {
    const { data } = await axios.post("setting/update/rpaiement", d, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const getRubriquePaiements = async (d) => {
  try {
    const { data } = await axios.post("setting/list/rpaiement", { idtacteurfoncier: d }, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteRubriquePaiements = async (d) => {
  return await axios.post("setting/delete/rpaiement", d, config);
};
