/**
 * @description liste => pays
 * @returns jsx
 */

import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { FooterPDF, HeaderPDF } from "../../../components/headerAndFooterOfPDF";

const Pays = ({ title, printing }) => {
  const componentRef = useRef();

  const _title = <div>{title}</div>;

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="btn btn-primary" style={{ marginBottom: 10 }}>
            Imprimer
          </button>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }} className="row">
        <div className="col-md-8" ref={componentRef}>
          <div
            style={{
              height: "26cm",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div id="longueur">
              <HeaderPDF title={_title} />
              <div style={{ marginTop: 20 }}>
                <table
                  border={1}
                  style={{ width: "100%", border: "1px solid black" }}
                >
                  <tr style={{ textAlign: "center" }}>
                    <td>NOM</td>
                    <td> NATIONALITE</td> <td>%TVA</td>
                  </tr>
                  {printing?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.NOM}</td>
                        <td>{item.NATIONALITE}</td>
                        <td>{item.TAUXTVA}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            <FooterPDF />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pays;
