import React, { useState } from "react";
import { useEffect } from "react";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Layout from "../../layout/Layout";
import {
  createAffectation,
  listeActeur,
  listeGrille,
  listeOperateur,
  listeOperationImmo,
  listeTypeActteur,
} from "../../services/affectationDossier";

const AffectationDossiers = () => {
  const [grilles, setGrilles] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [choiceAll, setChoiceAll] = useState(false);
  const [typeActeurs, setTypeActeurs] = useState([]);
  const [acteurs, setActeurs] = useState([]);
  const [operateurs, setOperateurs] = useState([]);
  const [operations, setOperations] = useState([]);
  const [flash, setFlash] = useState({ message: null, color: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({
    idoperateur: "",
    idoperation: "",
    idtacteur: "",
    idacteur: "",
    listdoss: [
      // {
      //   id: 1044,
      // },
    ],
  });

  const onChange = (e) => {
    e.preventDefault();
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const onSelectOperateur = (e) => {
    setIsLoading(true);
    setValue({ ...value, idoperateur: e.target.value });
    setOperations([]);
    listeOperationImmo(e.target.value)
      .then(({ data }) => {
        setOperations(data.data);
        setFlash({ message: null });
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setFlash({ color: "danger", message: response.data.message });
        setIsLoading(false);
      });
  };

  const onSelectOperation = (e) => {
    setIsLoading(true);
    setTypeActeurs([]);
    setValue({ ...value, idoperation: e.target.value });

    listeGrille(e.target.value).then(({ data }) => {
      const d = page(1, data.data);
      setCurrentTableData(d);
      const coutPage = parseInt(data.data.length / PageSize);
      setTotalPage(coutPage);
      setTableData(data.data);
      setGrilles(data.data);
      const checked = new Array(data.data.length).fill(false);
      setCheckedState(checked);
    });

    listeTypeActteur()
      .then(({ data }) => {
        setTypeActeurs(data.data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setFlash({ color: "danger", message: response.data.message });
        setIsLoading(false);
      });
  };

  const onSelectTypeActeur = (e) => {
    setIsLoading(true);
    setActeurs([]);
    setValue({ ...value, idtacteur: e.target.value });

    listeGrille(value.idoperation, e.target.value).then(({ data }) => {
      const d = page(1, data.data);
      setCurrentTableData(d);
      const coutPage = parseInt(data.data.length / PageSize);
      setTotalPage(coutPage);
      setTableData(data.data);
      setGrilles(data.data);

      const checked = new Array(data.data.length).fill(false);
      setCheckedState(checked);
    });

    listeActeur(e.target.value)
      .then(({ data }) => {
        setActeurs(data.data);
        setIsLoading(false);
      })
      .catch();
  };

  const fecthOperateur = () => {
    setIsLoading(true);
    listeOperateur()
      .then(({ data }) => {
        setOperateurs(data.data);
        setIsLoading(false);
      })
      .catch(({ response }) => { });
  };

  // pagination code
  let PageSize = 10;

  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);

  const page = (currentPage, datas) => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return datas.slice(firstPageIndex, lastPageIndex);
  };

  const onPageChange = (e) => {
    const d = page(parseInt(e.selected + 1), tableData);
    setCurrentTableData(d);
    // console.log(d);
  };
  // fin code pagination

  const onChecked = (current, i) => {
    const updatedCheckedState = checkedState.map((check, index) =>
      index === i ? !check : check
    );

    setCheckedState(updatedCheckedState);
    setChoiceAll(false);

    const copy = [...value.listdoss];
    const find = copy.find((liste) => liste.id == current.ID);
    if (typeof find !== "undefined") {
      setChoiceAll(false);
      const update = copy.filter((liste) => liste.id != current.ID);
      setValue({
        ...value,
        listdoss: [...update],
      });
    } else {
      const add = { id: current.ID };
      copy.push(add);
      setValue({
        ...value,
        listdoss: [...copy],
      });
    }
  };

  const onChechedAll = (e) => {
    const copy = [...value.listdoss];
    setChoiceAll(e.target.checked);

    if (e.target.checked) {
      const check = new Array(tableData.length).fill(true);
      setCheckedState(check);
      for (const item of tableData) {
        let find = copy.find((liste) => liste.id == item.ID);
        if (typeof find !== "undefined") {
          let update = copy.filter((liste) => liste.id != item.ID);
          setValue({
            ...value,
            listdoss: [...update],
          });
        } else {
          let add = { id: item.ID };
          copy.push(add);
          setValue({
            ...value,
            listdoss: [...copy],
          });
        }
      }
    } else {
      const check = new Array(tableData.length).fill(false);
      setCheckedState(check);
      setValue({
        ...value,
        listdoss: [],
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(value);
    setIsLoading(true);
    createAffectation(value)
      .then(({ data }) => {
        // console.log(data);
        setFlash({ message: data.message, color: "success" });
        setValue({ ...value, listdoss: [] });
        const check = new Array(tableData.length).fill(false);
        setCheckedState(check);
        listeGrille(value.idoperation, value.idtacteur).then(({ data }) => {
          const d = page(1, data.data);
          setCurrentTableData(d);
          const coutPage = parseInt(data.data.length / PageSize);
          setTotalPage(coutPage);
          setTableData(data.data);
          setGrilles(data.data);
          setIsLoading(false);
        });
      })
      .catch(({ response }) => {
        setFlash({ message: response.data.message, color: "danger" });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fecthOperateur();
  }, []);
  return (
    <Layout>
      <div className="col-xs-1" />
      <div className="form-horizontal col-xs-10 ui-sortable">
        <div className="widget-box widget-color-blue">
          {/* Entete du cadre */}
          <div className="widget-header ui-sortable-handle">
            <h5 className="widget-title">
              Affectation des dossiers aux acteurs
            </h5>
          </div>
          {/* Contenu du cadre */}
          <div className="widget-body">
            <div className="widget-main">
              {flash?.message && (
                <Alert message={flash?.message} color={flash?.color} />
              )}
              <label className="block clearfix">
                <label htmlFor="text" className="col-lg-2">
                  Opérateur
                </label>
                <select
                  name="idoperateur"
                  onChange={onSelectOperateur}
                  tabIndex={1}
                  className="form-control input-xxlarge"
                >
                  <option value={""} />
                  {operateurs?.map((item, key) => (
                    <option key={key} value={item.ID}>
                      {item.LIBELLE}
                    </option>
                  ))}
                </select>
              </label>
              <label className="block clearfix">
                <label htmlFor="text" className="col-lg-2">
                  Opération
                </label>
                <select
                  name="idoperation"
                  onChange={onSelectOperation}
                  id="ContentPlaceHolder1_CBOPERATION"
                  tabIndex={2}
                  className="form-control input-xxlarge"
                >
                  <option value={""} />
                  {operations?.map((item, key) => (
                    <option key={key} value={item.ID}>
                      {item.LIBELLE}
                    </option>
                  ))}
                </select>
              </label>
              <label className="block clearfix">
                <label htmlFor="text" className="col-lg-2">
                  Type acteur
                </label>
                <select
                  name="idtacteur"
                  onChange={onSelectTypeActeur}
                  tabIndex={3}
                  className="form-control input-xxlarge"
                >
                  <option value={""} />
                  {typeActeurs?.map((item, key) => (
                    <option key={key} value={item.ID}>
                      {item.LIBELLE}
                    </option>
                  ))}
                </select>
              </label>
              <label className="block clearfix">
                <label htmlFor="text" className="col-lg-2">
                  Acteur
                </label>
                <select
                  onChange={onChange}
                  name="idacteur"
                  tabIndex={4}
                  className="form-control input-xxlarge"
                >
                  <option value={""} />
                  {acteurs?.map((item, key) => (
                    <option key={key} value={item.ID}>
                      {item.NOM}
                    </option>
                  ))}
                </select>
              </label>
              <label className="block clearfix">
                <span className="block">
                  <div>
                    <table
                      cellSpacing={0}
                      rules="all"
                      className="table-header"
                      border={1}
                      id="ContentPlaceHolder1_GV"
                      style={{
                        borderColor: "#E1E1E1",
                        fontSize: "10pt",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr
                          align="center"
                          valign="middle"
                          style={{
                            color: "#707070",
                            backgroundColor: "#F1F1F1",
                            fontWeight: "normal",
                          }}
                        >
                          <th
                            className="MasquerColonne"
                            scope="col"
                            style={{ width: 90 }}
                          >
                            Code
                          </th>
                          <th scope="col" style={{ width: 80 }}>
                            Date
                          </th>
                          <th scope="col">Client</th>
                          <th scope="col" style={{ width: 110 }}>
                            N° de compte
                          </th>
                          <th scope="col" style={{ width: 110 }}>
                            Numéro dossier
                          </th>
                          <th scope="col" style={{ width: 80 }}>
                            Numéro lot
                          </th>
                          <th scope="col">Type de logement</th>
                          <th scope="col" style={{ width: 30 }}>
                            &nbsp;
                          </th>
                        </tr>

                        {currentTableData?.map((item, i) => {
                          return (
                            <tr
                              key={i}
                              style={{
                                color: "Black",
                                backgroundColor: "White",
                              }}
                            >
                              <td className="MasquerColonne"></td>
                              <td>{item?.DATE}</td>
                              <td>{item?.CLIENT}</td>
                              <td>{item?.NCPTECOMPTABLE}</td>
                              <td>{item?.NUMDOSSIER}</td>
                              <td>{item?.NUMLOT}</td>
                              <td>{item?.TYPELOGEMENT}</td>
                              <td className="ace text-center">
                                <input
                                  type="checkbox"
                                  checked={checkedState[i]}
                                  name=""
                                  onChange={() => onChecked(item, i)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                        {/* Pagination */}
                        <Pagination
                          onPageChange={onPageChange}
                          totalPage={totalPage}
                        />
                      </tbody>
                    </table>
                    <span className="ace">
                      <input
                        id="all"
                        type="checkbox"
                        name=""
                        checked={choiceAll}
                        onClick={onChechedAll}
                        tabIndex={5}
                      />
                      <label htmlFor="all">Cocher tout</label>
                    </span>
                  </div>
                </span>
              </label>
            </div>
            <div className="widget-toolbox padding-8 clearfix">
              <div className="pull-right">
                <input
                  onClick={onSubmit}
                  type="submit"
                  value="Affecter"
                  tabIndex={6}
                  className="btn btn-success"
                  style={{ borderStyle: "None", width: 120 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <Loading isLoading={isLoading} /> : <></>}
    </Layout>
  );
};

export default AffectationDossiers;
